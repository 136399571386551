import { connect } from "react-redux";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import "./layout.css";
import React from "react";

class HomeUserDashboardLayout extends React.Component {
  render() {
    return (
      <>
        <Navbar className="container-fluid" type="dashboard" />
        <div className="home-dashboard-layout-container ">
          <Sidebar {...this.props} />
          <div className="dashboard-layout">{this.props.children}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(HomeUserDashboardLayout);
