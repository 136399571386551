import React from "react";
import { Send } from "lucide-react";
import { Loading } from "../Loader";

const MessageInput = ({ value, onChange, onSend, isSending, setIsSending }) => {
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      setIsSending(true);
      onSend(value);
    }
  };

  return (
    <div className="p-3 border-top">
      <div className="input-group">
        <input
          type="text"
          className="form-control border-end-0"
          placeholder="Type a message..."
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button
          className="btn btn-primary d-flex align-items-center"
          onClick={() => onSend(value)}
          disabled={!value.trim()}
        >
          {isSending ? <Loading size={18} /> : <Send size={18} />}
        </button>
      </div>
    </div>
  );
};

export default MessageInput;
