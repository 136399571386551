import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import { apiInstance } from "../axiosInstance";

export const fetchFarmers = async (lng = "english", params = {}) => {
  let { page, limit, active = undefined, id } = params;
  try {
    const user = await getStorage();

    const { accessRole } = user;

    const canRead = defineRole({
      roles: accessRole,
      menu: "Farmers",
      operation: "read",
    });

    if (!canRead) return;

    const options = {
      method: "GET",
      url: `/user`,
      params: {
        page: page,
        limit: limit,
        active,
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return {
      data,
      count,
    };
  } catch (error) {
    console.log(error);
    toastMessage("error", language[lng].unable_fetch_accounts);
  }
};
export const deleteAccount = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `/account`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await apiInstance(options);

    toastMessage("success", language[lng].success_delete_account);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_account);
  }
};


export const getProfile = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `/survey/profile`,
      params: {
        uniqueAnswer: id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await apiInstance(options);

    return queryRes.data;
  }
  catch (error) {
    toastMessage("error", language[lng].unable_fetch_profile);
  }
}

export const fetchEvaluation = async (lng = "english", params = {}) => {
  let { page, limit, type } = params;
  console.log("params", params);
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `/survey`,
      params: {
        page: page,
        limit: limit,
        'service.type': type,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return {
      data,
      count,
    };
  } catch (error) {
    console.log(error);
    toastMessage("error", language[lng].unable_fetch_accounts);
  }
}