import React from "react";
import { connect } from "react-redux";
import { Checkbox, Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import toastMessage from "../../../utils/toastMessage";
import { common_input_types, input_types } from "../../../constants/document";
import { getStorage } from "../../../utils/storage";
import MultipleChoice from "./DynamicForm/MultipleChoice";
import MultipleAnswer from "./DynamicForm/MultipleAnswer";
import IntegrationForm from "./DynamicForm/IntegrationForm";
import icons from "../../../constants/icons";
import { apiInstance } from "../../../utils/axiosInstance";

class NewQuestionnaire extends React.Component {
  state = {
    error: {},
    questionText: "",
    answerType: "",
    answerOptions: [],
    multiAnswer: [],
    responseKey: "data",
    apiMethod: "GET",
    apiParams: "",
    linkedQuestionnaire: [],
    allQuestionnaire: [],
    moreOptions: [
      {
        linkedQuestionnaire: [],
        questionGroupOption: [],
      },
    ],
    inputTypes: [
      {
        label: "Text Input",
        value: "textInput",
      },
      {
        label: "Radio Button",
        value: "radioButton",
      },
      {
        label: "Checkbox",
        value: "checkbox",
      },
    ],
    inputType: input_types[0],
    allowMultipleSelection: false,
    fields: [],
    available: false,
    required: false,
  };

  componentDidMount() {
    try {
      let { moreOptions } = this.state;
      let { selectedData } = this.props;
      const { dataIndex } = selectedData;

      if (dataIndex !== undefined) {
        const { singleAnswer, answerType, multiAnswer, questionText } =
          selectedData || {};

        let _answerType = {};

        common_input_types(this.props.defaultLanguage).forEach((el) => {
          if (el.value === answerType) {
            _answerType = el;
          }
        });

        this.handleQuestionLinkedOptions([selectedData]);

        if (!singleAnswer?.moreOptions) {
          moreOptions = [
            {
              linkedQuestionnaire: singleAnswer?.linkedQuestionnaire || [],
              questionGroupOption: singleAnswer?.questionGroupOption || [],
              fields: singleAnswer?.fields || [],
            },
          ];
        } else {
          moreOptions = singleAnswer.moreOptions;
        }

        this.setState({
          questionText,
          answerType: _answerType,
          answerOptions: multiAnswer,
          dataIndex,
          moreOptions,
          inputType: singleAnswer.inputType || input_types[0],
          allowMultipleSelection: singleAnswer.allowMultipleSelection || false,
          integrationType: singleAnswer.integrationType,
          fields: singleAnswer.fields,
          required: singleAnswer.required,
          available: singleAnswer.available,
        });
      }

      this.getAllQuestions();
    } catch (error) {
      console.log(error);
    }
  }

  getAllQuestions() {
    let selectedQuestion,
      { serviceData, dataIndex, questionnaires } = this.props,
      results = [];

    if (dataIndex !== undefined && this.props.serviceData) {
      selectedQuestion = serviceData?.questionnaire[dataIndex] || {};
    }

    questionnaires.forEach((el) => {
      if (el._id !== selectedQuestion?._id) {
        delete el.options;
        results.push({
          label: el.questionText,
          value: el._id,
          ...el,
        });
      }
    });

    this.setState({ allQuestionnaire: results });
  }

  onChangeText(field, e, fieldName, index) {
    let { error, moreOptions } = this.state;
    delete error[field];

    if (field !== "moreOptions") {
      this.setState({
        [field]: e.target ? e.target.value : e,
        error,
      });
    }

    if (field === "moreOptions" && index !== undefined) {
      moreOptions[index][fieldName] = e;

      this.setState({ moreOptions });
    }

    if (field === "linkedQuestionnaire") {
      this.handleQuestionLinkedOptions(e);
    }
  }

  handleQuestionLinkedOptions(questions = []) {
    let questionGroupOptions = [];
    let option = [];
    questions.forEach((el) => {
      if (el.multiAnswer) {
        el.multiAnswer.forEach((answer) => {
          if (!option.includes(answer.name)) {
            questionGroupOptions.push({
              label: answer.name,
              value: answer.name,
              ...answer,
            });
          }
          option.push(answer.name);
        });
      }
    });

    this.setState({
      questionGroupOptions,
    });
  }

  handleMultipleChoiceChanges({
    arrayName = "answerOptions",
    action,
    index,
    item,
  }) {
    let answerOptions = this.state[arrayName];

    if (item && action === "typing") {
      answerOptions[index] = item;
    }

    if (item && action === "add") {
      answerOptions.push(item);
    }

    if (action === "remove") {
      answerOptions.splice(index, 1);
    }

    this.setState({ [arrayName]: answerOptions });
  }

  handleCheckbox(field) {
    this.setState({
      [field]: !this.state[field],
    });
  }

  validateForm = async () => {
    let { answerType, error, questionText } = this.state;

    if (!answerType) {
      error.answerType =
        language[this.props.defaultLanguage].input_type_required;
    }

    if (questionText === "") {
      error.questionText =
        language[this.props.defaultLanguage].question_required;
    }

    this.validateAnswerOptions();

    this.setState({ error });
  };

  validateAnswerOptions() {
    const { answerOptions, error, answerType = {} } = this.state;

    answerOptions.forEach((el, index) => {
      this.validateIntegrationForm({
        ...el,
        index,
        isMultipleOption: true,
      });
    });

    if (answerType.value === "integration") {
      this.validateIntegrationForm({
        name: this.state.name,
        integrationType: this.state.integrationType,
      });
    }

    this.setState({ error });
  }

  validateIntegrationForm(params) {
    const { error } = this.state;

    const { integrationType, index, option = {} } = params;

    let separator = index !== undefined ? "_" + index : "";

    //validate all fields
    if (option?.value === "integration") {
      if (!integrationType?.value) {
        error["integrationType" + separator] =
          language[this.props.defaultLanguage].integration_type_required;
      }
    }
  }

  onSubmit = async () => {
    let { serviceData, defaultLanguage } = this.props;
    let { questionnaire = [] } = serviceData;

    if (!serviceData._id) {
      return toastMessage(
        "error",
        language[defaultLanguage].service_is_missing
      );
    }

    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      let user = await getStorage();

      this.setState({
        isSubmitting: true,
      });

      const {
        answerType,
        questionText,
        dataIndex,
        answerOptions,
        linkedQuestionnaire,
        moreOptions,
        inputType,
        allowMultipleSelection,
        integrationType,
        fields,
        available,
        required,
      } = this.state;

      let method = "PUT";

      let singleAnswer = {};

      let _answerOptions = answerOptions
        ? answerOptions.map((el, i) => {
            if (el && el.value !== "" && el.option) return el;
          })
        : [];

      if (
        answerType.value !== "multipleChoice" ||
        answerType.value !== "multipleAnswer"
      ) {
        singleAnswer = {
          linkedQuestionnaire,
          answerType: answerType.value,
        };
      }

      singleAnswer.moreOptions = moreOptions;
      singleAnswer.inputType = inputType;
      singleAnswer.fields = fields;
      singleAnswer.required = required;
      singleAnswer.available = available;
      singleAnswer.allowMultipleSelection = allowMultipleSelection;

      if (answerType.value === "integration") {
        singleAnswer["integrationType"] = integrationType;
      }

      //update
      const thisQuestionnaire = {
        questionText,
        answerType: answerType.value,
        multiAnswer: _answerOptions,
        singleAnswer,
      };

      // if (dataIndex !== undefined) {
      //   // thisQuestionnaire={}
      //   questionnaire[dataIndex].questionText = questionText;
      //   questionnaire[dataIndex].answerType = answerType.value;
      //   questionnaire[dataIndex].singleAnswer = singleAnswer;

      //   if (answerOptions) {
      //     questionnaire[dataIndex].multiAnswer = _answerOptions;
      //   }
      // } else {
      //   questionnaire.push({
      //     questionText,
      //     answerType: answerType.value,
      //     multiAnswer: _answerOptions,
      //     singleAnswer,
      //   });
      // }

      const options = {
        method,
        url: `/service`,
        data: {
          id: serviceData._id,
          dataIndex,
          answerOptions: !!answerOptions,
          questionnaire: thisQuestionnaire,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      apiInstance(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              dataIndex !== undefined
                ? "update_questionnaire_success"
                : "add_questionnaire_success"
            ]
          );

          this.props.handleCloseModal();
          this.props.getData();
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "error",
            language[this.props.defaultLanguage][
              dataIndex !== undefined
                ? "error_update_questionnaire"
                : "error_add_questionnaire"
            ]
          );
        });
    }
  };

  handleAddMoreOption() {
    let { moreOptions } = this.state;
    moreOptions.push({
      linkedQuestionnaire: [],
      questionGroupOption: [],
    });
    this.setState({ moreOptions });
  }

  handleRemoveMoreOption(index) {
    let { moreOptions } = this.state;
    moreOptions.splice(index, 1);
    this.setState({ moreOptions });
  }

  handleCheck(field) {
    this.setState({
      [field]: !this.state[field],
    });
  }

  render() {
    const { question, answer_type, question_placeholder, select } =
      language[this.props.defaultLanguage];
    return (
      <div>
        <div className="card-body">
          <Input
            placeholder={question_placeholder}
            label={question}
            required
            value={this.state.questionText}
            onChange={(e) => this.onChangeText("questionText", e)}
            error={this.state.error.questionText}
          />
          <Select
            placeholder={select}
            options={common_input_types(this.props.defaultLanguage)}
            label={answer_type}
            required
            value={this.state.answerType}
            onChange={(e) => this.onChangeText("answerType", e)}
            error={this.state.error.answerType}
          />
          {this.state.answerType.value === "multipleChoice" && (
            <MultipleChoice
              handleOptionsChanges={this.handleMultipleChoiceChanges.bind(this)}
              answerOptions={this.state?.answerOptions?.map((value, i) => {
                return {
                  field: "choice_" + i,
                  ...value,
                };
              })}
              error={this.state.error}
            />
          )}
          {this.state.answerType.value === "integration" && (
            <IntegrationForm
              onChangeInput={(field, e) => this.onChangeText(field, e)}
              integrationType={this.state?.integrationType}
              fields={this.state?.fields}
              error={this.state.error}
            />
          )}
          {this.state.answerType.value === "multipleAnswer" && (
            <>
              <MultipleAnswer
                handleOptionsChanges={this.handleMultipleAnswerChanges.bind(
                  this
                )}
                multiAnswer={this.state.multiAnswer}
                isUpdate={this.props.dataIndex !== undefined}
              />
            </>
          )}
        </div>
        <hr />
        <div className="card-body">
          <Select
            options={this.state.inputTypes}
            label={language[this.props.defaultLanguage].input_type}
            onChange={(e) => this.onChangeText("inputType", e)}
            value={this.state.inputType}
          />

          <Checkbox
            label="Allow Multiple Selection"
            onCheck={() => this.handleCheck("allowMultipleSelection")}
            checked={this.state.allowMultipleSelection}
          />
          <Checkbox
            label="Available"
            onCheck={() => this.handleCheck("available")}
            checked={this.state.available}
          />
          <Checkbox
            label="Required"
            onCheck={() => this.handleCheck("required")}
            checked={this.state.required}
          />
        </div>

        <hr />
        <div className="card-body">
          {this.state.moreOptions.map((el, k) => {
            return (
              <div className="row" key={k}>
                <div className="col-md-6">
                  <Select
                    options={this.state.questionGroupOptions}
                    placeholder={select}
                    label={language[this.props.defaultLanguage].if_answer_is}
                    value={el.questionGroupOption}
                    onChange={(e) =>
                      this.onChangeText(
                        "moreOptions",
                        e,
                        "questionGroupOption",
                        k
                      )
                    }
                    error={this.state.error.questionGroupOption}
                  />
                </div>
                <div className="col-md-6">
                  <Select
                    options={this.state.allQuestionnaire}
                    placeholder={select}
                    label={"Question Group"}
                    value={el.linkedQuestionnaire}
                    onChange={(e) =>
                      this.onChangeText(
                        "moreOptions",
                        e,
                        "linkedQuestionnaire",
                        k
                      )
                    }
                    error={this.state.error.linkedQuestionnaire}
                    isMulti
                  />
                </div>
                <div className="d-flex gap-3">
                  {k !== 0 && (
                    <Button
                      icon={icons.remove}
                      className="btn-default"
                      onPress={() => this.handleRemoveMoreOption(k)}
                    />
                  )}
                  {k === this.state.moreOptions.length - 1 && (
                    <>
                      <Button
                        icon={icons.add}
                        onPress={this.handleAddMoreOption.bind(this)}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="modal-footer gap-2">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewQuestionnaire);
