import englishImage from "../assets/english.png";
import frenchImage from "../assets/french.png";
import kinyarwandaImage from "../assets/kinyarwanda.png";

const country = process.env.REACT_COUNTRY || "Rwanda";
const population = "Rwandaise";

const data = {
  english: {
    image: englishImage,
    login_text: "Log In",
    intro_title_01: "Comprehensive Progress Monitoring System",
    intro_description:
      "Optimize field and survey based monitoring with a comprehensive tracking system. Tailored dynamic forms, data analytics and skilled enumerators with real-time updates on progressive insights.",
    how_works: "How it Works?",
    login: "Login",
    contact_us: "Contact us",
    login_title: "Welcome to " + process.env.REACT_APP_NAME,
    login_description: "Use your credentials to continue",
    fsc_farmers: "FSC Farmers",
    candidates: "Candidates",
    messages: "Messages",
    locations: "Locations",
    project_categories: "Project Categories",
    geographical_navigation: "Geographical Navigation",
    project_status: "Project Status",
    impact_assessment: "Impact Assessment",
    community_engagement: "Community Engagement",
    project_overview: "Project Overview",
    financial_overview: "Financial Overview",
    projects: "Projects",
    reports: "Reports",
    individual_surveys: "Individual Surveys",
    fsc_surveys: "FSC Survey",
    application_number: "Application Number",
    communication: "Communication",
    candidates: "Candidates",
    edit_candidate: "Edit Candidate",
    delete_candidate: "Delete Candidate",
    candidate: "Candidate",
    add_candidate: "Add Candidate",
    contact: "Contact",
    last_name: "Last Name",
    national_id_number_placeholder: "Enter National ID Number",
    national_id_number: "National ID Number",
    address: "Address",
    name_placeholder: "Enter name",
    phone_number_placeholder: "Enter phone number",
    email_address_placeholder: "Enter email address",
    address_placeholder: "Enter address",
    add_location: "Add location",
    delete_location: "Delete location",
    new_location: "New location",
    performance: "Performance",
    total_revenues: "Total Revenues",
    project_name: "Project Name",
    investment: "Investment",
    fsc_farmer_off: "Off-farm business",
    individual_farmer: "Individual Farmer",
    market_actors: "Market Actors",
    Gender: "Gender",
    gender: "Gender",
    job_type: "Job Type",
    Demography: "Demography",

    //revisit
    search_service_text: "Search for service",
    signup: "Sign Up",
    individuals: "Individual Farmers",
    institutions: "Institutions",
    home: "Home",
    request_service_title: "Request for a service",
    support_24_title: "24 / 7 Support",
    get_instant_notification_title: "Get Instant Notification",
    more: "More",
    less: "Less",
    receive_otp_description:
      "You will receive an OTP code to your email address",
    phone_number_or_email: "Phone number or Email Address",
    enter_phone_number_or_email: "Enter Phone number or Email address",
    dont_have_account: "Don't have an account?",
    register: "Register",
    enter_otp_received_on: "Enter OTP code received to ",
    verify_account: "Verify Account",
    phone_number_email_required: "Phone number or email address is required",
    invalid_phone_number_email:
      "Invalid input. Please enter a valid email address or phone number.",
    resend: "Resend",
    otp_code_six_digits: "OTP Code (6 Digits)",
    missing_phone_email:
      "Please provide a your email address or phone number, will sent you otp code for authentication",
    otp_code_required: "OTP code is required",
    otp_code_invalid: "Invalid OTP code",
    unable_to_verify_code:
      "Unable to verify your account please check your code and make sure you have used corrected OTP code, or resend to get new OTP code",
    identification_document_type_required:
      "Identification document type required",

    // part 2
    first_name_required: "First name is required",
    identification_number_required: "Identification number is required",
    all_fields_required: "All fields",
    required: "required",
    individual_services: "Individual Services",
    institution_services: "Institution Services",
    identification_document_type: "Identification Document Type",

    firstName: "First Name",
    lastName: "Last Name",
    first_name_placeholder: "Enter first name",
    passport: "Passport",
    passport_placeholder: "Enter passport",
    national_id: `${country} National ID`,
    national_ids: "Unique Code",
    national_id_placeholder: "Enter national id",
    already_have_account: "Already have account?",
    select: "Select",
    passport_number: "Passport Number",
    passport_number_placeholder: "Enter your passport number",
    national_id_required: "Enter national required",
    invalid_national_id:
      "Invalid " +
      country +
      " national id, must be " +
      process.env.REACT_APP_NATIONAL_ID_LENGTH +
      "  digits",
    invalid_passport_number: "Invalid passport number",
    passport_number_required: "Passport number required",
    national_id_is_valid_and_is_fetching_info:
      "Fetching National Identity information, please wait...",
    account_created: "Account created successfully",

    //part 3
    settings: "Settings",
    dashboard: "Dashboard",
    my_profile: "My Profile",
    requests: "Requests",
    pending: "Pending",
    processing: "Processing",
    approved: "Approved",
    rejected: "Rejected",
    logout: "Logout",
    services: "Services",
    notifications: "Notifications",
    alerts: "Alerts",
    accounts: "Accounts",
    change_password: "Change Password",
    setting_description: "Manage public service request portal settings",
    new_password_placeholder: "Enter new password",
    new_password: "New Password",
    confirm_password_placeholder: "Enter password again",
    confirm_password: "Retype Password",
    change_password_success: "Password changed successfully",
    otp_code: "OTP Code",
    otp_code_will_be_sent_confirm_reset:
      "An OTP code will be sent to you for confirming the reset of your password.",
    reset_password: "Reset Password",
    send_otp: "Send OTP",
    enter_otp_code: "Enter OTP Code",
    departments: "Departments",
    roles_permission: "Roles & Permissions",
    departments_title: "Departments",
    departments_description: "Manage departments and positions",
    institution_name_placeholder: "Enter institution name",
    institution_name: "Institution name",
    add_institution: "Add Institution",
    edit_institution: "Edit Institution",
    delete_institution: "Delete Institution",
    export_: "Export",
    submit: "Submit",
    institution_name_required: "Institution name required",
    add_institution_success: "Institution  added successfully",
    department: "Department",
    add_department: "Add Department",
    delete_department: "Delete Department",
    edit_department: "Edit Department",
    department_name: "Department Name",
    department_name_placeholder: "Enter department name",
    department_name_required: "Department name required",
    add_department_success: "Department  added successfully",
    update_department_success: "Department updated successfully",
    error_add_department_success: "Unable to add department, please try again",
    error_update_department_success:
      "Unable to update department, please try again",
    error_delete_department_success:
      "Unable to delete department, please try again",
    role: "Role",
    add_role: "Add Role",
    role_name: "Role Name",
    role_name_placeholder: "Enter role name",
    role_name_required: "Role name required",
    add_role_success: "Role  added successfully",
    permission: "Permission",
    add_permission: "Add Permission",
    edit: "Edit",
    delete_: "Delete",
    cancel: "Cancel",
    institution: "Institution",
    unable_fetch_institutions: "Unable to fetch institutions",
    unable_fetch_departments: "Unable to fetch departments",
    services_title: "Services",
    services_description:
      "Manage services that are to be requested by public services",
    add_service: "Add a service",
    individual: "Individual",
    edit_service: "Edit Service",
    delete_service: "Delete Service",
    service_name: "Service Name",
    service_name_placeholder: "Enter service name",
    service_name_required: "Service name required",
    category: "Category",
    categories: "Categories",
    filter_by_category: "Filter by category",
    add_translation: "Add Translation",
    max_number_days: "Maximum Response Time",
    remove_translation: "Remove Translation",
    maxDays_required: "Max. Number of days required",
    category_required: "Category required",
    required_documents: "Required Documents",
    questionnaire: "Questionnaire",
    translations: "Translations",
    signatures: "Signatures",
    approval_levels: "Approvals",
    output_letter: "Output Letter",
    publish: "Publish",
    avg_response_time: "Avg. Response time",
    avg_response_time_abbr: "Avg.RT",
    add_document: "Add document",
    edit_document: "Edit document",
    delete_document: "Delete document",
    document_name_required: "Document name required",
    document_name: "Document Name",
    file_extension: "File Extension",
    save: "Save",
    required_document: "Required document",
    size_limit_required: "Size limit required",
    file_extension_required: "File extension required",
    data_title: "Data Title",
    input_type: "Input Type",
    input_requirement: "Input Requirement",
    created_at: "Created At",
    actions: "Actions",
    add_data: "Add Data",
    delete: "Delete Data",
    edit_data: "Edit Data",
    data_title_required: "Data title required",
    input_type_required: "Input type required",
    input_requirement_required: "Input requirement required",
    data_title_placeholder: "Enter Data Title",
    mandatory: "Mandatory",
    optional: "Optional",
    text: "Text",
    checkbox: "Checkbox",
    translation: "Translation",
    default_translation: "Default text",
    language: "Language",
    size_limit: "Size limit",
    email_address: "Email Address",
    enter_email: "Enter Email Address",
    password: "Password",
    enter_password: "Enter Password",
    email_required: "Email address required",
    password_required: "Password required",
    wrong_email_password: "Wrong email address or password",
    invalid_email: "Invalid email address",
    success_delete_department: "Department Deleted successfully",
    job_positions: "Job Positions",
    position_name: "Position Name",
    add_position_success: "Position added successfully",
    error_add_position: "Unable to add position",
    update_position_success: "Position updated successfully",
    error_update_position: "Unable to update position",
    error_delete_position: "Unable to update position",
    unable_fetch_positions: "Unable to fetch positions",
    add_job_position: "Add Position",
    delete_job_position: "Delete Position",
    edit_job_position: "Edit Position",
    job_position: "Job position",
    job_position_name: "Job Position Name",
    available: "Available",

    //part 4
    job_position_name_placeholder: "Enter Job Position Name",
    job_position_name_required: "Job Position Name Required",
    forgot_password: "Forgot Password?",
    login_title: "Login",
    new_password_required: "New password required",
    different_password_required: "Password must match",
    confirm_password_required: "Retype new password",
    unable_reset_password:
      "Unable to reset password, make sure otp sent is valid and try again",
    reset_password_title: "Reset Password",
    verify: "Verify",
    account_nt_exist: "Account does not exist",
    employee_id: "Employee ID",
    employee_id_placeholder: "Enter Employment ID",
    invalid_employee_id: "Invalid Employment ID",
    employee_required: "Employment ID is required",
    employee_is_valid_and_is_fetching_info:
      "Fetching info for employee, please wait...",
    resend_otp: "Resend OTP Code",
    back_text: "Back",
    accounts_title: "Accounts",
    accounts_description: "Manage users accounts",
    ippisNumber: "Employee ID",
    access_role: "Access Role",
    unable_fetch_accounts: "Unable to fetch accounts",
    add_account: "Add Account",
    edit_account: "Edit Account",
    delete_account: "Delete Account",
    account: "Account",
    names: "Names",
    name: "Name",
    names_placeholder: "Enter Names",
    error_add_account: "Unable to add account",
    error_update_account: "Unable to update account",
    add_account_success: "Account added successfully",
    update_account_success: "Account updated successfully",
    ippis_number: "Employee ID",
    ippis_number_placeholder: "Enter Employee ID",
    ippis_number_required: "Employee ID is required",
    account_info: "Account Information",
    access_roles: "Access Roles",
    unable_fetch_access_role: "Unable to fetch access roles",
    success_delete_access_role: "Access Roles deleted successfully",
    error_delete_access_role: "Unable to delete access roles",
    add_access_role: "Add access role",
    edit_access_role: "Edit Access Role",
    delete_access_role: "Delete Access Role",
    access_role_name_placeholder: "Enter Access Role Name",
    operations: "Operations",
    menus: "Menus",
    access_role_name_required: "Access Role Name Required",
    access_role_menus_required: "Please select Accessed menus",
    error_add_access_role: "Unable to add Access Role",
    error_update_access_role: "Unable to update Access Role",
    add_access_role_success: "Access Role added successfully",
    update_access_role_success: "Access role updated successfully",
    view_: "View",
    account_name_required: "Account Name Required",
    username_required: "Username Required",
    username_placeholder: "Enter user name",
    username: "User Name",
    department_required: "Department Required",
    job_position_required: "Job position Required",
    access_role_required: "Access Role Required",
    email_placeholder: "Enter Email address",
    status: "Status",
    password_placeholder: "Enter Password",
    positions: "Positions",
    request_title: "Requests",
    individual_requests: "Individual Requests",
    institution_requests: "Institution Requests",
    request_description: "Manage public servant requests",
    unable_fetch_surveys: "Unable Fetch  Requests",
    service_categories: "Service Categories",
    description: "Description",
    type: "Type",
    add_service_category: "Add Service Category",
    edit_service_category: "Edit Service Category",
    delete_service_category: "Delete Service Category",
    service_category: "Service Category",
    service_category_name: "Service Category Name",
    service_category_name_placeholder: "Enter Service Category Name",
    service_category_description: "Service Category Description",
    service_category_description_placeholder:
      "Enter Service Category Description",
    error_add_service_category: "Unable to add service category",
    error_update_service_category: "Unable to update service category",
    add_service_category_success: "Service Category Added Successfully",
    update_service_category_success: "Service Category Updated Successfully",
    service_category_type_required: "Service Category Type Required",
    service_category_name_required: "Service Category Name Required",
    service_Category_type: "Service Category Type",
    success_delete_service: "Service deleted successfully",
    unable_fetch_services: "Unable to fetch services",
    error_delete_service: "Unable to delete service",
    two_factor_authentication_description:
      "You will be able to login with OTP(One Time Password) next time you log in",
    two_factor_authentication_label: "2FA Authentication",
    roles_and_permissions: "Roles & permissions",
    two_factor_tab_name: "2FA Authentication",
    required_document_name: "Document Name",
    accepted_file_extension: "Accepted File Extension",
    size_limit_measurement: "Size Limit Measurement",
    size_limit_placeholder: "Enter a size limit",
    service_is_missing: "Service is missing",
    add_required_document_success: "Required document added successfully",
    update_required_document_success: "Required document updated successfully",
    error_add_required_document: "Unable to add required document",
    error_update_required_document: "Unable to update required document",
    text_input: "Text input",
    dropdown: "Dropdown",
    signature_owner: "Signature Owner",
    owner_account_required: "Owner Account is required",
    add_signature_success: "Signature added successfully",
    update_signature_success: "Signature updated successfully",
    error_add_signature: "Unable to add signature",
    owner_name: "Owner Name",
    upload_signature: "Upload Signature",
    add_signature: "Add Signature",
    edit_signature: "Edit Signature",
    delete_signature: "Delete Signature",
    signature: "Signature",
    level_name: "Level Name",

    // part 5
    level_order: "Level Order",
    level_name_placeholder: "Enter Level Name",
    level_order_placeholder: "Enter Level Order",
    unable_fetch_service_category: "Unable to fetch service category",
    institution_required: "Institution name is Required",
    add_service_error: "Unable to add service",
    update_service_error: "Unable to update service",
    add_service_success: "Service added successfully",
    update_service_success: "Service updated successfully",
    success_delete_service_category: "Service category deleted successfully",
    error_delete_service_category: "Unable to delete service category",
    institution_placeholder: "Enter institution name",
    service_description_required: "Service description required",
    service_description: "Service description",
    service_description_placeholder: "Enter service description",
    service: "Service",
    document_name_placeholder: "Enter document name",
    delete_required_document_success: "Required document deleted successfully",
    error_delete_required_document: "Unable to delete service document",
    question: "Question",
    answer_type: "Answer Type",
    question_placeholder: "Enter question text",
    answer_requirement: "Answer requirement",
    question_required: "Question is required",
    multiple_choice: "Multiple Choice",
    add_choice: "Add Option",
    choice_name_placeholder: "Enter choice name",
    add_questionnaire: "Add Question",
    edit_questionnaire: "Edit Question",
    delete_questionnaire: "Delete Question",
    questionnaire: "Questions",
    delete_questionnaire_success: "Questionnaire deleted successfully",
    error_delete_questionnaire: "Unable to delete Questionnaire",
    delete_level_success: "Level Added successfully",
    error_delete_level: "Level Deleted successfully",
    add_level: "Add Level",
    edit_level: "Edit Level",
    delete_level: "Delete Level",
    approval_level: "Approval Level",
    add_approver_success: "Approver Added successfully",
    error_add_approver: "Unable to add Approver",
    view_template: "View Template",
    approver: "Approver",
    add_approver: "Add Approver",
    delete_approver: "Delete Approver",
    edit_approver: "Edit Approver",
    delete_approver_success: "Approver deleted successfully",
    error_delete_approver: "Unable to delete Approver",
    position_not_find: "position does not found",
    default_placeholder_input: "Enter ",
    upload_documents_below: "Upload documents below",
    success_add_data: "Data has been submitted successfully",
    error_add_data: "Unable to submit data, please try again later",
    pending_30_days: "Pending 30 Days+",
    pending_90_days: "Pending 90 Days+",
    alerts_title: "Alerts",
    alerts_description: "Manage stuck or abandoned public requests",
    error_user_sign_up: "Unable to register your account, please try again",
    employee_id_not_find: "Employee ID not found",
    date_requested: "Date Requested",
    request_information: "Request Information",
    comments: "Comments",
    current_department: "Current Department",
    assignee: "Assignee",
    notifications_title: "Notifications",
    retry: "Retry",
    error_retrieving_user_info: "Unable to retrieve user info",
    invalid_email_required: "Invalid email",
    request_name: "Request Name",
    document_attached: "Documents Attached",
    survey_number: "survey_number",
    avg_wait_time: "Avg Wait Time",
    approved_at: "Approved At",
    error_fetch_approvers: "Unable to fetch Approvers",
    add_approval_level: "Add Approval Level",
    level: "Level",
    level_placeholder: "Enter Approval Level",
    approver_required: "Approver account is required",
    level_required: "Level is required",
    add_approval_level_success: "Approval Level added successfully",
    update_approval_level_success: "Approval Level updated successfully",
    delete_approval_level_success: "Approval Level deleted successfully",
    update_approval_level_error: "Unable to update Approval Level",
    add_approval_level_error: "Unable to add Approval Level",
    delete_approval_level_error: "Unable to delete Approval Level",
    edit_approval_level: "Edit Approval Level",
    delete_approval_level: "Delete Approval Level",
    approver_exist: "Approver already exists",
    answer: "Answer",
    document: "Document",
    file_type: "File Type",
    error_delete_account: "Unable to delete account",
    success_delete_account: "Account deleted successfully",
    department_not_exist: "Department does not exist",
    job_position_placeholder: "Enter job position",
    last_name_placeholder: "Enter last name",
    username_required: "User name is required",
    approved: "Approved",
    rejected: "Rejected",
    reason: "Reason",
    unable_find_request: "Unable too find the request, please try again",
    not_allowed_approve_request: "You are not allowed to approve this request",
    unable_to_approve_request:
      "Unable to approve this request, please try again",
    not_allowed_reject_request: "You are not allowed to approve this request",
    unable_to_reject_request: "Unable to reject this request",
    my_requests: "My Requests",
    unable_fetch_notifications: "Unable to fetch notifications",
    unable_fetch_alerts: "Unable to fetch alerts",
    error_update_notifications: "Unable to update notification",
    notification: "Notification Body",
    date_time: "Date & Time",
    unread_notification: "Unread Notifications",
    read_notification: "Read Notifications",
    user_login: "Data Collector",
    admin_login: "Admin Login",
    admin: "Admin",
    phone_number: "Phone Number",
    recent_institution_requests: "Recent Institution Requests",
    recent_individual_requests: "Recent Individual Requests",
    number: "Number",
    date_time: "Date & Time",
    multiple_line_text: "Multiple Line Text Input",
    field_name: "Field",
    field_name_placeholder: "Enter field name",
    multi_answer: "Multiple Answer",
    value_type: "Value Type",
    add_object: "Add Object",
    object_title: "Object Title",
    object_title_placeholder: "Enter object title",
    main_search_placeholder: "Search Employee ID, Request Number...",
    filter_by_department: "Filter by Department",
    total_counts: "Total Requests",
    total_processed_7: "Processed with 7 days",
    total_processed_15: "Processed above 15 days",
    total_processed_30: "Processed above 30 days",
    unable_fetch_analytics: "Unable to fetch analytics",
    view_application: "View this application",
    daily_pending_requests: "Daily Pending Requests",
    no_data: "No Data",
    total_daily_pending_requests: "Total Daily Pending Requests",
    total_monthly_requests: "Total Monthly Pending Requests",
    monthly_requests: "Monthly Pending Requests",
    weekly_requests: "Weekly Pending Requests",
    filter_by_service: "Filter by service",
    date_range: "Date Range",
    filter_by_date: "Filter by date",
    total_weekly_requests: "Total Weekly Pending Requests",
    individual_max_response_time_required:
      "Individual Max Response Time is Required",
    individual_max_response_time: "Individual Max Response Time - Day(s)",
    individual_max_response_time_placeholder:
      "Enter individual Max Response Time",
    sub_approvers: "Sub Approvers",
    sub_approver: "Sub Approver",
    approvers: "Approvers",
    outside_organization_title: "Outside Organization",
    outside_organization_description:
      "If this account is not part of your organization's internal network.",
    active_accounts: "Active Accounts",
    inactive_accounts: "Inactive Accounts",
    account_already_exist: "Accounts already exist",
    level_already_taken: "Level already taken",
    sub_approvals: "Sub Approvals",
    alerts: "Alerts",
    outside_organization: "Outside Organization",
    out_of: "out of",
    day_of_week: "Day of Week Pending Requests",
    total_day_of_week_requests: "Total Day Of Week Requests",
    requests_per_category: "Requests Per Category",
    total_requests_per_category: "Total Requests Per Category",
    total_pending_7: "Pending above 7 days",
    total_pending_15: "Pending above 15 days",
    total_pending_30: "Pending above 30 days",
    approved_at: "Approved at",
    approved: "Approved",
    requester: "Requester",
    filter__by_institution: "Filter by institution",
    reset_filters: "Reset filters",
    clear: "Clear",
    or_upload_signature_image: "Or Upload Signature",
    upload_img: "Upload Image",
    otp_code_placeholder: "Enter OTP Code",
    send_otp_success: "OTP code has been sent to",
    unable_to_add_signature: "Unable to add signature",
    something_went_wrong: "Something went wrong",
    add_signature_success: "Signature has been added successfully",
    copy: "Copy",
    copied: "Copied",
    digital_key: "Digital Key",
    digital_key_placeholder: "Key here",
    upload_digital_signature: "Upload Digital Signature",
    invalid_signature: "Your signature is invalid",
    upload_your_digital_signature: "Upload your digital signature",
    upload_your_digital_signature_description:
      "You are going to sign with your digital signature, make sure you have provided a valid signature",
    validate: "Validate",
    signature_required: "Signature Required",
    upload_signature: "Upload Signature",
    sign: "Sign",
    invalid_signature: "Invalid Signature",
    document_signed_title: "",
    document_signed_description: "",
    sign_and_approved: "Sign and Approve",
    please_fill_the_form_below: "Fill the form Below",
    letter_title: "Letter Title",
    letter_title_placeholder: "Enter a letter title",
    yes: "Yes",
    no: "No",
    not_allowed_sign_request: "You are not allowed to sign this request",
    reason: "Reason (Optional)",
    comment: "Comment (Optional)",
    rejected_reason_placeholder: "Enter a reason here",
    approved_reason_placeholder: "Leave a comment",
    choose_services: "Choose Service",
    remainingDays: "Remaining",
    individual_max_response_time_high_remaining_day:
      "Individual max response time high than the remaining days",
    add_sub_approver: "Add Sub-Approver",
    days: "day(s)",
    individual_max_response_time_must_less_than:
      "Must be less than or equal to",
    today: "Today",
    this_week: "This Week",
    this_month: "This Month",
    outside_organization_accounts: "Outside Organization Accounts",
    sub_approval_status: "Sub Approval Status",
    no_form_here: "No form found",
    questionnaire_answers: "Questionnaire Answers",
    attachments: "Attachments",
    download: "Download",
    reviewed: "Reviewed",
    write_message: "Write message...",
    approvals: "Approvals",
    comment_required: "Comment is required",
    comment_send_sccuess: "Comment has been sent successfully",
    unable_send_comment: "Unable to send comment, try again",
    max_number_days_placeholder: "Enter a number of day(s)",
    approve: "Approve",
    reject: "Reject",
    initiator: "Initiator",
    comments_and_approvals: "Comments and Approvals",
    write_comment: "Write a comment...",
    sub_approvals_logs: "Sub Approvals Logs",
    approvals_logs: "Approvals Logs",
    date: "Date",
    time: "Time",
    email_has_been_sent_to: "Email has been sent to ",
    otp_code_required: "OTP code required",
    enter_otp_code_received_to_email: "Enter otp code received to",
    reset_password_success: "Reset password successfully",
    invalid_otp_code: "Invalid otp code",
    count: "Count",
    Identity: "Identity",

    account_exist: "Account already exists",
    access_role_exist: "Access role already exists",
    service_exist: "Service already exists",
    service_category_exist: "Service category already exists",
    names: "Names",
    request_reset_password: "Request reset password",
    password_must_match: "Password must match",
    confirm_password_required: "Confirm password is required",
    invalid_password: "Invalid password",
    department_exists: "Department already exists",
    job_position_exists: "Job position already exists",
    password_must: "Password must contain",
    password_min_error: "Minimum 8 characters",
    password_contains_uppercase_letter: "At least one uppercase letter",
    password_contains__lowercase_letter: "At least one lowercase letter",
    password_contains_number: "At lease one number.",
    successfully: "successfully",
    letter_subject: "Letter subject",
    letter_subject_placeholder: "Enter a letter subject",
    letter_body: "Letter body",
    letter_body_placeholder: "Enter a letter body",
    attach_file: "Attach file",
    attachment_title: "Attachment title",
    file: "File",
    title: "Title",
    position: "Position",
    ippis_units: "IPPIS-Units",
    ippis_levels: "IPPIS-Levels",
    ippis_scales: "IPPIS-Scales",
    ippis_source_funds: "IPPIS Source of Funds",
    ippis_payroll_groups: "IPPIS Payroll Group",
    ippis_cost_centers: "IPPIS Cost Centers",
    choice_option_type_placeholder: "Type",
    choice_item: "Choice Item",
    choice_item_placeholder: "Enter Item name",
    choice_item_name: "Choice Item Name",
    unable_to_fetch_ippis_units:
      "Retrieving IPPIS units has encountered an issue.",
    unable_to_fetch_ippis_levels:
      "Retrieving IPPIS levels has encountered an issue.",
    unable_to_fetch_ippis_scales:
      "Retrieving IPPIS scales has encountered an issue.",
    unable_to_fetch_ippis_cost_center:
      "Retrieving IPPIS cost centers has encountered an issue.",
    unable_to_fetch_ippis_payroll_group:
      "Retrieving IPPIS payroll group has encountered an issue.",
    unable_to_fetch_ippis_source_fund:
      "Retrieving IPPIS  source fund has encountered an issue.",
    unable_to_fetch_ippis_info:
      "Retrieving IPPIS  source fund information has encountered an issue, contact your administrator",
    integration: "Integration",
    api_url: "API URL",
    dropdown_field_name: "Dropdown Field Name",
    dropdown_field_value: "Dropdown Field Value",
    dropdown_field_name_placeholder: "Object Key",
    dropdown_field_value_placeholder: "Object Value",
    api_url_placeholder: "Enter API full URL",
    item_name_required: "Item name is required",
    api_url_required: "API URL is required",
    api_url_invalid: "API URL is invalid, must start with http or https",
    field_name_required: "Field name is required",
    field_value_required: "Field value is required",
    response_key_name_placeholder: "Enter response key name",
    response_key_name: "Response Key Name",
    api_method_placeholder: "Enter API method",
    api_method: "API Method",
    api_method_required: "API Method is required",
    api_params_placeholder: "Enter Api Parameters",
    api_params: "Api Params",
    api_params_description: "Multiple API parameters are separated by commas",
    description: "Description",
    description_placeholder: "Enter Description",
    surveys: "Surveys",
    provinces: "Provinces",
    villages: "Villages",
    cells: "Cells",
    districts: "Districts",
    sectors: "Sectors",
    fsc: "Farmer Service Center",
    update_questionnaire_success: "Update Questionnaire Successful",
    add_questionnaire_success: "Add Questionnaire Successful",
    error_update_questionnaire: "Error Update Questionnaire",
    error_add_questionnaire: "Error Add Questionnaire",
    linked_questionnaire: "Linked Questionnaire",
    user_login: "Data Collector",
    admin_login: "Admin Login",
    total_income: "Total Income",
    total_expenses: "Total Expenses",
    budget: "Budget",
    cooperative: "Cooperative",
    financial_service: "Financial Service",
    province: "Province",
    district: "District",
    sector: "Sector",
    village: "Village",
    cell: "Cell",
    address: "Address",
    latitude: "Latitude",
    longitude: "Longitude",
    contact: "Contact",
    first_name: "First Name",
    fsc_registration: "FSC Registration",
    fsc_individual: "FSC Individual",
    farmer_training: "Farmer Training",
    data_collectors: "Data Collectors",
    profiling: "Profiling",
    finance: "Finance",
    market_actors_registration: "Market Actors Registration",
    fsc_market_actors: "FSC Market Actors",
    value_chain_and_income: "Value Chain and income",
    if_answer_is: "If answer is",
    access_to_market: "Access to market",
    skills_enhancement: "Skills Enhancement",
    perception_and_visioning: "PERCEPTION AND VISIONING",
    name_of_the_coach: "Name of the coach",
    signature: "Signature",
    integration_type_required: "Integration Type is required",
    finance: "Finance",
    kpi: "KPI",
    impact: "Impact",
    search: "Search",
    add_new: "Add New",
    login_as_admin: "Admin",
    login_as_fsc: "Farmer Service Center",
    login_as_data_collector: "Data Collector",
    choose_survey_description: "Choose service to see data collected",
    survey_info: "Survey Information",
    field_required: "This field is required",
    survey_answer_is_missing: "Survey Answer is missing",
    update: "Update",
    error_delete_survey: "Unable to delete survey data",
    success_delete_survey: "Data Successfully deleted",
    account_type: "Account Type",
    new_entry: "New Entry",
    delete_success: "Data has been deleted successfully",
    all: "All",
    remove: "Remove",
    menus: "menu(s)",
    select_all_menus: "Select all menu(s)",
    save_changes: "Save changes",
    error_update_failed: "Unable to update changes",
    data_update_success: "Data update successfully",
    integration_type: "Integration Type",
    fields: "Fields",
    regions: "Regions",
    is_required: "is required",
    delete_answer_confirmation: "Are you sure you want to delete this answer?",
    delete_data_failed: "Error occurred while deleting data",
    regions_regions_per_gender: "Regions Per Gender",
    youth_region: "Youth Per Region",
    fsc_per_gender: "FSCs Per Gender",
    young_farmers_per_gender: "Young Farmers Per Gender",
    see_all_regions: "See All Regions",
    region: "Region",
    analytics: "Analytics",
    farmer_service_centers: "Farmer Service Centers",
    survey_services: "Survey Services",
    add_account: "Add Account",
    delete_account: "Delete Account",
    account: "Account",
    location: "Location",
    is_invalid: "is invalid",
    gender_per_region: "Gender Per Region",
    region_per_age: "Gender Per Region",
    non_youth_off_farm: "Non-Youth Off Farm",
    young_off_farm: "Young Off Farm",
    young_farmers: "Young Farmers",
    age: "Age",
    year_birth: "Year Birth",
    filter_by_year_birth: "Filter By Year Birth",
    unique_number: "Unique Number",
    survey: "Survey",
    generate_unique_number: "Generate Unique Number",
    unique_number_placeholder: "Enter a unique number",
    unique_number_required: "Unique Number is required",
    baseline_registrations: "Baseline Registrations",
    limit: "Limit",
    template: "Template",
    import_data: "Import Data",
    questionnaire_answer: "Questionnaire Answer",
    filter_by_answer: "Filter by Answer",
    location_type: "Location Type",
    sms_enabled: "SMS Enabled",
    list_on_sidebar: "Listing on sidebar",
    charts: "Charts",
    menu_name: "Menu Name",
    menu_name_placeholder: "Enter menu name",
    available_charts: "Available Charts",
    filters: "Filters",
    main_filters: "Main Filters",
    group: "Group",
    position_placeholder: "Enter position",
    card_counts: "Card Counts",
    group_position: "Enter Group Position",
    group_position_placeholder: "Group Position",
    menu_position: "Menu Position",
    deleteAllSurveyData: "Delete All Survey Data",
    graduationEvaluation: "Graduation Evaluation",
    confirmed_vs_false_graduates: "Confirmed Vs False Graduates",
    false_graduations_per_category: "False graduations per category",
    graduation_reasons: "Graduation Reasons",
    over_all_impact: "Over All Impact",
    districtFilter: "Filter By District",
    provinceFilter: "Filter By Province",
    educationFilter: "Education Level",
    sectorFilter: "Filter By Sector",
    ageRangeFilter: "Filter By Age Range",
    evaluations: "Evaluations",
    evaluationDescription: "Participants monitoring and evaluation",
    profiling_overwiew: "Profiling Overview",
    record_achievement: "Record Achievement",
    communication: "Communication",
    achievement_recording: "Achievement Recording",
    new_farmer: "New Farmer",
    market_actor_linkage: "Market Actor Linkage",
    new_trainings_recieved: "New Trainings Recieved",
    equipment: "Equipment",
    access_to_finance: "Access to finance",
    income_generation: "Income Generation",
    new_farmer_registration: "New Farmer Registration",
    log_and_history: "Log and History",
    unique_identifier: "Unique Identifier",
    unique_identifier_placeholder: "Enter unique identifier",
    unique_identifier_required: "Unique Identifier is required",
    unable_start_conversation: "Unable to start a conversation",
  },
  french: {
    image: frenchImage,
    login_text: "Se connecter",
    intro_title_01: "Système complet de suivi des progrès",
    intro_description:
      "Optimisez la surveillance sur le terrain et les enquêtes avec un système de suivi complet. Formulaires dynamiques adaptés, analyses de données et agents qualifiés avec des mises à jour en temps réel sur les progrès.",
    how_works: "Comment ça marche ?",
    login: "Se connecter",
    contact_us: "Contactez-nous",
    login_title: "Bienvenue sur " + process.env.REACT_APP_NAME,
    login_description: "Utilisez vos identifiants pour continuer",
    fsc_farmers: "Agriculteurs FSC",
    candidates: "Candidats",
    messages: "Messages",
    locations: "Emplacements",
    project_categories: "Catégories de projets",
    geographical_navigation: "Navigation géographique",
    project_status: "Statut du projet",
    impact_assessment: "Évaluation de l'impact",
    community_engagement: "Engagement communautaire",
    project_overview: "Vue d'ensemble du projet",
    financial_overview: "Vue d'ensemble financière",
    projects: "Projets",
    reports: "Rapports",
    individual_surveys: "Enquêtes individuelles",
    fsc_surveys: "Enquête FSC",
    application_number: "Numéro de la demande",
    communication: "Communication",
    candidates: "Candidats",
    edit_candidate: "Modifier le candidat",
    delete_candidate: "Supprimer le candidat",
    candidate: "Candidat",
    add_candidate: "Ajouter un candidat",
    contact: "Contact",
    last_name: "Nom de famille",
    national_id_number_placeholder:
      "Entrez le numéro d'identification nationale",
    national_id_number: "Numéro d'identification nationale",
    address: "Adresse",
    name_placeholder: "Entrez le nom",
    phone_number_placeholder: "Entrez le numéro de téléphone",
    email_address_placeholder: "Entrez l'adresse e-mail",
    address_placeholder: "Entrez l'adresse",
    add_location: "Ajouter un emplacement",
    delete_location: "Supprimer un emplacement",
    new_location: "Nouvel emplacement",
    performance: "Performance",
    total_revenues: "Revenus totaux",
    project_name: "Nom du projet",
    investment: "Investissement",
    fsc_farmer_off: "Entreprise hors exploitation agricole",
    individual_farmer: "Agriculteur individuel",
    market_actors: "Acteurs du marché",
    Gender: "Sexe",
    gender: "Sexe",
    job_type: "Type de travail",
    Demography: "Démographie",

    //part 2
    search_service_text: "Rechercher un service",
    signup: "S'inscrire",
    individuals: "Agriculteurs individuels",
    institutions: "Institutions",
    home: "Accueil",
    request_service_title: "Demander un service",
    support_24_title: "Assistance 24 / 7",
    get_instant_notification_title: "Recevoir des notifications instantanées",
    more: "Plus",
    less: "Moins",
    receive_otp_description: "Vous recevrez un code OTP à votre adresse e-mail",
    phone_number_or_email: "Numéro de téléphone ou adresse e-mail",
    enter_phone_number_or_email:
      "Entrez le numéro de téléphone ou l'adresse e-mail",
    dont_have_account: "Vous n'avez pas de compte ?",
    register: "S'inscrire",
    enter_otp_received_on: "Entrez le code OTP reçu à ",
    verify_account: "Vérifier le compte",
    phone_number_email_required:
      "Le numéro de téléphone ou l'adresse e-mail est requis",
    invalid_phone_number_email:
      "Entrée invalide. Veuillez entrer une adresse e-mail ou un numéro de téléphone valide.",
    resend: "Renvoyer",
    otp_code_six_digits: "Code OTP (6 chiffres)",
    missing_phone_email:
      "Veuillez fournir votre adresse e-mail ou votre numéro de téléphone, nous vous enverrons un code OTP pour l'authentification",
    otp_code_required: "Le code OTP est requis",
    otp_code_invalid: "Code OTP invalide",
    unable_to_verify_code:
      "Impossible de vérifier votre compte, veuillez vérifier votre code et vous assurer que vous avez utilisé le bon code OTP, ou renvoyez-le pour obtenir un nouveau code OTP",
    identification_document_type_required:
      "Le type de document d'identification est requis",
    first_name_required: "Le prénom est requis",
    identification_number_required: "Le numéro d'identification est requis",
    all_fields_required: "Tous les champs",
    required: "requis",
    individual_services: "Services individuels",
    institution_services: "Services institutionnels",
    identification_document_type: "Type de document d'identification",
    firstName: "Prénom",
    lastName: "Nom",
    first_name_placeholder: "Entrez le prénom",
    passport: "Passeport",
    passport_placeholder: "Entrez le passeport",
    national_id: `${country} ID National`,
    national_ids: "Code unique",
    national_id_placeholder: "Entrez l'identifiant national",
    already_have_account: "Vous avez déjà un compte ?",
    select: "Sélectionner",
    passport_number: "Numéro de passeport",
    passport_number_placeholder: "Entrez votre numéro de passeport",
    national_id_required: "Entrez l'identifiant national requis",
    invalid_national_id:
      "Identifiant national " +
      country +
      " invalide, il doit comporter " +
      process.env.REACT_APP_NATIONAL_ID_LENGTH +
      " chiffres",
    invalid_passport_number: "Numéro de passeport invalide",
    passport_number_required: "Numéro de passeport requis",
    national_id_is_valid_and_is_fetching_info:
      "Récupération des informations de l'identité nationale, veuillez patienter...",
    account_created: "Compte créé avec succès",
    settings: "Paramètres",
    dashboard: "Tableau de bord",
    my_profile: "Mon profil",
    requests: "Demandes",
    pending: "En attente",
    processing: "En traitement",
    approved: "Approuvé",
    rejected: "Rejeté",
    logout: "Se déconnecter",
    services: "Services",
    notifications: "Notifications",
    alerts: "Alertes",
    accounts: "Comptes",
    change_password: "Changer le mot de passe",
    setting_description:
      "Gérer les paramètres du portail des demandes de service public",
    new_password_placeholder: "Entrez un nouveau mot de passe",
    new_password: "Nouveau mot de passe",
    confirm_password_placeholder: "Confirmez le mot de passe",
    confirm_password: "Retapez le mot de passe",
    change_password_success: "Mot de passe modifié avec succès",
    otp_code: "Code OTP",
    otp_code_will_be_sent_confirm_reset:
      "Un code OTP vous sera envoyé pour confirmer la réinitialisation de votre mot de passe.",
    reset_password: "Réinitialiser le mot de passe",
    send_otp: "Envoyer OTP",
    enter_otp_code: "Entrez le code OTP",
    departments: "Départements",
    roles_permission: "Rôles & Permissions",
    departments_title: "Départements",
    departments_description: "Gérer les départements et les rôles",
    institution_name_placeholder: "Entrez le nom de l'institution",
    institution_name: "Nom de l'institution",
    add_institution: "Ajouter une institution",
    edit_institution: "Modifier l'institution",
    delete_institution: "Supprimer l'institution",
    export_: "Exporter",
    submit: "Soumettre",
    institution_name_required: "Nom de l'institution requis",
    add_institution_success: "Institution ajoutée avec succès",
    department: "Département",
    add_department: "Ajouter un département",
    delete_department: "Supprimer un département",
    edit_department: "Modifier un département",
    department_name: "Nom du département",
    department_name_placeholder: "Entrez le nom du département",
    department_name_required: "Nom du département requis",
    add_department_success: "Département ajouté avec succès",
    update_department_success: "Département mis à jour avec succès",
    error_add_department_success:
      "Impossible d'ajouter le département, veuillez réessayer",
    error_update_department_success:
      "Impossible de mettre à jour le département, veuillez réessayer",
    error_delete_department_success:
      "Impossible de supprimer le département, veuillez réessayer",
    role: "Rôle",
    add_role: "Ajouter un rôle",
    role_name: "Nom du rôle",
    role_name_placeholder: "Entrez le nom du rôle",
    role_name_required: "Le nom du rôle est requis",
    add_role_success: "Rôle ajouté avec succès",
    permission: "Permission",
    add_permission: "Ajouter une permission",
    edit: "Modifier",
    delete_: "Supprimer",
    cancel: "Annuler",
    institution: "Institution",
    unable_fetch_institutions: "Impossible de récupérer les institutions",
    unable_fetch_departments: "Impossible de récupérer les départements",
    services_title: "Services",
    services_description: "Gérez les services demandés par le public",
    add_service: "Ajouter un service",
    individual: "Individuel",
    edit_service: "Modifier le service",
    delete_service: "Supprimer le service",
    service_name: "Nom du service",
    service_name_placeholder: "Entrez le nom du service",
    service_name_required: "Le nom du service est requis",
    category: "Catégorie",
    categories: "Catégories",
    filter_by_category: "Filtrer par catégorie",
    add_translation: "Ajouter une traduction",
    max_number_days: "Délai maximal",
    remove_translation: "Supprimer la traduction",
    maxDays_required: "Le délai maximal est requis",
    category_required: "La catégorie est requise",
    required_documents: "Documents requis",
    questionnaire: "Questionnaire",
    translations: "Traductions",
    signatures: "Signatures",
    approval_levels: "Niveaux d'approbation",
    output_letter: "Lettre de sortie",
    publish: "Publier",
    avg_response_time: "Temps de réponse moyen",
    avg_response_time_abbr: "TRM",
    add_document: "Ajouter un document",
    edit_document: "Modifier un document",
    delete_document: "Supprimer un document",
    document_name_required: "Le nom du document est requis",
    document_name: "Nom du document",
    file_extension: "Extension de fichier",
    save: "Enregistrer",
    required_document: "Document requis",
    size_limit_required: "La taille limite est requise",
    file_extension_required: "L'extension du fichier est requise",
    data_title: "Titre des données",
    input_type: "Type d'entrée",
    input_requirement: "Exigence d'entrée",
    created_at: "Créé le",
    actions: "Actions",
    add_data: "Ajouter des données",
    delete: "Supprimer les données",
    edit_data: "Modifier les données",
    data_title_required: "Le titre des données est requis",
    input_type_required: "Le type d'entrée est requis",
    input_requirement_required: "L'exigence d'entrée est requise",
    data_title_placeholder: "Entrez le titre des données",
    mandatory: "Obligatoire",
    optional: "Optionnel",
    text: "Texte",
    checkbox: "Case à cocher",
    translation: "Traduction",
    default_translation: "Texte par défaut",
    language: "Langue",
    size_limit: "Limite de taille",
    email_address: "Adresse e-mail",
    enter_email: "Entrez l'adresse e-mail",
    password: "Mot de passe",
    enter_password: "Entrez le mot de passe",
    email_required: "Adresse e-mail requise",
    password_required: "Mot de passe requis",
    wrong_email_password: "Adresse e-mail ou mot de passe incorrect",
    invalid_email: "Adresse e-mail invalide",
    success_delete_department: "Département supprimé avec succès",
    job_positions: "Postes",
    position_name: "Nom du poste",
    add_position_success: "Poste ajouté avec succès",
    error_add_position: "Impossible d'ajouter le poste",
    update_position_success: "Poste mis à jour avec succès",
    error_update_position: "Impossible de mettre à jour le poste",
    error_delete_position: "Impossible de supprimer le poste",
    unable_fetch_positions: "Impossible de récupérer les postes",
    add_job_position: "Ajouter un poste",
    delete_job_position: "Supprimer un poste",
    edit_job_position: "Modifier un poste",
    job_position: "Poste",
    job_position_name: "Nom du poste",
    available: "Disponible",
  },
  kinyarwanda: {
    image: kinyarwandaImage,
    login_text: "Injira",
    intro_title_01: "Sisitemu yo gukurikirana iterambere y'uburyo bwose",
    intro_description:
      "Hindura uburyo bwo gukurikirana ibikorwa n'ubushakashatsi ukoresheje sisitemu yihariye yo gukurikirana. Ifishi zishingiye ku mikorere, isesengura ry'ibipimo no gukorana n'ababishinzwe bafite amakuru agezweho ku byo bagezeho.",
    how_works: "Bikora bite?",
    login: "Injira",
    contact_us: "Twandikire",
    login_title: "Ikaze muri " + process.env.REACT_APP_NAME,
    login_description: "Koresha amakuru yawe kugirango ukomeze",
    fsc_farmers: "Abahinzi ba FSC",
    candidates: "Abakandida",
    messages: "Ubutumwa",
    locations: "Aho duherereye",
    project_categories: "Amoko y'imishinga",
    geographical_navigation: "Ihuriro ry'ibibanza",
    project_status: "Imiterere y'umushinga",
    impact_assessment: "Isesengura ry'ingaruka",
    community_engagement: "Kujyana n'umuryango",
    project_overview: "Isuzuma ry'umushinga",
    financial_overview: "Isuzuma ry'ubukungu",
    projects: "Imishinga",
    reports: "Raporo",
    individual_surveys: "Ubushakashatsi bw'umuntu ku giti cye",
    fsc_surveys: "Ubushakashatsi bwa FSC",
    application_number: "Nimero y'ubusabe",
    communication: "Itumanaho",
    candidates: "Abakandida",
    edit_candidate: "Hindura umukandida",
    delete_candidate: "Siba umukandida",
    candidate: "Umukandida",
    add_candidate: "Ongeramo umukandida",
    contact: "Kontaki",
    last_name: "Izina rya nyuma",
    national_id_number_placeholder: "Injiza nimero y'indangamuntu",
    national_id_number: "Nimero y'indangamuntu",
    address: "Aho utuye",
    name_placeholder: "Injiza izina",
    phone_number_placeholder: "Injiza nimero ya telefone",
    email_address_placeholder: "Injiza aderesi ya imeli",
    address_placeholder: "Injiza aho utuye",
    add_location: "Ongeramo aho duherereye",
    delete_location: "Siba aho duherereye",
    new_location: "Ahantu hashya",
    performance: "Imikorere",
    total_revenues: "Amafaranga yinjijwe yose",
    project_name: "Izina ry'umushinga",
    investment: "Ishoramari",
    fsc_farmer_off: "Ubucuruzi bwo hanze y'ubuhinzi",
    individual_farmer: "Umuhinzi ku giti cye",
    market_actors: "Abakinnyi bo ku isoko",
    Gender: "Igitsina",
    gender: "Igitsina",
    job_type: "Ubwoko bw'akazi",
    Demography: "Demogarafiya",

    //part 2
    search_service_text: "Shaka serivisi",
    signup: "Iyandikishe",
    individuals: "Abahinzi ku giti cye",
    institutions: "Ibigo",
    home: "Urugo",
    request_service_title: "Saba serivisi",
    support_24_title: "Ubufasha 24 / 7",
    get_instant_notification_title: "Bona Itangazo Rihuse",
    more: "Benshi",
    less: "Bike",
    receive_otp_description:
      "Uzoherezwa kode ya OTP kuri aderesi yawe ya imeli",
    phone_number_or_email: "Nimero ya telefone cyangwa Aderesi ya Imeli",
    enter_phone_number_or_email:
      "Injiza nimero ya telefone cyangwa aderesi ya imeli",
    dont_have_account: "Ntabwo ufite konti?",
    register: "Iyandikishe",
    enter_otp_received_on: "Injiza kode ya OTP woherejwe kuri ",
    verify_account: "Emeza Konti",
    phone_number_email_required:
      "Nimero ya telefone cyangwa aderesi ya imeli irakenewe",
    invalid_phone_number_email:
      "Ihinduka ridakwiye. Nyamuneka injiza aderesi ya imeli cyangwa nimero ya telefone ikwiye.",
    resend: "Subiramo",
    otp_code_six_digits: "Kode ya OTP (Imibare 6)",
    missing_phone_email:
      "Nyamuneka tanga aderesi yawe ya imeli cyangwa nimero ya telefone, tuzohereza kode ya OTP kugirango wemeze",
    otp_code_required: "Kode ya OTP irakenewe",
    otp_code_invalid: "Kode ya OTP itari yo",
    unable_to_verify_code:
      "Ntibishobotse kwemeza konti yawe, nyamuneka reba kode yawe kandi wemeze ko ukoresha kode ya OTP ikwiye, cyangwa subira uhereze kugirango ubone kode ya OTP nshya",
    identification_document_type_required:
      "Ubwoko bw'inyandiko y'ibanze burakenewe",

    //part 3

    first_name_required: "Izina ryambere rirakenewe",
    identification_number_required: "Nimero y'indangamuntu irakenewe",
    all_fields_required: "Ibice byose",
    required: "birakenewe",
    individual_services: "Serivisi z'abahinzi ku giti cye",
    institution_services: "Serivisi z'ibigo",
    identification_document_type: "Ubwoko bw'inyandiko y'ibanze",

    firstName: "Izina ry'ibanze",
    lastName: "Izina ry'umuryango",
    first_name_placeholder: "Injiza izina ry'ibanze",
    passport: "Pasiporo",
    passport_placeholder: "Injiza pasiporo",
    national_id: `${country} Indangamuntu`,
    national_ids: "Kode yihariye",
    national_id_placeholder: "Injiza indangamuntu",
    already_have_account: "Ufite konti? ",
    select: "Hitamo",
    passport_number: "Nimero ya Pasiporo",
    passport_number_placeholder: "Injiza nimero ya pasiporo yawe",
    national_id_required: "Injiza indangamuntu irakenewe",
    invalid_national_id:
      "Indangamuntu idakwiye ya " +
      country +
      ", igomba kuba ifite " +
      process.env.REACT_APP_NATIONAL_ID_LENGTH +
      " imibare",
    invalid_passport_number: "Nimero ya pasiporo idakwiye",
    passport_number_required: "Nimero ya pasiporo irakenewe",
    national_id_is_valid_and_is_fetching_info:
      "Turimo gukusanya amakuru y'Indangamuntu, nyamuneka tegereza...",
    account_created: "Konti yaremwe neza",

    //part 4
    settings: "Igenamiterere",
    dashboard: "Ikibaho cy'amakuru",
    my_profile: "Profil Yanjye",
    requests: "Ibisabwa",
    pending: "Birategereje",
    processing: "Biri gutunganywa",
    approved: "Byemejwe",
    rejected: "Byanzwe",
    logout: "Sohoka",
    services: "Serivisi",
    notifications: "Amakuru",
    alerts: "Impuruza",
    accounts: "Konti",
    change_password: "Hindura ijambo ry'ibanga",
    setting_description:
      "Menya uburyo bwo gucunga ibisabwa by'ibikorwa rusange",
    new_password_placeholder: "Injiza ijambo ry'ibanga rishya",
    new_password: "Ijambo ry'ibanga rishya",
    confirm_password_placeholder: "Injiza ijambo ry'ibanga wihaye",
    confirm_password: "Ongeza ijambo ry'ibanga",
    change_password_success: "Ijambo ry'ibanga ryahinduwe neza",
    otp_code: "Kode ya OTP",
    otp_code_will_be_sent_confirm_reset:
      "Kode ya OTP izoherezwa kugira ngo umenye neza ko wahinduye ijambo ry'ibanga.",
    reset_password: "Simbuza ijambo ry'ibanga",
    send_otp: "Ohereza OTP",
    enter_otp_code: "Injiza kode ya OTP",
    departments: "Amashami",
    roles_permission: "Imyanya n'Uburenganzira",
    departments_title: "Amashami",
    departments_description: "Gucunga amashami n'ibikorwa by'umwanya",
    institution_name_placeholder: "Injiza izina ry'ikigo",
    institution_name: "Izina ry'ikigo",
    add_institution: "Ongeramo ikigo",
    edit_institution: "Hindura ikigo",
    delete_institution: "Siba ikigo",
    export_: "Gusohora",
    submit: "Ohereza",
    institution_name_required: "Izina ry'ikigo rirakenewe",
    add_institution_success: "Ikigo cyashyizwemo neza",
    department: "Ikigo",
    add_department: "Ongeramo ikigo",
    delete_department: "Siba ikigo",
    edit_department: "Hindura ikigo",
    department_name: "Izina ry'ikigo",
    department_name_placeholder: "Injiza izina ry'ikigo",
    department_name_required: "Izina ry'ikigo rirakenewe",
    add_department_success: "Ikigo cyashyizwemo neza",
    update_department_success: "Ikigo cyavuguruwe neza",
    error_add_department_success:
      "Ntibishoboka kongera ikigo, nyamuneka gerageza nanone",
    error_update_department_success:
      "Ntibishoboka kuvugurura ikigo, nyamuneka gerageza nanone",
    error_delete_department_success:
      "Ntibishoboka gusiba ikigo, nyamuneka gerageza nanone",
    role: "Umwanya",
    add_role: "Ongeramo umwanya",
    role_name: "Izina ry'umwanya",
    role_name_placeholder: "Injiza izina ry'umwanya",
    role_name_required: "Izina ry'umwanya rirakenewe",
    add_role_success: "Umwanya yashyizwemo neza",
    permission: "Uburenganzira",
    add_permission: "Ongeramo uburenganzira",
    edit: "Hindura",
    delete_: "Siba",
    cancel: "Siba",
    institution: "Ikigo",
    unable_fetch_institutions: "Ntibishoboka kubona amakuru y'ibigo",
    unable_fetch_departments: "Ntibishoboka kubona amashami",
    services_title: "Serivisi",
    services_description: "Gucunga serivisi zisabwa n'ibikorwa rusange",
    add_service: "Ongeramo serivisi",
    individual: "Igiti cy'umuntu",
    edit_service: "Hindura serivisi",
    delete_service: "Siba serivisi",
    service_name: "Izina rya serivisi",
    service_name_placeholder: "Injiza izina rya serivisi",
    service_name_required: "Izina rya serivisi rirakenewe",
    category: "Icyiciro",
    categories: "Amakipe",
    filter_by_category: "Filita hakurikijwe icyiciro",
    add_translation: "Ongeramo ubusobanuro",
    max_number_days: "Igihe ntarengwa cyo gusubiza",
    remove_translation: "Siba ubusobanuro",
    maxDays_required: "Igihe ntarengwa cyo gusubiza kirakenewe",
    category_required: "Icyiciro kirakenewe",
    required_documents: "Inyandiko zisabwa",
    questionnaire: "Ibibazo",
    translations: "Ubusesenguzi",
    signatures: "Amasinywe",
    approval_levels: "Urwego rw'ibyemezo",
    output_letter: "Ibaruwa isohoka",
    publish: "Sohora",
    avg_response_time: "Igihe cyo gusubiza gisanzwe",
    avg_response_time_abbr: "Avg.RT",
    add_document: "Ongeramo inyandiko",
    edit_document: "Hindura inyandiko",
    delete_document: "Siba inyandiko",
    document_name_required: "Izina ry'inyandiko rirakenewe",
    document_name: "Izina ry'inyandiko",
    file_extension: "Ihererekanyabubiko",
    save: "Bika",
    required_document: "Inyandiko zisabwa",
    size_limit_required: "Gupima urwego rw'ubunini",
    file_extension_required: "Guhitamo inyandiko ya format birakenewe",
    data_title: "Umutwe w'amakuru",
    input_type: "Ubwoko bw'inyandiko",
    input_requirement: "Ibisabwa ku nyandiko",
    created_at: "Yaremwe Ku",
    actions: "Ibikorwa",
    add_data: "Ongeramo amakuru",
    delete: "Siba amakuru",
    edit_data: "Hindura amakuru",
    data_title_required: "Umutwe w'amakuru urakenewe",
    input_type_required: "Ubwoko bw'inyandiko burakenewe",
    input_requirement_required: "Ibisabwa ku nyandiko birakenewe",
    data_title_placeholder: "Injiza umutwe w'amakuru",
    mandatory: "Bisabwa",
    optional: "Bishobora kuba",
    text: "Andika",
    checkbox: "Ishusho y'itandukanye",
    translation: "Ubusobanuro",
    default_translation: "Ubusobanuro busanzwe",
    language: "Ururimi",
    size_limit: "Inyandiko ikenewe",
    email_address: "Imeri",
    enter_email: "Injiza Imeri",
    password: "Ijambo ry'ibanga",
    enter_password: "Injiza Ijambo ry'ibanga",
    email_required: "Imeri irakenewe",
    password_required: "Ijambo ry'ibanga rirakenewe",
    wrong_email_password: "Imeri cyangwa ijambo ry'ibanga ni nabi",
    invalid_email: "Imeri itari yo",
    success_delete_department: "Ikigo cyasibwe neza",
    job_positions: "Imyanya y'akazi",
    position_name: "Izina ry'umwanya",
    add_position_success: "Umwanya yongerewe neza",
    error_add_position: "Ntibishoboka kongera umwanya",
    update_position_success: "Umwanya wavuguruwe neza",
    error_update_position: "Ntibishoboka kuvugurura umwanya",
    error_delete_position: "Ntibishoboka gusiba umwanya",
    unable_fetch_positions: "Ntibishoboka kubona imyanya y'akazi",
    add_job_position: "Ongeramo umwanya w'akazi",
    delete_job_position: "Siba umwanya w'akazi",
    edit_job_position: "Hindura umwanya w'akazi",
    job_position: "Umwanya w'akazi",
    job_position_name: "Izina ry'umwanya w'akazi",
    available: "Birahari",
  },
};

export default data;
