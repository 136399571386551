import { Route, Routes, Outlet } from "react-router-dom";
import SignupScreen from "../screens/User/SignupScreen";
import UserScreen from "../screens/User";
import LoginScreen from "../screens/User/Authentication/LoginScreen";
// import VerifyPhoneEmailScreen from "../screens/AdminDashboard/Authentication/VerifyPhoneEmailScreen";
const routes = [
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/signup",
    element: <SignupScreen />,
  },
  {
    path: "/signup/:serviceId",
    element: <SignupScreen />,
  },
  {
    path: "/user/*",
    element: <UserScreen />,
  },
//   {
//     path: "/verify_phone_email",
//     element: <VerifyPhoneEmailScreen />,
//   },
];

export default () => {
  return (
    <>
    <Routes>
    {routes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ))}
  </Routes>
      <Outlet />
    </>
  );
};
