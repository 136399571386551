import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Button } from "../Button";

const Footer = (props) => {
  return (
    <div className="modal-footer mt-2" style={{ borderTop: 0 }}>
      <Button
        text={language[props.defaultLanguage].submit}
        onPress={props.onSubmit}
        isSubmitting={props.isSubmitting}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Footer);
