import React, { useEffect, useState } from "react";
import { StatCard } from "./cards/StatCard";
import { CapitalGrowth } from "./cards/CapitalGrowth";
import { FinancialLinkage } from "./cards/FinancialLinkage";
import { QuarterlyChart } from "./charts/QuarterlyChart";
import {
  DashboardBarChart,
  DashboardDoughnutChart,
  DashboardLineChart,
} from "../Charts/DoughnutChart";
import icons from "../../constants/icons";
import { Button } from "../Button";
import { useParams } from "react-router-dom";
import { fetchSurveyDashboard } from "../../utils/queries/surveysQuery";
import { Loading } from "../Loader";

const quarterlyData = [
  { quarter: "Q1", values: [80, 40, 60] },
  { quarter: "Q2", values: [70, 45, 85] },
  { quarter: "Q3", values: [60, 42, 55] },
  { quarter: "Q4", values: [40, 48, 75] },
];

const institutions = [
  { name: "Banks", connected: true },
  {
    name: "VSLA",
    connected: true,
    details: ["VSLA Member", "VSLA capital improved"],
  },
  { name: "MIF", connected: false },
];

function DashboardContainer(props) {
  const { uniqueNumber } = useParams();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const getChartData = () => {
    if (!!props.categoryType) {
      fetchSurveyDashboard(
        {
          uniqueIdentifier: uniqueNumber, //Fsc-50714-3
          serviceType: props.keyPoint, //"FSC_SKILLS_ENHANCEMENT",
          serviceName: props.categoryType || "FSC",
          quarter: props.quarter,
          startDate: "",
          endDate: "",
          keySearch: "KPI",
        },
        (status, res) => {
          setLoading(status);
          setChartData(res);
        }
      );
    }
  };
  useEffect(() => {
    getChartData();
  }, [props.keyPoint, props.categoryType, props.quarter]);

  return (
    <div className="min-vh-100 bg-light py-4">
      <div className="container-fluid px-4">
        <h1 className="h3 mb-4">
          {props.keyPoint === "access_to_finance"
            ? "Finance Performance Overview"
            : props.keyPoint === "access_to_market"
            ? "Access to Market"
            : props.keyPoint === "skills_enhancement"
            ? "Skills Enhancement"
            : props.keyPoint === "value_chain_and_income" &&
              "Value Chain and Income"}
        </h1>

        {props.keyPoint === "access_to_finance" && (
          <AccessToFinance data={chartData} loading={loading} />
        )}
        {props.keyPoint === "access_to_market" && (
          <AccessToMarket data={chartData} loading={loading} />
        )}
        {props.keyPoint === "skills_enhancement" && (
          <SkillsEnhancement data={chartData} loading={loading} />
        )}
        {props.keyPoint === "value_chain_and_income" && (
          <ValueChainAndIncome data={chartData} loading={loading} />
        )}
      </div>
    </div>
  );
}

export default DashboardContainer;

const AccessToFinance = (props) => {
  const { data, loading } = props;
  return (
    <>
      <div className="row g-4 mb-4">
        <div className="col-md-4">
          <StatCard
            title="Loan Receipt"
            value={(data?.summary?.loanReceipt || 0).toLocaleString()}
            loading={loading}
            // change={10}
            // sparklineData={[30, 70, 90, 70, 85, 60]}
          />
        </div>
        <div className="col-md-4">
          <StatCard
            title="Equipment Financing"
            value={(data?.summary?.equipmentFinancing || 0).toLocaleString()}
            // change={-5}
            // sparklineData={[60, 40, 80, 55, 70, 50]}
            loading={loading}
          />
        </div>
        <div className="col-md-4">
          <StatCard
            title="New jobs created status"
            value={data?.summary?.newJobCreated}
            loading={loading}
            // change={5}
            // doughnutData={{ percentage: 50 }}
          />
        </div>
      </div>

      <div className="row g-4 mb-4">
        <div className="col-md-6">
          <CapitalGrowth
            baselineCapital={data?.summary?.baselineCapital || 0}
            currentCapital={data?.summary?.currentCapital || 0}
            growthFactors={data?.growthFactors || []}
            loading={loading}
          />
        </div>
        <div className="col-md-6">
          <FinancialLinkage
            institutions={(data?.financialInstitutionLinkage || []).map(
              (item) => ({ name: item, connected: true })
            )}
            loading={loading}
          />
        </div>
      </div>

      {false && (
        <div className="row">
          <div className="col-12">
            <QuarterlyChart data={quarterlyData} loading={loading} />
          </div>
        </div>
      )}
    </>
  );
};

const AccessToMarket = (props) => {
  const { data, loading } = props;
  return (
    <>
      <div className="row g-4 mb-4">
        <div className="col-md-4">
          <StatCard
            title="Market Linkage"
            value={(data?.marketLinkage || []).length}
            // change={10}
            // sparklineData={[30, 70, 90, 70, 85, 60]}
            loading={loading}
          />
        </div>
        <div className="col-md-4">
          <StatCard
            title="Customer base"
            value={data?.summary?.customerBase || 0}
            loading={loading}
            // change={-5}
            // sparklineData={[60, 40, 80, 55, 70, 50]}
          />
        </div>
        <div className="col-md-4">
          <StatCard
            title="Registered Farmers"
            value={data?.summary?.registeredFarmer || 0}
            loading={loading}
            // change={5}
            // doughnutData={{ percentage: 5 }}
          />
        </div>
      </div>

      <div className="row g-4 mb-4">
        <div className="col-md-6">
          <ChartCard title="Customer Growth Chart">
            <DashboardDoughnutChart
              hideTitle
              chartData={{
                labels: [
                  `Gained Customers(${data?.summary?.customerGained || 0})`,
                  `Baseline Customers(${data?.summary?.customerBase || 0})`,
                ],
                datasets: [
                  {
                    data: [
                      data?.summary?.customerGained || 0,
                      data?.summary?.customerBase || 0,
                    ],
                    backgroundColor: [
                      "rgba(255,109,0,0.6)",
                      "rgba(0, 200, 80, 0.6)",
                    ],
                    borderColor: ["rgba(255,109,0, 1)", "rgba(0, 200, 80, 1)"],
                    borderWidth: 1,
                  },
                ],
              }}
              loading={false}
            />
          </ChartCard>
        </div>
        <div className="col-md-6">
          <ChartCard title="Sales Volume Change">
            <DashboardLineChart
              chartData={{
                labels: ["Tons"],
                datasets: [
                  {
                    label: "Tons",
                    data: data?.salesVolumeChange || [],
                    backgroundColor: "rgba(0, 200, 80,0.6)",
                    borderColor: "rgba(0, 200, 80, 1)",
                    fill: false,
                    borderWidth: 1,
                  },
                ],
              }}
              // legendPosition="right"
              loading={loading}
              hideTitle
            />
          </ChartCard>
        </div>
        <div className="col-md-6">
          <ChartCard
            title="New Jobs Created Per Gender"
            footer={`Total jobs created ${
              (data?.jobCreated?.female || 0) + (data?.jobCreated?.male || 0)
            }, ${data?.jobCreated?.young || 0} young, ${
              (data?.jobCreated?.female || 0) +
              (data?.jobCreated?.male || 0) -
              (data?.jobCreated?.young || 0)
            } no youth`}
          >
            <DashboardDoughnutChart
              hideTitle
              chartData={{
                labels: [
                  `Female(${data?.jobCreated?.female || 0})`,
                  `Male(${data?.jobCreated?.male || 0})`,
                  `Young(${data?.jobCreated?.young || 0})`,
                ],
                datasets: [
                  {
                    data: [
                      data?.jobCreated?.female || 0,
                      data?.jobCreated?.male || 0,
                      data?.jobCreated?.young || 0,
                    ],
                    backgroundColor: [
                      "rgba(0, 200, 80,0.6)",
                      "rgba(255,109,0,0.6)",
                      "rgba(160,200,0,0.6)",
                    ],
                    borderColor: [
                      "rgba(0, 200, 80,1)",
                      "rgba(255,109,0,1)",
                      "rgba(160,200,0,1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              loading={false}
            />
          </ChartCard>
        </div>
      </div>
    </>
  );
};

const SkillsEnhancement = (props) => {
  const { data, loading } = props;
  return (
    <>
      <div className="row g-4 mb-4">
        <div className="col-md-4">
          <div className="trainings-container">
            <h2>Trainings Attended</h2>
            {loading ? (
              <Loading />
            ) : (
              <ul>
                {!!(data?.trainingAttended || []).length ? (
                  (data?.trainingAttended || []).map((training, index) => (
                    <li key={index}>{training}</li>
                  ))
                ) : (
                  <li>No Training Attended</li>
                )}
              </ul>
            )}
          </div>
        </div>
        {false && (
          <div className="col-md-4">
            <StatCard
              title="Equipment Financing"
              value={data?.registeredFarmer || 0}
              // change={5}
              // doughnutData={{ percentage: 5 }}
              loading={loading}
            />
          </div>
        )}
      </div>

      <div className="row g-4 mb-4">
        <div className="col-md-6">
          <ChartCard title="Skill utilization Percentage">
            <DashboardDoughnutChart
              hideTitle
              chartData={{
                labels: [
                  `Business Model Development(16%)`,
                  `Accounting Management(70%)`,
                  `GAP(14%)`,
                ],
                datasets: [
                  {
                    data: [16, 70, 14],
                    backgroundColor: [
                      "rgba(133,195,138,0.6)",
                      "rgba(220,130,54,0.6)",
                      "rgba(175,207,60,0.6)",
                    ],
                    borderColor: [
                      "rgba(133,195,138,1)",
                      "rgba(220,130,54,1)",
                      "rgba(175,207,60,1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              loading={loading}
            />
          </ChartCard>
        </div>
        <div className="col-md-6">
          <ChartCard
            title="Impact of the knowledge sharing"
            footer={`Total farmers: ${
              (data?.impactKnowledgeSharing?.male || 0) +
                data?.impactKnowledgeSharing?.female || 0
            }, ${data?.impactKnowledgeSharing?.female || 0} female, ${
              data?.impactKnowledgeSharing?.male || 0
            } male`}
          >
            <DashboardDoughnutChart
              hideTitle
              chartData={{
                labels: [
                  `Male (${data?.impactKnowledgeSharing?.male || 0})`,
                  `Female (${data?.impactKnowledgeSharing?.female || 0})`,
                ],
                datasets: [
                  {
                    data: [
                      data?.impactKnowledgeSharing?.male || 0,
                      data?.impactKnowledgeSharing?.female || 0,
                    ],
                    backgroundColor: [
                      "rgba(113,143,79,0.6)",
                      "rgba(255,109,0,0.6)",
                    ],
                    borderColor: ["rgba(113,143,79,1)", "rgba(255,109,0,1)"],
                    borderWidth: 1,
                  },
                ],
              }}
              loading={loading}
            />
          </ChartCard>
        </div>
        <div className="col-md-6">
          <ChartCard title="Income Generation">
            <DashboardBarChart
              hideTitle
              chartData={{
                labels: ["Baseline", "Current Income"],
                datasets: [
                  {
                    label: "Dataset 1",
                    backgroundColor: "rgba(0, 200, 80, 0.6)",
                    borderColor: "rgba(0, 200, 80, 1)",
                    borderWidth: 1,
                    data: [200000, 500000],
                  },
                  {
                    label: "Dataset 2",
                    backgroundColor: "rgba(113,143,79, 0.6)",
                    borderColor: "rgba(113,143,79, 1)",
                    borderWidth: 1,
                    data: [250000, 400000],
                  },
                ],
              }}
              isStackedChart={false}
              isHorizontalBar={false}
              loading={loading}
            />
          </ChartCard>
        </div>
      </div>
    </>
  );
};

const ValueChainAndIncome = (props) => {
  const { data, loading } = props;
  return (
    <>
      <div className="row g-4 mb-4">
        <div className="col-md-8">
          <div className="trainings-container">
            <h2>New Source of Income</h2>
            {loading ? (
              <Loading />
            ) : (
              <ul>
                {(data?.newSourceIncome || []).length ? (
                  (data?.newSourceIncome || []).map((sourceIncome, index) => (
                    <li key={index}>{sourceIncome}</li>
                  ))
                ) : (
                  <li>No Source of Income</li>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="col-md-4">
          <StatCard
            title="New Value Chain"
            value={data?.newValueChain}
            subTitle={data?.newValueChains}
            // change={-5}
            // sparklineData={[60, 40, 80, 55, 70, 50]}
            loading={loading}
          />
        </div>
      </div>

      <div className="row g-4 mb-4">
        <div className="col-md-6">
          <ChartCard title="Change in income">
            <DashboardBarChart
              hideTitle
              chartData={{
                labels: ["Increased", "Decreased", "No Change"],
                datasets: [
                  {
                    label: "Dataset 1",
                    data: [12000, 7000, 19000],
                    backgroundColor: "rgba(0, 200, 80, 0.6)",
                    borderColor: "rgba(0, 200, 80, 1)",
                    borderWidth: 1,
                  },
                  {
                    label: "Dataset 1",
                    data: [18000, 5000, 23000],
                    backgroundColor: "rgba(113,143,79, 0.6)",
                    borderColor: "rgba(113,143,79, 1)",
                    borderWidth: 1,
                  },
                  {
                    label: "Dataset 1",
                    data: [30000, 12000, 42000],
                    backgroundColor: "rgba(160,200,0, 0.6)",
                    borderColor: "rgba(160,200,0, 1)",
                    borderWidth: 1,
                  },
                ],
              }}
              isStackedChart={false}
              isHorizontalBar={false}
              loading={false}
            />
          </ChartCard>
        </div>
        <div className="col-md-6">
          <ChartCard title="">
            <DashboardBarChart
              hideTitle
              chartData={data?.sourceIncomeChart}
              isStackedChart={false}
              isHorizontalBar={false}
              loading={loading}
            />
          </ChartCard>
        </div>
      </div>
    </>
  );
};

export const ChartCard = ({
  title,
  subTitle,
  footer,
  hideBtn = false,
  children,
}) => {
  return (
    <div className="card h-100">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between">
          <div className="">
            <h3 className="h5 mb-4">{title}</h3>
            {subTitle && (
              <span
                style={{
                  fontSize: "9px",
                }}
                className="text-secondary"
              >
                {subTitle}
              </span>
            )}
          </div>

          {!hideBtn && (
            <div className="d-flex align-items-center gap-2">
              <Button
                className="btn-primary btn-sm"
                text="Filter"
                icon={icons.filter}
                onPress={() => {}}
              />
              <Button
                className="btn-primary btn-sm"
                text="Export"
                icon={icons.download}
                onPress={() => {}}
              />
            </div>
          )}
        </div>
        <div>{children}</div>
        {!!footer && <p>{footer}</p>}
      </div>
    </div>
  );
};
