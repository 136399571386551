import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import language from "../../language";
import { apiInstance } from "../axiosInstance";

export const getConversations = async (lng = "english") => {
  try {
    const userLoggedIn = await getStorage();
    const { token, role } = userLoggedIn;

    let url = "/conversations";
    const options = {
      method: "GET",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryRes = await apiInstance(options);
    const conversations = queryRes.data.map((conversation) => {
      const users = conversation.members.filter(
        (member) => member._id !== userLoggedIn.id
      );
      return {
        _id: conversation._id,
        users,
        lastMessage: {
          ...conversation?.lastMessage,
          isOwn: conversation?.lastMessage?.sender?._id === userLoggedIn.id,
        },
      };
    });
    return conversations;
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_surveys);
  }
};

export const createConversation = async ({ lng = "english", users }) => {
  try {
    const loggedUser = await getStorage();
    const allUsers = [
      ...users,
      {
        _id: loggedUser.id,
        active: true,
        accessRole: loggedUser?.accessRole?._id ?? undefined,
        firstName: loggedUser.firstname ?? "System",
        lastName: loggedUser.lastname ?? "Admin",
        email: loggedUser.email,
        username: loggedUser.email,
        accountType: loggedUser.accountType,
      },
    ];
    const requestBody = {
      members: allUsers,
    };
    const options = {
      method: "POST",
      url: `/conversations`,
      data: requestBody,
      headers: {
        Authorization: `Bearer ${loggedUser.token}`,
      },
    };
    const queryRes = await apiInstance(options);
    const data = queryRes.data;
    return { _id: data._id, users };
  } catch (error) {
    console.log(error);
    // toastMessage("error", language[lng].unable_start_conversation);
    return false;
  }
};

export const fetchUsers = async (lng = "english", accountType) => {
  try {
    const loggedUser = await getStorage();

    const options = {
      method: "GET",
      url: `/account`,
      params: {
        limit: 100,
        active: true,
      },
      headers: {
        Authorization: `Bearer ${loggedUser.token}`,
      },
    };
    // if (
    //   !!accountType &&
    //   !(loggedUser.accountType || loggedUser.loginType)
    //     .toLowerCase()
    //     .includes("admin")
    // ) {
    options.params.accountType = accountType;
    // }
    const queryRes = await apiInstance(options);
    const data = queryRes.data;

    return data.filter((user) => user._id !== loggedUser.id);
  } catch (error) {
    console.log(error);
    toastMessage("error", language[lng].unable_fetch_accounts);
  }
};
export const fetchSMSUsers = async (lng = "english", params) => {
  try {
    const loggedUser = await getStorage();

    const options = {
      method: "GET",
      url: `/account/sms-users`,
      params,
      headers: {
        Authorization: `Bearer ${loggedUser.token}`,
      },
    };

    const queryRes = await apiInstance(options);
    const data = queryRes.data;

    return data.filter((user) => user._id !== loggedUser.id);
  } catch (error) {
    console.log(error);
    toastMessage("error", language[lng].unable_fetch_accounts);
  }
};
export const getMessages = async ({ lng = "english", conversationId }) => {
  try {
    const loggedUser = await getStorage();

    const options = {
      method: "GET",
      url: `/messages`,
      params: {
        conversationId,
      },
      headers: {
        Authorization: `Bearer ${loggedUser.token}`,
      },
    };
    const queryRes = await apiInstance(options);
    const data = queryRes.data;
    const messages = data.map((message) => ({
      ...message,
      isOwn: message.sender._id === loggedUser.id,
    }));
    return messages;
  } catch (error) {
    console.log(error);
    toastMessage("error", language[lng].unable_fetch_accounts);
  }
};
export const sendMessageApi = async ({
  lng = "english",
  conversationId,
  text,
}) => {
  try {
    const loggedUser = await getStorage();
    const requestBody = {
      conversationId,
      sender: {
        _id: loggedUser.id,
        active: true,
        accessRole: loggedUser?.accessRole?._id ?? undefined,
        firstName: loggedUser.firstname ?? "System",
        lastName: loggedUser.lastname ?? "Admin",
        email: loggedUser.email,
        username: loggedUser.email,
        accountType: loggedUser.accountType,
      },
      text,
    };

    const options = {
      method: "POST",
      url: `/messages`,
      data: requestBody,
      headers: {
        Authorization: `Bearer ${loggedUser.token}`,
      },
    };
    const queryRes = await apiInstance(options);
    const data = queryRes.data;
    // const messages = data.map((message) => ({
    //   ...message,
    //   isOwn: message.sender._id === loggedUser.id,
    // }));
    return data;
  } catch (error) {
    console.log(error);
    toastMessage("error", language[lng].unable_fetch_accounts);
  }
};

const fetchData = async (lng = "english", params) => {
  try {
    const {
      page,
      limit,
      type,
      category,
      user,
      id,
      requestType,
      startCreatedAt,
      endCreatedAt,
    } = params;

    const userLoggedIn = await getStorage();
    const { token, role } = userLoggedIn;

    let requestBody = {
      ...params,
      page: page || 1,
      limit: limit || 10,
      category,
      user,
      id,
      type: requestType,
      startCreatedAt,
      endCreatedAt,
    };

    if (role === "user") {
      requestBody.user = [userLoggedIn.id];
    }

    let url =
      requestBody["rank"] || requestBody["order"]
        ? "/survey/analytics"
        : "/survey";
    const options = {
      method: "GET",
      url: url,
      params: requestBody,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      data = formatSelectData(data, "description", "_id");
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_surveys);
  }
};

export const deleteSurvey = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `/survey`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await apiInstance(options);

    toastMessage("success", language[lng].success_delete_survey);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_survey);
  }
};

export default fetchData;

export const fetchCombinedSurvey = async (lng = "english", params) => {
  try {
    const {
      page,
      limit,
      type,
      category,
      user,
      id,
      requestType,
      startCreatedAt,
      endCreatedAt,
    } = params;

    const userLoggedIn = await getStorage();
    const { token, role } = userLoggedIn;

    let requestBody = {
      ...params,
      category,
      user,
      id,
      type: requestType,
      startCreatedAt,
      endCreatedAt,
    };

    if (role === "user") {
      requestBody.user = [userLoggedIn.id];
    }

    let url = "/survey/combine";
    const options = {
      method: "GET",
      url: url,
      params: requestBody,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes;

    return { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_surveys);
  }
};
