import moment from "moment";
import language from "../language";

export default (lng) => [
  {
    name: language[lng].today,
    startDate: moment().startOf("D").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().endOf("D").format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    name: language[lng].this_week,
    startDate: moment()
      .startOf("D")
      .subtract(6, "days")
      .format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().endOf("D").format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    name: language[lng].this_month,
    value: moment().format("YYYY-MM-DD"),
    startDate: moment()
      .startOf("D")
      .subtract(29, "days")
      .format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().endOf("D").format("YYYY-MM-DD HH:mm:ss"),
  },
  // {
  //   name: "Customize date",
  //   startDate: moment()
  //     .startOf("D")
  //     .subtract(30, "days")
  //     .format("YYYY-MM-DD HH:mm:ss"),
  //   endDate: moment().endOf("D").format("YYYY-MM-DD HH:mm:ss"),
  // },
];
