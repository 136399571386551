import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "./styles.css";
import AppMarker from "./AppMarker";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Loading } from "../Loader";

const Map = (props) => {
  let group_by_address = {};

  for (let i = 0; i < (props?.data?.length || []); i++) {
    const address = props.data[i].address && props.data[i].address;

    if (!group_by_address[address]) {
      group_by_address[address] = [];
    }

    group_by_address[address].push(props.data[i]);
  }

  const addresses = Object.keys(group_by_address);
  return (
    <div className="open-street-map-container">
      {props.isLoading ? (
        <Loading className="loader-fullscreen" />
      ) : (
        <MapContainer
          scrollWheelZoom={true}
          zoomControl={true}
          center={[-1.960539, 30.125772]}
          zoom={13}
          keyboard={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {addresses.map((addr, a) => {
            return (
              <MarkerClusterGroup
                key={a}
                spiderfyDistanceMultiplier={1}
                showCoverageOnHover={false}
                maxClusterRadius={35}
              >
                {group_by_address[addr].map((marker, m) => {
                  return <AppMarker marker={marker} key={m} />;
                })}
              </MarkerClusterGroup>
            );
          })}
        </MapContainer>
      )}
    </div>
  );
};

export default Map;
