import { connect } from "react-redux";
import icons from "../../constants/icons";
import { Button } from "../Button";
import { Input } from "../Input";
import "./styles.css";
import language from "../../language";
import { Link } from "react-router-dom";

const CardHeader = (props) => {
  return (
    <div>
      <div
        className={`card-header-container ${
          props.borderBottom ? "border-bottom" : ""
        }`}
        style={props.styles}
      >
        <div style={{ flex: 1 }}>
          {props.title && <h3>{props.title}</h3>}
          {props.amount && (
            <h2 style={{ marginTop: "1rem" }} className="text-bold">
              {props.amount}
            </h2>
          )}
        </div>
        {/* {props.closeButton && (
          <div className="card-header-action-btn">
            <div className="icon-container">
              <span className={`${process.env.REACT_APP_ICONS_TYPE}`}>
                {icons.close}
              </span>
            </div>
          </div>
        )} */}
        {props.expandMore && (
          <div className="card-header-action-btn">
            <div className="icon-container">
              <span className={`${process.env.REACT_APP_ICONS_TYPE}`}>
                {icons.chevronDown}
              </span>
            </div>
          </div>
        )}
      </div>
      {props.isSearch && (
        <div style={{ padding: "0 0.8rem" }}>
          <Input
            placeholder="Search..."
            leftIcon={icons.search}
            value={props.searchValue}
            onChange={(e) => props.handleSearch(e)}
            className="sm"
          />
        </div>
      )}
      {props.reset && (
        <div style={{ padding: "0 0.8rem", paddingBottom: "0.8rem" }}>
          <Link
            to="#"
            className="text-primary"
            onClick={props.handleResetFilter}
          >
            {language[props.defaultLanguage].reset_filters}
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CardHeader);
