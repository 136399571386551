import React from "react";

export function ChartBar({ value, maxValue, colorClass }) {
  return (
    <div
      className={`flex-grow-1 rounded-top ${colorClass}`}
      style={{ height: `${(value / maxValue) * 100}%` }}
    />
  );
}
