import React from "react";
import woman from "../../../assets/woman.png";
import man from "../../../assets/man.png";
import surveyQuery from "../../../utils/queries/surveysQuery";
import { getStorage } from "../../../utils/storage";
import { connect } from "react-redux";
import { Table } from "../../../components/Table";
import dynamicTableData from "../../../utils/dynamicTableData";
import { getProfile } from "../../../utils/queries/farmersQuery";
import { getDistinctDomainInterventions } from "../../../utils/extractService";
import { Loading } from "../../../components/Loader";

class ProfileOverview extends React.Component {
  state = {
    isLoading: false,
    isLoadingInfo: true,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    error: {},
    csvData: [],
    user: {},
    totalPageCount: 0,
    tableHeaders: [],
    profile: {},
    type: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    await this.fetchParticipantProfile(user);
    await this.handleFetchData();
  };

  handleFetchData = async () => {
    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.handleFetchData();
    }
  }

  returnFilters() {
    let _filters = {
      ["service.type"]: "KPI",
      ["questionnaireAnswer.answer[]"]: this.state.user?.uniqueId,
      limit: 100,
    };

    return _filters;
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await surveyQuery(
        defaultLanguage,
        this.returnFilters()
      );

      this.setState({
        type: data[0].service.name,
        data,
        totalPageCount: count,
        domainInterventions: getDistinctDomainInterventions(data),
        isLoading: false,
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchParticipantProfile = async (user) => {
    const data = await getProfile(this.state.user.language, user?.uniqueId);
    this.setState({ profile: data, isLoadingInfo: false });
  };

  render() {
    return (
      <div className="container my-4">
        {/* Profile Header */}
        {this.state.isLoadingInfo ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <Loading />
          </div>
        ) : (
          <div>
            <div className="d-flex align-items-center mb-2">
              <img
                src={
                  this.state.profile?.gender?.toLowerCase() === "female"
                    ? woman
                    : man
                }
                alt="User"
                className="rounded-circle"
                width="50"
                height="50"
              />
              <div className="ms-3">
                <h5 className="mb-0">
                  {this.state.profile?.firstname} {this.state.profile?.lastname}
                </h5>
                <p className="text-muted">
                  {this.state.profile.category_type}, age{" "}
                  {this.state.profile?.age}
                </p>
              </div>
            </div>
            {/* Personal Info */}
            <div className="mb-4">
              <h6>Personal Info</h6>
              <hr />
              <div className="row">
                <div className="col-3">
                  <p className="text-primary">Gender:</p>
                  <p>{this.state.profile.gender}</p>
                </div>
                <div className="col-3">
                  <p className="text-primary">Age:</p>
                  <p>{this.state.profile.age}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-3">
                  <p className="text-primary">Location:</p>
                  <p>{this.state.profile.district}</p>
                </div>
                <div className="col-3">
                  <p className="text-primary">Occupation:</p>
                  <p>{this.state.profile.occupation}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* KPIs Table */}
        {!this.state.isLoadingInfo && (
          <div className="mb-4">
            <h6>KPIs</h6>
            <Table
              data={this.state.data}
              isLoading={this.state.isLoading}
              headers={this.state.tableHeaders}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ProfileOverview);
