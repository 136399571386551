import React from 'react';
import { TrendingUp, TrendingDown } from "lucide-react";

export function StatCard({
  title,
  value,
  change,
  subTitle,
  sparklineData,
  doughnutData,
  lineChartData,
}) {
  return (
    <div className="card h-100">
      <div className="card-body">
        <p className="text-muted small mb-1">{title}</p>
        <div className="d-flex align-items-baseline gap-2">
          <h3 className="h2 mb-0">{value}</h3>
          {change !== undefined && (
            <span
              className={`d-flex align-items-center small ${
                change >= 0 ? "text-success" : "text-danger"
              }`}
            >
              {change >= 0 ? (
                <TrendingUp size={16} />
              ) : (
                <TrendingDown size={16} />
              )}
              {Math.abs(change)}%
            </span>
          )}
        </div>
        {(!!sparklineData || !!doughnutData || !!lineChartData) && (
          <div className="mt-4" style={{ height: "48px" }}>
            <div className="d-flex align-items-end h-100 gap-1">
              {!!sparklineData ? (
                sparklineData.map((value, index) => (
                  <div
                    key={index}
                    className="bg-success rounded-top"
                    style={{ width: "8px", height: `${value}%` }}
                  />
                ))
              ) : !!doughnutData ? (
                <div
                  className="doughnut-chart"
                  style={{ width: "45px", height: "45px" }}
                >
                  <div
                    className="doughnut-fill"
                    style={{
                      background: `conic-gradient(
                        green ${doughnutData.percentage}deg,
                        lightgray ${doughnutData.percentage}deg
                      )`,
                    }}
                  ></div>
                </div>
              ) : (
                !!lineChartData && (
                  <div
                    className="line-chart"
                    style={{ width: "45px", height: "45px" }}
                  >
                    <svg viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M 5 40 L 15 30 L 25 20 L 35 35 L 45 10"
                        stroke="blue"
                        fill="none"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                )
              )}
            </div>
          </div>
        )}
        {subTitle && (
          <span className="text-sm text-truncate mt-1 mb-0">{subTitle}</span>
        )}
      </div>
    </div>
  );
}