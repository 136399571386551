// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-list-container .service-item-content {
  max-height: 60vh;
  /* overflow-y: auto; */
  background-color: var(--light-color);
}

.service-list-container .loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
}

.service-list-container .service-list-item {
  display: flex;
  min-height: 55px;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--text-color-5);
  transition: background-color 0.4s;
}

.service-list-container .service-list-item .name {
  flex: 1 1;
}

.service-list-container .service-list-item span {
  color: var(--text-color);
  font-weight: var(--font-weight);
}

.service-list-container .service-list-item:hover {
  background-color: var(--text-color-5);
  cursor: pointer;
}

.service-item-content-sidebar {
  border-right: 1px solid var(--bg-color);
  overflow-y: auto;
  max-height: 70vh;
}

.service-item-content-sidebar .service-item-content-sidebar-item {
  min-height: 45px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  opacity: 0.5;
  cursor: pointer;
}

.service-item-content-sidebar .service-item-content-sidebar-item:hover,
.service-item-content-sidebar .service-item-content-sidebar-item.active {
  opacity: 1;
  background-color: var(--bg-color);
}

.service-list-service_container {
}
`, "",{"version":3,"sources":["webpack://./src/app/components/UserServices/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;EACpB,4CAA4C;EAC5C,iCAAiC;AACnC;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,wBAAwB;EACxB,+BAA+B;AACjC;;AAEA;EACE,qCAAqC;EACrC,eAAe;AACjB;;AAEA;EACE,uCAAuC;EACvC,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,eAAe;AACjB;;AAEA;;EAEE,UAAU;EACV,iCAAiC;AACnC;;AAEA;AACA","sourcesContent":[".service-list-container .service-item-content {\n  max-height: 60vh;\n  /* overflow-y: auto; */\n  background-color: var(--light-color);\n}\n\n.service-list-container .loader-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 30vh;\n}\n\n.service-list-container .service-list-item {\n  display: flex;\n  min-height: 55px;\n  align-items: center;\n  padding: 0.5rem 1rem;\n  border-bottom: 1px solid var(--text-color-5);\n  transition: background-color 0.4s;\n}\n\n.service-list-container .service-list-item .name {\n  flex: 1;\n}\n\n.service-list-container .service-list-item span {\n  color: var(--text-color);\n  font-weight: var(--font-weight);\n}\n\n.service-list-container .service-list-item:hover {\n  background-color: var(--text-color-5);\n  cursor: pointer;\n}\n\n.service-item-content-sidebar {\n  border-right: 1px solid var(--bg-color);\n  overflow-y: auto;\n  max-height: 70vh;\n}\n\n.service-item-content-sidebar .service-item-content-sidebar-item {\n  min-height: 45px;\n  display: flex;\n  align-items: center;\n  padding: 0 1rem;\n  opacity: 0.5;\n  cursor: pointer;\n}\n\n.service-item-content-sidebar .service-item-content-sidebar-item:hover,\n.service-item-content-sidebar .service-item-content-sidebar-item.active {\n  opacity: 1;\n  background-color: var(--bg-color);\n}\n\n.service-list-service_container {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
