import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { HomeAdminDashboardLayout } from "../../Layout";
import adminSidebarMenus from "../../constants/adminSidebarMenus";
import ViewSingleServiceScreen from "./Services/ViewSingleServiceScreen";
import { getStorage } from "../../utils/storage";
import { restartAutoReset } from "../../utils/inactivityRecords";
import SingleFarmerScreen from "./Home/Farmers/SingleFarmerScreen";
import { connect } from "react-redux";
import RegionsCountScreen from "./RegionsCountsScreen";
import ServiceSurveysScreen from "../User/Surveys/ServiceSurveysScreen";
import ViewSingleSurveyScreen from "./Surveys/ViewSingleSurveyScreen";
import DetailScreen from "./DetailScreen";
class HomeDashboardScreen extends React.Component {
  state = {
    menus: [],
  };

  componentDidMount = async () => {
    await adminSidebarMenus(this);

    const user = await getStorage();

    if (!user.token) return (window.location.href = "/landing");

    //restart timer on every user activity
    restartAutoReset({ navigateTo: "/login" });
  };

  render() {
    return (
      <HomeAdminDashboardLayout>
        <Routes>
          {this.props?.menus?.map((route, index) => {
            if (!route?.path) return;
            return (
              <>
                <Route
                  key={index}
                  path={route?.path || "/"}
                  element={route.component}
                />

                {route.menus && (
                  <Route
                    key={index}
                    path={route?.path + "/*"}
                    element={subMenusRoutes(route)}
                  />
                )}
              </>
            );
          })}
          <Route
            path={"/services/single-view/:serviceId"}
            element={<ViewSingleServiceScreen />}
          />
          <Route
            path={"/services/single-view/:serviceId/:serviceName"}
            element={<ViewSingleServiceScreen />}
          />
          <Route
            path={"/service/surveys/:serviceId/:serviceName"}
            element={<ServiceSurveysScreen role="dashboard" />}
          />
          <Route
            path={"/service/surveys/:serviceId/:serviceName/:category"}
            element={<ServiceSurveysScreen role="dashboard" />}
          />
          <Route
            path={"/home/farmer/:farmerId/:urlFilters"}
            element={<SingleFarmerScreen />}
          />
          <Route path={"/analytics/regions"} element={<RegionsCountScreen />} />

          <Route
            path={"/dn/:serviceName/:uniqueNumber"}
            element={<ViewSingleSurveyScreen />}
          />
          <Route
            path={"/kpis/profile/:uniqueNumber"}
            element={<DetailScreen />}
          />
        </Routes>
        <Outlet />
      </HomeAdminDashboardLayout>
    );
  }
}

const subMenusRoutes = (route) => {
  return (
    <Routes>
      <Route path="/" element={route.component} />
      {route?.menus?.map((el, index) => {
        return (
          <>
            <Route key={index} path={el.path} element={el.component} />
            {el.menus && (
              <Route
                key={index}
                path={el.path + "/*"}
                element={subSubMenusRoutes(el)}
              />
            )}
          </>
        );
      })}
    </Routes>
  );
};

const subSubMenusRoutes = (route) => {

  return (
    <Routes>
      <Route path="/" element={route.component} />
      {route?.menus?.map((el, index) => {
        return <Route key={index} path={el.path} element={el.component} />;
      })}
    </Routes>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { menus } = state.Sidebar;
  return {
    defaultLanguage,
    menus,
  };
};

export default connect(mapStateToProps)(HomeDashboardScreen);
