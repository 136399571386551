import React from "react";
import { HomeAnalyticsLayout } from "../../Layout";
import Analytics from "../../components/HomeAnalytics/Analytics";

class HomeScreen extends React.Component {
  render() {
    return (
      <div className="card-body">
        <Analytics />
      </div>
    );
  }
}

export default HomeScreen;
