
export const allUsers = [
  {
    id: "1",
    name: "John Doe",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100&h=100&fit=crop&q=80",
    lastMessage: "You: What's up",
    lastActive: "20 hours ago",
  },
  {
    id: "2",
    name: "Uwamariya Chantal",
    avatar:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop&q=80",
    lastMessage: "You: Hi",
    lastActive: "20 hours ago",
  },
  {
    id: "3",
    name: "Jmv Ngerageze",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=100&h=100&fit=crop&q=80",
    lastMessage: "You: hi",
    lastActive: "20 hours ago",
  },
  {
    id: "4",
    name: "Sarah Wilson",
    avatar:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&h=100&fit=crop&q=80",
    lastMessage: "",
    lastActive: "1 day ago",
  },
  {
    id: "5",
    name: "Michael Chen",
    avatar:
      "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop&q=80",
    lastMessage: "",
    lastActive: "2 days ago",
  },
];
