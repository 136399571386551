import React from "react";
import woman from "../../assets/woman.png";
import man from "../../assets/man.png";
import surveyQuery from "../../utils/queries/surveysQuery";
import { getStorage } from "../../utils/storage";
import { connect } from "react-redux";
import { Table } from "../../components/Table";
import dynamicTableData from "../../utils/dynamicTableData";
import { getProfile, fetchEvaluation } from "../../utils/queries/farmersQuery";
import {
  getDistinctDomainInterventions,
  extractServiceInfo,
  getEvaluationDetailsByUniqueNumber,
} from "../../utils/extractService";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loader";

const url = window.location.href;
const uniqueNumber = url.split("/").pop();

class DetailScreen extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    error: {},
    csvData: [],
    user: {},
    totalPageCount: 0,
    tableHeaders: [],
    profile: {},
    type: "",
    evaluations: [],
    currentEvaluation: [],
    domainInterventions: [],
    isLoadingEvaluation: true,
  };

  componentDidMount = async () => {
    await this.fetchParticipantProfile();
    await this.handleFetchData();
    await this.getEvaluations();
  };

  handleFetchData = async () => {
    const user = await getStorage();
    const { id } = this.props;

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.handleFetchData();
    }

    if (this.props.filters !== prevProps.filters) {
      this.handleFetchData();
    }
  }

  returnFilters() {
    let _filters = {
      ["service.type"]: "KPI",
      ["service.name"]: this.props.category,
      ["questionnaireAnswer.answer[]"]: uniqueNumber,
    };

    return _filters;
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await surveyQuery(
        defaultLanguage,
        this.returnFilters()
      );
      this.setState({
        type: data[0].service.name,
        data,
        totalPageCount: count,
        isLoading: false,
        domainInterventions: getDistinctDomainInterventions(data),
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchParticipantProfile = async () => {
    const data = await getProfile(this.state.user.language, uniqueNumber);
    this.setState({ profile: data });
  };

  getEvaluations = async () => {
    this.setState({ isLoadingEvaluation: true });
    await fetchEvaluation(this.state.user.language, {
      type: "FSC_EVALUATION",
    });
    this.setState({
      // evaluations: extractServiceInfo(data?.data || []),
      // currentEvaluation: getEvaluationDetailsByUniqueNumber(
      //   data?.data,
      //   uniqueNumber
      // ),
      isLoadingEvaluation: false,
    });
  };

  getEvaluationStatus = (evaluation) => {
    return (
      <Button
        text="View Quarters"
        className="bg-success w-50"
        onPress={() => {
          const evaluationTobeTaken = {
            domain: evaluation,
            uniqueNumber: uniqueNumber,
          };
          localStorage.setItem(
            "evaluationTobeTaken",
            JSON.stringify(evaluationTobeTaken)
          );
          window.location.href = `/dashboard/kpis/evaluation/${uniqueNumber}`;
        }}
      />
    );
  };

  getEvaluationBadge(evaluation) {
    return "Not Graduated";
  }

  render() {
    return (
      <div className="container my-4">
        {/* Profile Header */}
        <div className="d-flex align-items-center mb-2">
          <img
            src={
              this.state.profile?.gender?.toLowerCase() === "female"
                ? woman
                : man
            }
            alt="User"
            className="rounded-circle"
            width="50"
            height="50"
          />
          <div className="ms-3">
            <h5 className="mb-0">
              {this.state.profile?.firstname} {this.state.profile?.lastname}
            </h5>
            <p className="text-muted">
              {this.state.profile.category_type}, age {this.state.profile?.age}
            </p>
          </div>
          {/* <button className="btn btn-outline-secondary ms-auto">:</button> */}
        </div>
        {/* <button className="btn btn-outline-secondary mb-2">Edit profile</button> */}
        {/* Personal Info */}
        <div className="mb-4">
          <h6>Personal Info</h6>
          <hr />
          <div className="row">
            <div className="col-3">
              <p className="text-primary">Gender:</p>
              <p>{this.state.profile.gender}</p>
            </div>
            <div className="col-3">
              <p className="text-primary">Age:</p>
              <p>{this.state.profile.age}</p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-3">
              <p className="text-primary">Location:</p>
              <p>{this.state.profile.district}</p>
            </div>
            <div className="col-3">
              <p className="text-primary">Occupation:</p>
              <p>{this.state.profile.occupation}</p>
            </div>
          </div>
        </div>

        {/* KPIs Table */}
        <div className="mb-4">
          <h6>KPIs</h6>
          <Table
            data={this.state.data}
            isLoading={this.state.isLoading}
            headers={this.state.tableHeaders}
          />
        </div>

        {/* Evaluation Table */}
        <div className="mb-4">
          <h6>Taken Evaluations</h6>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Domain of Intervention</th>
                <th>Evaluation Status</th>
                <th>Evaluation Badge</th>
              </tr>
            </thead>
            <tbody>
              {this.state.isLoadingEvaluation ? (
                <tr>
                  <td colSpan={3} align="center">
                    <Loading />
                  </td>
                </tr>
              ) : (
                this.state.domainInterventions.map(
                  (domainIntervention, index) => (
                    <tr key={index}>
                      <td>{domainIntervention}</td>
                      <td align="center">
                        {this.getEvaluationStatus(domainIntervention)}
                      </td>
                      <td>Not Graduated</td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(DetailScreen);
