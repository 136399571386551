import React, { useState } from "react";
import Sidebar from "./Sidebar";
import ChatArea from "./ChatArea";

const MessageList = () => {
  const [startConvo, setStartConvo] = useState(false);
  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-md-4 border-end p-0">
          <Sidebar startConvo={startConvo} setStartConvo={setStartConvo} />
        </div>
        <div className="col-md-8 p-0">
          <ChatArea startConvo={startConvo} setStartConvo={setStartConvo}/>
        </div>
      </div>
    </div>
  );
};

export default MessageList;
