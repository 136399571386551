import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import language from "../../language";
import { apiInstance } from "../axiosInstance";

const fetchData = async (lng = "english", params) => {
  try {
    const {
      page,
      limit,
      type,
      category,
      user,
      id,
      requestType,
      startCreatedAt,
      endCreatedAt,
    } = params;

    const userLoggedIn = await getStorage();
    const { token, role } = userLoggedIn;

    let requestBody = {
      ...params,
      page: page || 1,
      limit: limit || 10,
      category,
      user,
      id,
      type: requestType,
      startCreatedAt,
      endCreatedAt,
    };

    if (role === "user") {
      requestBody.user = [userLoggedIn.id];
    }

    let url =
      requestBody["rank"] || requestBody["order"]
        ? "/survey/analytics"
        : "/survey";
    const options = {
      method: "GET",
      url: url,
      params: requestBody,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      data = formatSelectData(data, "description", "_id");
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_surveys);
  }
};

export const deleteSurvey = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `/survey`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await apiInstance(options);

    toastMessage("success", language[lng].success_delete_survey);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_survey);
  }
};

export default fetchData;

export const fetchCombinedSurvey = async (lng = "english", params) => {
  try {
    const {
      page,
      limit,
      type,
      category,
      user,
      id,
      requestType,
      startCreatedAt,
      endCreatedAt,
    } = params;

    const userLoggedIn = await getStorage();
    const { token, role } = userLoggedIn;

    let requestBody = {
      ...params,
      category,
      user,
      id,
      type: requestType,
      startCreatedAt,
      endCreatedAt,
    };

    if (role === "user") {
      requestBody.user = [userLoggedIn.id];
    }

    let url = "/survey/combine";
    const options = {
      method: "GET",
      url: url,
      params: requestBody,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes;

    return { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_surveys);
  }
};

export const fetchSurveyDashboard = async (
  params = {
    // "questionnaireAnswer.answer[]": "Fsc-50714-4", //Fsc-50714-3
    uniqueIdentifier: "FSC-50714-5", //Fsc-50714-3
    serviceType: "FSC_ACCESS_TO_FINANCE", //"FSC_SKILLS_ENHANCEMENT",
    serviceName: "FSC",
    // questionText: "Baseline_Value_of_Money",
    quarter: "",
    startDate: "",
    endDate: "",
    keySearch: "IMPACT-RPTG", //"KPI",
  },
  cb
) => {
  const lng = "english";
  !!cb && cb(true, null);
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `/survey/dashboard`,
      params: params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const res = await apiInstance(options);
    !!cb && cb(false, res.data);
  } catch (error) {
    !!cb && cb(false, false);
    toastMessage("error", language[lng].error_delete_survey);
  }
};

export const downloadFile = async (
  params = {
    // "questionnaireAnswer.answer[]": "Fsc-50714-4", //Fsc-50714-3
    uniqueIdentifier: "FSC-50714-5", //Fsc-50714-3
    serviceType: "FSC_ACCESS_TO_FINANCE", //"FSC_SKILLS_ENHANCEMENT",
    serviceName: "FSC",
    // questionText: "Baseline_Value_of_Money",
    quarter: "",
    startDate: "",
    endDate: "",
    keySearch: "IMPACT-RPTG", //"KPI",
    fileType: "excel",
  },
  cb
) => {
  const lng = "english";
  !!cb && cb(true, null);
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `/survey/report`,
      params: params,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const res = await apiInstance(options);

    // Create a blob URL and trigger download
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `data.${params.fileType === "excel" ? "xlsx" : "csv"}`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    !!cb && cb(false);
  } catch (error) {
    !!cb && cb(false, false);
    toastMessage("error", language[lng].error_delete_survey);
  }
};