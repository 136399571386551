import React from "react";
import { MoreVertical } from "lucide-react";
import Avatar from "./Avatar";

const ChatHeader = ({ conversation }) => {
  return (
    <div className="p-3 border-bottom">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Avatar
            // initials={`${conversation?.user?.firstName[0] ?? "J"}${
            //   (conversation?.user?.lastName && conversation.user?.lastName[0]) ?? "D"
            // }`}
            initials={`JD`}
            size={40}
          />
          <h6 className="mb-0 ms-2">{`${conversation?.user?.firstName} ${conversation?.user?.lastName}`}</h6>
        </div>
        <MoreVertical size={20} className="text-muted cursor-pointer" />
      </div>
    </div>
  );
};

export default ChatHeader;
