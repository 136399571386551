import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { HomeUserDashboardLayout } from "../../Layout";
import userSidebarMenus from "../../constants/userSidebarMenus";
import { getStorage } from "../../utils/storage";
import { restartAutoReset } from "../../utils/inactivityRecords";
import NotificationScreen from "../common/NotificationScreen";
import SettingScreen from "../User/SettingScreen";
import { connect } from "react-redux";
import ServiceQuestionnaireScreen from "./ServiceQuestionnaireScreen";
import ServiceQuestionnaireEvaluationScreen from "./ServiceQuestionnaireEvaluationScreen";
import ServiceSurveysScreen from "./Surveys/ServiceSurveysScreen";
import EvaluationDetailScreen from "./Evaluation/EvaluationDetailScreen";
class HomeUserDashboard extends React.Component {
  componentDidMount = async () => {
    await userSidebarMenus(this);
    console.log("this.props", this.props);

    const user = await getStorage();

    if (!user.token) return (window.location.href = "/landing");

    //restart timer on every user activity
    restartAutoReset({ navigateTo: "/login" });
  };

  render() {
    return (
      <HomeUserDashboardLayout>
        <Routes>
          {this.props?.menus?.map((route, index) => {
            return (
              <>
                <Route
                  key={index}
                  path={route.path}
                  element={route.component}
                />

                {route.menus && (
                  <Route
                    key={index}
                    path={route.path + "/*"}
                    element={subMenusRoutes(route)}
                  />
                )}
              </>
            );
          })}
          <Route path={"/settings"} element={<SettingScreen />} />
          <Route
            path={"/services/:serviceId/:serviceName"}
            element={<ServiceQuestionnaireScreen />}
          />
          <Route
            path={"/services/:serviceId/:serviceName/:uniqueNumber"}
            element={<ServiceQuestionnaireEvaluationScreen />}
          />

          <Route
            path={"/service/surveys/:serviceId/:serviceName"}
            element={<ServiceSurveysScreen role="user" />}
          />

          <Route
            path={"/service/surveys/:serviceId/:serviceName/:surveyId"}
            element={<ServiceSurveysScreen role="user" />}
          />

          <Route
            path={"/services/:serviceId/:surveyId/:actionName"}
            element={<ServiceQuestionnaireScreen />}
          />
          <Route path={"/notifications"} element={<NotificationScreen />} />

          <Route path={"/user/evaluations/:uniqueNumber"} element={<EvaluationDetailScreen />} />
        </Routes>
        <Outlet />
      </HomeUserDashboardLayout>
    );
  }
}

const subMenusRoutes = (route) => {
  return (
    <Routes>
      <Route path="/" element={route.component} />
      {route?.menus?.map((el, index) => {
        return (
          <>
            <Route key={index} path={el.path} element={el.component} />
          </>
        );
      })}
    </Routes>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { menus } = state.Sidebar;
  return {
    defaultLanguage,
    menus,
  };
};

export default connect(mapStateToProps)(HomeUserDashboard);
