import React from "react";
import { connect } from "react-redux";
import surveyQuery from "../../utils/queries/surveysQuery";
import { getStorage } from "../../utils/storage";
import formateFilters from "../../utils/formateFilters";
import { Map } from "../Map";
import { getCoordinates } from "./geoCoording";
import "./styles.css";
import LocationTypeFilter from "../Filters/LocationTypeFilter";
import { Input } from "../Input";
import language from "../../language";
import icons from "../../constants/icons";

let copyData = [];

class GeographicalMapping extends React.Component {
  state = {
    isLoading: false,
    data: [],
    search_text: "",
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    tableHeaders: [],
    surveyAnswers: [],
    selectedAnswer: {},
    selectedData: {},
    isLoading: true,
    keyName: "district",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });

    this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      if (
        this.props.filters.locationType &&
        Object.keys(this.props.filters.locationType).length > 0
      ) {
        this.setState({ keyName: this.props.filters.locationType.value });
      }
      this.getData(true);
    }
  }

  returnFilters() {
    const { filters } = this.props;

    let _filters = {
      rank: true,
      order: filters?.locationType?.value,
      ...formateFilters(filters),
    };

    delete _filters.startCreatedAt;
    delete _filters.endCreatedAt;

    return _filters;
  }

  getData = async (isLoading) => {
    this.setState({
      isLoading,
    });
    try {
      const { defaultLanguage } = this.props;

      const { data } = await surveyQuery(defaultLanguage, this.returnFilters());

      const dataWithCoordinates = await this.handleFormatData(data);

      this.setState({
        data: dataWithCoordinates,
        isLoading: false,
      });

      copyData = dataWithCoordinates.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleFormatData = async (data) => {
    try {
      const dataWithCoordinates = await Promise.all(
        data.map(async (el) => {
          const address = `Rwanda, ${el[this.state?.keyName]}`;
          const coordinates = await getCoordinates(address);
          return {
            ...el,
            address,
            latitude: coordinates.lat,
            longitude: coordinates.lon,
          };
        })
      );
      return dataWithCoordinates;
    } catch (error) {
      console.log(error);
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  onSearch(e) {
    let result = [];

    const search = e.target.value;

    for (let el of copyData) {
      if (el.address.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
        result.push(el);
      }
    }

    this.setState({ data: result });
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <div className="mapFiltersContainer">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body d-flex flex-grow gap-3">
                <LocationTypeFilter />
                <Input
                  styles={{ margin: 0, flex: 1 }}
                  placeholder={`${
                    language[this.props.defaultLanguage].search
                  } ${this.state.keyName}...`}
                  icon={icons.search}
                  className="sm"
                  onChange={this.onSearch.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
        <Map data={this.state.data} isLoading={this.state.isLoading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(GeographicalMapping);
