import React from "react";
import woman from "../../assets/woman.png";
import man from "../../assets/man.png";
import surveyQuery from "../../utils/queries/surveysQuery";
import { getStorage } from "../../utils/storage";
import { connect } from "react-redux";
import dynamicTableData from "../../utils/dynamicTableData";
import DashboardContainer from "../../components/Evaluation/DashboardContainer";
import { getProfile } from "../../utils/queries/farmersQuery";
import { getDistinctDomainInterventions } from "../../utils/extractService";
import { Tabs, Tab } from "react-bootstrap";
import CustomFilter from "../../components/Filters/CustomFilter";

const url = window.location.href;
const uniqueNumber = url.split("/").pop();

class DetailScreen extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    error: {},
    csvData: [],
    user: {},
    totalPageCount: 0,
    tableHeaders: [],
    profile: {},
    type: "",
    isLoadingEvaluation: false,
    evaluationTobeTaken: {},
    evaluations: [],
    evaluationHeaders: [],
    selectedTab: "access_to_finance",
    quarter: "1-2025",
  };

  componentDidMount = async () => {
    const evaluationTobeTaken = JSON.parse(
      localStorage.getItem("evaluationTobeTaken")
    );
    this.setState({ evaluationTobeTaken });

    this.fetchParticipantProfile();
    this.handleFetchData();
    this.getEvaluations();
  };

  handleFetchData = async () => {
    const user = await getStorage();
    const { id } = this.props;
    await this.setState({ user });

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.handleFetchData();
    }

    if (this.props.filters !== prevProps.filters) {
      this.handleFetchData();
    }
  }

  returnFilters() {
    let _filters = {
      ["service.type"]: "KPI",
      ["service.name"]: this.props.category,
      "questionnaireAnswer.questionText": [
        "Unique_identifier",
        "Domain_intervention",
      ],
      "questionnaireAnswer.answer": [
        uniqueNumber,
        this.state.evaluationTobeTaken.domain,
      ],
      advanced_search: true,
    };

    return _filters;
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await surveyQuery(defaultLanguage, {
        ...this.returnFilters(),
      });
      this.setState({
        type: data[0].service.name,
        data,
        totalPageCount: count,
        isLoading: false,
        domainInterventions: getDistinctDomainInterventions(data),
      });
      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchParticipantProfile = async () => {
    const data = await getProfile(this.state.user.language, uniqueNumber);
    this.setState({ profile: data });
  };

  getEvaluations = async () => {
    const evaluationTobeTaken = JSON.parse(
      localStorage.getItem("evaluationTobeTaken")
    );
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingEvaluation: true });

      const { data, count } = await surveyQuery(defaultLanguage, {
        "service.type": "FSC_EVALUATION",
        "questionnaireAnswer.questionText": [
          "Unique Number",
          "Domain_intervention",
        ],
        "questionnaireAnswer.answer": [
          uniqueNumber,
          evaluationTobeTaken.domain,
        ],
        advanced_search: true,
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        evaluations: rowData,
        evaluationHeaders: tableHeaders,
        isLoadingEvaluation: false,
      });
    } catch (error) {
      this.setState({
        isLoadingEvaluation: false,
      });
    }
  };

  getEvaluationBadge(evaluation) {
    return "Not Graduated";
  }

  handleTabSelect = async (key) => {
    this.setState({ selectedTab: key });
  };

  render() {
    return (
      <div>
        {/* Profile Header */}
        <div className="d-flex align-items-center mb-2">
          <img
            src={
              this.state.profile?.gender?.toLowerCase() === "female"
                ? woman
                : man
            }
            alt="User"
            className="rounded-circle"
            width="50"
            height="50"
          />
          <div className="ms-3">
            <h5 className="mb-0">
              {this.state.profile?.firstname} {this.state.profile?.lastname}
            </h5>
            <p className="text-muted">
              {this.state.type}, age {this.state.profile?.age}
            </p>
          </div>
          {/* <button className="btn btn-outline-secondary ms-auto">:</button> */}
        </div>

        <div className="mb-4 mt-4">
          <h2 className="h4 mb-3">General Information</h2>
          <div className="row g-3">
            <div className="col-md-3">
              <div className="card p-2 text-muted d-flex mb-1">
                <div className="row">
                  <p className="col-md-6">Select Quarter:</p>
                  <CustomFilter
                    // isLoading={}
                    title="Quarter"
                    data={["1-2025", "2-2025", "3-2025"]}
                    selectedData={this.state.quarter}
                    setSelectedData={(value) =>
                      this.setState({ quarter: value })
                    }
                    isNotMultiple={true}
                    resetValue="1-2025"
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-md-2">
              <div className="card p-2 text-muted  mb-1">
                Registered Farmers : 208
              </div>
            </div> */}
          </div>
        </div>

        <br />
        <h1>Performance Metrics Navigation</h1>
        <br />
        <Tabs onSelect={this.handleTabSelect}>
          <Tab eventKey="access_to_finance" title="Access to Finance">
            <DashboardContainer
              keyPoint={this.state.selectedTab}
              categoryType={this.state.profile?.category_type}
              quarter={this.state.quarter}
            />
          </Tab>
          <Tab eventKey="access_to_market" title="Access to Market">
            <DashboardContainer
              keyPoint={this.state.selectedTab}
              categoryType={this.state.profile?.category_type}
              quarter={this.state.quarter}
            />
          </Tab>
          <Tab eventKey="skills_enhancement" title="Skills Enhancement">
            <DashboardContainer
              keyPoint={this.state.selectedTab}
              categoryType={this.state.profile?.category_type}
              quarter={this.state.quarter}
            />
          </Tab>
          <Tab eventKey="value_chain_and_income" title="Value Chain & Income">
            <DashboardContainer
              keyPoint={this.state.selectedTab}
              categoryType={this.state.profile?.category_type}
              quarter={this.state.quarter}
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(DetailScreen);
