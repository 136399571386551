import React, { createContext, useContext, useState } from "react";
import { getConversations, sendMessageApi } from "../utils/queries/chatQuery";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [selectedConversation, setSelectedConversation] = useState();
  const [conversations, setConversations] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const sendMessage = async (content) => {
    if (!content.trim()) return;
    console.log(selectedConversation);

    const data = await sendMessageApi({
      conversationId: selectedConversation?._id,
      text: content,
    });
    const newMessage = {
      ...data,
      isOwn: true,
      status: "sent",
    };

    setMessages([...messages, newMessage]);
    setIsSending(false);
    setInputMessage("");
    reFetchConversations();
  };

  const reFetchConversations = async () => {
    const data = await getConversations();
    setConversations(data);
  };

  return (
    <ChatContext.Provider
      value={{
        messages,
        setMessages,
        inputMessage,
        setInputMessage,
        sendMessage,
        selectedConversation,
        setSelectedConversation,
        conversations,
        setConversations,
        isSending,
        setIsSending,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  return useContext(ChatContext);
};
