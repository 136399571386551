// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process-container {
  display: flex;
  padding: 5rem 0;
  background-color: var(--primary-color-5);
}

.process-item-container {
  border-radius: 8px;
  box-shadow: 0px 20px 40px 0px rgba(134, 165, 146, 0.2);
  min-height: 80px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  gap: 2rem;
  padding: 1rem;
  position: relative;
  cursor: pointer;
  z-index: 6;
  /* border: 2px solid var(--primary-color); */
}

.process-container .process-header {
  margin-bottom: 4rem;
}

.process-description-container {
  background-color: var(--dark-primary-color);
  padding: 3rem;
  border-radius: 8px;
  min-height: 465px;
  position: relative;
  left: -50px;
  z-index: 4;
}

.process-description-container p {
  color: var(--light-color);
  opacity: 0.8;
}

.process-description-container .process-description-container-top {
  flex: 1 1;
}

.process-description-container .process-description-container-footer {
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.number{
font-family: "Montserrat";
color: rgba(0, 0, 0, 0.3);
}

.active-number {
  font-weight: bold;
  color: #00C850;

}



`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/Process/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,sDAAsD;EACtD,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,4CAA4C;AAC9C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;EAC3C,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;AACA,yBAAyB;AACzB,yBAAyB;AACzB;;AAEA;EACE,iBAAiB;EACjB,cAAc;;AAEhB","sourcesContent":[".process-container {\n  display: flex;\n  padding: 5rem 0;\n  background-color: var(--primary-color-5);\n}\n\n.process-item-container {\n  border-radius: 8px;\n  box-shadow: 0px 20px 40px 0px rgba(134, 165, 146, 0.2);\n  min-height: 80px;\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  margin-bottom: 2rem;\n  gap: 2rem;\n  padding: 1rem;\n  position: relative;\n  cursor: pointer;\n  z-index: 6;\n  /* border: 2px solid var(--primary-color); */\n}\n\n.process-container .process-header {\n  margin-bottom: 4rem;\n}\n\n.process-description-container {\n  background-color: var(--dark-primary-color);\n  padding: 3rem;\n  border-radius: 8px;\n  min-height: 465px;\n  position: relative;\n  left: -50px;\n  z-index: 4;\n}\n\n.process-description-container p {\n  color: var(--light-color);\n  opacity: 0.8;\n}\n\n.process-description-container .process-description-container-top {\n  flex: 1;\n}\n\n.process-description-container .process-description-container-footer {\n  min-height: 50px;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n}\n\n.number{\nfont-family: \"Montserrat\";\ncolor: rgba(0, 0, 0, 0.3);\n}\n\n.active-number {\n  font-weight: bold;\n  color: #00C850;\n\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
