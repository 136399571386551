import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./style.css";

const ControlledTabs = (props) => {
  const [key, setKey] = useState(
    props.options.length > 0 ? props.options[0].title.toLowerCase() : undefined
  );

  useEffect(() => {
    props.getActiveTab && props.getActiveTab(key);
    if (props.activeTab) {
      setKey(props.activeTab);
      props.getActiveTab && props.getActiveTab(props.activeTab);
    }
  }, [key]);

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
        }}
        className={`tab gap-3 flex-nowrap ${props.className}`}
        style={props.styles}
      >
        {props.options.map((item, i) => {
          return (
            item?.title && (
              <Tab
                key={i}
                eventKey={item?.title?.toLowerCase()}
                title={`${item?.title}`}
              >
                {item?.data}
              </Tab>
            )
          );
        })}
      </Tabs>
    </>
  );
};

{
  /* <React.Fragment>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                    className="active"
                  >
                    <div className="tab-name">{item.title}</div>
                    <NotificationBadgeCount count={2} styles={{}} />
                  </div>
                </React.Fragment> */
}

export default ControlledTabs;
