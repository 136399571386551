import React from "react";
import "./styles.css";
import language from "../../language";
import { connect } from "react-redux";
import { LocationFilter } from "../Filters";
import { Button } from "../Button";
import DateFilter from "../Filters/DateFilter";
import ServiceFilter from "../Filters/ServiceFilter";
import Counts from "./Counts";
import FalseVsTrueGraduates from "../Charts/Impact/FalseVsTrueGraduates";
import GraduationReasons from "../Charts/Impact/GraduationReasons";
import FalseGraduationsPerCategory from "../Charts/Impact/FalseGraduationsPerCategory";
import ImpactEvaluation from "./ImpactEvaluation";
class Analytics extends React.Component {
  state = {};

  handleToggle(isToggled) {
    this.setState({
      [isToggled]: !this.state[isToggled],
    });
  }

  render() {
    return (
      <>
        <div className="d-flex gap-2 mb-2">
          <h3>{
            language[this.props.defaultLanguage].over_all_impact
          }</h3>
        </div>
        <hr />
        <Counts />
        <div className="row">
          <div className="col-md-6">
            <div className="card mb-3" style={{ minHeight: 373 }}>
              <div>
                <div className="chart-header">
                  <h3 className="text-disabled">
                    {
                      language[this.props.defaultLanguage].confirmed_vs_false_graduates
                    }
                  </h3>
                </div>
              </div>
              <div className="card-body">
                <FalseVsTrueGraduates />
              </div>
            </div>
            <div className="card mb-3">
              <div>
                <div className="chart-header">
                  <h3 className="text-disabled mb-2">
                    {
                      language[this.props.defaultLanguage].graduation_reasons
                    }
                  </h3>
                </div>
              </div>
              <div className="card-body" style={{ position: "relative" }}>
                <GraduationReasons />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-3" style={{ minHeight: 373 }}>
              <div>
                <div className="chart-header">
                  <h3 className="text-disabled">
                    {
                      language[this.props.defaultLanguage].false_graduations_per_category
                    }
                  </h3>
                </div>
              </div>
              <div className="card-body" style={{ position: "relative" }}>
                <FalseGraduationsPerCategory />
              </div>
            </div>
            <div className="card mb-3" style={{ minHeight: 373 }}>
              <div className="regions-counts">
                <div>
                  <div className="chart-header">
                    <h3 className="text-disabled">
                      {
                        language[this.props.defaultLanguage].graduationEvaluation
                      }
                    </h3>
                  </div>
                </div>
                <div
                  className="card-body"
                // style={{ height: 280, overflowY: "auto" }}
                >
                  <ImpactEvaluation />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Analytics);
