export const ON_ADD_MENUS = "ON_ADD_MENUS";
export const ON_UPDATE_MENUS = "ON_UPDATE_MENUS";

export const onAddSidebarMenus = (payload) => {
  return {
    type: ON_ADD_MENUS,
    payload,
  };
};

export const onUpdateSidebarMenus = (menu, index) => {
  return {
    type: ON_UPDATE_MENUS,
    index,
    menu,
  };
};
