// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  background-color: var(--bg-color);
}

.splash-container .fetch-service-error-container {
  max-width: 300px;
  margin-top: 2rem;
}

.splash-container .fetch-service-error-container p {
  margin-bottom: 1rem;
}

.splash-logo-container {
}

.splash-logo-container img {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.splash-logo-container span {
  color: var(--text-color);
  font-weight: 300;
}

/* MEDIA QUERIES - RESPONSIVE CONTROL */
@media screen and (max-width: 500px) {
  .splash-container {
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 980px) {
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Splash/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;AACA;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA,uCAAuC;AACvC;EACE;EACA;AACF;;AAEA;AACA;;AAEA;AACA","sourcesContent":[".splash-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  flex-direction: column;\n  background-color: var(--bg-color);\n}\n\n.splash-container .fetch-service-error-container {\n  max-width: 300px;\n  margin-top: 2rem;\n}\n\n.splash-container .fetch-service-error-container p {\n  margin-bottom: 1rem;\n}\n\n.splash-logo-container {\n}\n\n.splash-logo-container img {\n  width: 60px;\n  height: 60px;\n  margin-bottom: 1rem;\n}\n\n.splash-logo-container span {\n  color: var(--text-color);\n  font-weight: 300;\n}\n\n/* MEDIA QUERIES - RESPONSIVE CONTROL */\n@media screen and (max-width: 500px) {\n  .splash-container {\n  }\n}\n\n@media screen and (max-width: 768px) {\n}\n\n@media screen and (max-width: 980px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
