import "./styles.css";

const Empty = (props) => {
  return (
    <div className="empty-activity">
      {props.icon && (
        <div className="icon-container">
          <span className={process.env.REACT_APP_ICONS_TYPE}>{props.icon}</span>
        </div>
      )}
      <span className="text-bold">{props.title}</span>
    </div>
  );
};

export default Empty;
