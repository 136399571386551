import HomeScreen from "../screens/User/HomeScreen";
import SettingScreen from "../screens/AdminDashboard/SettingScreen";
import language from "../language";
import icons from "./icons";
import { onAddSidebarMenus } from "../actions/Sidebar";
import SurveysScreen from "../screens/User/Surveys/SurveysScreens";
import EvaluationScreen from "../screens/User/Evaluation/EvaluationsScreen";
import RecordAchievement from "../screens/User/RecordAchievement/Index";
import { getStorage } from "../utils/storage";
import ProfileOverview from "../screens/User/RecordAchievement/ProfileOverview";
import MessageList from "../components/Chat/MessageList";
import Index from "../components/mutiChat";

const data = async (that) => {
  try {
    const lng = that.props.defaultLanguage || "english";
    const user = await getStorage();

    const fscMenus = [
      {
        parentPath: "/user",
        path: `/home`,
        name: language[lng].profiling_overwiew,
        icon: icons.user,
        component: <ProfileOverview role="user" />,
      },
      {
        parentPath: "/user",
        path: `/record_achievement`,
        name: language[lng].record_achievement,
        icon: icons.reports,
        component: <RecordAchievement role="user" />,
      },
      {
        parentPath: "/user",
        path: `/messages`,
        name: language[lng].messages,
        icon: icons.communication,
        component: <Index />,
      },
    ];
    const userMenus = [
      {
        parentPath: "/user",
        path: `/home`,
        name: "Home",
        icon: icons.home,
        component: <HomeScreen />,
      },
      {
        parentPath: "/user",
        path: `/surveys`,
        name: language[lng].surveys,
        icon: icons.request,
        component: <SurveysScreen role="user" />,
      },
      {
        parentPath: "/user",
        path: `/eveluations`,
        name: language[lng].evaluations,
        icon: icons.reports,
        component: <EvaluationScreen role="user" />,
      },
      {
        parentPath: "/user",
        path: `/messages`,
        name: language[lng].messages,
        icon: icons.communication,
        component: <Index />,
      },
    ];
    const menusData =
      user.accessRole.name === "FARMER SERVICE CENTER"
        ? [...fscMenus]
        : [...userMenus];

    let sidebarList = menusData.filter(async (el) => {
      return el;
    });
    const menus = [
      ...sidebarList,
      {
        parentPath: "/user",
        path: "/settings",
        name: language[lng].settings,
        icon: icons.setting,
        component: <SettingScreen />,
      },
    ];

    that.props.dispatch(onAddSidebarMenus(menus));
  } catch (error) {
    console.log(error);
    window.location.href = "/";
  }
};

export default data;
