import isJsonString from "./isJsonString";

export default (questionnaireAnswer, headers) => {
  let results = [];

  headers.forEach((header) => {
    questionnaireAnswer.map((dataItem) => {
      if (dataItem.questionText === header) {
        if (isJsonString(dataItem.answer)) {
          const fData = JSON.parse(dataItem.answer);

          if (fData instanceof Array) {
            let _answer = "";
            fData.forEach((el) => {
              _answer += el.answer || el.value;
            });

            results.push(_answer);
          } else {
            results.push(fData.answer || fData.value);
          }
        } else {
          results.push(dataItem.answer);
        }
      }
    });
  });

  return results;
};
