import React, { useState } from "react";
import { allUsers } from "./data/users";
import { Sidebar } from "./Sidebar";
import { ChatArea } from "./ChatArea";
import { UserSelectionModal } from "./UserSelectionModal";
import { useChat } from "../../hooks/useChat";
import { createConversation } from "../../utils/queries/chatQuery";

function Index() {
  const {
    conversations,
    setConversations,
    sendMessage,
    selectedConversation,
    setSelectedConversation,
    setMessages,
  } = useChat();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalSearch, setModalSearch] = useState("");
  const [tempSelectedUsers, setTempSelectedUsers] = useState([]);
  console.log({ tempSelectedUsers });

  const [isNewConversation, setIsNewConversation] = useState(false);
  const [isStartingConversation, setIsStartingConversation] = useState(false);

  const filteredConversations = conversations.filter((conv) => {
    const participantNames = conv.users
      .map((user) => `${user.firstName} ${user.lastName}`)
      .join(" ");
    return participantNames.includes(search.toLowerCase());
  });

  const filteredModalUsers = allUsers.filter((user) =>
    user.name.toLowerCase().includes(modalSearch.toLowerCase())
  );

  const handleConversationClick = (conversation) => {
    setSelectedConversation(conversation);
    setIsNewConversation(false);
  };

  const openNewConversation = () => {
    setTempSelectedUsers([]);
    setIsNewConversation(true);
    setSelectedConversation(null);
    setMessages([]);
    setShowModal(true);
  };

  const startConversation = async (selectedUsers) => {
    const _tempSelectedUsers = !!tempSelectedUsers[0]
      ? tempSelectedUsers
      : !!selectedUsers
      ? selectedUsers
      : [];
    if (_tempSelectedUsers.length === 0) {
      setShowModal(false);
      return;
    }
    const data = await createConversation({ users: _tempSelectedUsers });
    if (data) {
      setSelectedConversation(data);
      setShowModal(false);
    } else {
      const targetConversation = conversations.find((conversation) =>
        conversation.users.some((u) => u._id === _tempSelectedUsers[0]._id)
      );
      setSelectedConversation(targetConversation);
      setShowModal(false);
    }
  };

  return (
    <div className="container-fluid overflow-scroll" style={{ height: "90vh" }}>
      <div className="row g-0 shadow rounded" style={{ height: "89vh" }}>
        <div className="col-md-4 border-end p-0 bg-light">
          <Sidebar
            conversations={filteredConversations}
            selectedConversationId={selectedConversation?.id}
            search={search}
            onSearchChange={setSearch}
            onNewChat={openNewConversation}
            onConversationSelect={handleConversationClick}
          />
        </div>
        <div className="col-md-8 p-0">
          <ChatArea
            conversation={selectedConversation}
            isNewConversation={isNewConversation}
            selectedUsers={tempSelectedUsers}
          />
        </div>
      </div>

      {showModal && (
        <UserSelectionModal
          users={filteredModalUsers}
          selectedUsers={tempSelectedUsers}
          search={modalSearch}
          onSearchChange={setModalSearch}
          onUserToggle={(userId) =>
            setTempSelectedUsers((prev) =>
              prev.includes(userId)
                ? prev.filter((id) => id !== userId)
                : [...prev, userId]
            )
          }
          onClose={() => {
            setTempSelectedUsers([]);
            setIsNewConversation(false);
            setShowModal(false);
          }}
          onConfirm={startConversation}
          isStartingConversation={isStartingConversation}
        />
      )}
    </div>
  );
}

export default Index;
