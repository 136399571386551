import icons from "./icons";

export const cardCounts = [
  {
    label: "Male",
    value: "totalMaleYouthCounts",
    icon: "recent_actors",
  },
  {
    label: "Female",
    value: "totalFemaleYouthCounts",
    icon: "face",
  },
  {
    label: "Non-Youth",
    value: "totalNonYouthCounts",
    icon: "contacts",
  },
  {
    label: "Total Youth",
    value: "totalYouthCounts",
    icon: icons.users,
  },
  {
    label: "Total FSCs",
    value: "total",
    icon: icons.users,
  },
];

export const homeCardCounts = [
  {
    title: "Total FSCs",
    fieldName: "totalFSCs",
    icon: "contacts",
    onPress: () => (window.location.href = "/dashboard/farmers/fsc"),
  },
  {
    title: "Individual Farmers",
    fieldName: "totalIndividualFarmers",
    icon: icons.users,
    onPress: () =>
      (window.location.href = "/dashboard/farmers/individual-farmers"),
  },
  {
    title: "Market Actors",
    fieldName: "totalMarketActorsFarmers",
    icon: "face",
    onPress: () => (window.location.href = "/dashboard/farmers/market_actors"),
  },
  {
    title: "Off-Farmers",
    fieldName: "totalOffFarm",
    icon: "recent_actors",
    onPress: () => (window.location.href = "/dashboard/farmers/off-farmers"),
  },
];
