import { CSVLink } from "react-csv";
import { Button, DropdownButton } from "../Button";
import { Link } from "react-router-dom";
import { Input } from "../Input";
import DateFilter from "../Filters/DateFilter";
import LocationFilter from "../Filters/LocationFilter";
import ServiceCategoriesFilter from "../Filters/ServiceCategoriesFilter";

const TableHeader = (props) => {
  return (
    <>
      <div>
        <div className="table-header-container">
          {props.isSearch && (
            <div className="table-search-container ">
              <Input
                placeholder={
                  props.placeholder ? props.placeholder : "Search..."
                }
                leftIcon="search"
                value={
                  props.search_text ? props.search_text : props.search_text
                }
                onChange={
                  props.handleSearch
                    ? props.tab
                      ? (e) => props.handleSearch(props.tab, e)
                      : props.handleSearch
                    : props.handleSearch.bind(this)
                }
                iconRight="search"
                inputContainerStyle={{ marginBottom: 0 }}
                autoComplete="off"
                type="search"
                className="sm w-sm-100"
                // textarea={"multipleLineText"}
                addSearchButton
              />
              {/* {props.addSearchButton && (
                <div style={{ marginLeft: "0.5rem" }}>
                  <Button
                    className="btn-primary btn-sm"
                    icon="search"
                    onPress={props.handlePressSearch}
                  />
                </div>
              )} */}
            </div>
          )}
          {props.filters &&
            props.filters.map((filter, f) => {
              if (filter.button_type === "dropdown") {
                return (
                  <>
                    {filter.filterName === "location" ? (
                      <LocationFilter />
                    ) : filter.filterName === "date_range" ? (
                      <DateFilter />
                    ) : filter.filterName === "service_category" ? (
                      <ServiceCategoriesFilter />
                    ) : (
                      <DropdownButton
                        className="btn-transparent btn-bordered w-sm-100 btn-sm"
                        text={filter.title}
                        selected={filter.selected}
                        isSearchable={filter.isSearchable}
                        options={filter.options}
                        onSelectDropdownItem={filter.onSelectDropdownItem}
                        search_text={filter.search_text}
                        onChangeSearch={filter.onChangeSearch}
                        selected_item={filter.selected_item}
                        isLoading={filter.isLoading}
                        isMulti={filter.isMulti}
                        default_key={filter.default_key}
                        handleReset={filter.handleReset}
                        clickBehaviorId="dropdownMenuClickableInside"
                        autoCloseType="outside"
                        icon={filter.icon}
                      >
                        {filter?.options?.map((option, o) => {
                          return (
                            <li key={o}>
                              {option.isDownloadCsv ? (
                                <CSVLink
                                  filename={option.filename + ".csv"}
                                  data={
                                    option && option.csvData
                                      ? option.csvData
                                      : []
                                  }
                                  asyncOnClick={true}
                                  // onClick={
                                  //   option.onPress ? option.onPress : null
                                  // }
                                  onClick={(event, done) => {
                                    option.onPress().then((res) => {
                                      done(false); // Don't Proceed
                                    });
                                  }}
                                  className="dropdown-item"
                                >
                                  {option.name
                                    ? option.name
                                    : option.label
                                      ? option.label
                                      : ""}
                                </CSVLink>
                              ) : (
                                <Link
                                  onClick={() => option.onPress(option)}
                                  className="dropdown-item"
                                  to="#"
                                >
                                  {option.name
                                    ? option.name
                                    : option.label
                                      ? option.label
                                      : ""}
                                </Link>
                              )}
                            </li>
                          );
                        })}
                      </DropdownButton>
                    )}
                  </>
                );
              } else {
                return (
                  <Button
                    key={f}
                    selected={filter.selected}
                    className={`btn-gray btn-sm ${filter.className}`}
                    text={filter.title}
                    onPress={filter.onPress}
                    icon={filter.icon ? filter.icon : "filter_alt"}
                    isSubmitting={filter.isSubmitting}
                  />
                );
              }
            })}
          {props.showAdd && (
            <Button
              className="btn-primary btn-sm"
              onPress={props.handleAddPressed}
              text={props.addButtonText}
              icon="add"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TableHeader;
