import React from "react";
// import { Services } from "../../components/Services";

import { DashboardHeader } from "../../../components/Header";
import language from "../../../language";
import { connect } from "react-redux";
import { Input } from "../../../components/Input";
import { getProfile } from "../../../utils/queries/farmersQuery";
import { Button } from "../../../components/Button";

class EvaluationScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      searchText: "",
      isSearching: false,
      profile: {},
      notFound: false,
    };
  }

  handleSearch = async () => {
    if (!this.state.searchText) {
      return;
    }
    this.setState({ isSearching: true });
    const data = await getProfile(
      this.state.user.language,
      this.state.searchText
    );
    if (data.firstname) {
      this.setState({ profile: data });
    } else {
      this.setState({ notFound: true });
    }
    this.setState({ isSearching: false });
  };

  componentDidUpdate() {
    if (this.state.profile?.firstname) {
      window.location.href = `user/evaluations/${this.state.searchText}`;
    }
  }

  render() {
    return (
      <>
        <DashboardHeader
          title={language[this.props.defaultLanguage].evaluations}
          description={
            language[this.props.defaultLanguage].evaluationDescription
          }
        />
        <div className="row">
          <div className="col-md-4">
            <Input
              placeholder={"Search by Unique Number..."}
              leftIcon="search"
              value={this.state.searchText}
              onChange={(e) => {
                this.setState({
                  searchText: e.target.value.trim().toUpperCase(),
                });
              }}
              iconRight="search"
              inputContainerStyle={{ marginBottom: 0 }}
              autoComplete="off"
              type="search"
              className="sm w-sm-100"
              addSearchButton
            />
          </div>
          <div className="col-md-6 mt-2 ">
            <Button
              text={language[this.props.defaultLanguage].search}
              onPress={this.handleSearch}
              isSubmitting={this.state.isSearching}
            />
          </div>
        </div>

        {this.state.notFound && (
          <div className="row mt-3">
            <div className="col-md-12">
              <p className="text-danger">Invalid Unique Number</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(EvaluationScreen);
