import React, { useEffect, useState } from "react";
import { Search } from "lucide-react";
import {
  getConversations,
  fetchUsers,
  createConversation,
} from "../../utils/queries/chatQuery";
import { Button } from "../Button";
import icons from "../../constants/icons";
import Avatar from "./Avatar";
import { Loading } from "../Loader";
import { useChat } from "../../hooks/useChat";
import { formatTimestamp } from "../../utils/chatHelpers";

const Sidebar = ({ startConvo, setStartConvo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    setSelectedConversation,
    conversations,
    setConversations,
    setMessages,
  } = useChat();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getConversations();
      setConversations(data);
    };
    fetchData();
    getData(true);
  }, []);

  const getData = async (isLoading) => {
    try {
      setIsLoading(isLoading);
      const data = await fetchUsers();
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const startConversation = async (user) => {
    const data = await createConversation({ user });
    if (data) {
      setSelectedConversation(data);
      setStartConvo(false);
    } else {
      const targetConversation = conversations.find(
        (conversation) => conversation.user._id === user._id
      );
      setSelectedConversation(targetConversation);
      setStartConvo(false);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredData = startConvo
    ? data.filter(
        (user) =>
          user.firstName?.toLowerCase().includes(searchQuery) ||
          user.lastName?.toLowerCase().includes(searchQuery)
      )
    : conversations.filter(
        (conversation) =>
          conversation.user.firstName?.toLowerCase().includes(searchQuery) ||
          conversation.user.lastName?.toLowerCase().includes(searchQuery)
      );

  return (
    <div className="d-flex flex-column h-100">
      <div className="p-3 border-bottom d-flex justify-content-between align-items-center">
        {startConvo ? (
          <div className="d-flex align-items-center">
            <Button
              icon={icons.arrowLeft}
              className="btn btn-sm btn-outline-secondary"
              onPress={() => setStartConvo(false)}
            />
            <h5 className="mb-0 ms-2">New Message</h5>
          </div>
        ) : (
          <>
            <h5 className="mb-0">Messages</h5>
            <Button
              icon={icons.add}
              className="btn btn-sm btn-outline-secondary"
              onPress={() => setStartConvo(true)}
            />
          </>
        )}
      </div>
      <div className="p-3">
        <div className="input-group">
          <span className="input-group-text bg-light border-0">
            <Search size={20} className="text-muted" />
          </span>
          <input
            type="text"
            className="form-control border-0 bg-light outline-none"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex flex-column justify-content-center align-items-center gap-2 h-100">
          <Loading />
        </div>
      ) : (
        <>
          {startConvo ? (
            <div>
              <ul className="list-group">
                {filteredData.map((user) => (
                  <div
                    key={user._id}
                    className="p-3 border-bottom hover-bg-light"
                    style={{ cursor: "pointer" }}
                    onClick={() => startConversation(user)}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <Avatar
                        // initials={`${user.firstName[0]}${user.lastName[0]}`}
                        initials="JD"
                        size={40}
                      />
                      <h6 className="mb-0 ms-2">{`${user.firstName} ${user.lastName}`}</h6>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          ) : filteredData.length === 0 ? (
            <div className="d-flex flex-column justify-content-center align-items-center gap-2 h-100">
              <p className="text-center mt-5">No conversations found.</p>
              <button
                className="btn btn-primary mt-3"
                onClick={() => setStartConvo(true)}
              >
                Start a new conversation
              </button>
            </div>
          ) : (
            filteredData.map((conversation) => (
              <div
                key={conversation._id}
                className="p-3 border-bottom hover-bg-light"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMessages([]);
                  setSelectedConversation(conversation);
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="mb-0">{`${conversation.user.firstName} ${conversation.user.lastName}`}</h6>
                  <small className="text-muted">
                    {conversation?.lastMessage &&
                      formatTimestamp(conversation?.lastMessage?.timestamp)}
                  </small>
                </div>
                <p className="mb-0 text-muted small">
                  {conversation?.lastMessage && conversation?.lastMessage?.isOwn
                    ? "You: "
                    : ""}
                  {conversation?.lastMessage?.text}
                </p>
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
};

export default Sidebar;
