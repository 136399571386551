import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import { Slides } from "../Slides";
import language from "../../language";
import validateEmail from "../../utils/validateEmail";
import toastMessage from "../../utils/toastMessage";
import { setStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import loginSlidesContent from "../../constants/loginSlidesContent";
import { apiInstance } from "../../utils/axiosInstance";

class Login extends React.Component {
  state = {
    username: "",
    isSubmitting: false,
    error: {},
    password: "",
    isUserLogin: true,
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e.target.value });
  };

  validateForm = () => {
    let { error, username, password } = this.state;
    if (username === "") {
      error.username = language[this.props.defaultLanguage].email_required;
    } 
    // else if (!validateEmail(username)) {
    //   error.username =
    //     language[this.props.defaultLanguage].invalid_email_required;
    // }

    if (password === "") {
      error.password = language[this.props.defaultLanguage].password_required;
    }

    this.setState({ error });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { username, password } = this.state;

      let url = `/authentication/account`;

      if (username === "info@nebotech.rw") {
        url = `/authentication/superAdmin`;
      }

      const options = {
        method: "POST",
        url,
        data: {
          email: username,
          password,
        },
      };

      apiInstance(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          if (data.data.status === "error")
            return toastMessage("error", data.data.message);

          let userInfo = {
            accessRole: data?.data?.accessRole,
            token: data?.data?.token,
            email: username,
            role: data.data.role,
            institution:
              data?.data?.institution || process.env.REACT_APP_INSTITUTION,
            accountType: data.data.accountType,
            uniqueId: data.data.userInfo?.uniqueId,
            firstname: data.data.userInfo?.firstname,
            lastname: data.data.userInfo?.lastname,
          };

          if (data.data?.id) {
            userInfo.id = data.data?.id;
          }

          userInfo.displayName = username?.split("@")[0] ?? userInfo.firstname;

          this.onSuccess(userInfo);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          toastMessage(
            "error",
            language[this.props.defaultLanguage].wrong_email_password
          );
        });
    }
  };

  onSuccess = async (data) => {
    const { type } = this.props;
    await setStorage({ ...data, loginType: type });

    window.location.href = type === "admin" ? "/dashboard/home" : "/user/home";
  };

  render() {
    return (
      <AuthFormLayout>
        <div className="row">
          <div className="col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Slides data={loginSlidesContent} viewSize={1} />
            </div>
          </div>
          <div className="col-md-5">
            <div className="auth-form">
              <div className="title">
                <h2 className="text-primary text-bold">
                  {this.props.title ||
                    language[this.props.defaultLanguage].login}
                </h2>
                <p className="text-disabled">
                  {language[this.props.defaultLanguage].login_description}
                </p>
              </div>
              <form>
                <Input
                  label={language[this.props.defaultLanguage].email_address}
                  placeholder={language[this.props.defaultLanguage].enter_email}
                  required
                  leftIcon={icons.user}
                  value={this.state.username}
                  error={this.state.error.username}
                  onChange={(e) => this.onChangeText("username", e)}
                />
                <Input
                  label={language[this.props.defaultLanguage].password}
                  placeholder={
                    language[this.props.defaultLanguage].enter_password
                  }
                  required
                  leftIcon={icons.password}
                  value={this.state.password}
                  error={this.state.error.password}
                  onChange={(e) => this.onChangeText("password", e)}
                  type="password"
                />
                <Button
                  text={language[this.props.defaultLanguage].login_text}
                  className="w-100"
                  isSubmitting={this.state.isSubmitting}
                  onPress={this.onSubmit.bind(this)}
                />
                <br />
                <div className="footer">
                  <div
                    onClick={() => (window.location.href = "/forgot-password")}
                  >
                    <span
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                    >
                      {language[this.props.defaultLanguage].forgot_password}
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthFormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Login);
