import language from "../language";
import { clearStorage } from "../utils/storage";

const onLogout = async () => {
  await clearStorage();

  window.location.href = "/landing";
};

const nonAuthMenus = (lng = "english") => [
  {
    name: language[lng].home,
    route: "/",
    access: ["public"],
  },
  {
    name: language[lng].how_works,
    route: "/landing/how_works",
    access: ["public"],
  },
  {
    name: language[lng].contact_us,
    route: "/landing/contact_us",
    access: ["public"],
  },
];

const adminAuthMenus = (lng = "english") => {
  return [
    // {
    //   name: language[lng].dashboard,
    //   icon: "grid_view",
    //   onPress: () => (window.location.href = "/dashboard/home"),
    // },
    {
      name: language[lng].settings,
      icon: "settings",
      onPress: () => (window.location.href = "/dashboard/settings"),
    },
  ];
};

const userAuthMenus = (lng = "english") => {
  return [
    // {
    //   name: language[lng].dashboard,
    //   icon: "grid_view",
    //   onPress: () => (window.location.href = "/user/my requests"),
    // },
    {
      name: language[lng].settings,
      icon: "settings",
      onPress: () => (window.location.href = "/user/settings"),
    },
  ];
};

const commonAuthMenus = (lng = "english") => {
  return [
    {
      name: language[lng].logout,
      onPress: () => onLogout(),
      icon: "logout",
    },
  ];
};

export { nonAuthMenus, adminAuthMenus, commonAuthMenus, userAuthMenus };
