import axios from "axios";
import Bottleneck from "bottleneck";

// Create a Bottleneck instance for rate limiting
const limiter = new Bottleneck({
  minTime: 1000, // 1 second
});

// Create a cache to store coordinates
const cache = new Map();

export async function getCoordinates(address) {
  // Check if the address is already in the cache
  if (cache.has(address)) {
    return cache.get(address);
  }

  const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
    address
  )}&format=json`;

  try {
    // Use the limiter to schedule the API request
    const response = await limiter.schedule(() => axios.get(url));
    if (response.data.length > 0) {
      const latitude = response.data[0].lat;
      const longitude = response.data[0].lon;
      const coordinates = { lat: latitude, lon: longitude };

      // Store the result in the cache
      cache.set(address, coordinates);

      return coordinates;
    } else {
      console.log("No coordinates found for the given address.");
    }
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    console.log("An error occurred while fetching the coordinates.");
  }
}
