import { connect } from "react-redux";
import "./styles.css";
import language from "../../../language";
import icons from "../../../constants/icons";

const Features = (props) => {
  const listFeatures = [];

  return (
    <div div className={`features row ${props.className}`}>
      {listFeatures.map((item, i) => {
        return <div></div>;
      })}
    </div>
  );
};

const mapStateProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateProps)(Features);
