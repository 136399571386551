import { Route, Routes, Outlet } from "react-router-dom";
import HomeDashboardScreen from "../screens/AdminDashboard";
import LoginScreen from "../screens/AdminDashboard/Authentication/LoginScreen";

const routes = [
  {
    path: "/admin-login",
    element: <LoginScreen />,
  },
  {
    path: "/dashboard/*",
    element: <HomeDashboardScreen />,
  },
];

export default () => {
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Outlet />
    </>
  );
};
