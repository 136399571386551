import React from 'react';

const Avatar = ({ initials, size, className = '' }) => {
  return (
    <div
      className={`bg-secondary rounded-circle ${className}`}
      style={{ width: size, height: size }}
    >
      <span className="text-white d-flex align-items-center justify-content-center h-100">
        {initials}
      </span>
    </div>
  );
};

export default Avatar;
