import React from "react";
import Button from "./Button";
import { connect } from "react-redux";

class FileUploadButton extends React.Component {
  constructor() {
    super();
  }

  handleClick = () => {
    this.refs.inputRef.click();
  };

  handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files;
    if (!fileObj) {
      return;
    }
    // event.target.value = null;

    this.props.handleFiles(Array.from(event.target.files));
  };

  render() {
    return (
      <>
        <input
          style={{ display: "none" }}
          ref={"inputRef"}
          type="file"
          onChange={this.handleFileChange.bind(this)}
          accept={this.props.accept}
          multiple
        />
        <Button
          className={this.props.className}
          icon={this.props.icon}
          text={this.props.text}
          onPress={this.handleClick.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(FileUploadButton);
