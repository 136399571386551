import React from "react";
import { Search, Plus } from "lucide-react";
import { ConversationList } from "./ConversationList";

export function Sidebar({
  conversations,
  search,
  onSearchChange,
  onNewChat,
  onConversationSelect,
}) {
  return (
    <div className="d-flex flex-column overflow-scroll" style={{
      height: "90vh",
    }} >
      <div className="p-3 border-bottom">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className="h5 mb-0">Messages</h1>
          <button
            onClick={onNewChat}
            className="btn btn-light p-2 d-flex align-items-center justify-content-center rounded-circle"
            title="New Chat"
          >
            <Plus
              className="text-success"
              style={{ width: "20px", height: "20px" }}
            />
          </button>
        </div>
        <div className="input-group">
          <span className="input-group-text bg-light border-0">
            <Search
              className="text-muted"
              style={{ width: "16px", height: "16px" }}
            />
          </span>
          <input
            type="text"
            placeholder="Search conversations..."
            value={search}
            onChange={(e) => onSearchChange(e.target.value)}
            className="form-control border-0"
          />
        </div>
      </div>
      <div className=" overflow-scroll" style={{height:"90vh"}} >
        <ConversationList
          conversations={conversations}
          onSelect={onConversationSelect}
        />
      </div>
    </div>
  );
}
