import React from "react";
import "../styles.css";
import CardCount from "../../CardCount/CardCount";
import { fetchCounts } from "../../../utils/queries/analyticsQuery";
import { getStorage } from "../../../utils/storage";
import { connect } from "react-redux";
import formateFilters from "../../../utils/formateFilters";
import { homeCardCounts } from "../../../constants/cardCounts";
import icons from "../../../constants/icons";

class Counts extends React.Component {
  state = {
    cardCounts: homeCardCounts,
    isLoading: true,
  };
  componentDidMount = async () => {
    this.handleFetchData();
  };

  handleFetchData = async () => {
    const user = await getStorage();
    await this.setState({ user });

    const { service = {} } = this.props.filters;
    const { settings = {} } = service;

    if (settings && settings?.cardCounts?.length > 0) {
      this.fetchCountsPerService(settings.cardCounts);
    } else {
      //   this.fetchGeneralCounts();
      this.fetchCountsPerService([
        {
          label: "Total Graduates",
          value: "graduationRate",
          icon: "analytics",
        },
        {
          label: "Young Farmers",
          value: "farmersGraduated",
          icon: "contacts",
        },
        {
          label: "Off-farmers",
          value: "offFarmersGraduated",
          icon: icons.users,
        },
        {
          label: "FSCs",
          value: "fscsGraduated",
          icon: icons.users,
        },
        {
          label: "Non Youth Farmers",
          value: "nonYouthFarmersGraduated",
          icon: icons.users,
        },
      ]);
    }
  };

  //   fetchGeneralCounts = () => {
  //     this.getCounts(true, {
  //       fieldName: "totalFSCs",
  //       ["service.type"]: ["FSCs"],
  //     });

  //     this.getCounts(true, {
  //       fieldName: "totalIndividualFarmers",
  //       ["service.type"]: "FARMERS",
  //       //   [
  //       //     "INDIVIDUAL_FARMER_REGISTRATION",
  //       //     "INDIVIDUAL_YOUNG_FARMER",
  //       //     "YOUNG_FARMER_DEMOGRAPHY",
  //       //     "DEMOGRAPHY",
  //       //   ],
  //     });

  //     this.getCounts(true, {
  //       fieldName: "totalMarketActorsFarmers",
  //       ["service.type"]: ["MARKET_ACTORS_REGISTRATION"],
  //     });

  //     this.getCounts(true, {
  //       fieldName: "totalOffFarm",
  //       ["service.type"]: ["OFF_FARM_REGISTRATION"],
  //     });
  //   };

  fetchCountsPerService(cardCounts = []) {
    this.setState({ cardCounts });
    const { isLoading } = this.state;

    cardCounts.forEach((cardCount) => {
      if (cardCount.value === "graduationRate") {
        this.getCounts(isLoading, {
          fieldName: "graduationRate",
          // ["service.id"]: "6718beed09b9cd7db894a330",
          ["questionnaireAnswer.questionText"]: ["Choose Farmer Type"],
          ["questionnaireAnswer.answer"]: ["FSC", "Young Farmer","Non Young Farmer","Off Farmer"],
          count: true,
        });
      }

      if (cardCount.value === "farmersGraduated") {
        this.getCounts(isLoading, {
          fieldName: "farmersGraduated",
          ["questionnaireAnswer.questionText"]: ["Choose Farmer Type"],
          ["questionnaireAnswer.answer"]: ["Young Farmer"],
          count: true,
        });
      }

      if (cardCount.value === "offFarmersGraduated") {
        this.getCounts(isLoading, {
          fieldName: "offFarmersGraduated",
          ["questionnaireAnswer.questionText"]: ["Choose Farmer Type"],
          ["questionnaireAnswer.answer"]: ["Off Farmer"],
          count: true,
        });
      }

      if (cardCount.value === "fscsGraduated") {
        this.getCounts(isLoading, {
          fieldName: "fscsGraduated",
          ["questionnaireAnswer.questionText"]: ["Choose Farmer Type"],
          ["questionnaireAnswer.answer"]: ["FSC"],
          count: true,
        });
      }

      if (cardCount.value === "nonYouthFarmersGraduated") {
        this.getCounts(isLoading, {
          fieldName: "nonYouthFarmersGraduated",
          ["questionnaireAnswer.questionText"]: ["Choose Farmer Type"],
          ["questionnaireAnswer.answer"]: ["Non Young Farmer"],
          count: true,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.handleFetchData(this.props.filters?.settings?.cardCounts);
    }
  }

  getCounts = async (isLoading, params = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ [`is_${params.fieldName}`]: isLoading });

      let returnFilters = {
        ...formateFilters(this.props.filters),
        ...params,
      };

      delete returnFilters.startCreatedAt;
      delete returnFilters.endCreatedAt;

      const data = await fetchCounts(defaultLanguage, returnFilters);

      let count = 0;

      if (data.length > 0) {
        count = data[0].count;
      }

      this.setState({
        [params.fieldName]: count,
        [`is_${params.fieldName}`]: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        [`is_${params.fieldName}`]: false,
        [params.fieldName]: 0,
      });
    }
  };

  handleToggle(isToggled) {
    this.setState({
      [isToggled]: !this.state[isToggled],
    });
  }

  
  render() {

    return (
      <>
        <div className="row mb-3">
          {this.state.cardCounts.map((el, i) => {
            return (
              <div
                className={`mb-3 card-container`}
                key={i}
              >
                <CardCount
                  icon={el.icon}
                  color="#00C850"
                  count={this.state[el.fieldName || el.value]}
                  title={el.title || el.label}
                  isLoading={this.state[`is_${el.fieldName || el.value}`]}
                />
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Counts);
