import React from "react";
import { DropdownButton } from "../Button";

import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import language from "../../language";
import { Checkbox } from "../Input";
import { onAddFilter, onResetFilters } from "../../actions/Filters";
import serviceQuery from "../../utils/queries/serviceQuery";
import formatSelectData from "../../utils/formatSelectData";
import { getStorage } from "../../utils/storage";
import { Loading } from "../Loader";

let copyData = [];

class ServiceCategoriesFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      serviceCategories: [],
    };
  }

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });
    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target.value });
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await serviceQuery(defaultLanguage, {
        page: 1,
        limit: 100,
      });
      const results = formatSelectData(data, "name", "_id");
      this.setState({
        data: results,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = results.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onCheck(el) {
    let { filters } = this.props;
    let { service = [] } = filters;

    service = {
      settings: el.settings,
      value: el.value,
      label: el.label,
    };

    this.props.dispatch(
      onAddFilter({
        service,
      })
    );
  }

  handleSearch(e) {
    const searchValue = e.target.value;
    let results = [];

    this.setState({ searchValue });

    copyData.map((el) => {
      if (el.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
        results.push(el);
      }
    });

    this.setState({ data: results });
  }

  handleResetFilter() {
    this.props.dispatch(onResetFilters("service"));
  }
  render() {
    return (
      <DropdownButton
        leftIcon={icons.category}
        text={language[this.props.defaultLanguage].service}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        selectedData={this.props?.filters?.service}
        clickOutside
      >
        <CardHeader
          title={language[this.props.defaultLanguage].service}
          borderBottom
          closeButton
          isSearch
          reset
          handleResetFilter={this.handleResetFilter.bind(this)}
          handleSearch={this.handleSearch.bind(this)}
          searchValue={this.state.searchValue}
        />
        <div className="dropdown-content">
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            this.state?.data?.map((el, i) => {
              return (
                <Checkbox
                  label={el.label || el.name}
                  key={i}
                  onCheck={() => this.onCheck(el)}
                  checked={
                    this.props?.filters?.service?.value === (el.value || el._id)
                  }
                />
              );
            })
          )}
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ServiceCategoriesFilter);
