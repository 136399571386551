export const serviceCategoriesOptions = (lng = "english") => {
  const API_ACCESS = "/api/access";

  const surveyApi = `${API_ACCESS}/survey`;
  return [
    {
      label: "YOUNG FARMERS",
      value: "YOUNG_FARMERS",
      paths: {
        _POST: [surveyApi],
        _GET: [surveyApi],
        _PUT: [surveyApi],
        _DELETE: [surveyApi],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Create", value: "create" },
        { label: "Update", value: "update" },
        { label: "Delete", value: "delete" },
      ],
    },
    {
      label: "NON YOUNG FARMERS",
      value: "NON_YOUNG_FARMERS",
      paths: {
        _POST: [surveyApi],
        _GET: [surveyApi],
        _PUT: [surveyApi],
        _DELETE: [surveyApi],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Create", value: "create" },
        { label: "Update", value: "update" },
        { label: "Delete", value: "delete" },
      ],
    },
    {
      label: "OFF FARMERS",
      value: "OFF_FARMERS",
      paths: {
        _POST: [surveyApi],
        _GET: [surveyApi],
        _PUT: [surveyApi],
        _DELETE: [surveyApi],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Create", value: "create" },
        { label: "Update", value: "update" },
        { label: "Delete", value: "delete" },
      ],
    },
    {
      label: "FSCs",
      value: "FSCs",
      paths: {
        _POST: [surveyApi],
        _GET: [surveyApi],
        _PUT: [surveyApi],
        _DELETE: [surveyApi],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Create", value: "create" },
        { label: "Update", value: "update" },
        { label: "Delete", value: "delete" },
      ],
    },
    {
      label: "MARKET ACTORS",
      value: "MARKET_ACTORS",
      paths: {
        _POST: [surveyApi],
        _GET: [surveyApi],
        _PUT: [surveyApi],
        _DELETE: [surveyApi],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Create", value: "create" },
        { label: "Update", value: "update" },
        { label: "Delete", value: "delete" },
      ],
    },
    {
      label: "KPI",
      value: "KPI",
      paths: {
        _POST: [surveyApi],
        _GET: [surveyApi],
        _PUT: [surveyApi],
        _DELETE: [surveyApi],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Create", value: "create" },
        { label: "Update", value: "update" },
        { label: "Delete", value: "delete" },
      ],
    },
    {
      label: "IMPACT",
      value: "IMPACT",
      paths: {
        _POST: [surveyApi],
        _GET: [surveyApi],
        _PUT: [surveyApi],
        _DELETE: [surveyApi],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Create", value: "create" },
        { label: "Update", value: "update" },
        { label: "Delete", value: "delete" },
      ],
    },
    {
      label: "FSC_EVALUATION",
      value: "FSC_EVALUATION",
      paths: {
        _POST: [surveyApi],
        _GET: [surveyApi],
        _PUT: [surveyApi],
        _DELETE: [surveyApi],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Create", value: "create" },
        { label: "Update", value: "update" },
        { label: "Delete", value: "delete" },
      ],
    },
    {
      label: "YOUNG_FARMERS_EVALUATION",
      value: "YOUNG_FARMERS_EVALUATION",
      paths: {
        _POST: [surveyApi],
        _GET: [surveyApi],
        _PUT: [surveyApi],
        _DELETE: [surveyApi],
      },
      operations: [
        { label: "Read", value: "read" },
        { label: "Create", value: "create" },
        { label: "Update", value: "update" },
        { label: "Delete", value: "delete" },
      ],
    },
    // {
    //   label: "INDIVIDUAL FARMER",
    //   value: "INDIVIDUAL_FARMER",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "INDIVIDUAL YOUNG FARMER",
    //   value: "INDIVIDUAL_YOUNG_FARMER",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "NON YOUTH INDIVIDUAL FARMER",
    //   value: "NON_YOUTH_INDIVIDUAL_FARMER",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "INDIVIDUAL FARMER REGISTRATION",
    //   value: "INDIVIDUAL_FARMER_REGISTRATION",
    //   type: "REGISTRATION",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "YOUNG FSC FARMER",
    //   value: "YOUNG_FSC_FARMER",
    //   type: "REGISTRATION",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "NON YOUTH FSC FARMER",
    //   value: "NON_YOUTH_FSC_FARMER",
    //   type: "REGISTRATION",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FSC FARMER REGISTRATION",
    //   value: "FSC_REGISTRATION",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "OFF-FARM REGISTRATION",
    //   value: "OFF_FARM_REGISTRATION",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "MARKET ACTORS REGISTRATION",
    //   value: "MARKET_ACTORS_REGISTRATION",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "VALUE CHAIN AND INCOME",
    //   value: "VALUE_CHAIN_AND_INCOME",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "ACCESS TO MARKET",
    //   value: "ACCESS_TO_MARKET",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "YOUNG FARMER ACCESS TO MARKET",
    //   value: "YOUNG_FARMER_ACCESS_TO_MARKET",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FINANCE",
    //   value: "FINANCE",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "YOUNG FARMER DEMOGRAPHY",
    //   value: "YOUNG_FARMER_DEMOGRAPHY",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "YOUNG_FARMER_FINANCE",
    //   value: "YOUNG_FARMER_FINANCE",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "YOUNG FARMER VALUE CHANGE AND INCOME",
    //   value: "YOUNG_FARMER_VALUE_CHANGE_AND_INCOME",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "YOUNG FARMER ACCESS TO FINANCE",
    //   value: "YOUNG_FARMER_ACCESS_TO_FINANCE",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "YOUNG FARMER SKILLS ENHANCEMENT",
    //   value: "YOUNG_FARMER_SKILLS_ENHANCEMENT",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "YOUNG FARMER PERCEPTION AND VISIONING",
    //   value: "YOUNG_FARMER_PERCEPTION_AND_VISIONING",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "COOPERATIVE",
    //   value: "COOPERATIVE",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "SKILLS ENHANCEMENT",
    //   value: "SKILLS_ENHANCEMENT",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "KPI",
    //   value: "KPI",
    //   type: "KPI",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "IMPACT",
    //   value: "IMPACT",
    //   type: "IMPACT",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FARMERS LINKED TO THE FSC",
    //   value: "FARMERS_LINKED_TO_THE_FSC",
    //   type: "FARMERS_LINKED_TO_THE_FSC",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "PERCEPTION AND VISIONING",
    //   value: "PERCEPTION_AND_VISIONING",
    //   type: "PERCEPTION_AND_VISIONING",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },

    // {
    //   label: "FSC DEMOGRAPHY",
    //   value: "FSC_DEMOGRAPHY",
    //   type: "FSC_DEMOGRAPHY",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FSC VALUE CHAIN AND INCOME",
    //   value: "FSC_VALUE_CHAIN_AND_INCOME",
    //   type: "FSC_VALUE_CHAIN_AND_INCOME",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FSC ACCESS TO MARKET",
    //   value: "FSC_ACCESS_TO_MARKET",
    //   type: "FSC_ACCESS_TO_MARKET",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FSC ACCESS TO FINANCE",
    //   value: "FSC_ACCESS_TO_FINANCE",
    //   type: "FSC_ACCESS_TO_FINANCE",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FSC SKILLS ENHANCEMENT",
    //   value: "FSC_SKILLS_ENHANCEMENT",
    //   type: "FSC_SKILLS_ENHANCEMENT",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FSC PERCEPTION AND VISIONING",
    //   value: "FSC_PERCEPTION_AND_VISIONING",
    //   type: "FSC_PERCEPTION_AND_VISIONING",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FSC-KPI:ACCESS TO MARKET",
    //   value: "FSC_KPI_ACCESS_TO_MARKET",
    //   type: "FSC_KPI_ACCESS_TO_MARKET",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FSC-KPI:ACCESS TO FINANCE",
    //   value: "FSC_KPI_ACCESS_TO_FINANCE",
    //   type: "FSC_KPI_ACCESS_TO_FINANCE",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
    // {
    //   label: "FSC-KPI:SKILLS ENHANCEMENT",
    //   value: "FSC_KPI_SKILLS_ENHANCEMENT",
    //   type: "FSC_KPI_SKILLS_ENHANCEMENT",
    //   paths: {
    //     _POST: [surveyApi],
    //     _GET: [surveyApi],
    //     _PUT: [surveyApi],
    //     _DELETE: [surveyApi],
    //   },
    //   operations: [
    //     { label: "Read", value: "read" },
    //     { label: "Create", value: "create" },
    //     { label: "Update", value: "update" },
    //     { label: "Delete", value: "delete" },
    //   ],
    // },
  ];
};
