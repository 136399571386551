import React from "react";
import { connect } from "react-redux";
import {
  downloadFile,
  fetchSurveyDashboard,
} from "../../utils/queries/surveysQuery";
import { Modal } from "../Modal";
import CustomFilter from "../Filters/CustomFilter";
import {
  DashboardBarChart,
  DashboardLineChart,
  DashboardPieChart,
} from "../Charts/DoughnutChart";
import { ChartCard } from "../Evaluation/DashboardContainer";
import { Loading } from "../Loader";
import { Input } from "../Input";
import { Button } from "../Button";
import capitalizeText from "../../utils/capitalizeText";

const reportCategory = [
  {
    id: "SER",
    name: "Skills Enhancement Report",
    types: [
      {
        id: "SER-TP",
        name: "Trained Participants",
        reports: [
          { id: "SE-TP-TNP", name: "Total Number of Participants" },
          { id: "SE-TP-TD", name: "Training Details" },
          { id: "SE-TP-UI", name: "Untrained Individuals" },
        ],
      },
      {
        id: "SER-SA",
        name: "Skills Applications",
        reports: [
          { id: "SE-SA-PWAS", name: "Participants Who Applied Skills" },
          { id: "SE-SA-PWDNAS", name: "Participants Who Did Not Apply Skills" },
        ],
      },
      {
        id: "SER-MI",
        name: "Measurable Improvement",
        reports: [
          { id: "SE-MI-TNP", name: "Individuals Who Showed Improvement" },
          { id: "SE-MI-TD", name: "Individuals Without Improvement" },
        ],
      },
      {
        id: "SER-KS",
        name: "Knowledge Sharing",
        reports: [
          { id: "SE-KS-TNP", name: "Participants Who Shared Knowledge" },
          { id: "SE-KS-TD", name: "Participants Who Did Not Share Knowledge" },
        ],
      },
      {
        id: "SER-ATN",
        name: "Additional Training Needs",
        reports: [
          { id: "SE-ATN-TNP", name: "Identified Training Gaps" },
          { id: "SE-ATN-TD", name: "Participants’ Requested Trainings" },
        ],
      },
      {
        id: "SER-PHSI",
        name: "Post-Harvest Skilled Individuals",
        reports: [
          {
            id: "SE-PHSI-TNP",
            name: "List of Participants Skilled in Post-Harvest Techniques",
          },
          { id: "SE-PHSI-TD", name: "Sponsoring Organizations/Entities" },
        ],
      },
    ],
  },
  {
    id: "AMR",
    name: "Access to Market Reports",
    types: [
      {
        id: "ML",
        name: "Market Linkages",
        reports: [
          { id: "AMR-ML-MLE", name: "Market Linkages Established" },
          { id: "AMR-ML-TML", name: "Types of Market Linkages" },
        ],
      },
      {
        id: "CBG",
        name: "Customer Base Growth",
        reports: [
          {
            id: "AMR-CBG-PWRCBG",
            name: "Participants Who Reported Customer Base Growth",
          },
          {
            id: "AMR-CBG-CCB",
            name: "Current customer base",
          },
        ],
      },
      {
        id: "CA",
        name: "Contract Acquisition",
        reports: [
          { id: "AMR-CA-PWSC", name: "Participants Who Secured Contracts" },
          { id: "AMR-CA-PSSC", name: "Participants Still Seeking Contracts" },
        ],
      },
      {
        id: "SVC",
        name: "Sales Volume Change",
        reports: [
          { id: "AMR-SVC-CS", name: "Changes in Sales (Quantity & Value)" },
        ],
      },
      {
        id: "RF",
        name: "Registered Farmers",
        reports: [
          { id: "AMR-RF-TNRF", name: "Total Number of Registered Farmers" },
        ],
      },
      {
        id: "JC",
        name: "Job Creation",
        reports: [
          { id: "AMR-JC-TNJC", name: "Total New Jobs Created" },
          {
            id: "AMR-JC-BGA",
            name: "Breakdown by Gender(female and male) & Age",
          },
          { id: "AMR-JC-JC", name: "Job Categories" },
        ],
      },
      {
        id: "NOD",
        name: "New Opportunities Discovered",
        reports: [
          {
            id: "AMR-NOD-TO",
            name: "Type of Opportunities",
          },
        ],
      },
    ],
  },
  {
    id: "AFR",
    name: "Access to Finance Reports",
    types: [
      {
        id: "AFR-CBG",
        name: "Capital Base Growth",
        reports: [
          { id: "AFR-CBG-PRCG", name: "Participants Reporting Capital Growth" },
          { id: "AFR-CBG-CCBV", name: "Current Capital Base Value" },
        ],
      },
      {
        id: "SA",
        name: "Skills Applications",
        reports: [
          {
            id: "AFR-SA-PLFI",
            name: "Participants Linked to Financial Institutions",
          },
          {
            id: "AFR-SA-TFL",
            name: "Type of Financial Linkage",
          },
        ],
      },
      {
        id: "LA",
        name: "Loan Access",
        reports: [
          { id: "AFR-LA-PWRL", name: "Participants Who Received Loans" },
          { id: "AFR-LA-LVR", name: "Loan Value Received" },
          { id: "AFR-LA-PWWSL", name: "Participants Who Wish to Secure Loans" },
        ],
      },
      {
        id: "KS",
        name: "Knowledge Sharing",
        reports: [
          { id: "AFR-KS-TNP", name: "Participants Who Shared Knowledge" },
          { id: "AFR-KS-TD", name: "Participants Who Did Not Share Knowledge" },
        ],
      },
      {
        id: "GA",
        name: "Grant Access",
        reports: [
          { id: "AFR-GA-PWRG", name: "Participants Who Received Grants" },
          { id: "AFR-GA-GVS", name: "Grant Value & Source" },
        ],
      },
      {
        id: "EF",
        name: "Equipment Financing",
        reports: [
          {
            id: "AFR-EF-PREL",
            name: "Participants Who Received Equipment or Land",
          },
          {
            id: "AFR-EF-MVER",
            name: "Monetary Value of Equipment/Land Received",
          },
        ],
      },
      {
        id: "IFA",
        name: "Impact of Finance Access",
        reports: [
          {
            id: "AFR-IFA-HAFIBR",
            name: "Has Accessing Finance Increased Business Revenue?",
          },
          {
            id: "AFR-IFA-HFHBE",
            name: "Has Finance Helped in Business Expansion?",
          },
        ],
      },
    ],
  },
  {
    id: "VCIR",
    name: "Value Chain & Income Reports",
    types: [
      {
        id: "TP",
        name: "Trained Participants",
        reports: [
          { id: "SE-TP-TNP", name: "Total Number of Participants" },
          { id: "SE-TP-TD", name: "Training Details" },
          { id: "SE-TP-UI", name: "Untrained Individuals" },
        ],
      },
      {
        id: "SA",
        name: "Skills Applications",
        reports: [
          { id: "SE-SA-PWAS", name: "Participants Who Applied Skills" },
          { id: "SE-SA-PWDNAS", name: "Participants Who Did Not Apply Skills" },
        ],
      },
      {
        id: "MI",
        name: "Measurable Improvement",
        reports: [
          { id: "SE-MI-TNP", name: "Individuals Who Showed Improvement" },
          { id: "SE-MI-TD", name: "Individuals Without Improvement" },
        ],
      },
      {
        id: "KS",
        name: "Knowledge Sharing",
        reports: [
          { id: "SE-KS-TNP", name: "Participants Who Shared Knowledge" },
          { id: "SE-KS-TD", name: "Participants Who Did Not Share Knowledge" },
        ],
      },
      {
        id: "ATN",
        name: "Additional Training Needs",
        reports: [
          { id: "SE-ATN-TNP", name: "Identified Training Gaps" },
          { id: "SE-ATN-TD", name: "Participants’ Requested Trainings" },
        ],
      },
      {
        id: "PHSI",
        name: "Post-Harvest Skilled Individuals",
        reports: [
          {
            id: "SE-PHSI-TNP",
            name: "List of Participants Skilled in Post-Harvest Techniques",
          },
          { id: "SE-PHSI-TD", name: "Sponsoring Organizations/Entities" },
        ],
      },
    ],
  },
];

class Impacts extends React.Component {
  state = {
    loadingRPTGData: false,
    loadingYAFTGData: false,
    loadingVFAPData: false,
    loadingNISData: false,
    loadingPTLSData: false,
    rptgData: null,
    yaftgData: null,
    vfapData: [],
    nisData: null,
    ptlsData: null,
    searchText: "",
    quarter: "1-2025",
    district: "",
    showReportModal: false,
    selectedReportCategory: null,
    selectedReportType: null,
    selectedReport: null,
    loadingReport: false,
  };

  getData = (
    option,
    stateLoadingKey = "loadingRPTGData",
    stateDataKey = "rptgData"
  ) => {
    fetchSurveyDashboard(option, (status, res) => {
      this.setState({ [stateLoadingKey]: status, [stateDataKey]: res });
    });
  };

  componentDidMount = async () => {
    if (!this.state.rptgData && !this.state.loadingRPTGData) {
      this.getData(
        {
          serviceName:
            this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
          quarter: this.state.quarter,
          district: this.state.district,
          keySearch: "IMPACT-RPTG", //"KPI",
        },
        "loadingRPTGData",
        "rptgData"
      );
    }
    if (!this.state.yaftgData && !this.state.loadingYAFTGData) {
      this.getData(
        {
          serviceName:
            this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
          quarter: this.state.quarter,
          district: this.state.district,
          keySearch: "IMPACT-YAFTG", //"KPI",
        },
        "loadingYAFTGData",
        "yaftgData"
      );
    }
    if (!this.state.vfapData.length && !this.state.loadingVFAPData) {
      this.getData(
        {
          serviceName:
            this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
          quarter: this.state.quarter,
          district: this.state.district,
          keySearch: "IMPACT-VFAP", //"KPI",
        },
        "loadingVFAPData",
        "vfapData"
      );
    }
    if (!this.state.vfapData.length && !this.state.loadingVFAPData) {
      this.getData(
        {
          serviceName:
            this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
          quarter: this.state.quarter,
          district: this.state.district,
          keySearch: "IMPACT-NIS", //"KPI",
        },
        "loadingNISData",
        "nisData"
      );
    }
    if (!this.state.ptlsData && !this.state.loadingPTLSData) {
      this.getData(
        {
          serviceName:
            this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
          quarter: this.state.quarter,
          district: this.state.district,
          keySearch: "IMPACT-PTLS", //"KPI",
        },
        "loadingPTLSData",
        "ptlsData"
      );
    }
  };

  handleFilter() {
    this.getData(
      {
        serviceName:
          this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
        quarter: this.state.quarter,
        district: this.state.district,
        keySearch: "IMPACT-RPTG", //"KPI",
      },
      "loadingRPTGData",
      "rptgData"
    );
    this.getData(
      {
        serviceName:
          this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
        quarter: this.state.quarter,
        district: this.state.district,
        keySearch: "IMPACT-YAFTG", //"KPI",
      },
      "loadingYAFTGData",
      "yaftgData"
    );
    this.getData(
      {
        serviceName:
          this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
        quarter: this.state.quarter,
        district: this.state.district,
        keySearch: "IMPACT-VFAP", //"KPI",
      },
      "loadingVFAPData",
      "vfapData"
    );
    this.getData(
      {
        serviceName:
          this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
        quarter: this.state.quarter,
        district: this.state.district,
        keySearch: "IMPACT-NIS", //"KPI",
      },
      "loadingNISData",
      "nisData"
    );
    this.getData(
      {
        serviceName:
          this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
        quarter: this.state.quarter,
        district: this.state.district,
        keySearch: "IMPACT-PTLS", //"KPI",
      },
      "loadingPTLSData",
      "ptlsData"
    );
  }

  handleDownloadReport() {
    const option = {
      serviceName: this.props.category === "Young Farmers" ? "FARMERS" : "FSC",
      quarter: this.state.quarter,
      district: this.state.district,
      keySearch: this.state.selectedReport?.id || "",
      fileType: "excel",
    };
    downloadFile(option, (status) => this.setState({ loadingReport: status }));
  }

  render() {
    return (
      <>
        <div className="mb-2 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <CustomFilter
              isLoading={
                this.state.loadingRPTGData ||
                this.state.loadingPTLSData ||
                this.state.loadingRPTGData ||
                this.state.loadingVFAPData ||
                this.state.loadingYAFTGData
              }
              title="Quarter"
              data={["1-2025", "2-2025", "3-2025"]}
              selectedData={this.state.quarter}
              setSelectedData={(value) => this.setState({ quarter: value })}
              isNotMultiple={true}
              resetValue="1-2025"
            />
            <Input
              placeholder="District"
              // leftIcon="search"
              value={this.state.district}
              onChange={(e) =>
                this.setState({ district: capitalizeText(e.target.value) })
              }
              // iconRight="search"
              inputContainerStyle={{ marginBottom: 0 }}
              autoComplete="off"
              type="search"
              className="sm w-100"
              // textarea={"multipleLineText"}
              // addSearchButton
            />
            <Button
              // selected={filter.selected}
              className={`btn-xsm`}
              text="Search"
              onPress={() => this.handleFilter()}
              disabled={!this.state.quarter && !this.state.district}
              // icon={"filter_alt"}
              // isSubmitting={filter.isSubmitting}
            />
            {/* <districtFilter />
          <DistrictFilter />
          <SectorFilter />
          <DateFilter /> */}
            {/* <AdvancedFilter
            // isLoading={this.state.isLoading}
            headers={this.state.tableHeaders}
          /> */}
          </div>

          <Button
            // selected={filter.selected}
            className={`btn-xsm btn-default`}
            text="Generate Report"
            onPress={() => this.setState({ showReportModal: true })}
            icon={"filter_alt"}
            // isSubmitting={filter.isSubmitting}
          />
        </div>

        <div className="row g-4 mb-4 justify-content-between">
          <div className="col-md-4">
            <ChartCard hideBtn title="Registered participants by Type">
              <DashboardBarChart
                hideTitle
                chartData={this.state.rptgData}
                loading={this.state.loadingRPTGData || !this.state.rptgData}
                legendPosition="bottom"
              />
            </ChartCard>
          </div>
          <div className="col-md-4">
            <div className="finance-summary">
              <h2>Value of finance acquired by participants</h2>
              <table className="w-100">
                <thead>
                  <tr>
                    <th>Financing mechanism</th>
                    <th>Value in Rwf</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.loadingVFAPData ? (
                    <tr>
                      <td colSpan={2} align="center">
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    this.state.vfapData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.label}</td>
                        <td>{(item.amount || 0).toLocaleString()}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <ChartCard hideBtn title="Youth Registered participants by Type">
              <DashboardBarChart
                hideTitle
                chartData={this.state.yaftgData}
                loading={this.state.loadingYAFTGData || !this.state.yaftgData}
              />
            </ChartCard>
          </div>
          <div className="col-md-4">
            <ChartCard
              hideBtn
              title="Total profiled participants (youth) by Type"
            >
              <DashboardLineChart
                hideTitle
                chartData={{
                  labels: [
                    "Young Farmer",
                    "Off-term Youth",
                    "FSC",
                    "Market Actors",
                  ],
                  datasets: [
                    {
                      label: "Dataset 1",
                      fill: false,
                      // lineTension: 0.1,
                      backgroundColor: [
                        "rgba(0, 200, 80, 0.6)",
                        // "#FF6D00",
                        // "#A0C800",
                      ],
                      borderColor: "rgba(0, 200, 80, 1)",
                      borderWidth: 1,
                      data: [
                        8000000, 7200000, 5600000, 2000000, 618000, 420000,
                        120000,
                      ],
                    },
                  ],
                }}
                isStackedChart={false}
                isHorizontalBar={false}
                loading={false}
              />
            </ChartCard>
          </div>
          <div className="col-md-4">
            <ChartCard hideBtn title="New Source of Income">
              <DashboardBarChart
                hideTitle
                chartTitle=""
                chartData={this.state.nisData}
                isStackedChart={false}
                isHorizontalBar={false}
                loading={this.state.loadingNISData || !this.state.nisData}
              />
            </ChartCard>
          </div>
          <div className="col-md-4">
            <ChartCard hideBtn title="Outcome: New jobs and Entrepreneurship">
              <DashboardBarChart
                hideTitle
                chartData={{
                  labels: [
                    "Loans",
                    "Grants",
                    // "Microcredits",
                    "Assets",
                    "Pre-finance",
                  ],
                  datasets: [
                    {
                      label: "Male",
                      backgroundColor: "rgba(54, 162, 235, 0.6)",
                      borderColor: "rgba(54, 162, 235, 1)",
                      borderWidth: 1,
                      data: [200000, 150000, 300000, 250000, 400000],
                    },
                    {
                      label: "Female",
                      backgroundColor: "rgba(255, 99, 132, 0.6)",
                      borderColor: "rgba(255, 99, 132, 1)",
                      borderWidth: 1,
                      data: [180000, 160000, 280000, 270000, 390000],
                    },
                    {
                      label: "Total",
                      backgroundColor: "rgba(75, 192, 192, 0.6)",
                      borderColor: "rgba(75, 192, 192, 1)",
                      borderWidth: 1,
                      data: [380000, 310000, 580000, 520000, 790000],
                    },
                  ],
                }}
                isStackedChart={true}
                isHorizontalBar={false}
                loading={false}
                legendPosition="bottom"
              />
            </ChartCard>
          </div>
          <div className="col-md-4">
            <ChartCard
              hideBtn
              title="Participants(Youth) trained or linked to services"
            >
              <DashboardPieChart
                hideTitle
                chartData={this.state.ptlsData}
                loading={this.state.loadingPTLSData}
                legendPosition="bottom"
              />
            </ChartCard>
          </div>
          <div className="col-md-4">
            <ChartCard
              hideBtn
              title="Graduation: Youth In Work(Yiw) measurement"
            >
              <DashboardBarChart
                hideTitle
                chartData={{
                  labels: [
                    "Loans",
                    "Grants",
                    "Microcredits",
                    "Assets",
                    "Pre-finance",
                  ],
                  datasets: [
                    {
                      label: "Male",
                      backgroundColor: "rgba(54, 162, 235, 0.6)",
                      borderColor: "rgba(54, 162, 235, 1)",
                      borderWidth: 1,
                      data: [200000, 150000, 300000, 250000, 400000],
                    },
                    {
                      label: "Female",
                      backgroundColor: "rgba(255, 99, 132, 0.6)",
                      borderColor: "rgba(255, 99, 132, 1)",
                      borderWidth: 1,
                      data: [180000, 160000, 280000, 270000, 390000],
                    },
                    {
                      label: "Total",
                      backgroundColor: "rgba(75, 192, 192, 0.6)",
                      borderColor: "rgba(75, 192, 192, 1)",
                      borderWidth: 1,
                      data: [380000, 310000, 580000, 520000, 790000],
                    },
                  ],
                }}
                isStackedChart={true}
                isHorizontalBar={false}
                loading={false}
                legendPosition="bottom"
              />
            </ChartCard>
          </div>
        </div>

        {this.state.showReportModal && (
          <Modal
            handleClose={() => this.setState({ showReportModal: false })}
            show={this.state.showReportModal}
            title="Generate Report"
            showHeaderBottomBorder={false}
          >
            <div className="container my-3">
              <div className="d-flex flex-column gap-2 align-items-center justify-content-start">
                <div>
                  <CustomFilter
                    isLoading={this.state.loadingReport}
                    title="Report Category"
                    data={reportCategory}
                    selectedData={this.state.selectedReportCategory}
                    setSelectedData={(value) =>
                      this.setState({
                        selectedReportCategory: value,
                        selectedReportType: null,
                        selectedReport: null,
                      })
                    }
                    isNotMultiple={true}
                    resetValue={null}
                  />
                </div>
                <div>
                  <CustomFilter
                    disabled={
                      this.state.loadingReport ||
                      !this.state.selectedReportCategory
                    }
                    title="Report Type"
                    data={this.state.selectedReportCategory?.types || []}
                    selectedData={this.state.selectedReportType}
                    setSelectedData={(value) =>
                      this.setState({
                        selectedReportType: value,
                        selectedReport: null,
                      })
                    }
                    isNotMultiple={true}
                    resetValue={null}
                  />
                </div>

                <div>
                  <CustomFilter
                    isLoading={
                      this.state.loadingReport || !this.state.selectedReportType
                    }
                    title="Report"
                    data={this.state.selectedReportType?.reports || []}
                    selectedData={this.state.selectedReport}
                    setSelectedData={(value) =>
                      this.setState({ selectedReport: value })
                    }
                    isNotMultiple={true}
                    resetValue={null}
                  />
                </div>

                <div>
                  <Button
                    // selected={filter.selected}
                    className={`btn-sm`}
                    text="Download Report"
                    onPress={() => this.handleDownloadReport()}
                    // icon={"filter_alt"}
                    isSubmitting={this.state.loadingReport}
                    disabled={
                      !this.state.selectedReport || !!this.state.loadingReport
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Impacts);
