import { Route, Routes, Outlet } from "react-router-dom";
import SplashScreen from "../screens/common/SplashScreen";
import LandingScreen from "../screens/common/LandingScreen";
import LoginScreen from "../screens/common/Authentication/LoginScreen";
import ChangePasswordScreen from "../screens/common/Authentication/ChangePasswordScreen";
import ResetPasswordScreen from "../screens/common/Authentication/ResetPasswordScreen";

const routes = [
  {
    path: "/",
    element: <SplashScreen />,
  },
  {
    path: "/landing",
    element: <LandingScreen />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/forgot-password",
    element: <ChangePasswordScreen />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordScreen />,
  },
];

export default () => {
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Outlet />
    </>
  );
};
