import moment from "moment";

export default (date1, date2) => {
  const formatDate1 = moment(date1);
  const formatDate2 = moment(date2);
  const diffDays = Math.abs(formatDate1.diff(formatDate2, "days"));
  const diffWeeks = Math.abs(formatDate1.diff(formatDate2, "weeks"));
  const diffMonths = Math.abs(formatDate1.diff(formatDate2, "months"));

  if (diffDays === 0) {
    return "Today";
  } else if (diffWeeks === 0) {
    return "This Week";
  } else if (diffMonths === 0) {
    return "This Month";
  } else {
    return "More than a month ago";
  }
};
