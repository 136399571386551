// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-candidate-performance-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
}

.regions-counts .filters-header-container {
  display: flex;
  align-items: center;
}

.regions-counts .card-header {
  display: flex;
  align-items: center;
}

.regions-counts .filters-header-container .icon-container span {
  font-size: 20px;
  opacity: 0.8;
}

.regions-counts .card-header .title {
  flex: 1 1;
}

.regions-counts .filters-header-container .filter-btn {
  background-color: var(--text-color-10);
  display: flex;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  opacity: 0.8;
  padding: 0.3rem 0.6rem;
}

.regions-counts .filters-header-container .filter-btn:hover {
  opacity: 1;
}

.chart-header {
  border: 1px solid var(--text-color-5);
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.card-container {
  flex: 1 1;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .card-container {
    flex: none;
  }

}`, "",{"version":3,"sources":["webpack://./src/app/components/ImpactAnalytics/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,sCAAsC;EACtC,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qCAAqC;EACrC,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,SAAO;EACP,eAAe;AACjB;;AAEA;EACE;IACE,UAAU;EACZ;;AAEF","sourcesContent":[".list-candidate-performance-item {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  min-height: 60px;\n}\n\n.regions-counts .filters-header-container {\n  display: flex;\n  align-items: center;\n}\n\n.regions-counts .card-header {\n  display: flex;\n  align-items: center;\n}\n\n.regions-counts .filters-header-container .icon-container span {\n  font-size: 20px;\n  opacity: 0.8;\n}\n\n.regions-counts .card-header .title {\n  flex: 1;\n}\n\n.regions-counts .filters-header-container .filter-btn {\n  background-color: var(--text-color-10);\n  display: flex;\n  align-items: center;\n  border-radius: 16px;\n  cursor: pointer;\n  opacity: 0.8;\n  padding: 0.3rem 0.6rem;\n}\n\n.regions-counts .filters-header-container .filter-btn:hover {\n  opacity: 1;\n}\n\n.chart-header {\n  border: 1px solid var(--text-color-5);\n  min-height: 50px;\n  display: flex;\n  align-items: center;\n  padding: 1rem;\n}\n\n.card-container {\n  flex: 1;\n  flex-wrap: wrap;\n}\n\n@media screen and (max-width: 768px) {\n  .card-container {\n    flex: none;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
