import React from "react";
import { Services } from "../../components/Services";

class HomeScreen extends React.Component {
  render() {
    return (
      <>
        <Services type="listview" />
      </>
    );
  }
}

export default HomeScreen;
