export function extractServiceInfo(data) {
  return data.map((item) => ({
    id: item._id,
    name: item.name, // Optional: include files if needed
  }));
}

export function getEvaluationDetailsByUniqueNumber(data, uniqueNumber) {
  const filteredRecords = data.filter((record) => {
    return record.questionnaireAnswer.some(
      (answer) =>
        answer.answerType === "uniqueNumber" && answer.answer === uniqueNumber
    );
  });

  // If no records are found, return an empty result
  if (filteredRecords.length === 0) {
    return { uniqueNumber, records: [] };
  }

  // Process the filtered records
  const result = filteredRecords.map((record) => {
    const quarterAnswer = record.questionnaireAnswer.find(
      (answer) => answer.questionText === "Quarter"
    );
    const domainAnswer = record.description;

    return {
      quarter: quarterAnswer ? quarterAnswer.answer : undefined,
      domainIntervention: domainAnswer ? domainAnswer : undefined,
    };
  });

  // Group results by quarter and domain intervention
  const groupedResults = result.reduce(
    (acc, { quarter, domainIntervention }) => {
      if (!acc[quarter]) {
        acc[quarter] = [];
      }
      if (!acc[quarter].includes(domainIntervention)) {
        acc[quarter].push(domainIntervention);
      }
      return acc;
    },
    {}
  );

  return { uniqueNumber, records: groupedResults };
}

export function getDistinctDomainInterventions(data) {
  const domainInterventions = new Set();
  data.forEach((record) => {
    record.questionnaireAnswer.forEach((answer) => {
      if (
        answer.questionText === "Domain_intervention" ||
        answer.questionText === "Domain of Intervantion"
      ) {
        domainInterventions.add(answer.answer);
      }
    });
  });

  // Convert the Set to an array for the result
  return Array.from(domainInterventions);
}
