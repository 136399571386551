import { Select } from "../../Input";

const NormalDropdown = ({ label, data = [], onChangeText }) => {
  return (
    <Select
      label={label}
      options={data}
      // error={props.error[props.questionText]}
      // value={props[props.questionText]}
      onChange={(e) => onChangeText(label, e)}
      required
      // isMulti
    />
  );
};
export default NormalDropdown;
