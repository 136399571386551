import React from "react";
import { useParams } from 'react-router-dom';

import Surveys from "../../../components/ServiceSurvey/Surveys";
import { withRouter } from "../../../utils/withRouter";
import { DashboardHeader } from "../../../components/Header";
import { Breadcrumb } from "../../../components/Breadcrumb";
import language from "../../../language";
import { connect } from "react-redux";
import { getStorage } from "../../../utils/storage";
class SurveysScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  goBack() {
    window.history.go(-1);
    return false;
  }

  render() {
    return (
      <>
        <Breadcrumb
          homeUrl={`/${this.props.role}/home`}
          data={[
            {
              name: language[this.props.defaultLanguage].surveys,
              url: `/${this.props.role}/surveys`,
            },
            {
              name: this.props.routeParams.serviceName,
              active: true,
            },
          ]}
        />
        <DashboardHeader title={`${this.props.routeParams.serviceName}`} />
        <Surveys
          serviceId={this.props.routeParams.serviceId}
          surveyId={this.props.routeParams.surveyId}
          serviceName={this.props.routeParams.serviceName}
          category={this.props.routeParams.category}
          role={this.props.role}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(SurveysScreen));
