import React from "react";
import { DropdownButton, Button } from "../Button";
import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import language from "../../language";
import { onAddFilter } from "../../actions/Filters";
import { Input } from "../Input";

class ProvinceFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      isSubmitting: false,
      isResetting: false,
    };
  }

  handleTextChange(e) {
    this.setState({ value: e.target.value });
  }

  async resetFilter() {
    this.setState({
      isResetting: true,
    });

    await this.props.dispatch(
      onAddFilter({
        questionText: ["Province", "province"],
        answer: "",
      })
    );

    this.setState({
      isResetting: false,
      value: "",
    });
  }

  applyFilter = async () => {
    const { value } = this.state;

    this.setState({
      isSubmitting: true,
    });

    await this.props.dispatch(
      onAddFilter({
        questionText: ["Province", "province"],
        answer: value,
      })
    );

    this.setState({
      isSubmitting: false,
    });
  };

  render() {
    return (
      <DropdownButton
        leftIcon={icons.filter}
        text={language[this.props.defaultLanguage].provinceFilter}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        selectedData={this.props?.filters?.textFilter}
        clickOutside
      >
        <CardHeader
          title={language[this.props.defaultLanguage].provinceFilter}
          borderBottom
        />
        <div className="dropdown-content">
        <div className="card-body">
          <Input
            type="text"
            placeholder="Type to filter"
            value={this.state.value}
            onChange={(e) => this.handleTextChange(e)}
            className="filter-input"
          />
        </div>
        </div>
        <div className="modal-footer">
          <Button
            text="Reset"
            className="btn-default"
            onPress={this.resetFilter.bind(this)}
            isSubmitting={this.state.isResetting}
          />
          <Button
            text="Apply"
            onPress={this.applyFilter.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ProvinceFilter);
