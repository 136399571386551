import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import language from "../../language";
class MyProfile extends React.Component {
  state = {
    email: "",
    displayName: "",
    phoneNumber: "",
    error: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ ...user });
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <Input
              label={language[this.props.defaultLanguage].names}
              required
              value={this.state.displayName}
              onChange={(e) => this.onChangeText("displayName", e)}
              error={this.state.error.displayName}
              leftIcon={icons.user}
            />
            <Input
              label={language[this.props.defaultLanguage].email_address}
              required
              value={this.state.email}
              onChange={(e) => this.onChangeText("email", e)}
              leftIcon={icons.email}
            />
            <Input
              label={language[this.props.defaultLanguage].institution}
              required
              value={this.state.institution}
              onChange={(e) => this.onChangeText("institution", e)}
              leftIcon={icons.institution}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MyProfile);
