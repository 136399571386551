import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import serviceQuery from "../../utils/queries/serviceQuery";
import handleSearch from "../../utils/handleSearch";
import { getStorage } from "../../utils/storage";
import { Loading } from "../Loader";
import Empty from "../Empty";
import { Input } from "../Input";
import "./styles.css";
import icons from "../../constants/icons";
import serviceCategoryQuery from "../../utils/queries/serviceCategoryQuery";

let copyData = [];

class ServicesModal extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 50,
    search_text: "",
    selected_data: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    services: [],
    categories: [],
    category: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });
    this.getCategories(true);
    this.getServices(true);
  };

  getCategories = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, routeCategory } = this.props;

      this.setState({ isLoading });

      let { data } = await serviceCategoryQuery(defaultLanguage, {
        page,
        limit,
        categoryType: routeCategory,
      });

      data.unshift({
        name: "All",
      });

      this.setState({
        categories: data,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getServices = async (isLoadingServices, category) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingServices });

      let params = {
        page,
        limit,
      };

      if (category !== "All") {
        params.categories = category;
      } else {
        delete params.categories;
      }

      const { data, count } = await serviceQuery(defaultLanguage, params);

      this.setState({
        data,
        totalPageCount: count,
        isLoadingServices: false,
        selectedCategory: {},
      });

      copyData = data?.slice(0) || [];
    } catch (error) {
      console.log(error);
      this.setState({
        isLoadingServices: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].service,
        key: "name",
      },
    ];

    return headers;
  }

  handleSelectCategory(item) {
    this.setState({ category: item });

    if (item.name === "All") {
      return this.getServices(true, this.state.categories);
    }
    this.getServices(true, [item?._id]);
  }

  render() {
    return (
      <div className="service-list-container">
        {this.state.isLoading ? (
          <div className="loader-container">
            <Loading />
          </div>
        ) : (
          <>
            <div
              className="card-body"
              style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
            >
              <Input
                placeholder="Search service..."
                value={this.state.search_text}
                onChange={(e) =>
                  handleSearch(this, {
                    copyData,
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="service-item-content">
              <div className="row">
                <div className="service-item-content-sidebar col-md-4">
                  {this.state.categories.map((el, i) => {
                    return (
                      <div
                        className={`service-item-content-sidebar-item gap-3 ${
                          this.state.category._id === el._id ? "active" : ""
                        }`}
                        key={i}
                        onClick={() => this.handleSelectCategory(el)}
                      >
                        <div className="service-item-content-sidebar-item-info">
                          <span>{el.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="col-md-8">
                  <div className="service-list-service_container">
                    {this.state.isLoadingServices ? (
                      <center>
                        <Loading />
                      </center>
                    ) : this.state.data.length > 0 ? (
                      this.state.data.map((el, i) => {
                        return (
                          <div
                            className="service-list-item"
                            key={i}
                            onClick={() =>
                              (window.location.href =
                                "/user/services/" + el._id + "/" + el.name)
                            }
                          >
                            <div className="icon-container">
                              <span
                                className={`${process.env.REACT_APP_ICONS_TYPE} text-primary`}
                                style={{ marginRight: "0.5rem" }}
                              >
                                {icons.service}
                              </span>
                            </div>
                            <div className="name">
                              <span style={{ textTransform: "capitalize" }}>
                                {el.name}
                              </span>
                            </div>
                            <span
                              className={process.env.REACT_APP_ICONS_TYPE}
                              style={{ opacity: 0.2 }}
                            >
                              {icons.chevronRight}
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      <Empty title="No result found" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ServicesModal);
