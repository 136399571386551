import vectorHome from "../../../assets/vector-home.png";
import "./styles.css";

const ImageContent = () => {
  return (
    <div className="col-md-4">
      <div className="intro-img">
        <img src={vectorHome} alt="Home vector" />
      </div>
    </div>
  );
};

export default ImageContent;
