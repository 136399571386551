import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import { Select } from "../Input";
import documentTypes from "../../constants/documentTypes";
import Features from "../Landing/Features/Features";
import handleChangeInput from "./utils/handleChangeInput";
import handleSubmission from "./utils/handleSubmission";
import { Link } from "react-router-dom";
import icons from "../../constants/icons";

class Signup extends React.Component {
  constructor(props) {
    super(props);

    const identificationDocumentTypes = documentTypes(props.defaultLanguage);

    this.state = {
      documentTypes: identificationDocumentTypes,
      username: "",
      isSubmitting: false,
      error: {},
      identificationDocumentType: identificationDocumentTypes[0],
      firstName: "",
      passportNumber: "",
      nationalId: "",
      validFields: {},
      ippisNumber: "",
      password: "",
    };
  }

  renderIdentificationInputField = ({ name, field, maxLength, type }) => {
    return (
      <Input
        label={language[this.props.defaultLanguage][name]}
        placeholder={
          language[this.props.defaultLanguage][name + "_placeholder"]
        }
        required
        leftIcon={icons.identification}
        value={this.state[field]}
        error={this.state.error[field]}
        onChange={(e) => handleChangeInput(this, field, e)}
        type={type || "number"}
        maxLength={maxLength}
        success={this.state.validFields[field]}
        isLoading={this.state.isRetrievingUserInfo}
        profileData={this.state.profileData}
      />
    );
  };
  render() {
    return (
      <AuthFormLayout>
        <div className="row">
          <div className="col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Features
                className="features-vertical-container"
                columnSize={8}
                itemClassName={"item-horizontal"}
                type="vertical"
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="auth-form">
              <div className="title">
                <h3 className="text-primary">
                  {language[this.props.defaultLanguage].signup}
                </h3>
                <p>
                  {language[this.props.defaultLanguage].all_fields_required}(
                  <span className="error-text">*</span>)
                  <span>{language[this.props.defaultLanguage].required}</span>
                </p>
              </div>
              <form>
                <Select
                  options={this.state.documentTypes}
                  label={
                    language[this.props.defaultLanguage]
                      .identification_document_type
                  }
                  required
                  leftIcon={icons.list}
                  value={this.state.identificationDocumentType}
                  error={this.state.error.identificationDocumentType}
                  onChange={(e) =>
                    handleChangeInput(this, "identificationDocumentType", e)
                  }
                  placeholder={language[this.props.defaultLanguage].select}
                />
                <Input
                  label={language[this.props.defaultLanguage].firstName}
                  placeholder={
                    language[this.props.defaultLanguage].first_name_placeholder
                  }
                  required
                  leftIcon={icons.user}
                  value={this.state.firstName}
                  error={this.state.error.firstName}
                  onChange={(e) => handleChangeInput(this, "firstName", e)}
                />
                {this.state?.identificationDocumentType &&
                  this.renderIdentificationInputField({
                    ...this.state?.identificationDocumentType,
                  })}

                {/* {Object.keys(this.state.profileData || {}).length > 0 && ( */}
                <>
                  <Input
                    label={language[this.props.defaultLanguage].password}
                    placeholder={
                      language[this.props.defaultLanguage].password_placeholder
                    }
                    required
                    leftIcon={icons.password}
                    value={this.state.password}
                    error={this.state.error.password}
                    onChange={(e) => handleChangeInput(this, "password", e)}
                    type="password"
                  />
                  <Button
                    text={language[this.props.defaultLanguage].register}
                    className="w-100"
                    isSubmitting={this.state.isSubmitting}
                    onPress={(e) => handleSubmission(this, e)}
                  />
                  <br />
                  <div className="footer">
                    <span>
                      {
                        language[this.props.defaultLanguage]
                          .already_have_account
                      }{" "}
                      <Link to="/login">
                        <span>
                          {language[this.props.defaultLanguage].login_text}
                        </span>
                      </Link>
                    </span>
                  </div>
                </>
                {/* )} */}
              </form>
            </div>
          </div>
        </div>
      </AuthFormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Signup);
