import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import icons from "../../constants/icons";
import accountTypes from "../../constants/accountType";
import validatePassword from "../../utils/validatePassword";
import { getStorage } from "../../utils/storage";
import toastMessage from "../../utils/toastMessage";
import apiError from "../../utils/apiError";
import accessRoleQuery from "../../utils/queries/accessRoleQuery";
import validateEmail from "../../utils/validateEmail";
import { apiInstance } from "../../utils/axiosInstance";

class NewAccount extends React.Component {
  state = {
    email: "",
    email: "",
    firstName: "",
    lastName: "",
    error: {},
    username: "",
    unique_identifier: "",
  };

  componentDidMount = async () => {
    if (this.props._id) {
      let accountType = this.props.accountType
        ? {
            label: this.props.accountType,
            value: this.props.accountType,
          }
        : null;
      this.setState({ ...this.props, accountType });
    }

    this.getRoles(true);
  };

  getRoles = async (isFetchingAccessRole) => {
    try {
      this.setState({
        isFetchingAccessRole,
      });

      const data = await accessRoleQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setSelectedRole(data);

      this.setState({
        roles: data,
        isFetchingAccessRole: false,
      });
    } catch (error) {
      this.setState({ isFetchingAccessRole: false });
    }
  };

  setSelectedRole(roles) {
    const { accessRole } = this.props;

    if (accessRole) {
      const selectedRole = roles.find(
        (el) => el._id === accessRole._id || accessRole
      );

      if (selectedRole._id) {
        this.setState({
          accessRole: {
            label: selectedRole.name,
            value: selectedRole._id,
          },
        });
      }
    }
  }

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let { defaultLanguage } = this.props;
    let inputValue = e.target ? e.target.value : e;
    console.log(field, inputValue);
    delete error[field];

    if (field === "password" && validatePassword(defaultLanguage, inputValue)) {
      error[field] = validatePassword(defaultLanguage, inputValue);
    }

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let {
      firstName,
      lastName,
      email,
      password,
      error,
      accessRole,
      username,
      unique_identifier,
    } = this.state;
    const { _id } = this.props;

    if (firstName === "") {
      error.firstName =
        language[this.props.defaultLanguage].first_name_required;
    }

    if (lastName === "") {
      error.lastName = language[this.props.defaultLanguage].last_name_required;
    }

    if (username === "") {
      error.username = language[this.props.defaultLanguage].username_required;
    }

    if (email === "") {
      error.email = language[this.props.defaultLanguage].email_required;
    } else if (!validateEmail(email)) {
      error.email = language[this.props.defaultLanguage].invalid_email;
    }

    if (!_id || _id === "") {
      if (password === "") {
        error.password = language[this.props.defaultLanguage].password_required;
      } else if (validatePassword(this.props.defaultLanguage, password)) {
        error.password = `${validatePassword(
          this.props.defaultLanguage,
          password
        )} `;
      }
    }

    if (!accessRole) {
      error.accessRole =
        language[this.props.defaultLanguage].access_role_required;
    }

    if (
      accessRole?.name === "FARMER SERVICE CENTER" &&
      unique_identifier === ""
    ) {
      error.unique_identifier =
        language[this.props.defaultLanguage].unique_identifier_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      const {
        firstName,
        lastName,
        password,
        email,
        accessRole,
        username,
        _id,
        accountType,
        unique_identifier,
      } = this.state;
      const user = await getStorage();
      let method = "POST";

      this.setState({ isSubmitting: true });

      let requestBody = {
        firstName,
        lastName,
        username,
        email,
        password,
        accessRole: accessRole.value,
        accountType: accountType.value,
        uniqueId: unique_identifier ?? undefined,
      };

      if (_id) {
        method = "PUT";

        requestBody.id = _id;

        delete requestBody.password;
      }
      const options = {
        method,
        url: "/account",
        data: requestBody,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      apiInstance(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "update_account_success"
                : "add_account_success"
            ]
          );

          this.props.getData();
          this.props.handleCloseModal();
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          const { error_add_account, error_update_account, account_exist } =
            language[this.props.defaultLanguage];

          apiError(error, {
            requestMethod: method,
            addErrorMessage: error_add_account,
            updateErrorMessage: error_update_account,
            dataExistErrorMessage: account_exist,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].first_name_placeholder
                }
                label={language[this.props.defaultLanguage].firstName}
                required
                value={this.state.firstName}
                onChange={(e) => this.onChangeText("firstName", e)}
                error={this.state.error.firstName}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].last_name_placeholder
                }
                label={language[this.props.defaultLanguage].lastName}
                required
                value={this.state.lastName}
                onChange={(e) => this.onChangeText("lastName", e)}
                error={this.state.error.lastName}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].username_placeholder
                }
                label={language[this.props.defaultLanguage].username}
                required
                value={this.state.username}
                onChange={(e) => this.onChangeText("username", e)}
                error={this.state.error.username}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.email}
                placeholder={
                  language[this.props.defaultLanguage].email_address_placeholder
                }
                label={language[this.props.defaultLanguage].email_address}
                required
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                error={this.state.error.email}
              />
            </div>
            {!this.props._id && (
              <div className="col-md-6">
                <Input
                  leftIcon={icons.password}
                  placeholder={
                    language[this.props.defaultLanguage].password_placeholder
                  }
                  label={language[this.props.defaultLanguage].password}
                  required
                  value={this.state.password}
                  onChange={(e) => this.onChangeText("password", e)}
                  error={this.state.error.password}
                  type="password"
                />
              </div>
            )}
            <div className="col-md-6">
              <Select
                leftIcon={icons.type}
                options={accountTypes}
                label={language[this.props.defaultLanguage].account_type}
                placeholder={language[this.props.defaultLanguage].select}
                required
                value={this.state.accountType}
                onChange={(e) => this.onChangeText("accountType", e)}
                error={this.state.error.accountType}
              />
            </div>
            <div className="col-md-6">
              <Select
                leftIcon={icons.role}
                options={this.state.roles}
                label={language[this.props.defaultLanguage].access_role}
                placeholder={language[this.props.defaultLanguage].select}
                required
                value={this.state.accessRole}
                onChange={(e) => this.onChangeText("accessRole", e)}
                error={this.state.error.accessRole}
                isLoading={this.state.isFetchingAccessRole}
              />
            </div>
            {this.state.accountType?.value === "user" &&
              this.state.accessRole?.name === "FARMER SERVICE CENTER" && (
                <div className="col-md-6">
                  <Input
                    leftIcon={icons.identification}
                    placeholder={
                      language[this.props.defaultLanguage]
                        .unique_identifier_placeholder
                    }
                    label={
                      language[this.props.defaultLanguage].unique_identifier
                    }
                    required
                    value={this.state.unique_identifier}
                    onChange={(e) => this.onChangeText("unique_identifier", e)}
                    error={this.state.error.unique_identifier}
                  />
                </div>
              )}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewAccount);
