import React from "react";
import { KPIs } from "../../components/KPI";
import { DashboardHeader } from "../../components/Header";
import capitalizeText from "../../utils/capitalizeText";
import { Tabs, Tab } from "react-bootstrap";
import { onAddFilter } from "../../actions/Filters";
import { connect } from "react-redux";

class KPIsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "FSC"
        };
    }
    resetFilters = async () => {
        const filtersData = {
            advanced_search: "",
            questionText: [],
            answer: []
        };

        await this.props.dispatch(onAddFilter(filtersData));
    };

    handleTabSelect = async (key) => {
        this.setState({ selectedTab: key });
        await this.resetFilters();
    };

    render() {
        return (
            <>
                <h1 className="mt-4">KPIs</h1>
                <div>
                    <Tabs onSelect={this.handleTabSelect}>
                        <Tab eventKey="FSC" title="FSC">
                            {this.state.selectedTab === "FSC" && <KPIs category="FSC" />}
                        </Tab>
                        <Tab eventKey="young-farmers" title="Young Farmers">
                            {this.state.selectedTab === "young-farmers" && <KPIs category="Young Farmer" />}
                        </Tab>
                    </Tabs>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { defaultLanguage } = state.Language;
    const { filters } = state.Filters;
    return {
        defaultLanguage,
        filters,
    };
};

export default connect(mapStateToProps)(KPIsScreen);
