import language from "../language";

export const sizeLimits = [
  {
    label: "MB",
    value: "MB",
  },
  {
    label: "KB",
    value: "KB",
  },
];

export const fileExtensions = [
  {
    label: "PDF",
    value: "pdf",
  },
  {
    label: "DOC",
    value: "docs",
  },
  {
    label: "PNG",
    value: "png",
  },
];

export const input_requirements = (lng = "english") => {
  return [
    {
      label: language[lng].mandatory,
      value: "mandatory",
    },
    {
      label: language[lng].optional,
      value: "optional",
    },
  ];
};

export const common_input_types = (lng = "english") => {
  return [
    {
      label: language[lng].text_input,
      value: language[lng].text_input,
    },
    {
      label: language[lng].multiple_line_text,
      value: "multipleLineText",
      type: "textarea",
    },
    {
      label: language[lng].number,
      value: "number",
      type: "number",
    },
    {
      label: language[lng].phone_number,
      value: "phoneNumber",
    },
    {
      label: language[lng].email_address,
      value: "emailAddress",
      type: "email",
    },
    {
      label: language[lng].multiple_choice,
      value: "multipleChoice",
      type: "dropdown",
    },
    {
      label: language[lng].date_time,
      value: language[lng].date_time,
      type: "datetime-local",
    },
    {
      label: language[lng].date,
      value: "date",
      type: "date",
    },
    {
      label: language[lng].time,
      value: "time",
      type: "time",
    },
    {
      label: language[lng].checkbox,
      value: "checkbox",
      type: "checkbox",
    },
    {
      label: language[lng].integration,
      value: "integration",
      type: "dropdown",
      query: "integration",
    },
    {
      label: language[lng].provinces,
      value: "province",
      type: "dropdown",
      requireParams: true,
    },
    {
      label: language[lng].districts,
      value: "district",
      type: "dropdown",
    },

    {
      label: language[lng].sectors,
      value: "sector",
      type: "dropdown",
    },
    {
      label: language[lng].villages,
      value: "village",
      type: "dropdown",
    },
    {
      label: language[lng].cells,
      value: "cell",
      type: "dropdown",
    },
    {
      label: language[lng].latitude,
      value: "latitude",
      type: "dropdown",
    },
    {
      label: language[lng].longitude,
      value: "longitude",
      type: "dropdown",
    },
    {
      label: language[lng].year_birth,
      value: "year_birth",
      type: "year_birth",
    },
    {
      label: language[lng].age,
      value: "age",
      type: "age",
    },
    {
      label: language[lng].unique_number,
      value: "uniqueNumber",
      type: "uniqueNumber",
    },
    {
      label: "DATA_COLLECTOR",
      value: "DATA_COLLECTORS",
      type: "DATA_COLLECTORS",
    },
    {
      label: "DATA_COLLECTOR_SIGNATURE",
      value: "DATA_COLLECTOR_SIGNATURE",
      type: "DATA_COLLECTOR_SIGNATURE",
    },
    {
      label: "DATE_NOW",
      value: "DATE_NOW",
      type: "DATE_NOW",
    },
  ];
};

export const input_types = (lng = "english") => {
  return [
    {
      label: "String",
      value: "string",
    },
    {
      label: "Number",
      value: "Number",
    },
    {
      label: "Other",
      value: "other",
    },
  ];
};
