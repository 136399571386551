// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb-nav-container {
  display: flex;
  align-items: center;
  min-height: 60px;
  gap: 1.5rem;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  opacity: 0.8;
}

.breadcrumb-item a {
  color: var(--text-color-60);
  font-weight: 700;
}

.breadcrumb-item .breadcrumb-item-name {
  font-weight: 700;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

ol {
  margin: 0;
  padding: 0;
}

.breadcrumb-item.active a {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Breadcrumb/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".breadcrumb-nav-container {\n  display: flex;\n  align-items: center;\n  min-height: 60px;\n  gap: 1.5rem;\n}\n\n.breadcrumb {\n  display: flex;\n  align-items: center;\n  margin-bottom: 0;\n}\n\n.breadcrumb-item {\n  display: flex;\n  align-items: center;\n  opacity: 0.8;\n}\n\n.breadcrumb-item a {\n  color: var(--text-color-60);\n  font-weight: 700;\n}\n\n.breadcrumb-item .breadcrumb-item-name {\n  font-weight: 700;\n}\n\n.breadcrumb-item a:hover {\n  text-decoration: underline;\n}\n\nol {\n  margin: 0;\n  padding: 0;\n}\n\n.breadcrumb-item.active a {\n  color: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
