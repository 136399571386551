export function extractServiceInfo(data) {
    return data.map(item => ({
        id: item.service._id,
        name: item.service.name,
        category: item.service.category,
        type: item.service.type,
        description: item.service.description,
        available: item.service.available,
        createdAt: item.service.createdAt,
        updatedAt: item.service.updatedAt,
        settings: item.service.settings, // Optional: include settings if needed
        files: item.service.files,       // Optional: include files if needed
    }));
}


export function getEvaluationDetailsByUniqueNumber(data, uniqueNumber) {
    const filteredRecords = data.filter(record => {
        return record.questionnaireAnswer.some(answer => 
            answer.questionText === "Unique Number" && answer.answer === uniqueNumber
        );
    });

    // If no records are found, return an empty result
    if (filteredRecords.length === 0) {
        return { uniqueNumber, records: [] };
    }

    // Process the filtered records
    const result = filteredRecords.map(record => {
        const quarterAnswer = record.questionnaireAnswer.find(answer => answer.questionText === "quarter");
        const domainAnswer = record.questionnaireAnswer.find(answer => answer.questionText === "Domain_intervention");

        return {
            quarter: quarterAnswer ? quarterAnswer.answer : "Unknown",
            domainIntervention: domainAnswer ? domainAnswer.answer : "Unknown"
        };
    });

    // Group results by quarter and domain intervention
    const groupedResults = result.reduce((acc, { quarter, domainIntervention }) => {
        if (!acc[quarter]) {
            acc[quarter] = [];
        }
        if (!acc[quarter].includes(domainIntervention)) {
            acc[quarter].push(domainIntervention);
        }
        return acc;
    }, {});

    return { uniqueNumber, records: groupedResults };
}


export function getDistinctDomainInterventions(data) {
    const domainInterventions = new Set();
    data.forEach(record => {
        record.questionnaireAnswer.forEach(answer => {
            if (answer.questionText === "Domain_intervention") {
                domainInterventions.add(answer.answer);
            }
        });
    });

    // Convert the Set to an array for the result
    return Array.from(domainInterventions);
}
