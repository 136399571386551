import React from "react";
import woman from "../../assets/woman.png";
import man from "../../assets/man.png";
import surveyQuery from "../../utils/queries/surveysQuery";
import { getStorage } from "../../utils/storage";
import { connect } from "react-redux";
import { Table } from "../../components/Table";
import dynamicTableData from "../../utils/dynamicTableData";
import { getProfile, fetchEvaluation } from "../../utils/queries/farmersQuery";
import {
  getDistinctDomainInterventions,
  extractServiceInfo,
  getEvaluationDetailsByUniqueNumber,
} from "../../utils/extractService";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loader";

const url = window.location.href;
const uniqueNumber = url.split("/").pop();

class DetailScreen extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    error: {},
    csvData: [],
    user: {},
    totalPageCount: 0,
    tableHeaders: [],
    profile: {},
    type: "",
    isLoadingEvaluation: false,
    evaluationTobeTaken: {},
    evaluations: [],
    evaluationHeaders: [],
  };

  componentDidMount = async () => {
    const evaluationTobeTaken = JSON.parse(
      localStorage.getItem("evaluationTobeTaken")
    );
    this.setState({ evaluationTobeTaken });

    this.fetchParticipantProfile();
    this.handleFetchData();
    this.getEvaluations();
  };

  handleFetchData = async () => {
    const user = await getStorage();
    const { id } = this.props;
    await this.setState({ user });

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.handleFetchData();
    }

    if (this.props.filters !== prevProps.filters) {
      this.handleFetchData();
    }
  }

  returnFilters() {
    let _filters = {
      ["service.type"]: "KPI",
      ["service.name"]: this.props.category,
      "questionnaireAnswer.questionText": [
        "Unique_identifier",
        "Domain_intervention",
      ],
      "questionnaireAnswer.answer": [
        uniqueNumber,
        this.state.evaluationTobeTaken.domain,
      ],
      advanced_search: true,
    };

    return _filters;
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await surveyQuery(defaultLanguage, {
        ...this.returnFilters(),
      });
      this.setState({
        type: data[0].service.name,
        data,
        totalPageCount: count,
        isLoading: false,
        domainInterventions: getDistinctDomainInterventions(data),
      });
      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchParticipantProfile = async () => {
    const data = await getProfile(this.state.user.language, uniqueNumber);
    this.setState({ profile: data });
  };

  getEvaluations = async () => {
    const evaluationTobeTaken = JSON.parse(
      localStorage.getItem("evaluationTobeTaken")
    );
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingEvaluation: true });

      const { data, count } = await surveyQuery(defaultLanguage, {
        "service.type": "FSC_EVALUATION",
        "questionnaireAnswer.questionText": [
          "Unique Number",
          "Domain_intervention",
        ],
        "questionnaireAnswer.answer": [
          uniqueNumber,
          evaluationTobeTaken.domain,
        ],
        advanced_search: true,
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        evaluations: rowData,
        evaluationHeaders: tableHeaders,
        isLoadingEvaluation: false,
      });
    } catch (error) {
      this.setState({
        isLoadingEvaluation: false,
      });
    }
  };

  getEvaluationBadge(evaluation) {
    return "Not Graduated";
  }

  render() {
    const headers = [
      {
        title: "Domain_intervention",
        key: "Domain_intervention.value",
      },
      {
        title: "KPI",
        key: "KPI.value",
      },
      {
        title: "Measure_type",
        key: "Measure_type.value",
      },
      {
        title: "Measure_Unit",
        key: "Measure_Unit.value",
      },
      {
        title: "Baseline_Quantity",
        key: "Baseline_Quantity.value",
      },
      {
        title: "Baseline_Value_of_Money",
        key: "Baseline_Value_of_Money.value",
      },
      {
        title: "Targeted_Quantity",
        key: "Targeted_Quantity.value",
      },
      {
        title: "Targeted_Value_of_Money",
        key: "Targeted_Value_of_Money.value",
      },
      {
        title: "Expected_End_Date",
        key: "Expected_End_Date.value",
      },
    ];
    return (
      <div className="container my-4">
        {/* Profile Header */}
        <div className="d-flex align-items-center mb-2">
          <img
            src={
              this.state.profile?.gender?.toLowerCase() == "female"
                ? woman
                : man
            }
            alt="User"
            className="rounded-circle"
            width="50"
            height="50"
          />
          <div className="ms-3">
            <h5 className="mb-0">
              {this.state.profile?.firstname} {this.state.profile?.lastname}
            </h5>
            <p className="text-muted">
              {this.state.type}, age {this.state.profile?.age}
            </p>
          </div>
          {/* <button className="btn btn-outline-secondary ms-auto">:</button> */}
        </div>
        {/* <button className="btn btn-outline-secondary mb-2">Edit profile</button> */}
        {/* Personal Info */}
        {/* <div className="mb-4">
          <h6>Personal Info</h6>
          <hr />
          <div className="row">
            <div className="col-3">
              <p className="text-primary">Gender:</p>
              <p>{this.state.profile.gender}</p>
            </div>
            <div className="col-3">
              <p className="text-primary">Age:</p>
              <p>{this.state.profile.age}</p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-3">
              <p className="text-primary">Location:</p>
              <p>{this.state.profile.district}</p>
            </div>
            <div className="col-3">
              <p className="text-primary">Occupation:</p>
              <p>{this.state.profile.occupation}</p>
            </div>
          </div>
        </div> */}

        {/* KPIs Table */}
        <br />
        <br />
        <div className="mb-4">
          <h6>KPIS / Baseline</h6>
          <Table
            data={this.state.data}
            isLoading={this.state.isLoading}
            headers={headers}
          />
        </div>
        <br />
        <br />
        {/* Evaluation Table */}
        <div className="mt-6">
          <h6>Evaluations</h6>
          <Table
            data={this.state.evaluations}
            isLoading={this.state.isLoadingEvaluation}
            headers={this.state.evaluationHeaders}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(DetailScreen);
