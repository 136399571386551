import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import exportPDF from "../../utils/exportPDF";
import handleSearch from "../../utils/handleSearch";
import surveyQuery from "../../utils/queries/surveysQuery";
import formateFilters from "../../utils/formateFilters";
import moment from "moment";

let copyData = [];

class RegionsCounts extends React.Component {
  state = {
    isLoading: false,
    data: [],
    error: {},
    csvData: [],
    location: "district",
  };

  componentDidMount = async () => {
    await this.getFarmers(true);
  };

  returnFilters() {
    const { filters } = this.props;

    return { rank: true, order: "district", ...formateFilters(filters) };
  }

  getFarmers = async (isLoading) => {
    this.setState({
      isLoading,
    });
    try {
      const { defaultLanguage } = this.props;

      const { data } = await surveyQuery(defaultLanguage, this.returnFilters());

      this.setState({
        data: this.handleFormatData(data),
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleFormatData(data) {
    return data.map((el) => {
      if (el[this.state.location].answer) {
        return {
          ...el,
          region: el[this.state.location].answer,
        };
      }

      return { ...el, region: el[this.state.location] };
    });
  }
  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].region,
        key: "region",
      },
      {
        title: language[this.props.defaultLanguage].count,
        key: "count",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const { data } = await surveyQuery(defaultLanguage, this.returnFilters());

      this.setState({
        isLoading: false,
        csvData: this.handleFormatData(data),
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { defaultLanguage } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await surveyQuery(defaultLanguage, this.returnFilters());

    this.setState({
      isLoading: false,
    });

    exportPDF(
      language[this.props.defaultLanguage].regions +
        " " +
        moment().format("lll"),
      headers,
      this.handleFormatData(data)
    );
  };

  render() {
    const { export_ } = language[this.props.defaultLanguage];
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "export",
              title: export_,
              button_type: "dropdown",
              icon: "download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <CSVLink
          ref="csvDownload"
          filename={
            language[this.props.defaultLanguage].regions +
            " " +
            moment().format("lll")
          }
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(RegionsCounts);
