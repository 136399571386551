// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  border-radius: var(--border-radius-half);
  background-color: transparent;
  transition: background-color 0.4s;
  cursor: pointer;
}

.icon-container.active {
  background-color: var(--primary-color);
}

.icon-container.active span {
  color: var(--light-color);
}

.icon-container.gray {
  background-color: var(--text-color-10);
}

.icon-container span {
  font-size: var(--font-size-md-xlg);
  color: var(--text-color);
}

.icon-container.sm span {
  font-size: var(--font-size-md);
}

.icon-container:hover {
  background-color: var(--text-color-10);
}

.icon-container.no-hover-bg:hover {
  background-color: transparent;
}

.material-symbols-outlined {
  color: var(--text-color-60);
  font-size: 20px;
}

.icon-primary-color {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/icons.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,wCAAwC;EACxC,6BAA6B;EAC7B,iCAAiC;EACjC,eAAe;AACjB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,kCAAkC;EAClC,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".icon-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-width: 24px;\n  min-height: 24px;\n  border-radius: var(--border-radius-half);\n  background-color: transparent;\n  transition: background-color 0.4s;\n  cursor: pointer;\n}\n\n.icon-container.active {\n  background-color: var(--primary-color);\n}\n\n.icon-container.active span {\n  color: var(--light-color);\n}\n\n.icon-container.gray {\n  background-color: var(--text-color-10);\n}\n\n.icon-container span {\n  font-size: var(--font-size-md-xlg);\n  color: var(--text-color);\n}\n\n.icon-container.sm span {\n  font-size: var(--font-size-md);\n}\n\n.icon-container:hover {\n  background-color: var(--text-color-10);\n}\n\n.icon-container.no-hover-bg:hover {\n  background-color: transparent;\n}\n\n.material-symbols-outlined {\n  color: var(--text-color-60);\n  font-size: 20px;\n}\n\n.icon-primary-color {\n  color: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
