// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
  border: 1px solid var(--primary-color-5);
  padding: 0;
  /* box-shadow: var(--dropdown-shadow); */
}

.dropdown-content {
  min-width: 300px;
  max-height: 50vh;
  overflow-y: auto;
}

.dropdown-header-container {
  border-bottom: 1px solid var(--primary-color-5);
  padding: 0.3rem 1rem;
  min-height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/dropdown.styles.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,UAAU;EACV,wCAAwC;AAC1C;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,+CAA+C;EAC/C,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":[".dropdown-menu {\n  border: 1px solid var(--primary-color-5);\n  padding: 0;\n  /* box-shadow: var(--dropdown-shadow); */\n}\n\n.dropdown-content {\n  min-width: 300px;\n  max-height: 50vh;\n  overflow-y: auto;\n}\n\n.dropdown-header-container {\n  border-bottom: 1px solid var(--primary-color-5);\n  padding: 0.3rem 1rem;\n  min-height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
