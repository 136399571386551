import React from "react";
import { ChartBar } from "./ChartBar";
import { ChartLegend } from "./ChartLegend";

const CHART_COLORS = ["bg-primary", "bg-danger", "bg-success"];
const LEGEND_ITEMS = [
  { label: "Initial Capital", colorClass: "bg-primary" },
  { label: "Investment", colorClass: "bg-danger" },
  { label: "Growth", colorClass: "bg-success" },
];

export function QuarterlyChart({ data }) {
  const maxValue = Math.max(...data.flatMap((d) => d.values));

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="h5 mb-0">Quarterly Increase in Capital base</h3>
          <div className="badge bg-success">+40% YoY</div>
        </div>

        <div className="position-relative">
          {/* Grid lines */}
          <div className="position-absolute w-100 h-100">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="border-top border-secondary border-opacity-10"
                style={{ height: "20%" }}
              />
            ))}
          </div>

          {/* Chart bars */}
          <div
            style={{ height: "256px" }}
            className="d-flex align-items-end gap-4 position-relative"
          >
            {data.map((quarter, index) => (
              <div
                key={index}
                className="flex-grow-1 d-flex align-items-end gap-2 position-relative"
              >
                {quarter.values.map((value, idx) => (
                  <ChartBar
                    key={idx}
                    value={value}
                    maxValue={maxValue}
                    colorClass={CHART_COLORS[idx]}
                  />
                ))}
                <div
                  className="position-absolute bottom-0 start-50 translate-middle-x text-muted small"
                  style={{ marginBottom: "-24px" }}
                >
                  {quarter.quarter}
                </div>
              </div>
            ))}
          </div>
        </div>

        <ChartLegend items={LEGEND_ITEMS} />
      </div>
    </div>
  );
}
