import React from "react";
import { Check } from "lucide-react";

export function FinancialLinkage({ institutions }) {
  return (
    <div className="card h-100">
      <div className="card-body">
        <h3 className="h5 mb-4">Financial Institution linkage</h3>
        <div className="d-flex flex-column gap-3">
          {institutions.map((institution, index) => (
            <div
              key={index}
              className={`p-3 rounded border ${
                institution.connected
                  ? "border-success bg-['#eaf4ef'] bg-opacity-10"
                  : "border-secondary"
              }`}
            >
              <div className="d-flex align-items-center justify-content-between">
                <span className="fw-medium">{institution.name}</span>
                {institution.connected && (
                  <Check className="text-success" size={20} />
                )}
              </div>
              {institution.details && (
                <div className="mt-2 d-flex flex-column gap-1">
                  {institution.details.map((detail, idx) => (
                    <p
                      key={idx}
                      className="small text-muted d-flex align-items-center gap-2 mb-0"
                    >
                      <Check size={14} className="text-success" />
                      {detail}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
