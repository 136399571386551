import React from "react";
import ChatHeader from "./ChatHeader";
import MessageThread from "./MessageThread";
import MessageInput from "./MessageInput";
import { useChat } from "../../hooks/useChat";
import { Button } from "../Button";
import icons from "../../constants/icons";

const ChatArea = ({ startConvo, setStartConvo }) => {
  const { inputMessage, setInputMessage, sendMessage, selectedConversation,isSending,setIsSending } =
    useChat();
  return (
    <div className="d-flex flex-column h-100">
      {!selectedConversation ? (
        <div className="d-flex flex-column align-items-center justify-content-center gap-2 h-100">
          <p>Select a conversation. or Start a new conversation!</p>
          <Button icon={icons.add} onPress={() => setStartConvo(true)} />
        </div>
      ) : (
        <>
          <ChatHeader conversation={selectedConversation} />
          <MessageThread />
          <MessageInput
            value={inputMessage}
            onChange={setInputMessage}
            onSend={sendMessage}
            isSending={isSending}
            setIsSending={setIsSending}
          />
        </>
      )}
    </div>
  );
};

export default ChatArea;
