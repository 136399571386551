import { Pagination } from "../Pagination";

const TableFooter = (props) => {
  const { data, totalPageCount, handlePagination, page, tab } = props;

  const firstPage = 1;
  const lastPage = totalPageCount;

  const paginate = async (numPage) => {
    await handlePagination(numPage, props.tab);
  };

  const nextPage = async () => {
    if (page === lastPage) return;
    await handlePagination(Number(props.page) + 1, tab);
  };

  const prevPage = async () => {
    if (page === firstPage) return;
    await handlePagination(props.page - 1, props.tab);
  };
  return (
    <>
      {props.totalPageCount !== 0 && props.totalPageCount && (
        <div style={{ paddingTop: "1.5rem" }}>
          <Pagination
            totalPageCount={props.totalPageCount}
            limit={props.limit}
            paginate={paginate}
            length={data.length}
            nextPage={nextPage}
            prevPage={prevPage}
            firstPage={firstPage}
            lastPage={lastPage}
            page={props.page}
            tab={props.tab}
          />
        </div>
      )}
    </>
  );
};

export default TableFooter;
