import React from "react";
import { Doughnut } from "react-chartjs-2";
import surveyQuery from "../../../utils/queries/surveysQuery";
import formateFilters from "../../../utils/formateFilters";
import { connect } from "react-redux";
import language from "../../../language";
import { Loading } from "../../Loader";
import Empty from "../../Empty/Empty";

const options = {
    legend: {
        position: "bottom",
        display: true,
        labels: {
            usePointStyle: true,
            boxWidth: 6,
        },
    },
    maintainAspectRatio: true,
    responsive: true,
};

class FalseVsTrueGraduates extends React.Component {
    state = {
        chartWidth: 300,
    };

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        this.getGraduates(true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.getGraduates(true);
        }
    }

    returnFilters() {
        const { filters } = this.props;

        let _filters = {
            ["questionnaireAnswer.questionText"]: ["Choose Farmer Type"],
            ["questionnaireAnswer.answer"]: ["FSC", "Young Farmer","Non Young Farmer","Off Farmer"],
            count: true,
            ...formateFilters(filters),
        };

        delete _filters.startCreatedAt;
        delete _filters.endCreatedAt;

        return _filters;
    }

    getGraduates = async (isLoading) => {
        this.setState({
            isLoading,
        });
        try {
            const { defaultLanguage } = this.props;

            const { data } = await surveyQuery(defaultLanguage, this.returnFilters());

            let chart = {},
                counts = [];

            chart = {
                "Confirmed": [],
                "False": [],
            };

            counts = [data[0].count, 1];

            // data.forEach((el) => {
            //     let genderKey = isJsonString(el.gender)
            //         ? JSON.parse(el.gender).value?.toUpperCase()?.replace(/\s+/g, "")
            //         : el.gender?.toUpperCase()?.replace(/\s+/g, "");

            //     if (!chart[genderKey]) {
            //         chart[genderKey] = [];
            //     }

            //     if (isJsonString(el.gender)) {
            //         el.gender = JSON.parse(el.gender)
            //             .value?.toUpperCase()
            //             ?.replace(/\s+/g, "");
            //     }

            //     chart[genderKey].push(el);

            //     counts.push(el.count);
            // });

            // console.log(chart, counts);

            const labels = Object.keys(chart);

            let customLabels = labels.map(
                (label, index) => `(${counts[index]}) ${label}`
            );

            const chartData = {
                labels: customLabels,
                datasets: [
                    {
                        label: "Gender",
                        data: counts,
                        borderRadius: 15,
                        backgroundColor: ["rgba(0, 200, 80, 1)", "#FF6D00", "#A0C800"],
                        barPercentage: 0.2,
                        tension: 0.1,
                    },
                ],
            };

            this.setState({
                data: chartData,
                isLoading: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({
                isLoading: false,
            });
        }
    };

    render() {
        return (
            <div>
                {this.state.isLoading ? (
                    <Loading className="loader-fullscreen" />
                ) : this.state.data?.labels?.length > 0 ? (
                    <Doughnut data={this.state.data} options={{ ...options }} />
                ) : (
                    <Empty title={language[this.props.defaultLanguage].no_data} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { filters } = state.Filters;
    const { defaultLanguage } = state.Language;
    return {
        filters,
        defaultLanguage,
    };
};

export default connect(mapStateToProps)(FalseVsTrueGraduates);
