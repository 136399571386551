import React from "react";

export function ChartLegend({ items }) {
  return (
    <div className="d-flex gap-4 justify-content-center mt-5">
      {items.map((item, index) => (
        <div key={index} className="d-flex align-items-center gap-2">
          <div
            className={`${item.colorClass} rounded`}
            style={{ width: "12px", height: "12px" }}
          />
          <span className="small text-muted">{item.label}</span>
        </div>
      ))}
    </div>
  );
}
