import "./styles.css";

const ListItem = (props) => {
  return (
    <div className="list-item" onClick={props.onPress}>
      {props.image && (
        <div className="list-img-container">
          <img src={props.image} alt={props.name} />
        </div>
      )}
      {props.icon && (
        <div className={`icon-container mr-0-5 ${props.iconClassName}`}>
          <span className={process.env.REACT_APP_ICONS_TYPE}>{props.icon}</span>
        </div>
      )}
      <div className="content">
        <div>
          <span style={props.titleStyles} className="list-item-name">
            {props.name}
          </span>
        </div>
      </div>
      {props.rightIcon && (
        <div className="list-right-icon-container">
          <span className={`bx ${process.env.REACT_APP_ICONS_TYPE}`}>
            {props.rightIcon}
          </span>
        </div>
      )}
    </div>
  );
};

export default ListItem;
