import React from "react";
import { SingleService } from "../../../components/AdminServices";
import { withRouter } from "../../../utils/withRouter";
import { DashboardHeader } from "../../../components/Header";
import { Breadcrumb } from "../../../components/Breadcrumb";
import language from "../../../language";
import { connect } from "react-redux";

class ViewSingleServiceScreen extends React.Component {
  render() {
    return (
      <>
        <Breadcrumb
          homeUrl={`/dashboard/home`}
          data={[
            {
              name: language[this.props.defaultLanguage].services,
              url: `/dashboard/services`,
            },
            {
              name: this.props.routeParams.serviceName,
              active: true,
            },
          ]}
        />
        <DashboardHeader title={this.props.routeParams.serviceName} />
        <SingleService
          serviceId={this.props?.routeParams?.serviceId}
          serviceName={this.props.routeParams.serviceName}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(ViewSingleServiceScreen));
