import React from "react";
import woman from "../../../assets/woman.png";
import man from "../../../assets/man.png";
import surveyQuery from "../../../utils/queries/surveysQuery";
import { getStorage } from "../../../utils/storage";
import { connect } from "react-redux";
import { Table } from "../../../components/Table";
import dynamicTableData from "../../../utils/dynamicTableData";
import {
  getProfile,
  fetchEvaluation,
  fetchEvalutionSurvey,
} from "../../../utils/queries/farmersQuery";
import { Button } from "../../../components/Button";
import {
  extractServiceInfo,
  getEvaluationDetailsByUniqueNumber,
  getDistinctDomainInterventions,
} from "../../../utils/extractService";
import { Loading } from "../../../components/Loader";

const url = window.location.href;
const uniqueNumber = url.split("/").pop();

class EvaluationDetailScreen extends React.Component {
  state = {
    isLoading: false,
    isLoadingEvaluation: true,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    error: {},
    csvData: [],
    user: {},
    totalPageCount: 0,
    tableHeaders: [],
    profile: {},
    type: "",
    evaluations: [],
    currentEvaluation: [],
    domainInterventions: [],
  };

  componentDidMount = async () => {
    await this.fetchParticipantProfile();
    await this.handleFetchData();
    await this.getEvaluations();
  };

  handleFetchData = async () => {
    const user = await getStorage();
    const { id } = this.props;
    this.setState({ user });
    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.handleFetchData();
    }

    if (this.props.filters !== prevProps.filters) {
      this.handleFetchData();
    }
  }

  returnFilters() {
    let _filters = {
      ["service.type"]: "KPI",
      ["service.name"]: this.props.category,
      ["questionnaireAnswer.answer[]"]: uniqueNumber,
    };

    return _filters;
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await surveyQuery(
        defaultLanguage,
        this.returnFilters()
      );

      this.setState({
        type: data[0].service.name,
        data,
        totalPageCount: count,
        domainInterventions: getDistinctDomainInterventions(data),
        isLoading: false,
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchParticipantProfile = async () => {
    const data = await getProfile(this.state.user.language, uniqueNumber);
    this.setState({ profile: data });
  };

  getEvaluations = async () => {
    this.setState({ isLoadingEvaluation: true });
    const data = await fetchEvaluation(this.state.user.language, {
      type:
        this.state.profile.category_type === "FSCs"
          ? "FSC_EVALUATION"
          : "YOUNG_FARMERS_EVALUATION",
    });
    const res = await fetchEvalutionSurvey(this.state.user.language, {
      type:
        this.state.profile.category_type === "FSCs"
          ? "FSC_EVALUATION"
          : "YOUNG_FARMERS_EVALUATION",
    });

    this.setState({
      evaluations: extractServiceInfo(res.data),
      currentEvaluation: getEvaluationDetailsByUniqueNumber(
        data.data,
        uniqueNumber
      ),
      isLoadingEvaluation: false,
    });
  };

  getEvaluationStatus = (evaluation) => {
    const { currentEvaluation } = this.state;
    // Function to find the matching evaluation by name
    const findEvaluationByName = (name) => {
      return this.state.evaluations.find((e) =>
        e.name.toLowerCase().includes(name.toLowerCase())
      );
    };

    // Function to create the evaluationTobeTaken object
    const createEvaluationObject = (quarter, domain, uniqueNumber) => {
      const evaluationTobeTaken = {
        q: quarter,
        firstname:this.state.profile.firstname,
        lastname:this.state.profile.lastname,
        uniqueNumber: uniqueNumber,
      };
      localStorage.setItem(
        "evaluationTobeTaken",
        JSON.stringify(evaluationTobeTaken)
      );
    };

    // Function to create the button with navigation
    const createNavigationButton = (
      text,
      className,
      evaluation,
      uniqueNumber,
      formNotFound = false
    ) => {
      // Find the appropriate evaluation to use for the navigation
      const targetEvaluation =
        findEvaluationByName(evaluation) || this.state.evaluations[0];

      if (formNotFound) {
        return <Button text={text} className={className || "w-50"} disabled />;
      }

      return (
        <Button
          text={text}
          className={className || "w-50"}
          onPress={() => {
            window.location.href = `/user/services/${targetEvaluation.id}/${targetEvaluation.name}/${uniqueNumber}`;
          }}
        />
      );
    };

    // Check which quarter includes the evaluation
    if (
      currentEvaluation.records &&
      currentEvaluation.records["Quarter 4"] &&
      currentEvaluation.records["Quarter 4"].some((element) =>
        element.toLowerCase().includes(evaluation.toLowerCase())
      )
    ) {
      return <Button text="submitted" className="bg-success disabled w-50" />;
    } else if (
      currentEvaluation.records &&
      currentEvaluation.records["Quarter 3"] &&
      currentEvaluation.records["Quarter 3"].some((element) =>
        element.toLowerCase().includes(evaluation.toLowerCase())
      )
    ) {
      createEvaluationObject(4, evaluation, uniqueNumber);
      return createNavigationButton(
        "Take Q4 Evaluation",
        "w-50",
        evaluation,
        uniqueNumber
      );
    } else if (
      currentEvaluation.records &&
      currentEvaluation.records["Quarter 2"] &&
      currentEvaluation.records["Quarter 2"].some((element) =>
        element.toLowerCase().includes(evaluation.toLowerCase())
      )
    ) {
      createEvaluationObject(3, evaluation, uniqueNumber);
      return createNavigationButton(
        "Take Q3 Evaluation",
        "w-50",
        evaluation,
        uniqueNumber
      );
    } else if (
      currentEvaluation.records &&
      currentEvaluation.records["Quarter 1"] &&
      currentEvaluation.records["Quarter 1"].some((element) =>
        element.toLowerCase().includes(evaluation.toLowerCase())
      )
    ) {
      createEvaluationObject(2, evaluation, uniqueNumber);
      return createNavigationButton(
        "Take Q2 Evaluation",
        "w-50",
        evaluation,
        uniqueNumber
      );
    } else {
      // If not found in any quarter, check if the evaluation exists in the evaluations array
      const matchingEvaluation = findEvaluationByName(evaluation);
      if (matchingEvaluation) {
        return createNavigationButton(
          "Take Q1 Evaluation",
          "w-50",
          evaluation,
          uniqueNumber
        );
      } else {
        // Default case - use the first evaluation in the array
        return createNavigationButton(
          "Form not available",
          "w-50",
          evaluation,
          uniqueNumber,
          true
        );
      }
    }
  };

  render() {
    return (
      <div className="container my-4">
        {/* Profile Header */}
        <div className="d-flex align-items-center mb-2">
          <img
            src={
              this.state.profile?.gender?.toLowerCase() == "female"
                ? woman
                : man
            }
            alt="User"
            className="rounded-circle"
            width="50"
            height="50"
          />
          <div className="ms-3">
            <h5 className="mb-0">
              {this.state.profile?.firstname} {this.state.profile?.lastname}
            </h5>
            <p className="text-muted">
              {this.state.profile.category_type}, age {this.state.profile?.age}
            </p>
          </div>
        </div>
        {/* Personal Info */}
        <div className="mb-4">
          <h6>Personal Info</h6>
          <hr />
          <div className="row">
            <div className="col-3">
              <p className="text-primary">Gender:</p>
              <p>{this.state.profile.gender}</p>
            </div>
            <div className="col-3">
              <p className="text-primary">Age:</p>
              <p>{this.state.profile.age}</p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-3">
              <p className="text-primary">Location:</p>
              <p>{this.state.profile.district}</p>
            </div>
            <div className="col-3">
              <p className="text-primary">Occupation:</p>
              <p>{this.state.profile.occupation}</p>
            </div>
          </div>
        </div>

        {/* KPIs Table */}
        <div className="mb-4">
          <h6>KPIs</h6>
          <Table
            data={this.state.data}
            isLoading={this.state.isLoading}
            headers={this.state.tableHeaders}
          />
        </div>

        {/* Evaluation Table */}
        <div className="mb-4">
          <h6>Evaluation to be taken</h6>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Domain of Intervention</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.isLoadingEvaluation ? (
                <tr>
                  <td colSpan={2} align="center">
                    <Loading />
                  </td>
                </tr>
              ) : (
                this.state.domainInterventions.map(
                  (domainIntervention, index) => (
                    <tr key={index}>
                      <td>{domainIntervention}</td>
                      <td align="center">
                        {this.getEvaluationStatus(domainIntervention)}
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(EvaluationDetailScreen);
