import React from "react";
import { DropdownButton, Button } from "../Button";
import icons from "../../constants/icons";
import { Input } from "../Input";
import "./styles.css";
import { CardHeader } from "../Header";
import moment from "moment";
import { connect } from "react-redux";
import language from "../../language";
import { onResetFilters, onAddFilter } from "../../actions/Filters";
import dateFiltersOptions from "../../constants/dateFiltersOptions";
import dateDifferenceStatus from "../../utils/dateDifferenceStatus";

class DateFilter extends React.Component {
  state = {
    isCustomDate: true,
    show: false,
    startDate: moment().startOf("D").subtract(365, "days").format("YYYY-MM-DD"),
    endDate: moment().endOf("D").format("YYYY-MM-DD"),
    isSubmitting: false,
    isResetting: false,
    dateFilterOption: {},
  };

  componentDidMount() {
    this.setState({
      startDate: moment(this.props.filters.startDate).format("YYYY-MM-DD"),
      endDate: moment(this.props.filters.endDate).format("YYYY-MM-DD"),
    });
  }

  onChangeDate(field, e) {
    this.setState({
      [field]: e.target.value,
    });
  }

  setDates = async () => {
    const { startDate, endDate } = this.state;

    this.setState({
      isSubmitting: true,
    });

    await this.props.dispatch(
      onAddFilter({
        startDate,
        endDate,
      })
    );

    this.setState({
      isSubmitting: false,
    });
  };

  resetDates() {
    this.setState({
      isResetting: true,
    });

    this.props.dispatch(
      onResetFilters({
        startDate: moment()
          .startOf("D")
          .subtract(365, "days")
          .format("YYYY-MM-DD"),
        endDate: moment().endOf("D").format("YYYY-MM-DD"),
      })
    );

    this.setState({
      isResetting: false,
    });
  }

  handleSelectCustomDateOption(dateFilterOption) {
    this.setState(
      {
        dateFilterOption,
        startDate: dateFilterOption.startDate,
        endDate: dateFilterOption.endDate,
      },
      () => {
        this.setDates();
      }
    );
  }

  render() {
    return (
      <DropdownButton
        text={language[this.props.defaultLanguage].date}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        leftIcon={icons.calendar}
        selectedData={
          this.props.filters?.startDate + " - " + this.props.filters?.endDate
        }
      >
        <CardHeader
          title={language[this.props.defaultLanguage].filter_by_date}
          borderBottom
          closeButton
        />
        <div className="dropdown-content">
          <div className="card-body">
            <div className="filters-date-list">
              {/* {dateFiltersOptions(this.props.defaultLanguage).map((el, i) => {
                return (
                  <div
                    className={`filters-date-list-item`}
                    key={i}
                    onClick={() => this.handleSelectCustomDateOption(el)}
                  >
                    <div className="icon-container">
                      <span className={process.env.REACT_APP_ICONS_TYPE}>
                        {icons.check}
                      </span>
                    </div>
                    <span>{el.name}</span>
                  </div>
                );
              })} */}
            </div>
            {this.state.isCustomDate && (
              <>
                <Input
                  label="Start Date"
                  placeholder="DD-MM-YYYY"
                  value={this.state.startDate}
                  type="date"
                  onChange={(e) => this.onChangeDate("startDate", e)}
                />
                <Input
                  label="End Date"
                  placeholder="DD-MM-YYYY"
                  value={this.state.endDate}
                  type="date"
                  onChange={(e) => this.onChangeDate("endDate", e)}
                />
              </>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text="Reset"
            className="btn-default"
            onPress={this.resetDates.bind(this)}
            isSubmitting={this.state.isResetting}
          />
          <Button
            text="Apply"
            onPress={this.setDates.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(DateFilter);
