import { Select } from "../../../Input";
import language from "../../../../language";
import { connect } from "react-redux";
import { serviceCategoriesOptions } from "../../../../constants/servicesOptions";

const IntegrationForm = (props) => {

  let separator = "";
  if (props.index !== undefined) {
    separator = "_" + props.index;
  }

  return (
    <div>
      <div className="separator-container">
        <h3>Integration Settings</h3>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Select
            label={language[props.defaultLanguage].integration_type}
            placeholder={language[props.defaultLanguage].select}
            value={props.integrationType}
            onChange={(e) => props.onChangeInput("integrationType", e)}
            required
            error={props.error["integrationType" + separator]}
            options={[
              // {
              //   label: "FSC FARMER REGISTRATION",
              //   value: "FSC",
              // },
              ...serviceCategoriesOptions(props.defaultLanguage),
            ]}
            isMulti
          />
        </div>
        <div className="col-md-6">
          <Select
            label={language[props.defaultLanguage].fields}
            placeholder={language[props.defaultLanguage].select}
            value={props.fields}
            onChange={(e) => props.onChangeInput("fields", e)}
            required
            error={props.error["fields" + separator]}
            options={[]}
            creatable
            isMulti
            isClearable
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(IntegrationForm);
