import React from "react";
import { withRouter } from "../../utils/withRouter";
import { Impact } from "../../components/Impact";
import { DashboardHeader } from "../../components/Header";
import capitalizeText from "../../utils/capitalizeText";
import Analytics from "../../components/ImpactAnalytics/Analytics";

class ImpactScreen extends React.Component {
  render() {
    return (
      <>
        <DashboardHeader title={capitalizeText(this.props.name)} />
       <Analytics />
      </>
    );
  }
}

export default withRouter(ImpactScreen);
