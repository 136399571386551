import React, { useEffect, useState } from "react";
import { Search, Check } from "lucide-react";
import Avatar from "./Avatar";
import { fetchSMSUsers, fetchUsers } from "../../utils/queries/chatQuery";
import { Loading } from "../Loader";
import { getStorage } from "../../utils/storage";
import ProvinceFilter from "../Filters/ProvinceFilter";
import AdvancedFilter from "../Filters/AdvancedFilter";
import CustomFilter from "../Filters/CustomFilter";

export function UserSelectionModal({
  selectedUsers,
  search,
  onSearchChange,
  onUserToggle,
  onClose,
  onConfirm,
  isStartingConversation,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    provinces: [],
    occupations: [],
    valueChains: [],
  });
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [selectedOccupation, setSelectedOccupation] = useState([]);
  const [selectedValueChain, setSelectedValueChain] = useState([]);
  console.log({
    filters,
    selectedPosition,
    selectedOccupation,
    selectedValueChain,
  });

  const getData = async () => {
    try {
      setIsLoading(true);
      const loggedUser = await getStorage();

      const isAdmin = (loggedUser.accountType || loggedUser.loginType || "")
        .toLowerCase()
        .includes("admin");

      const params = {
        accountType: !isAdmin ? "Admin" : "user",
        province: selectedPosition,
        occupation: selectedOccupation,
        valueChain: selectedValueChain,
        district: [],
        sector: [],
      };

      const data = await fetchSMSUsers("english", params);
      // const data = await fetchUsers("english", !isAdmin ? "Admin" : "user");
      if (!isAdmin && !!data[0]) {
        data.forEach((user) => {
          if (!selectedUsers.includes(user._id)) {
            onUserToggle(user);
          }
        });
        onConfirm(data);
      } else if (!!data[0]) {
        setFilters(getDistinctValues(data));
      }
      setUsers(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getDistinctValues = (data) => {
    const distinctProvinces = new Set();
    const distinctOccupations = new Set();
    const distinctValueChains = new Set();

    data.forEach((user) => {
      if (user.province) distinctProvinces.add(user.province);
      if (user.occupation) distinctOccupations.add(user.occupation);
      if (user.valueChain) distinctValueChains.add(user.valueChain);
    });

    return {
      provinces: Array.from(distinctProvinces),
      occupations: Array.from(distinctOccupations),
      valueChains: Array.from(distinctValueChains),
    };
  };

  const handleSelectAll = () => {
    const filteredUser = handleFilter(users);
    if (selectedUsers.length === filteredUser.length) {
      filteredUser.forEach((user) => onUserToggle(user));
    } else {
      filteredUser.forEach((user) => {
        if (!selectedUsers.includes(user._id)) {
          onUserToggle(user);
        }
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (
      !!users[0] &&
      !filters.occupations &&
      !filters.valueChains &&
      !filters.provinces
    ) {
      setFilters(getDistinctValues(users));
    }
  }, [users]);

  const handleFilter = () => {
    const searchKey = (search || "").toLowerCase();
    let results = users;

    // filter if searchKey is not empty
    if (searchKey) {
      results = results.filter((item) =>
        ["firstName", "lastName", "email", "accountType"].some((field) =>
          item[field].toLowerCase().includes(searchKey)
        )
      );
    }

    // filters (province, occupation, valueChain)
    results = results.filter((item) => {
      const matchesProvince =
        selectedPosition.length === 0 ||
        selectedPosition.includes(item.province);
      const matchesOccupation =
        selectedOccupation.length === 0 ||
        selectedOccupation.includes(item.occupation);
      const matchesValueChain =
        selectedValueChain.length === 0 ||
        selectedValueChain.includes(item.valueChain);

      return matchesProvince && matchesOccupation && matchesValueChain;
    });

    return results;
  };

  return (
    <div
      className="modal fade show d-block"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Select Users</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center space-1 h-100 text-muted p-5">
              <Loading />
            </div>
          ) : (
            <>
              <div className="modal-body">
                <div className="d-flex justify-content-between">
                  {filters.provinces.length > 1 && (
                    <div style={{ marginRight: "4px" }}>
                      <CustomFilter
                        isLoading={isLoading}
                        title="Province"
                        data={filters.provinces || []}
                        selectedData={selectedPosition}
                        setSelectedData={setSelectedPosition}
                      />
                    </div>
                  )}
                  {filters.occupations.length > 1 && (
                    <div style={{ marginRight: "4px" }}>
                      <CustomFilter
                        isLoading={isLoading}
                        title="Occupation"
                        data={filters.occupations || []}
                        selectedData={selectedOccupation}
                        setSelectedData={setSelectedOccupation}
                      />
                    </div>
                  )}
                  {filters.valueChains.length > 1 && (
                    <div style={{ marginRight: "4px" }}>
                      <CustomFilter
                        isLoading={isLoading}
                        title="Value Chain"
                        data={filters.valueChains || []}
                        selectedData={selectedValueChain}
                        setSelectedData={setSelectedValueChain}
                      />
                    </div>
                  )}
                  {/* <AdvancedFilter isLoading={isLoading} headers={[]} /> */}
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <Search className="text-muted" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search users..."
                      value={search}
                      onChange={(e) => onSearchChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleSelectAll}
                  >
                    {selectedUsers.length === users.length
                      ? "Deselect All"
                      : "Select All"}
                  </button>
                  <span>{selectedUsers.length} selected</span>
                </div>
                <div
                  className="list-group overflow-auto"
                  style={{ maxHeight: "400px" }}
                >
                  {handleFilter(users).map((user) => (
                    <button
                      key={user._id}
                      type="button"
                      className={`list-group-item list-group-item-action d-flex align-items-center ${
                        selectedUsers.includes(user._id)
                          ? "list-group-item-success"
                          : ""
                      }`}
                      onClick={() => onUserToggle(user)}
                    >
                      <div className="position-relative me-3">
                        <Avatar
                          initials="JD"
                          size="48px"
                          alt="Avatar"
                          className="rounded-circle"
                          style={{
                            width: "48px",
                            height: "48px",
                            objectFit: "cover",
                          }}
                        />
                        {/* <img
                      src={user.avatar}
                      alt={user.name}
                      className="rounded-circle"
                      style={{ width: '48px', height: '48px', objectFit: 'cover' }}
                    /> */}
                        {selectedUsers.includes(user) && (
                          <div
                            className="position-absolute bottom-0 end-0 bg-success rounded-circle"
                            style={{ width: "17px", height: "17px" }}
                          >
                            <Check
                              className="text-white"
                              style={{ width: "12px", height: "12px" }}
                            />
                          </div>
                        )}
                      </div>
                      <span className="fw-bold">
                        {user.firstName} {user.lastName}
                      </span>
                    </button>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onConfirm}
                >
                  {isStartingConversation ? (
                    <Loading />
                  ) : (
                    <>Confirm ({selectedUsers.length} selected)</>
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
