import React, { useEffect, useState } from "react";
import Avatar from "./Avatar";
import { useChat } from "../../hooks/useChat";
import { getConversations } from "../../utils/queries/chatQuery";
import { formatTimestamp } from "../../utils/chatHelpers";
import { Loading } from "../Loader";

export function ConversationList({ onSelect }) {
  const { selectedConversation, conversations, setConversations } = useChat();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getConversations();
      setConversations(data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="overflow-auto">
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center h-100 text-muted">
          <div className="text-center">
            <Loading />
          </div>
        </div>
      ) : (
        conversations.map((conversation) => (
          <div
            key={conversation._id}
            onClick={() => onSelect(conversation)}
            className={`d-flex align-items-center p-3 cursor-pointer`}
            style={{
              transition: "background-color 0.2s",
              backgroundColor: `${
                selectedConversation?._id === conversation._id ? "#E8F5E9" : ""
              }`,
              cursor: "pointer",
            }}
          >
            <div className="me-3">
              <Avatar
                initials="JD"
                size="48px"
                alt="Avatar"
                className="rounded-circle"
                style={{ width: "48px", height: "48px", objectFit: "cover" }}
              />
            </div>
            <div className="flex-grow-1">
              <h6 className="mb-0 text-dark" style={{ fontSize: "0.875rem" }}>
                {conversation?.users
                  .slice(0, 1)
                  .map((user) => {
                    return user ? `${user.firstName} ${user.lastName}` : "";
                  })
                  .join(", ")}
                {conversation?.users.length > 1 &&
                  ` + ${conversation.users.length - 1} others`}
              </h6>
              <small className="text-muted" style={{ fontSize: "0.75rem" }}>
                {conversation?.lastMessage?.text
                  ? conversation.lastMessage.isOwn
                    ? `You: ${conversation.lastMessage?.text}`
                    : `${conversation.lastMessage?.text}`
                  : "No messages yet"}
              </small>
            </div>
            <span className="text-muted small" style={{ fontSize: "0.75rem" }}>
              {conversation?.lastMessage?.text &&
                formatTimestamp(conversation?.lastMessage?.timestamp)}
            </span>
          </div>
        ))
      )}
    </div>
  );
}
