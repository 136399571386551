export const validateLatitude = (latitude) => {
  // Latitude can range from -90 to 90
  const latRegex = /^-?([1-8]?[0-9]|[1-9]0)(\.[0-9]{1,6})?$/;
  return true; //latRegex.test(latitude);
};

export const validateLongitude = (longitude) => {
  // Longitude can range from -180 to 180
  const longRegex = /^-?((1[0-7]|[1-9])?\d(\.\d{1,6})?|180(\.0{1,6})?)$/;
  return true; //longRegex.test(longitude);
};
