import React from "react";
import "./styles.css";
import { connect } from "react-redux";
import language from "../../language";
import { Link } from "react-router-dom";

import {
  adminAuthMenus,
  commonAuthMenus,
  nonAuthMenus,
} from "../../constants/profileMenus";

const MobileNavbarRef = React.forwardRef((props, ref) => {
  const { user } = props;
  return (
    <div className="container">
      <div className={`mobile-navbar ${props.className}`} ref={ref}>
        {!user.token &&
          nonAuthMenus(props.defaultLanguage).map((menu, i) => {
            if (user.token && menu.access.includes("protected")) {
              return (
                <div className="nav-item" key={i}>
                  <Link to={menu.link}>{menu.name}</Link>
                </div>
              );
            }
          })}

        {user.token &&
          adminAuthMenus(props.defaultLanguage).map((menu, i) => {
            if (user.token) {
              return (
                <div className="nav-item" key={i}>
                  <Link to={menu.link}>{menu.name}</Link>
                </div>
              );
            }
          })}
        {user.token &&
          commonAuthMenus(props.defaultLanguage).map((menu, i) => {
            return (
              <div className="nav-item" key={i}>
                <Link to={menu.link}>{menu.name}</Link>
              </div>
            );
          })}
      </div>
    </div>
  );
});
const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MobileNavbarRef);
