import React from "react";
import { DashboardHeader } from "../../../components/Header";
import language from "../../../language";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import ContainerComponent from "../../../components/FSC/RecordAchievement/ContainerComponent";

class RecordAchievement extends React.Component {
  render() {
    return (
      <div>
        <DashboardHeader
          title={language[this.props.defaultLanguage].achievement_recording}
        />
        <Tabs defaultActiveKey="new_farmer" id="uncontrolled-tab-example">
          <Tab
            eventKey="new_farmer"
            title={language[this.props.defaultLanguage].new_farmer}
          >
            <ContainerComponent
              serviceId={"6772ae5b3499af27db32994b"}
              title={"New Farmer Registration"}
            />
          </Tab>
          <Tab
            eventKey="market_actor_linkage"
            title={language[this.props.defaultLanguage].market_actor_linkage}
          >
            <ContainerComponent
              serviceId={"6773b7343499af27db32f4c0"}
              title={"New Market actor"}
            />
          </Tab>
          <Tab
            eventKey="new_trainings_recieved"
            title={language[this.props.defaultLanguage].new_trainings_recieved}
          >
            <ContainerComponent
              serviceId={"6773bcb73499af27db33008c"}
              title={"New Trainings attended"}
            />
          </Tab>
          <Tab
            eventKey="equipment"
            title={language[this.props.defaultLanguage].equipment}
          >
            <ContainerComponent
              serviceId={"6773be503499af27db3303ec"}
              title={"Equipment Recieved"}
            />
          </Tab>
          <Tab
            eventKey="access_to_finance"
            title={language[this.props.defaultLanguage].access_to_finance}
          >
            <ContainerComponent
              serviceId={"6773bf3c3499af27db33076c"}
              title={"Financial support recievd"}
            />
          </Tab>
          <Tab
            eventKey="income_generation"
            title={language[this.props.defaultLanguage].income_generation}
          >
            <ContainerComponent
              serviceId={"6773c0293499af27db330db3"}
              title={"New Job Created"}
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RecordAchievement);
