// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-counter {
  top: 0;
  right: 5px;
  background-color: var(--error-color);
  height: 20px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--light-color);
  padding-left: 5px;
  padding-right: 5px;
}

.notification-counter.primary-border {
  border: 2px solid var(--dark-primary-color);
}

.notification-counter span {
  color: var(--light-color);
  font-size: 11px;
  font-weight: var(--font-weight-md);
}

.notification-counter:hover span {
  color: var(--light-color) !important;
}

.notification-counter.small span {
  font-size: 9px;
  height: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Notifications/counter.styles.css"],"names":[],"mappings":"AAAA;EACE,MAAM;EACN,UAAU;EACV,oCAAoC;EACpC,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;EACpC,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,cAAc;EACd,YAAY;AACd","sourcesContent":[".notification-counter {\n  top: 0;\n  right: 5px;\n  background-color: var(--error-color);\n  height: 20px;\n  border-radius: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 2px solid var(--light-color);\n  padding-left: 5px;\n  padding-right: 5px;\n}\n\n.notification-counter.primary-border {\n  border: 2px solid var(--dark-primary-color);\n}\n\n.notification-counter span {\n  color: var(--light-color);\n  font-size: 11px;\n  font-weight: var(--font-weight-md);\n}\n\n.notification-counter:hover span {\n  color: var(--light-color) !important;\n}\n\n.notification-counter.small span {\n  font-size: 9px;\n  height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
