import React from "react";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import serviceQuery from "../../../utils/queries/serviceQuery";
import surveyQuery from "../../../utils/queries/surveysQuery";
import { RequiredDocuments } from "../../SurveySetup";
import Questionnaire from "./Questionnaire";
import language from "../../../language";
import { getStorage } from "../../../utils/storage";
import toastMessage from "../../../utils/toastMessage";
import { Loading } from "../../Loader";
import { v4 as uuidv4 } from "uuid";
import Empty from "../../Empty";
import { calculateFileSize } from "../../../utils/fileSize";
import isValidJSONString from "../../../utils/isValidJSONString";
import { apiInstance } from "../../../utils/axiosInstance";
import formateAnswer from "../../../utils/formateAnswer";
import { validateLatitude, validateLongitude } from "../../../utils/validation";
import validateEmail from "../../../utils/validateEmail";
import validatePhone from "../../../utils/validatePhone";
import isJsonString from "../../../utils/isJsonString";
import { Button } from "../../Button";
class ServiceQuestionnaireScreen extends React.Component {
  state = {
    isLoading: true,
    data: {},
    error: {},
    profileData: {},
    firstName: "",
    user: {},
    questionnaireAnswer: [],
    numberForms: 1,
  };

  componentDidMount = async () => {
    await this.getData(true);

    if (this.props?.routeParams?.actionName === "edit") {
      this.populateSurveyInfo(true);
    }
  };

  populateSurveyInfo = async (isLoadingSurvey) => {
    try {
      const { defaultLanguage, routeParams } = this.props;
      const { surveyId } = routeParams;
      const serviceQuestionnaire = this.state?.data?.questionnaire || [];
      const { error } = this.state;

      if (!surveyId) return;

      this.setState({ isLoadingSurvey });

      const { data } = await surveyQuery(defaultLanguage, {
        id: surveyId,
      });

      let res = {};

      if (data?.length > 0) {
        res = data[0];

        let groupQuestionAnswer = {};

        res.questionnaireAnswer.map((answer) => {
          if (!groupQuestionAnswer[answer.duplicateId]) {
            groupQuestionAnswer[answer.duplicateId] = [];
          }

          groupQuestionAnswer[answer.duplicateId].push(answer);
        });

        Object.keys(groupQuestionAnswer).forEach((duplicateId, formKey) => {
          groupQuestionAnswer[duplicateId].forEach((answer) => {
            serviceQuestionnaire.forEach((question) => {
              if (question.questionText === answer.questionText) {
                let surveyAnswer = "";

                if (isValidJSONString(answer.answer)) {
                  surveyAnswer = JSON.parse(answer.answer);
                } else {
                  surveyAnswer = {
                    target: {
                      value: answer.answer,
                    },
                  };
                }

                const params = {
                  formKey,
                  value: {
                    label: question.questionText,
                    value: surveyAnswer,
                  },
                  questionId: question._id,
                  questionText: question.questionText,
                  answerType: answer.answerType,
                  duplicateId,
                };

                this.onChangeText(params);
              }
            });
          });
        });
      }

      this.setState({
        // data: res,
        isLoadingSurvey: false,
      });
    } catch (error) {
      this.setState({
        isLoadingSurvey: false,
      });
    }
  };

  getData = async (isLoading) => {
    try {
      const { defaultLanguage, serviceId } = this.props;

      this.setState({ isLoading });

      const data = await serviceQuery(defaultLanguage, {
        id: serviceId,
      });

      console.log("serviceQuestionnaireScreen-->", data);

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      this.setState({
        data: res,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  initiateQuestionnaireAnswer(params) {
    const { formKey, value, questionId, questionText, answerType } = params;

    let { questionnaireAnswer } = this.state;

    if (!questionnaireAnswer[formKey]) {
      questionnaireAnswer[formKey] = {};

      if (!questionnaireAnswer[formKey][questionId]) {
        if (value?.target) {
          questionnaireAnswer[formKey][questionId] = "";
        } else {
          questionnaireAnswer[formKey][questionId] = {};
        }
      }
    }

    if (value.target) {
      questionnaireAnswer[formKey][questionId] = {
        questionText,
        answer: value.target.value,
        answerType,
      };
    } else {
      value["questionText"] = questionText;
      value["answerType"] = answerType;
      value["answer"] = JSON.stringify(value);
      questionnaireAnswer[formKey][questionId] = value;
    }

    return questionnaireAnswer;
  }

  onChangeText(params) {
    try {
      const { formKey, questionId } = params;
      let { error } = this.state;

      const questionnaireAnswer = this.initiateQuestionnaireAnswer(params);

      this.appendInput({ params, questionnaireAnswer });

      delete error[`${questionId}_${formKey}`];
      delete error.fromInstitution;

      this.setState({ questionnaireAnswer, error });
    } catch (error) {
      console.log("questionnaire answers error***", error);
    }
  }

  appendInput(data) {
    let { params, questionnaireAnswer } = data;
    const { formKey, inputIndex, value } = params;

    let questionText = "";
    let inputId = this.state.data.questionnaire[inputIndex]?._id || undefined;

    if (value?.option?.value?.toLowerCase() === "other") {
      questionText = value.value + "  " + params.questionText;
    } else if (Array.isArray(value)) {
      value.forEach((el) => {
        if (el?.option?.value?.toLowerCase() === "other") {
          questionText = el.value + "  " + params.questionText;
        } else if (
          isJsonString(el.answer) &&
          Array.isArray(JSON.parse(el.answer))
        ) {
          JSON.parse(el.answer).forEach((chEl) => {
            if (chEl?.option?.value?.toLowerCase() === "other") {
              questionText = el.value + "  " + params.questionText;
            }
          });
        }
      });
    }

    if (!inputId) return;

    let formattedIndex = "other-" + inputId;

    if (!questionText) {
      questionnaireAnswer.forEach((el, index) => {
        if (el[inputId] && questionnaireAnswer[index]) {
          delete questionnaireAnswer[formKey][formattedIndex];
        }
      });

      this.state.data.questionnaire.forEach((el, index) => {
        if (el._id === formattedIndex) {
          this.state.data.questionnaire.splice(index, 1);
        }
      });
    } else {
      const itemToInsert = {
        options: [],
        multiAnswer: [],
        _id: formattedIndex,
        questionText: questionText,
        answerType: "Text input",
        singleAnswer: {
          available: true,
        },
      };

      this.state.data.questionnaire.splice(inputIndex + 1, 0, itemToInsert);
    }

    this.setState({ data: this.state.data, questionnaireAnswer });
  }
  handleFiles(index, filesData) {
    const fileList = Array.from(filesData);
    const { data, error } = this.state;
    const { files } = data || [];

    if (!files[index].uploadedFiles) {
      files[index].uploadedFiles = [];
    }

    const totalSize = calculateFileSize(fileList);
    const limit = 3 * 1024 * 1024; //  3MB

    if (fileList.length > 2) {
      error[files[index].name] =
        language[this.props.defaultLanguage].limit_files;

      this.setState({ error });
      return;
    }

    if (totalSize > limit) {
      error[files[index].name] =
        language[this.props.defaultLanguage].exceed_file_size_limit;

      this.setState({ error });

      return;
    }

    fileList.forEach((file) => {
      files[index].uploadedFiles.push(file);
    });

    delete error[files[index].name];
    delete error["message"];
    delete error.fromInstitution;

    this.setState({ data });
  }

  handleRemoveFile({ parentIndex, fileIndex }) {
    const { data } = this.state;
    const { files } = data || [];

    delete files[parentIndex].uploadedFiles[fileIndex];

    this.setState({ data });
  }

  validateQuestionnaireForm() {
    try {
      let { questionnaireAnswer, data, error, numberForms } = this.state;
      const { questionnaire } = data;

      // check if no answered questionnaire
      if (questionnaireAnswer.length === 0 && questionnaire.length > 0) {
        for (let i = 0; i < numberForms; i++) {
          questionnaire.forEach((el) => {
            if (el?.singleAnswer?.required) {
              let errorQuestionSeparator = el._id + "_" + i;

              error[errorQuestionSeparator] = `${el.questionText} ${
                language[this.props.defaultLanguage].is_required
              }`;
            }
          });
        }
      }
      //validate answer 1 by 1
      else if (questionnaireAnswer.length > 0) {
        for (let i = 0; i < numberForms; i++) {
          questionnaire.forEach((el) => {
            const questionAnswer = questionnaireAnswer.find((qAns) =>
              Object.keys(qAns).includes(el._id)
            );

            let errorQuestionSeparator = el._id + "_" + i;

            if (!questionAnswer && el?.singleAnswer?.required) {
              error[errorQuestionSeparator] = `${el.questionText} ${
                language[this.props.defaultLanguage].is_required
              }`;
            } else if (questionAnswer) {
              error = this.validateSingleAnswers(
                error,
                questionAnswer[el._id],
                errorQuestionSeparator
              );
            }
          });
        }
      }

      this.setState({ error });
    } catch (error) {
      console.log("validation error: ", error);
    }
  }

  validateSingleAnswers(error, answer, fieldName) {
    if (answer.answerType === "latitude" && !validateLatitude(answer.answer)) {
      error[fieldName] = `${answer.questionText} ${
        language[this.props.defaultLanguage].is_invalid
      }`;
    }

    if (
      answer.answerType === "longitude" &&
      !validateLongitude(answer.answer)
    ) {
      error[fieldName] = `${answer.questionText} ${
        language[this.props.defaultLanguage].is_invalid
      }`;
    }

    if (answer.answerType === "phoneNumber" && !validatePhone(answer.answer)) {
      error[fieldName] = `${answer.questionText} ${
        language[this.props.defaultLanguage].is_invalid
      }`;
    }

    if (answer.answerType === "email" && !validateEmail(answer.answer)) {
      error[fieldName] = `${answer.questionText} ${
        language[this.props.defaultLanguage].is_invalid
      }`;
    }

    return error;
  }

  validateDocumentForm() {
    const { error, data } = this.state;
    const { files } = data;
    const limit = 5 * 1024 * 1024;

    files?.forEach((el) => {
      if (el.uploadedFiles === undefined || el?.uploadedFiles?.length === 0) {
        error[el.name] = language[this.props.defaultLanguage].document_required;
      } else {
        const totalSize = calculateFileSize(el.uploadedFiles);
        if (totalSize > limit) {
          error[el.name] =
            language[this.props.defaultLanguage].document_exceed_limit;
        }
      }
    });

    const totalSize = calculateFileSize(files);
    if (totalSize > limit) {
      error["message"] =
        language[this.props.defaultLanguage].document_exceed_limit;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    try {
      await this.validateQuestionnaireForm();

      const { error } = this.state;

      const user = await getStorage();

      if (Object.keys(error).length === 0) {
        this.setState({ isSubmitting: true });

        const { data } = this.state;
        const { category } = data;

        const formData = new FormData();

        const requestBody = {
          category: category._id,
          service: data._id,
          description: data.name,
          questionnaireAnswer: [],
          user: user.id,
        };

        data?.files?.forEach((el, idx) => {
          el?.uploadedFiles?.map((el) => {
            requestBody.questionnaireAnswer.push({
              questionText: el.name,
              answerType: "file",
            });
            formData.append(`questionnaireAnswer[${idx}].answer`, el);
            formData.append(el.name, el);
          });
        });

        for (let i = 0; i < this.state.numberForms; i++) {
          const questionnaireId = uuidv4();

          const indexedQuestionnaireAnswer = this.state.questionnaireAnswer[i];

          const answers = Object.keys(indexedQuestionnaireAnswer).map((key) => {
            let answer = formateAnswer(indexedQuestionnaireAnswer[key]);
            let answerType = indexedQuestionnaireAnswer[key].answerType;

            return {
              questionText: indexedQuestionnaireAnswer[key].questionText,
              answer,
              answerType,
              duplicateId: questionnaireId,
            };
          });

          requestBody.questionnaireAnswer =
            requestBody.questionnaireAnswer.concat(answers);
        }

        for (let el of Object.keys(requestBody)) {
          formData.append(
            el,
            Array.isArray(requestBody[el])
              ? JSON.stringify(requestBody[el])
              : requestBody[el]
          );
        }

        const options = {
          method: "POST",
          url: `/survey`,
          data: requestBody,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };

        await apiInstance(options);

        toastMessage(
          "success",
          language[this.props.defaultLanguage].success_add_data
        );

        this.setState({ isSubmitting: false });
        this.props.setRefetching(true);

        // reset form
        this.setState({
          questionnaireAnswer: [],
          numberForms: 1,
        });

        // window.location.href = `/user/service/surveys/${data._id}/${data.name}`;
      }
    } catch (error) {
      this.setState({ isSubmitting: false });

      toastMessage(
        "error",
        language[this.props.defaultLanguage].error_add_data
      );
    }
  };

  updateNumberForms(numberForms) {
    this.setState({ numberForms });
  }

  goBack() {
    window.history.go(-1);
    return false;
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <div
            style={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        ) : this.state?.data?.questionnaire?.length > 0 ? (
          <div className="col-md-12 mt-2 mb-3">
            <div>
              <Questionnaire
                form={this.state?.data?.questionnaire || []}
                onChange={this.onChangeText.bind(this)}
                {...this.state}
                questionnaireAnswer={this.state.questionnaireAnswer}
                error={this.state.error}
                numberForms={this.state.numberForms}
                updateNumberForms={this.updateNumberForms.bind(this)}
              />
              {this.state?.data?.files?.length > 0 && (
                <div className="card card-shadow mb-4">
                  <RequiredDocuments
                    files={this.state?.data?.files || []}
                    handleFiles={this.handleFiles.bind(this)}
                    handleRemoveFile={this.handleRemoveFile.bind(this)}
                    error={this.state.error}
                  />
                </div>
              )}
              {(this.state?.data?.questionnaire?.length > 0 ||
                this.state?.data?.files?.length > 0) && (
                <div className="row">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <Button
                      style={{
                        backgroundColor: "#F2F2FD",
                        color: "#858AEC",
                        borderRadius: "50px",
                      }}
                      text={language[this.props.defaultLanguage].submit}
                      onPress={this.onSubmit.bind(this)}
                      className="w-100"
                      isSubmitting={this.state.isSubmitting}
                    />

                    {/* <button
                      style={{
                        backgroundColor: "#F2F2FD",
                        color: "#858AEC",
                        borderRadius: "50px",
                      }}
                      className="btn w-100"
                      onClick={this.onSubmit.bind(this)}
                    >
                      {language[this.props.defaultLanguage].submit}
                    </button> */}
                  </div>
                  {/* <div className="col-md-6">
                    <button
                      style={{
                        backgroundColor: "#636AE8",
                        color: "#FFFFFF",
                        borderRadius: "50px",
                      }}
                      className="btn btn-secondary w-100"
                      // onClick={this.goBack.bind(this)}
                    >
                      {language[this.props.defaultLanguage].cancel}
                    </button>
                  </div> */}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Empty title={language[this.props.defaultLanguage].no_data} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(ServiceQuestionnaireScreen));
