import React, { useState } from "react";
import { Send } from "lucide-react";
import { Loading } from "../Loader";
import { useChat } from "../../hooks/useChat";

export function MessageInput() {
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { sendMessage } = useChat();

  const handleSend = async () => {
    if (!message.trim()) return;
    setIsSending(true);
    await sendMessage(message);
    setMessage("");
    setIsSending(false);
  };

  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          className="form-control flex-grow-1"
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSend();
          }}
        />
        <button
          onClick={handleSend}
          disabled={!message.trim()}
          className="btn btn-success d-flex align-items-center justify-content-center"
        >
          {isSending ? <Loading size={18} /> : <Send size={18} />}
        </button>
      </div>
    </>
  );
}
