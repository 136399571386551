import React from "react";
import { DropdownButton, Button } from "../Button";
import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import language from "../../language";
import { onAddFilter } from "../../actions/Filters";
import { Input } from "../Input";

class AdvancedFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [], // To store multiple filters
            isSubmitting: false,
            isResetting: false,
            selectedFilterTitle: "",
            inputValue: ""
        };
    }

    handleFilterTypeChange = (e) => {
        this.setState({ selectedFilterTitle: e.target.value, inputValue: "" });
    };

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    };

    addFilter = () => {
        const { selectedFilterTitle, inputValue, filters } = this.state;
        if (selectedFilterTitle && inputValue) {
            this.setState({
                filters: [...filters, { title: selectedFilterTitle, value: inputValue }],
                selectedFilterTitle: "",
                inputValue: ""
            });
        }
    };

    removeFilter = (index) => {
        const { filters } = this.state;
        this.setState({
            filters: filters.filter((_, i) => i !== index)
        });
    };
    resetFilters = async () => {
        this.setState({ isResetting: true });
        const filtersData = {
            advanced_search: "",
            questionText: [],
            answer: []
        };
        await this.props.dispatch(onAddFilter(filtersData));
        this.setState({
            isResetting: false,
            filters: [],
            selectedFilterTitle: "",
            inputValue: ""
        });

    };

    applyFilters = async () => {
        const { filters } = this.state;
        if (filters.length === 0) {
            return;
        }
        const filtersData = {
            page:1,
            advanced_search: true,
            questionText: [],
            answer: []
        };
        filters.forEach((filter) => {
            filtersData.questionText.push(filter.title);
            filtersData.answer.push(filter.value);
        });


        this.setState({ isSubmitting: true });

        await this.props.dispatch(onAddFilter(filtersData));

        this.setState({ isSubmitting: false });
    };

    render() {

        if (this.props.isLoading) {
            return <Button text="Loading..." className="btn-transparent btn-bordered w-sm-100 btn-sm" />;
        }

        return (
            <DropdownButton
                leftIcon={icons.filter}
                text="Advanced Filters"
                className="btn-transparent btn-bordered w-sm-100 btn-sm"
                clickOutside
                loading={this.props.isLoading}
            >
                <CardHeader
                    title="Advanced Filters"
                    borderBottom
                />
                <div className="dropdown-content">
                    <div className="card-body">
                        <div className="applied-filters">
                            {this.state.filters.map((filter, index) => (
                                <div key={index} className="filter-item d-flex align-items-center mb-1">
                                    <span className="mr-2">{filter.title}: {filter.value}</span>
                                    <button className="btn btn-outline-danger btn-sm" onClick={() => this.removeFilter(index)}>
                                        x
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="row mb-2">
                            <select
                                className="col-4 mx-3 rounded"
                                value={this.state.selectedFilterTitle}
                                onChange={this.handleFilterTypeChange}
                                style={{ padding: '0.375rem 0.75rem', border: '1px solid #ced4da', borderRadius: '0.25rem', outline: 'none' }}
                            >
                                <option value="">Select Filter</option>
                                {
                                    this.props.headers?.map((item, index) => (
                                        <option key={index} value={item.title}>
                                            {item.title}
                                        </option>
                                    ))
                                }
                            </select>
                            <input
                                type="text"
                                className="col-4 mx-3 rounded"
                                placeholder="Enter value"
                                value={this.state.inputValue}
                                onChange={this.handleInputChange}
                                style={{ padding: '0.375rem 0.75rem', border: '1px solid #ced4da', borderRadius: '0.25rem', outline: 'none' }}
                            />
                            <button className="col-2 btn btn-primary" type="button" onClick={this.addFilter}>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        text="Reset"
                        className="btn-default"
                        onPress={this.resetFilters}
                        isSubmitting={this.state.isResetting}
                    />
                    <Button
                        text="Apply"
                        onPress={this.applyFilters}
                        isSubmitting={this.state.isSubmitting}
                    />
                </div>
            </DropdownButton>
        );
    }
}

const mapStateToProps = (state) => {
    const { defaultLanguage } = state.Language;
    const { filters } = state.Filters;
    return {
        defaultLanguage,
        filters,
    };
};

export default connect(mapStateToProps)(AdvancedFilter);
