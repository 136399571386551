import isValidJSONString from "./isValidJSONString";

export default (that, data, requiredFields, others = {}) => {
  // get current url
  const url = window.location.href;
  const urlSplit = url.split("/");
  const targetUrl = urlSplit[urlSplit.length - 1];
  
  try {
    let tableHeaders = [];
    let headers = new Set(); // Using a Set for unique header titles

    if (data?.length > 0) {
      // Iterate over each entry to collect all unique headers
      data.forEach((entry) => {
        entry?.questionnaireAnswer?.forEach((el) => {
          const questionText = el.questionText;
          const isRequiredField =
            requiredFields &&
            (requiredFields.includes(el.answerType) ||
              requiredFields.includes(questionText?.toLowerCase()));

          // Add header if it's unique and meets required criteria, if any
          if (!headers.has(questionText) && (!requiredFields || isRequiredField)) {
            let formattedHeader = {
              title: questionText,
              key: `${questionText}.value`,
            };

            // Add specific properties for headers with "unique" keyword
            if (
              questionText.toLowerCase().includes("unique") &&
              (that.state.user?.accountType !== "account" &&
                that.state?.user?.accountType !== "user")
            ) {

              formattedHeader.type = "link";
              formattedHeader.styles = { textTransform: "uppercase" };
              formattedHeader.onPress = (item) => {
                targetUrl.includes("kpis") ? (window.location.href = `/dashboard/kpis/profile/${item[questionText].value}`) :
                  (window.location.href = `/dashboard/dn/${item[questionText].title}/${item[questionText].value}`);
              }
            }

            tableHeaders.push(formattedHeader);
            headers.add(questionText);
          }
        });
      });
    }

    let rowData = {};

    // Process row data based on collected headers
    data.forEach((entry) => {
      entry?.questionnaireAnswer?.forEach((answer) => {
        const header = Array.from(headers).find((header) => header === answer.questionText);
        if (header) {
          const uniqueId = answer.duplicateId;
          if (!rowData[uniqueId]) {
            rowData[uniqueId] = {};
          }

          let formattedAnswer = readAnswer(answer.answer);
          const originalFormattedAnswer = answer.answer;

          const splitAnswer = formattedAnswer.split("-SNID-");
          if (splitAnswer.length > 1) {
            formattedAnswer = splitAnswer[0];
          }

          rowData[uniqueId][header] = {
            title: header,
            key: header,
            value: formattedAnswer,
            _id: entry._id,
            originalAnswer: originalFormattedAnswer,
            data: entry,
            uniqueId,
          };
        }
      });
    });

    return {
      tableHeaders,
      rowData: Object.values(rowData),
    };
  } catch (error) {
    console.log("Error occurred while formatting table*****", error);
  }
};

// Helper function to parse different answer formats
const readAnswer = (answerInfo) => {
  try {
    if (Array.isArray(answerInfo)) {
      return answerInfo.length === 1 ? answerInfo[0] : `${answerInfo.length} View`;
    }

    if (typeof answerInfo === "object") {
      return answerInfo.label || answerInfo.value || answerInfo.answer;
    }

    if (isValidJSONString(answerInfo)) {
      return readAnswer(JSON.parse(answerInfo));
    }

    return answerInfo;
  } catch (error) {
    return error;
  }
};

const isName = (answer) => {
  let firstName = "";
  let lastName = "";

  Object.keys(answer || {}).forEach((key) => {
    if (key.toLowerCase() === "firstname") {
      firstName = answer[key];
    }
    if (key.toLowerCase() === "lastname") {
      lastName = answer[key];
    }
  });

  return `${firstName} ${lastName}`;
};
