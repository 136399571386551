// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo-container .image-container {
  width: 30px;
}

.logo-container img {
  aspect-ratio: 2/2;
  width: 80%;
}

.logo-container .logo-text {
  margin-left: var(--margin-sm);
  font-weight: var(--font-weight-md);
  flex: 1 1;
}

.logo-container .logo-text span {
  font-weight: var(--font-weight-sm);
}

.logo-container.light .logo-text {
  color: var(--light-color);
}

.logo-container .mobile-container {
  display: none;
}

.logo-container .mobile-container.show {
  display: flex;
  margin: 0 1rem 0 0.5rem;
}

nav .logo-container .mobile-container span {
  color: var(--light-color);
}

@media (max-width: 768px) {
  nav .logo-container .mobile-container {
    display: flex;
    margin-right: var(--margin);
    cursor: pointer;
  }

  nav .logo-container .image-container {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Logo/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,6BAA6B;EAC7B,kCAAkC;EAClC,SAAO;AACT;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,aAAa;IACb,2BAA2B;IAC3B,eAAe;EACjB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".logo-container {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.logo-container .image-container {\n  width: 30px;\n}\n\n.logo-container img {\n  aspect-ratio: 2/2;\n  width: 80%;\n}\n\n.logo-container .logo-text {\n  margin-left: var(--margin-sm);\n  font-weight: var(--font-weight-md);\n  flex: 1;\n}\n\n.logo-container .logo-text span {\n  font-weight: var(--font-weight-sm);\n}\n\n.logo-container.light .logo-text {\n  color: var(--light-color);\n}\n\n.logo-container .mobile-container {\n  display: none;\n}\n\n.logo-container .mobile-container.show {\n  display: flex;\n  margin: 0 1rem 0 0.5rem;\n}\n\nnav .logo-container .mobile-container span {\n  color: var(--light-color);\n}\n\n@media (max-width: 768px) {\n  nav .logo-container .mobile-container {\n    display: flex;\n    margin-right: var(--margin);\n    cursor: pointer;\n  }\n\n  nav .logo-container .image-container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
