import React from "react";

import { connect } from "react-redux";
import language from "../../language";
import CardCount from "../CardCount/CardCount";
import icons from "../../constants/icons";
import surveyQuery, {
  fetchCombinedSurvey,
} from "../../utils/queries/surveysQuery";
import serviceQuery from "../../utils/queries/serviceQuery";
import { Table } from "../Table";
import handleSearch from "../../utils/handleSearch";
import { fetchCounts } from "../../utils/queries/analyticsQuery";
import dynamicTableData from "../../utils/dynamicTableData";
import { CSVLink } from "react-csv";
import moment from "moment";
import formateFilters from "../../utils/formateFilters";
import { LocationFilter } from "../../components/Filters";
import DateFilter from "../Filters/DateFilter";
import { getStorage } from "../../utils/storage";
import { Modal } from "../Modal";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import formatSelectData from "../../utils/formatSelectData";
import toastMessage from "../../utils/toastMessage";
import capitalizeText from "../../utils/capitalizeText";
import EducationFelter from "../Filters/EducationFelter";
import AgeRangeFilter from "../Filters/AgeRangeFilter";
import AdvancedFilter from "../Filters/AdvancedFilter";

let copyData = [];

class Farmers extends React.Component {
  state = {
    totalPageCount: 20,
    page: 1,
    limit: 10,
    data: [],
    tableHeaders: [],
    csvData: [],
    cardCounts: [
      {
        title: "Male",
        fieldName: "totalMaleYouthCounts",
        icon: "recent_actors",
      },
      {
        title: "Female",
        fieldName: "totalFemaleYouthCounts",
        icon: "face",
      },
      {
        title: "Non-Youth",
        fieldName: "totalNonYouthCounts",
        icon: "contacts",
      },
      {
        title: "Total Youth",
        fieldName: "totalYouthCounts",
        icon: icons.users,
      },
      {
        title: "Total FSCs",
        fieldName: "total",
        icon: icons.users,
      },
    ],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.fetchData(true);
    this.fetchServices(true);
  };

  fetchData = (isLoading) => {
    this.getFarmers(isLoading);

    this.props.cardCounts &&
      this.props.cardCounts.forEach((cardCount) => {
        if (cardCount.value === "totalYouthCounts") {
          this.getCounts(isLoading, {
            fieldName: "totalYouthCounts",
            count: true,
          });
        }

        if (cardCount.value === "totalMaleYouthCounts") {
          this.getCounts(isLoading, {
            fieldName: "totalMaleYouthCounts",
            ["questionnaireAnswer.questionText"]: ["Gender",],
            ["questionnaireAnswer.answer"]: [
              "Male",
            ],
            count: true,
          });
        }
        if (cardCount.value === "totalFemaleYouthCounts") {
          this.getCounts(isLoading, {
            fieldName: "totalFemaleYouthCounts",
            ["questionnaireAnswer.questionText"]: ["Gender"],
            ["questionnaireAnswer.answer"]: [
              "Female",
            ],
            count: true,
          });
        }

        if (cardCount.value === "totalNonYouthCounts") {
          this.getCounts(isLoading, {
            fieldName: "totalNonYouthCounts",
            ["startQuestionnaireAnswer.answer"]: 36,
            ["endQuestionnaireAnswer.answer"]: 150,
            count: true,
          });
        }

        if (cardCount.value === "total") {
          this.getCounts(isLoading, {
            fieldName: "total",
            count: true,
          });
        }
      });
  };

  onChangeText(field, e) {
    this.setState({
      [field]: e.target ? e.target.value : e,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filters !== this.props.filters ||
      prevProps.id !== this.props.id
    ) {
      this.fetchData(true);

    }
  }
  mergeFilters(filters1, filters2) {
    const merged = { ...filters1 };
    for (const key in filters2) {
      if (filters2.hasOwnProperty(key)) {
        if (merged.hasOwnProperty(key)) {
          // Combine values if the key exists in both objects
          merged[key] = [].concat(merged[key], filters2[key]);
        } else {
          // Otherwise, just add the value
          merged[key] = filters2[key];
        }
      }
    }
    return merged;
  };

  getCounts = async (isLoading, params = {}) => {
    try {
      const { defaultLanguage, cardCounts = [] } = this.props;

      this.setState({
        [`is_${params.fieldName}`]: isLoading,
        [params.fieldName]: 0,
      });
      
      const formatFilters = this.mergeFilters(this.returnFilters(), params);
      

      delete formatFilters.page;
      delete formatFilters.limit;

      const data = await fetchCounts(defaultLanguage, formatFilters);

      let count = 0;

      if (data?.length > 0) {
        count = data[0].count;
      }

      const updatedCounts = cardCounts.map((el) => {
        if (params.fieldName === el.value) {
          el["count"] = count;
        }

        return el;
      });

      this.setState({
        cardCounts: updatedCounts,
        [`is_${params.fieldName}`]: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        [`is_${params.fieldName}`]: false,
        [params.fieldName]: 0,
      });
    }
  };

  fetchServices = async () => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({
        isFetchingService: true,
      });

      const data = await serviceQuery(defaultLanguage);

      this.setState({
        services: formatSelectData(data, "name", "_id"),
        isFetchingService: false,
      });
    } catch (error) {
      this.setState({
        isFetchingService: false,
      });
    }
  };

  returnFilters() {
    const { page, limit } = this.state;

    const { filters, id } = this.props;

    let _filters = {
      page:filters.page || page,
      limit,
      ["service._id"]: id,
      ...formateFilters(filters),
    };

    return _filters;
  }

  getFarmers = async (isLoadingFSC) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingFSC, data: [], tableHeaders: [] });

      const { data, count } = await surveyQuery(defaultLanguage, {
        ...this.returnFilters(),
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
        totalPageCount: count,
        isLoadingFSC: false,
      });

      copyData = rowData.slice(0);
    } catch (error) {
      this.setState({
        isLoadingFSC: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getFarmers(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isExporting: true,
      });

      const { defaultLanguage, id } = this.props;
      const { service, limit } = this.state;

      let services = service.map((el) => el.value);

      const { data } = await fetchCombinedSurvey(defaultLanguage, {
        ...this.returnFilters(),
        services: [id, ...services],
      });

      let results = [];
      let group = {};

      // First, find all `duplicateId`s where `answerType` is `uniqueNumber`
      data.forEach((el) => {
        el?.questionnaireAnswer?.forEach((qEl) => {
          if (qEl.answerType === "uniqueNumber") {
            let field = qEl.duplicateId;

            if (!group[field]) {
              group[field] = {}; // Initialize as an object
            }

            // Assign the questionText as key and answer as value
            group[field][
              qEl.answerType === "uniqueNumber"
                ? "uniqueNumber"
                : qEl.questionText
            ] = qEl.answer;
          }
        });
      });

      // Then, group the data based on all found `duplicateId`s
      data.forEach((el) => {
        el?.questionnaireAnswer?.forEach((qEl) => {
          let field = qEl.duplicateId;

          // Only process if the duplicateId is already in the group (i.e., it corresponds to a unique number)
          if (group[field]) {
            // Assign the questionText as key and answer as value
            group[field][
              qEl.answerType === "uniqueNumber"
                ? "uniqueNumber"
                : qEl.questionText
            ] = qEl.answer;
          }
        });
      });
      const res = Object.values(group);

      let resultGroup = {};

      res.forEach((el) => {
        if (!resultGroup[el.uniqueNumber]) {
          resultGroup[el.uniqueNumber] = {};
        }

        resultGroup[el.uniqueNumber] = {
          ...resultGroup[el.uniqueNumber],
          ...el,
        };
      });

      this.setState(
        {
          isExporting: false,
          csvData: Object.values(resultGroup),
        },
        () => this.refs.csvDownload?.link.click()
      );
    } catch (error) {
      this.setState({
        isExporting: false,
      });
    }
  };

  handlePressSearch = async () => {
    try {
      const { search_text } = this.state;
      const { defaultLanguage } = this.props;

      if (search_text === "")
        return toastMessage("error", "Search text is required");

      this.setState({ isLoadingFSC: true });

      const { data, count } = await surveyQuery(defaultLanguage, {
        ["questionnaireAnswer.answer"]: [
          search_text,
          search_text.toUpperCase(),
          capitalizeText(search_text),
        ],
        ...this.returnFilters(),
        page: 1,
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
        totalPageCount: count,
        isLoadingFSC: false,
      });

      copyData = rowData.slice(0);
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <>
        <div className="d-flex gap-2 mb-2">
          <LocationFilter />
          {/* <QuestionnaireAnswerFilter surveyFilters={this.returnFilters()} /> */}
          <DateFilter />
          {/* <ProvinceFilter />
          <DistrictFilter />
          <SectorFilter /> */}
          <EducationFelter />
          <AgeRangeFilter />
          <AdvancedFilter isLoading={this.state.isLoadingFSC} headers={this.state.tableHeaders} />
        </div>
        <hr />
        <div className="row mb-3">
          {this.props?.cardCounts?.map((el, i) => {
            return (
              <div className="col-md-4" key={i}>
                <CardCount
                  icon={el.icon}
                  color="#00C850"
                  count={el.count}
                  title={el.label}
                  isLoading={this.state[`is_${el.value}`]}
                />
              </div>
            );
          })}
        </div>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          addSearchButton
          handlePressSearch={this.handlePressSearch.bind(this)}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoadingFSC}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.state.tableHeaders}
          filters={[
            {
              type: "export",
              title: language[this.props.defaultLanguage].export_,
              button_type: "dropdown",
              icon: "download",
              options: [
                {
                  name: "CSV",
                  onPress: () => this.handleShowModal("showExport"),
                },
              ],
            },
          ]}
          actions={[{}]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showExport")}
          show={this.state.showExport}
          title={this.state.modalTitle}
        >
          <div className="card-body">
            <Input
              label={language[this.props.defaultLanguage].limit}
              value={this.state.limit}
              onChange={(e) => this.onChangeText("limit", e)}
            />
            <Select
              options={this.state.services}
              label={language[this.props.defaultLanguage].service}
              value={this.state.service}
              onChange={(e) => this.onChangeText("service", e)}
              isMulti
              isLoading={this.state.isFetchingService}
            />
            <Button
              text={language[this.props.defaultLanguage].export_}
              isSubmitting={this.state.isExporting}
              onPress={this.downloadExcel.bind(this)}
            />
          </div>
        </Modal>

        <CSVLink
          ref="csvDownload"
          filename={"JSurvey - " + moment().format("lll")}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Farmers);
