import { Villages } from "rwanda";

export default (options) => {
  try {
    if (!options || !options instanceof Array || options?.length === 0) return;

    const listVillages = Villages(...options) || [];

    if (
      options.includes("East") &&
      options.includes("Nyagatare") &&
      options.includes("Mimuri")
    ) {
      listVillages.push("Karukwanzi");
      listVillages.push("Byimana");
    }

    return listVillages?.map((el) => {
      return {
        label: el,
        value: el,
      };
    });
  } catch (error) {
    console.log("Error while getting villages", error);
  }
};
