// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapfiltersContainer {
  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Geographical/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,OAAO;EACP,QAAQ;AACV","sourcesContent":[".mapfiltersContainer {\n  position: absolute;\n  z-index: 1000;\n  left: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
