// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-shadow {
  border: 0px solid var(--text-color-10);
  box-shadow: var(--card-shadow);
}

.card {
  border: 0;
  box-shadow: var(--card-shadow);
}

.card .card-header {
  background-color: var(--light-color);
  border-bottom: 0;
  /* 1px solid var(--text-color-10); */
}

.card-shadow .card-header {
  padding: 1rem;
  background-color: var(--primary-color-5);
  border-bottom: 0;
  /* border-bottom: 1px solid var(--text-color-10); */
}

.panel-default {
  background-color: var(--primary-color-5);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
}

.card-action-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card-action-btn .icon-container {
  opacity: 0.5;
}

.card-action-btn .icon-container:hover {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/card.styles.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,8BAA8B;AAChC;;AAEA;EACE,SAAS;EACT,8BAA8B;AAChC;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,wCAAwC;EACxC,gBAAgB;EAChB,mDAAmD;AACrD;;AAEA;EACE,wCAAwC;EACxC,mCAAmC;EACnC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".card-shadow {\n  border: 0px solid var(--text-color-10);\n  box-shadow: var(--card-shadow);\n}\n\n.card {\n  border: 0;\n  box-shadow: var(--card-shadow);\n}\n\n.card .card-header {\n  background-color: var(--light-color);\n  border-bottom: 0;\n  /* 1px solid var(--text-color-10); */\n}\n\n.card-shadow .card-header {\n  padding: 1rem;\n  background-color: var(--primary-color-5);\n  border-bottom: 0;\n  /* border-bottom: 1px solid var(--text-color-10); */\n}\n\n.panel-default {\n  background-color: var(--primary-color-5);\n  border-radius: var(--border-radius);\n  margin-bottom: 1rem;\n}\n\n.card-action-btn {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.card-action-btn .icon-container {\n  opacity: 0.5;\n}\n\n.card-action-btn .icon-container:hover {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
