// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-tabs {
  z-index: 100 !important;
  margin-bottom: 1rem !important;
}
.tab {
  border-bottom: 1px solid var(--text-color-10);
  margin-top: 0;
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tab::-webkit-scrollbar {
  display: none;
}

.tab .nav-item button,
.tab .nav-item {
  font-weight: 700;
  color: var(--text-color);
}

.nav-tabs .nav-link {
  opacity: 0.8;
  border: 0;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  font-size: var(--font-size);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0;
  border-radius: 0;
}

.nav-tabs .nav-link.active {
  opacity: 1;
  border-bottom: 3px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Tabs/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,8BAA8B;AAChC;AACA;EACE,6CAA6C;EAC7C,aAAa;EACb,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;EAEE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,6CAA6C;EAC7C,6BAA6B;EAC7B,2BAA2B;AAC7B","sourcesContent":[".nav-tabs {\n  z-index: 100 !important;\n  margin-bottom: 1rem !important;\n}\n.tab {\n  border-bottom: 1px solid var(--text-color-10);\n  margin-top: 0;\n  display: flex;\n  width: 100%;\n  overflow-x: auto;\n  overflow-y: hidden;\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n.tab::-webkit-scrollbar {\n  display: none;\n}\n\n.tab .nav-item button,\n.tab .nav-item {\n  font-weight: 700;\n  color: var(--text-color);\n}\n\n.nav-tabs .nav-link {\n  opacity: 0.8;\n  border: 0;\n  border-radius: 0;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  vertical-align: middle;\n  font-size: var(--font-size);\n}\n\n.nav-tabs .nav-item.show .nav-link,\n.nav-tabs .nav-link.active {\n  border: 0;\n  border-radius: 0;\n}\n\n.nav-tabs .nav-link.active {\n  opacity: 1;\n  border-bottom: 3px solid var(--primary-color);\n  background-color: transparent;\n  color: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
