import React from "react";
import { RegionsCounts } from "../../components/RegionsCounts";
import { DashboardHeader } from "../../components/Header";
import { connect } from "react-redux";
import language from "../../language";
import { Breadcrumb } from "../../components/Breadcrumb";

class RegionsCountScreen extends React.Component {
  render() {
    return (
      <div className="card-body">
        <Breadcrumb
          homeUrl="/user/home"
          data={[
            {
              name: language[this.props.defaultLanguage].analytics,
              url: "dashboard/home",
            },
            {
              name: language[this.props.defaultLanguage].regions,
              active: true,
            },
          ]}
        />
        <DashboardHeader title={language[this.props.defaultLanguage].regions} />
        <RegionsCounts />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RegionsCountScreen);
