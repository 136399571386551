import isJsonString from "./isJsonString";

export default (answerItem) => {
  try {
    let result = answerItem;

    if (Array.isArray(answerItem)) {
      const answerArray = answerItem.map((el) => {
        return el?.label || el.answer;
      });

      result = answerArray.join(", ");
    } else if (typeof answerItem === "object") {
      result = isJsonString(answerItem.answer)
        ? JSON.parse(answerItem.answer).value
        : answerItem.answer;
    }

    return result;
  } catch (error) {
    console.log(error);
  }
};
