import { Loading } from "../Loader";
import { Pagination } from "../Pagination";
import TableData from "./TableData";

const TableContainer = (props) => {
  const {
    headers,
    actions,
    rowPress,
    bordered,
    isLoading,
    handleSort,
    sortOrder,
    sortColumn,
    tab,
    data,
  } = props;

  return (
    <>
      <thead style={props.style_header}>
        <tr style={{ width: "100%" }}>
          {headers &&
            headers.map((item, i) => {
              return (
                <th key={i}>
                  {item.title}
                  {item.sort && (
                    <button
                      className="sort-btn"
                      onClick={() => handleSort(item.key, tab)}
                    >
                      {(sortOrder === -1 || sortOrder === "desc") &&
                      sortColumn === item.key ? (
                        <i className="arrow_upward"></i>
                      ) : (
                        <i className="south"></i>
                      )}
                    </button>
                  )}
                </th>
              );
            })}
        </tr>
      </thead>
      <tbody className={props.tableBodyClass}>
        {isLoading ? (
          <tr>
            <td align="center" colSpan={headers.length}>
              <Loading className="primary" />
            </td>
          </tr>
        ) : !data ? (
          <tr>
            <td colSpan={13} align="center">
              No data found
            </td>
          </tr>
        ) : data.length === 0 ? (
          <tr>
            <td colSpan={13} align="center">
              No data found
            </td>
          </tr>
        ) : (
          <TableData
            data={data}
            actions={actions}
            rowPress={rowPress}
            headers={headers}
            {...props}
          />
        )}
      </tbody>
    </>
  );
};

export default TableContainer;
