import React from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import surveyQuery from "../../../utils/queries/surveysQuery";
import formateFilters from "../../../utils/formateFilters";
import { connect } from "react-redux";
import language from "../../../language";
import { Loading } from "../../Loader";
import Empty from "../../Empty/Empty";
import isJsonString from "../../../utils/isJsonString";

const options = {
  legend: {
    position: "bottom",
    display: true,
    labels: {
      usePointStyle: true,
      boxWidth: 6,
    },
  },
  maintainAspectRatio: true,
  responsive: true,
};

class Gender extends React.Component {
  state = {
    chartWidth: 300,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getFarmers(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getFarmers(true);
    }
  }

  returnFilters() {
    const { filters } = this.props;

    let _filters = {
      rank: true,
      order: "gender",
      ["service.type"]: this.props.serviceType,
      // ["service.type"]: [
      //   "INDIVIDUAL_FARMER_REGISTRATION",
      //   "INDIVIDUAL_YOUNG_FARMER",
      //   "YOUNG_FARMER_DEMOGRAPHY",
      //   "DEMOGRAPHY",
      //   "INDIVIDUAL_FARMER_REGISTRATION",
      //   "NON_YOUTH_INDIVIDUAL_FARMER",
      //   "INDIVIDUAL_FARMER_REGISTRATION",
      //   "INDIVIDUAL_YOUNG_FARMER",
      //   "YOUNG_FARMER_DEMOGRAPHY",
      //   "NON_YOUTH_INDIVIDUAL_FARMER",
      // ],
      ...formateFilters(filters),
    };

    delete _filters.startCreatedAt;
    delete _filters.endCreatedAt;

    return _filters;
  }

  getFarmers = async (isLoading) => {
    this.setState({
      isLoading,
    });
    try {
      const { defaultLanguage } = this.props;

      const { data } = await surveyQuery(defaultLanguage, this.returnFilters());

      let chart = {},
        counts = [];

      data.forEach((el) => {
        let genderKey = isJsonString(el.gender)
          ? JSON.parse(el.gender).value?.toUpperCase()?.replace(/\s+/g, "")
          : el.gender?.toUpperCase()?.replace(/\s+/g, "");

        if (!chart[genderKey]) {
          chart[genderKey] = [];
        }

        if (isJsonString(el.gender)) {
          el.gender = JSON.parse(el.gender)
            .value?.toUpperCase()
            ?.replace(/\s+/g, "");
        }

        chart[genderKey].push(el);

        counts.push(el.count);
      });


      const labels = Object.keys(chart);

      let customLabels = labels.map(
        (label, index) => `(${counts[index]}) ${label}`
      );

      const chartData = {
        labels: customLabels,
        datasets: [
          {
            label: `${this.props.serviceType} Gender`,
            data: counts,
            borderRadius: 15,
            backgroundColor: ["rgba(0, 200, 80, 1)", "#FF6D00", "#A0C800"],
            barPercentage: 0.2,
            tension: 0.1,
          },
        ],
      };

      this.setState({
        data: chartData,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  renderContent() {
    if (this.state.isLoading) {
      return <Loading className="loader-fullscreen" />;
    }
    
    if (this.state.data?.labels?.length > 0) {
      return (
          <Doughnut data={this.state.data} options={{ ...options }} />
      );
    }
    
    return <Empty title={language[this.props.defaultLanguage].no_data} />;
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

Gender.propTypes = {
  serviceType: PropTypes.string.isRequired,
  filters: PropTypes.object,
  defaultLanguage: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  const { defaultLanguage } = state.Language;
  return {
    filters,
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Gender);
