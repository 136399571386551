import React, { useEffect, useState } from "react";
import { MessageSquare } from "lucide-react";
import { Modal } from "react-bootstrap";
import { MessageList } from "./MessageList";
import { MessageInput } from "./MessageInput";
import { fetchUsers } from "../../utils/queries/chatQuery";

export function ChatArea({ conversation, isNewConversation, selectedUsers }) {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchUsers();
        setUsers(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  if (!conversation && !isNewConversation) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100 text-secondary overflow-scroll">
        <div className="text-center">
          <MessageSquare
            className="mb-2 text-muted"
            style={{ width: "48px", height: "48px" }}
          />
          <p>Select a conversation or start a new one</p>
        </div>
      </div>
    );
  }

  const participants = isNewConversation
    ? selectedUsers
    : conversation?.users || [];

  const displayedParticipants = participants.slice(0, 4);
  const remainingParticipants = participants.slice(4);
  const remainingCount = participants.length - 4;

  return (
    <div className="d-flex flex-column justify-content-between" style={{ height: "90vh" }}>
      <div className="p-3 border-bottom bg-light">
        <h5 className="mb-0 text-dark d-flex align-items-center" style={{ fontSize: "0.875rem" }}>
          To:{" "}
          {displayedParticipants
            .map((user) => `${user.firstName} ${user.lastName}`)
            .join(", ")}
          {remainingCount > 0 && (
            <>
              , and{" "}
              <button
                onClick={() => setShowModal(true)}
                className="btn btn-link p-0 text-primary"
              >
                ({remainingCount}) Others
              </button>
            </>
          )}
        </h5>
      </div>
      <div className="flex-grow-1 p-3 border-bottom overflow-auto">
        <MessageList selectedConversation={conversation} />
      </div>
      <div className="p-3 bg-light">
        <MessageInput />
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Participants ({participants.length})</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
          <ul className="list-group">
            {participants.map((user, index) => (
              <li key={index} className="list-group-item">
                {user.firstName} {user.lastName}
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
