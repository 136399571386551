import React from "react";
import { Navbar } from "../../components/Navbar";
import { Introduction } from "../../components/Landing/Introduction";
import Process from "../../components/Landing/Process/Process";
import { Footer } from "../../components/Footer";
import { LandingPageLayout } from "../../Layout";

class LandingScreen extends React.Component {
  render() {
    return (
      <>
        <Navbar {...this.props} />
        <div className="main-landing-container">
          <LandingPageLayout className="intro-container">
            <Introduction />
          </LandingPageLayout>
          {/* <LandingPageLayout className="process-container">
            <Process />
          </LandingPageLayout>  */}
          {/* <LandingPageLayout className="projects-container">
            <Projects />
          </LandingPageLayout> */}
          <Footer />
        </div>
      </>
    );
  }
}
export default LandingScreen;
