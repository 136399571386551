// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-activity {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 270px;
  color: var(--text-color-60);
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Empty/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,2BAA2B;EAC3B,gBAAgB;AAClB","sourcesContent":[".empty-activity {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 270px;\n  color: var(--text-color-60);\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
