import { Link } from "react-router-dom";
import "./styles.css";
import { Loading } from "../Loader";
import NotificationBadgeCount from "../Notifications/NotificationBadgeCount";

const Button = (props) => {
  if (props.type === "link") {
    return (
      <Link
        key={props.key}
        to={props.to}
        className={`btn ${props.className} ${
          props.disabled || (props.isSubmitting && "btn-disabled")
        }`}
        style={props.styles}
        disabled={props.isSubmitting || props.disabled}
        title={props.title}
      >
        {props.icon && (
          <span className="material-symbols-outlined icon">{props.icon}</span>
        )}
        {props.isSubmitting ? <Loading /> : props.text}
      </Link>
    );
  }
  return (
    <button
      key={props.key}
      className={`btn btn-primary ${props.className}  ${
        props.disabled || (props.isSubmitting && "btn-disabled")
      }`}
      style={props.styles}
      disabled={props.isSubmitting || props.disabled}
      onClick={!props.isSubmitting ? props.onPress : null}
      id={props.id ? props.id : "dropdownMenuButton1"}
      data-bs-toggle={props.type === "dropdown" ? "dropdown" : ""}
      aria-expanded="false"
      data-bs-auto-close={props.autoCloseType}
      title={props.title}
    >
      {props.icon && (
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.notificationCount > 0 && (
            <NotificationBadgeCount
              count={props.notificationCount}
              className="small"
              styles={props.notificationStyles}
            />
          )}
          <span className={`${process.env.REACT_APP_ICONS_TYPE} icon`}>
            {props.icon}
          </span>
        </div>
      )}
      {props.icon && props.text && <span style={{ width: ".5rem" }}></span>}
      {props.isSubmitting ? <Loading /> : props.text}
    </button>
  );
};

export default Button;
