import "./styles.css";
const HorizontalLoader = (props) => {
  return (
    <span
      className="horizontalLoader"
      style={{ width: props.width || 20 }}
    ></span>
  );
};

export default HorizontalLoader;
