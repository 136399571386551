import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import language from "../../../language";
import Province from "../../../utils/rwanda/Province";
import District from "../../../utils/rwanda/District";
import Cell from "../../../utils/rwanda/Cell";
import Village from "../../../utils/rwanda/Village";
import Sector from "../../../utils/rwanda/Sector";
import isJsonString from "../../../utils/isJsonString";

const SingleInput = (props) => {
  let value = props.value ? props.value.answer : "";

  if (props.value && props.value.answer && isJsonString(props.value.answer)) {
    value = JSON.parse(props.value.answer);
  }
  const inputParameters = {
    placeholder:
      language[props.defaultLanguage].default_placeholder_input +
      " " +
      props.questionText,

    label: props.questionText,
    error: props.error,
    value,
    onChange: (e) =>
      props.onChange({
        questionText: props.questionText,
        fieldName: props.questionId,
        value: e,
        formKey: props.formKey,
        questionId: props.questionId,
        answerType: props.answerType,
      }),

    type:
      props.answerType === "phoneNumber" ||
      props.answerType === "age" ||
      props.answerType === "year_birth"
        ? "number"
        : props.answerType,
    answerType: props.answerType,
    disabled:
      props.answerType === "position" || props.answerType === "department",

    required: props.required,
  };

  if (inputParameters.answerType === "phone") {
    inputParameters.maxLength = 10;
  }

  const linkedQuestionnairesHandler = (linkedQuestionnaire) => {
    let linkedValues = [];
    const { formKey, questionnaireAnswer } = props;

    if (!questionnaireAnswer[formKey]) return;

    linkedQuestionnaire.forEach((eLinked) => {
      if (questionnaireAnswer[formKey][eLinked.value]) {
        let v =
          questionnaireAnswer[formKey][eLinked.value]?.value ||
          questionnaireAnswer[formKey][eLinked.value];

        if (!linkedValues.includes(v)) {
          linkedValues.push(v);
        }
      }
    });

    return linkedValues;
  };
  const selectedOptionValue = () => {
    const { formKey, questionnaireAnswer, form } = props;

    if (!questionnaireAnswer[formKey]) return;

    let linkedValues = [];

    form.forEach((el) => {
      if (
        el?.singleAnswer?.moreOptions?.length > 0 &&
        props.questionId === el._id
      ) {
        el?.singleAnswer?.moreOptions.forEach((elMoreOption) => {
          linkedValues = linkedQuestionnairesHandler(
            elMoreOption.linkedQuestionnaire
          );
        });
      }
    });

    return linkedValues || [undefined];
  };
  return (
    <div
     
    >
      {props?.answerType?.toLowerCase() === "province" ? (
        <Select
          options={Province()}
          {...inputParameters}
          placeholder={language[props.defaultLanguage].select}
        />
      ) : props?.answerType?.toLowerCase() === "district" ? (
        <Select
          options={District(selectedOptionValue())}
          {...inputParameters}
        />
      ) : props?.answerType?.toLowerCase() === "sector" ? (
        <Select options={Sector(selectedOptionValue())} {...inputParameters} />
      ) : props?.answerType?.toLowerCase() === "cell" ? (
        <Select options={Cell(selectedOptionValue())} {...inputParameters} />
      ) : props?.answerType?.toLowerCase() === "village" ? (
        <Select options={Village(selectedOptionValue())} {...inputParameters} />
      ) : (
        <Input
          {...inputParameters}
          textarea={props.answerType === "multipleLineText"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SingleInput);
