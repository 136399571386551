import React from 'react';


export function CapitalGrowth({ baselineCapital, currentCapital, growthFactors }) {
  return (
    <div className="card h-100">
      <div className="card-body">
        <h3 className="h5 mb-4">Capital growth</h3>
        <div className="row g-4 mb-4">
          <div className="col-6">
            <div className="bg-light rounded p-3">
              <p className="text-muted small mb-1">Baseline capital</p>
              <p className="h5 mb-0">{baselineCapital.toLocaleString()} Rwf</p>
            </div>
          </div>
          <div className="col-6">
            <div className="bg-light rounded p-3">
              <p className="text-muted small mb-1">Current capital</p>
              <p className="h5 mb-0">{currentCapital.toLocaleString()} Rwf</p>
            </div>
          </div>
        </div>
        <div>
          <p className="small fw-medium mb-2">Growth factors</p>
          <div className="d-flex flex-column gap-2">
            {growthFactors.map((factor, index) => (
              <div key={index} className="d-flex align-items-center gap-2">
                <div className="bg-secondary rounded" style={{ width: '16px', height: '16px' }} />
                <span className="small">{factor}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}