import Surveys from "./Surveys";
import FscSevey from "./FscSevey";
import DemographyServey from "./DemographyServey";
import IndividualFarmerSurvey from "./IndividualFarmerSurvey";

import Questionnaire from "./Questionnaire";
import RequiredDocuments from "./RequiredDocuments";
import Footer from "./Footer";

export { 
    Surveys,
    FscSevey,
    DemographyServey,
    IndividualFarmerSurvey,
     Questionnaire,
      RequiredDocuments, 
      Footer 
    };
