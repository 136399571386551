import React, { useEffect, useState } from "react";
import { MessageSquare } from "lucide-react";
import { useChat } from "../../hooks/useChat";
import { getMessages } from "../../utils/queries/chatQuery";
import Message from "./Message";

export function MessageList({ selectedConversation }) {
  const { setMessages, messages } = useChat();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!selectedConversation) return;

    const getConversationMessages = async () => {
      setMessages([]);
      setIsLoading(true);
      const data = await getMessages({
        conversationId: selectedConversation?._id,
      });
      setMessages(data);
      setIsLoading(false);
    };
    getConversationMessages();
  }, [selectedConversation]);

  return (
    <div className="flex-1 p-3 h-100" >
      {isLoading ? (
        <div className="flex items-center justify-center h-full text-gray-500">
          <div className="text-center">
            <MessageSquare className="mb-2 text-gray-400" size={48} />
            <p>Loading messages...</p>
          </div>
        </div>
      ) : (
        <>
          {messages?.length ? (
            <div style={{height:"10rem"}} >
              {messages.map((msg) => (
                <Message key={msg._id} message={msg} />
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center h-full text-gray-500">
              <div className="text-center">
                <MessageSquare className="mb-2 text-gray-400" size={48} />
                <p className="mb-0">Start a new conversation</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
