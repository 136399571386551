export default (str) => {
  try {
    if (typeof str !== "string" || !isNaN(parseFloat(str))) return;

    JSON.parse(str);

    return true;
  } catch (e) {
    return false;
  }
};
