import React from "react";

import { Input, Select } from "../../Input";
import surveyQuery from "../../../utils/queries/surveysQuery";
import accountQuery from "../../../utils/queries/accountQuery";
import { connect } from "react-redux";
import toastMessage from "../../../utils/toastMessage";
import { v4 as uuidv4 } from "uuid";
import isJsonString from "../../../utils/isJsonString";
class RemoteDropdown extends React.Component {
  state = {
    data: [],
    isFetching: true,
  };

  componentDidMount = async () => {
    const { integrationType } = this.props;

    try {
      if (integrationType?.value) {
        this.fetchIntegrationData(integrationType?.value);
      } else {
        let types = integrationType.map((el) => el.value);
        this.fetchIntegrationData(types);
      }
    } catch (error) {
      console.error("fetch dynamic form ", error);
      this.setState({
        isFetching: false,
      });
    }
  };

  fetchIntegrationData = async (type) => {
    try {
      this.setState({
        isFetching: true,
      });

      let fetchedData = {};

      if (type === "ACCOUNT") {
        fetchedData = await accountQuery(this.props.defaultLanguage, {
          active: true,
          accountType: type,
          type: "dropdown",
        });
      } else {
        const { data, count } = await surveyQuery(this.props.defaultLanguage, {
          ["service.type"]: type,
          limit: 100,
          page: 1,
        });

        if (data) {
          fetchedData = this.formIntegrationSurveyData(data, "other");
        }
      }

      this.setState({
        data: fetchedData,
        isFetching: false,
      });
    } catch (error) {
      console.error("unable to fetch dynamic form ", error);
      this.setState({
        isFetching: false,
      });
    }
  };

  handleChange(field, e) {
    this.setState({
      [field]: e.target ? e.target.value : e,
    });
  }

  handleSearch = async () => {
    try {
      this.setState({ isSearching: true });
      const { data } = await surveyQuery(this.props.defaultLanguage, {
        ["service.type"]: this.state.searchType,
        ["questionnaireAnswer.questionText"]: [this.state.questionOption.value],
        ["questionnaireAnswer.answer"]: [this.state.searchText.toString()],
      });

      let searchResult = {};

      if (data.length > 0) {
        searchResult = data[0];
      }

      this.setState({
        searchResult,
        isSearching: false,
      });
    } catch (error) {
      toastMessage("error", "Unable to search: " + JSON.stringify(error));

      this.setState({ isSearching: false });
    }
  };

  formIntegrationSurveyData(data) {
    let results = [];
    let questionAnswer = {};
    data.forEach((el) => {
      el?.questionnaireAnswer?.forEach((qEl) => {
        this.props?.fields?.forEach((field) => {
          if (
            field.value?.toLowerCase()?.replaceAll(" ", "") ===
            qEl.questionText?.toLowerCase()?.replaceAll(" ", "")
          ) {
            if (!questionAnswer[qEl.duplicateId]) {
              questionAnswer[qEl.duplicateId] = [];
            }
            questionAnswer[qEl.duplicateId].push({
              label: qEl.answer.answer || qEl?.answer?.value || qEl.answer,
              value: qEl.answer.answer || qEl?.answer?.value || qEl.answer,
            });
          }
        });
      });
    });

    results = Object.keys(questionAnswer).map((key) => {
      let label = "",
        value = "";
      questionAnswer[key].forEach((AnsEl) => {
        label += AnsEl.label;
        value = key;
      });

      return {
        label: label + " -SNID- " + key,
        value: label + " -SNID- " + key,
      };
    });

    return results;
  }

  render() {
    let value = this.props.value ? this.props.value.answer : "";

    if (
      this.props.value &&
      this.props.value.answer &&
      isJsonString(this.props.value.answer)
    ) {
      value = JSON.parse(this.props.value.answer);
    }

    return (
      <>
        <Select
          label={this.props.label}
          options={this.state.data}
          value={value}
          onChange={(e) =>
            this.props.onChange({
              questionText: this.props.questionText,
              fieldName: this.props.label,
              value: e,
              formKey: this.props.formKey,
              questionId: this.props.questionId,
              answerType: this.props.answerType,
            })
          }
          required={this.props.required}
          isLoading={this.state.isFetching}
          error={this.props.error}
          isMulti={this.props.allowMultipleSelection}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RemoteDropdown);
