import React from "react";
import { Input } from "../Input";
import toastMessage from "../../utils/toastMessage";
import language from "../../language";
import { connect } from "react-redux";
import { getStorage } from "../../utils/storage";
import { Button } from "../Button";
import { DeleteModal, Modal } from "../Modal";
import { deleteSurvey } from "../../utils/queries/surveysQuery";
import { apiInstance } from "../../utils/axiosInstance";

class SurveyInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {},
      isSubmitting: false,
      originalData: {},
      selectedAnswer: props.selectedAnswer,
    };
  }

  componentDidMount() {
    Object.keys(this.props.selectedAnswer).map((key) => {
      this.setState({
        [key]: this.props.selectedAnswer[key].value,
      });

      if (
        Object.keys(this.state.originalData || {}).length === 0 &&
        this.props.selectedAnswer[key].data
      ) {
        this.setState({
          originalData: this.props.selectedAnswer[key].data,
        });
      }
    });
  }

  onChangeText(field, e) {
    let { error, selectedAnswer } = this.state;

    delete error[field];

    selectedAnswer[field].value = e.target ? e.target.value : e;

    this.setState({
      selectedAnswer: { ...selectedAnswer },
      error,
    });
  }

  validateForm() {
    let { error, selectedAnswer } = this.state;

    Object.keys(selectedAnswer).forEach((key) => {
      if (this.state[key] === undefined) {
        error[key] = language[this.props.defaultLanguage].field_required;
      }
    });

    this.setState({ error });
  }

  onSubmit = async (method, loader) => {
    try {
      this.setState({ [loader]: true });

      // let { selectedAnswer, answerIndex } = this.props;
      let { originalData, selectedAnswer } = this.state;

      // if (method === "PUT") {
      //   // selectedAnswer.questionnaireAnswer.splice(answerIndex, 1);
      // }

      if (
        method === "DELETE" &&
        window.confirm(
          language[this.props.defaultLanguage].delete_answer_confirmation
        )
      ) {
        await this.handleSurveyData(originalData, method, loader);
      } else {
        await this.handleSurveyData(originalData, method, loader);
      }

      this.props.handleCloseModal();
    } catch (error) {
      this.setState({
        [loader]: false,
      });
    }
  };

  handleSurveyData = async (data, method, loader) => {
    let { selectedAnswer } = this.state;
    try {
      const user = await getStorage();

      let id = data._id;

      let requestBody = {};

      if (method === "PUT") {
        delete data._id;

        requestBody.questionnaireAnswer = this.formateData({
          originalData: data,
          selectedAnswer,
        });
      }

      const options = {
        method,
        url: `/survey`,
        data: {
          ...data,
          id,
        },
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      await apiInstance(options);

      this.setState({
        [loader]: false,
      });

      toastMessage(
        "success",
        language[this.props.defaultLanguage][
          method === "PUT" ? "data_update_success" : "delete_success"
        ]
      );
    } catch (error) {
      this.setState({
        [loader]: false,
      });

      toastMessage(
        "error",
        language[this.props.defaultLanguage].delete_data_failed +
          ": " +
          JSON.stringify(error?.response?.data) || error
      );
    }
  };

  formateData({ originalData, selectedAnswer }) {
    const { questionnaireAnswer } = originalData;

    const questionnaireFormat = questionnaireAnswer.map((eqAns) => {
      Object.keys(selectedAnswer).forEach((key) => {
        if (key === eqAns.questionText) {
          eqAns.answer = selectedAnswer[key].value;
        }
      });

      return eqAns;
    });

    return questionnaireFormat;
  }

  render() {
    return (
      <>
        <div className="card-body">
          <div className="row">
            {Object.keys(this.state.selectedAnswer).map((key) => {
              if (key === "dataIndex") return;
              return (
                <div key={key} className="col-md-4">
                  <Input
                    label={key}
                    value={this.state.selectedAnswer[key].value}
                    onChange={(e) => this.onChangeText(key, e)}
                    error={this.state.error[key]}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="modal-footer">
          <div
            style={{
              display: "flex",
              gap: "1.5rem",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <Button
              className="btn-danger"
              text={language[this.props.defaultLanguage].delete_}
              onPress={() => this.onSubmit("DELETE", "isDeleting")}
              isSubmitting={this.state.isDeleting}
            />
            <div className="d-flex gap-2">
              <Button
                text={language[this.props.defaultLanguage].cancel}
                onPress={this.props.handleCloseModal}
                className="btn-default"
              />
              <Button
                text={language[this.props.defaultLanguage].update}
                isSubmitting={this.state.isSubmitting}
                onPress={() => this.onSubmit("PUT", "isSubmitting")}
              />
            </div>
          </div>
        </div>
        {this.state.showDelete && (
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.username}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(SurveyInfo);
