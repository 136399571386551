import React from "react";
import { Line } from "react-chartjs-2";
import surveyQuery from "../../utils/queries/surveysQuery";
import { Select } from "../Input";
import { connect } from "react-redux";
import { Loading } from "../Loader";
import formateFilters from "../../utils/formateFilters";
import language from "../../language";
import Empty from "../Empty/Empty";
import isJsonString from "../../utils/isJsonString";


const locations = [
  { label: "Province", value: "province" },
  { label: "District", value: "district" },
  { label: "Sector", value: "sector" },
  { label: "Cell", value: "cell" },
  { label: "Village", value: "village" },
];

class ListCandidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      location: locations[0],
      serviceTypes: [],
      showFilter: props.showFilter,
    };
  }

  componentDidMount() {
    this.getFarmers(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getFarmers(true);
    }
  }

  returnFilters() {
    const { filters } = this.props;
    const { location } = this.state;

    let _filters = {
      rank: true,
      order: location.value,
      ...formateFilters(filters),
    };

    if (_filters.location) {
      _filters.region = _filters.location;
      delete _filters.location;
    }

    return _filters;
  }

  getFarmers = async (isLoading) => {
    this.setState({ isLoading });
    try {
      const { defaultLanguage } = this.props;

      // const { data } = await surveyQuery(defaultLanguage, this.returnFilters());

      const data = [
        { item: "Reported Graduations", count: 50 },
        { item: "Confirmed Graduations", count: 30 },
        { item: "False Graduations", count: 20 },
      ];

      this.setState({ data, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  getChartData() {
    return {
      labels: this.state.data.map((el) => el.item),
      datasets: [
        {
          label: "Counts",
          data: this.state.data.map((el) => el.count),
          backgroundColor: "rgba(0, 200, 80, .2)",
          borderColor: "rgba(0, 200, 80, 1)",
          borderWidth: 2,
          hoverBackgroundColor: "rgba(0, 200, 80, .7)",
          hoverBorderColor: "rgba(0, 200, 80, 1)",
          fill: true,
        },
      ],
    };
  }

  render() {
    const order = this.state?.location.value;
    return (
      <div className="">
        {this.state.showFilter && (
          <div className="row">
            <div className="col-md-12">
              <Select
                options={locations}
                value={this.state.location}
                onChange={(e) => this.onChangeText("location", e)}
              />
            </div>
          </div>
        )}

        {this.state.isLoading ? (
          <Loading className="loader-fullscreen" />
        ) : this.state.data && this.state.data.length > 0 ? (
          <div>
            <Line data={this.getChartData()} options={{ responsive: true }} />
          </div>
        ) : (
          <Empty title={language[this.props.defaultLanguage].no_data} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ListCandidates);
