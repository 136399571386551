// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-item {
  display: flex;
  align-items: center;
  min-height: 40px;
  opacity: 1;
  transition: opacity 0.4s;
  cursor: pointer;
  padding: 0.5rem;
}

.list-item:hover {
  opacity: 0.8;
}

.list-item .list-img-container {
  width: 35px;
  height: 35px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item .content {
  flex: 1 1;
}
.list-item .list-right-icon-container {
  opacity: 0.3;
}

.list-item img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 100%;
  border-radius: var(--border-radius-half);
}

.list-item .list-item-name {
  text-transform: capitalize;
}

.list-item .icon-container span {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/List/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,wBAAwB;EACxB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,SAAO;AACT;AACA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".list-item {\n  display: flex;\n  align-items: center;\n  min-height: 40px;\n  opacity: 1;\n  transition: opacity 0.4s;\n  cursor: pointer;\n  padding: 0.5rem;\n}\n\n.list-item:hover {\n  opacity: 0.8;\n}\n\n.list-item .list-img-container {\n  width: 35px;\n  height: 35px;\n  border-radius: var(--border-radius-half);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.list-item .content {\n  flex: 1;\n}\n.list-item .list-right-icon-container {\n  opacity: 0.3;\n}\n\n.list-item img {\n  aspect-ratio: 3/2;\n  object-fit: contain;\n  width: 100%;\n  border-radius: var(--border-radius-half);\n}\n\n.list-item .list-item-name {\n  text-transform: capitalize;\n}\n\n.list-item .icon-container span {\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
