import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import "./layout.css";

const AuthFormLayout = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <div>
          <Navbar />
        </div>
        <div
          style={{
            flex: 1,
            overflowY: "auto",
            padding: "3rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-md-9">{props.children}</div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AuthFormLayout;
