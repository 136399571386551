import React, { useEffect, useRef } from "react";
import Message from "./Message";
import { getMessages } from "../../utils/queries/chatQuery";
import { useChat } from "../../hooks/useChat";

const MessageThread = () => {
  const messagesEndRef = useRef(null);
  const { selectedConversation, setMessages, messages } = useChat();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);

  useEffect(() => {
    const getConversationMessages = async () => {
      const data = await getMessages({
        conversationId: selectedConversation._id,
      });
      setMessages(data);
    };
    getConversationMessages();
  }, [selectedConversation]);

  return (
    <div className="p-3 overflow-auto flex-grow-1">
      {messages.map((message) => (
        <Message key={message._id} message={message} />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageThread;
