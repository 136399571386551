import React from "react";

import { Splash } from "../../components/Splash";
import { connect } from "react-redux";
import { Footer } from "../../components/Footer";

class SplashScreen extends React.Component {
  state = {
    error: "",
  };

  componentDidMount = async () => {
    setTimeout(() => {
      window.location.href = "/landing";
    }, 3000);
  };

  render() {
    return (
      <>
        <Splash />
        <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SplashScreen);
