// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--text-color-5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--text-color-10);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--text-color-60);
}

::-webkit-scrollbar:hover {
  height: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/scrollbar.styles.css"],"names":[],"mappings":"AAAA,UAAU;AACV;EACE,UAAU;EACV,WAAW;AACb;;AAEA,UAAU;AACV;EACE,+BAA+B;AACjC;;AAEA,WAAW;AACX;EACE,gCAAgC;AAClC;;AAEA,oBAAoB;AACpB;EACE,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* width */\n::-webkit-scrollbar {\n  width: 5px;\n  height: 8px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: var(--text-color-5);\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: var(--text-color-10);\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: var(--text-color-60);\n}\n\n::-webkit-scrollbar:hover {\n  height: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
