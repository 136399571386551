// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features-container {
  display: flex;
  padding-top: 5rem;
  background-color: var(--primary-color-5);
  min-height: 90vh;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/Features/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,wCAAwC;EACxC,gBAAgB;AAClB","sourcesContent":[".features-container {\n  display: flex;\n  padding-top: 5rem;\n  background-color: var(--primary-color-5);\n  min-height: 90vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
