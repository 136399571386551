import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import surveyQuery from "../../utils/queries/surveysQuery";
import handleSearch from "../../utils/handleSearch";
import { getStorage } from "../../utils/storage";
import dynamicTableData from "../../utils/dynamicTableData";
import CardCount from "../CardCount/CardCount";
import { fetchCounts } from "../../utils/queries/analyticsQuery";
import moment from "moment";
import formateFilters from "../../utils/formateFilters";
import handleFormateDataCSV from "../../utils/handleFormateDataCSV";
import icons from "../../constants/icons";
import LocationFilter from "../Filters/LocationFilter";
import DateFilter from "../Filters/DateFilter";
import { Modal } from "../Modal";
import toastMessage from "../../utils/toastMessage";
import capitalizeText from "../../utils/capitalizeText";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import ProvinceFilter from "../Filters/ProvinceFilter";
import AdvancedFilter from "../Filters/AdvancedFilter";
import DistrictFilter from "../Filters/DistrictFilter";
import SectorFilter from "../Filters/SectorFilter";

let copyData = [];

// const cardCounts = [
//   {
//     title: "Not Started",
//     fieldName: "totalNotStarted",
//     icon: "schedule",
//   },
//   {
//     title: "In Progress",
//     fieldName: "totalInProgress",
//     icon: "autorenew",
//   },
//   {
//     title: "Completed",
//     fieldName: "totalCompleted",
//     icon: "check",
//   },
//   {
//     title: "Suspended",
//     fieldName: "totalSuspended",
//     icon: "delete",
//   },
// ];

class Impacts extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    error: {},
    csvData: [],
    user: {},
    totalPageCount: 0,
    tableHeaders: [],
    service_id: "",
  };

  componentDidMount = async () => {
    this.handleFetchData();
  };

  handleFetchData = async () => {
    const user = await getStorage();
    const { id } = this.props;
    this.setState({ user });

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.handleFetchData();
    }

    if (this.props.filters !== prevProps.filters) {
      this.handleFetchData();
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target.value });
  }

  returnFilters() {
    const { page, limit } = this.state;

    const { filters, id, type } = this.props;
    let _filters = {
      page,
      limit,
      ...this.props.urlFilters,
      ...formateFilters(filters),
      ["service.type"]: "IMPACT",
      ["service.name"]: this.props.category,
    };

    return _filters;
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await surveyQuery(
        defaultLanguage,
        this.returnFilters()
      );

      this.setState({
        service_id: data[0]?.service?._id,
        data,
        totalPageCount: count,
        isLoading: false,
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
      });

      copyData = rowData.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCounts = async (isLoading, params = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ [`is_${params.fieldName}`]: isLoading });

      const { data } = await fetchCounts(defaultLanguage, {
        ...this.returnFilters(),
        ...params,
      });

      let count = 0;

      if (data.length > 0) {
        count = data[0].count;
      }

      this.setState({
        [params.fieldName]: count,
        [`is_${params.fieldName}`]: false,
      });
    } catch (error) {
      this.setState({
        [`is_${params.fieldName}`]: false,
        [params.fieldName]: 0,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isExporting: true,
      });

      const { defaultLanguage } = this.props;
      const { limit } = this.state;

      let uniqueNumbers = [];

      const { data } = await surveyQuery(defaultLanguage, {
        ["service._id"]: this.state.service_id,
        limit,
        ["service.type"]: "IMPACT",
        ["service.name"]: this.props.category,
        ...this.returnFilters(),
      });

      data.forEach((el) => {
        el.questionnaireAnswer.forEach((elAnswer) => {
          if (
            elAnswer.questionText.toLowerCase().includes("unique") &&
            !uniqueNumbers.includes(elAnswer.answer)
          ) {
            uniqueNumbers.push(elAnswer.answer);
          }
        });
      });

      const response = await surveyQuery(defaultLanguage, {
        ["questionnaireAnswer.answer"]: uniqueNumbers,
        ["service._id"]: this.state.service_id,
        limit,
        ["service.type"]: "IMPACT",
        ["service.name"]: this.props.category,
      });

      let joinedQuestionnaire = [];

      response.data.reverse().forEach((dataEl) => {
        joinedQuestionnaire = joinedQuestionnaire.concat(
          dataEl.questionnaireAnswer
        );
      });

      let groupData = {},
        finalData = [];

      uniqueNumbers.forEach((uEl) => {
        let duplicatedIds = [];

        joinedQuestionnaire.forEach((el) => {
          if ( el.questionText.toLowerCase().includes("unique") && el.answer === uEl) {
            duplicatedIds.push(el.duplicateId);
          }
        });

        duplicatedIds.forEach((duplicateId) => {
          joinedQuestionnaire.forEach((el) => {
            if (el.duplicateId === duplicateId) {
              if (!groupData[uEl]) {
                groupData[uEl] = [];
              }

              groupData[uEl].push(el);
            }
          });
        });

        let responseObject = {};

        groupData[uEl].forEach((el) => {
          responseObject[el.questionText] = el.answer;
        });

        finalData.push(responseObject);
      });

      if (finalData.length === 0) {
        this.setState({
          isExporting: false,
        });

        return toastMessage("error", "No survey information available");
      }

      this.setState(
        {
          isExporting: false,
          csvData: finalData,
        },
        () => this.refs.csvDownload?.link.click()
      );
    } catch (error) {
      this.setState({
        isExporting: false,
      });
    }
  };

  renderItemPreview(data) {
    return Object.keys(data).map((key, i) => {
      return (
        <li key={i} className="list-group-item d-flex gap-2">
          <span style={{ flex: 1 }}>{key}</span>
          <span
            className={key === "Status" ? "badge badge-default" : ""}
            style={{ maxWidth: 200 }}
          >
            <b>{data[key].value}</b>
          </span>
        </li>
      );
    });
  }

  handlePressSearch = async () => {
    try {
      const { search_text } = this.state;
      const { defaultLanguage } = this.props;

      if (search_text === "")
        return toastMessage("error", "Search text is required");

      this.setState({ isLoading: true });

      const { data, count } = await surveyQuery(defaultLanguage, {
        ["questionnaireAnswer.answer"]: [
          search_text,
          search_text.toUpperCase(),
          capitalizeText(search_text),
        ],
        ...this.returnFilters(),
      });

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      this.setState({
        data: rowData,
        tableHeaders,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = rowData.slice(0);
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { export_ } = language[this.props.defaultLanguage];
    return (
      <>
        <div className="d-flex gap-2 mb-2">
          <ProvinceFilter />
          <DistrictFilter />
          <SectorFilter /> 
          <DateFilter />
          <AdvancedFilter isLoading={this.state.isLoading} headers={this.state.tableHeaders} />
        </div>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          addSearchButton
          handlePressSearch={this.handlePressSearch.bind(this)}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.state.tableHeaders}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
        filters={[
          {
            type: "export",
            title: language[this.props.defaultLanguage].export_,
            button_type: "dropdown",
            icon: "download",
            options: [
              {
                name: "CSV",
                onPress: () => this.handleShowModal("showExport"),
              },
            ],
          },
        ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state?.selectedData?.name}
          showHeaderBottomBorder={false}
        >
          <div className="card-body">
            <div>{this.renderItemPreview(this.state.selectedData)}</div>
          </div>
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showExport")}
          show={this.state.showExport}
          title={this.state.modalTitle}
        >
          <div className="card-body">
            <Input
              label={language[this.props.defaultLanguage].limit}
              value={this.state.limit}
              onChange={(e) => this.onChangeText("limit", e)}
            />
            <Button
              text={language[this.props.defaultLanguage].export_}
              isSubmitting={this.state.isExporting}
              onPress={this.downloadExcel.bind(this)}
            />
          </div>
        </Modal>

        <CSVLink
          ref="csvDownload"
          filename={this.props.category + " - " + moment().format("lll")}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Impacts);
