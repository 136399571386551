//This function search all the elements in the document
export default (
  that,
  { searchKey, copyData, value, stateFieldName = "data" }
) => {
  that.setState({ search_text: value });

  let data = [];

  for (let el of copyData) {
    if (searchKey) {
      if (el[searchKey].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        data.push(el);
      }
    } else {
      if (
        JSON.stringify(el).toLowerCase().indexOf(value.toLowerCase()) !== -1
      ) {
        data.push(el);
      }
    }
  }

  that.setState({ [stateFieldName]: data });
};
