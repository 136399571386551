import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  ADD_BULK_NOTIFICATIONS,
} from "../actions/Notification";

const KEY_NAME = "NOTIFICATIONS";

const getStorage = (key) => {
  let storage = sessionStorage.getItem(key);

  return storage ? JSON.parse(storage) : [];
};

const setLocalStorage = (value) => {
  sessionStorage.setItem(KEY_NAME, JSON.stringify(value));
};

let notifications = getStorage(KEY_NAME) ? getStorage(KEY_NAME) : [];

const initialState = {
  notifications,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      let notificationIDArray = [];
      if (state.notifications.length > 0) {
        for (let notificationEl of state.notifications) {
          notificationIDArray.push(notificationEl.notificationID);
        }
      } else {
        notificationIDArray.push(action.notificationID);
      }

      if (
        action.payload &&
        !notificationIDArray.includes(action.payload.notificationID)
      ) {
        state.notifications.push(action.payload);
      }

      setLocalStorage([...state.notifications]);
      return {
        ...state,
        notifications: [...state.notifications],
      };
    case REMOVE_NOTIFICATION:
      for (let [index, notificationEl] of state.notifications.entries()) {
        if (
          notificationEl.notificationID === action.notificationID ||
          notificationEl.bodyID === action.notificationID
        ) {
          state.notifications.splice(index, 1);
        }
      }
      setLocalStorage([...state.notifications]);
      return {
        ...state,
        notifications: [...state.notifications],
      };
    case ADD_BULK_NOTIFICATIONS:
      state.notifications.forEach((stateNotificationId) => {
        const findNotification = action.notifications.find(
          (nfEl) =>
            (nfEl?._id || nfEl.id || nfEl) ===
            stateNotificationId.notificationID
        );

        if (!findNotification) {
          state.notifications.push(stateNotificationId);
        }
      });
      setLocalStorage([...state.notifications]);
      return {
        ...state,
        notifications: [...state.notifications],
      };
    default:
      return state;
  }
};
export default reducer;
