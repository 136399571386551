import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import surveyQuery from "../../utils/queries/surveysQuery";
import handleSearch from "../../utils/handleSearch";
import { getStorage } from "../../utils/storage";
import { onResetFilters } from "../../actions/Filters";
import dynamicTableData from "../../utils/dynamicTableData";
import { DeleteModal, Modal } from "../Modal";
import SurveyInfo from "./SurveyInfo";
import icons from "../../constants/icons";
import toastMessage from "../../utils/toastMessage";
import { apiInstance } from "../../utils/axiosInstance";
import { defineRole } from "../../utils/handleAccessRoles";
import { CSVLink } from "react-csv";
import moment from "moment";
import formateFilters from "../../utils/formateFilters";
import handleFormateDataCSV from "../../utils/handleFormateDataCSV";
import { Loading } from "../Loader";

let copyData = [];
class Surveys extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      page: 1,
      limit: 10,
      search_text: "",
      delete_value: "",
      error: {},
      csvData: [],
      user: {},
      tableHeaders: [],
      surveyAnswers: [],
      selectedAnswer: {},
      selectedData: {},
      serviceInfo: {},
      isImporting: false,
    };

    this.upload = React.createRef();
  }

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user }, () => {
      this.getData(true);
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.routeCategory !== this.props.routeCategory) {
      //reset filters
      this.props.dispatch(onResetFilters());

      this.getData(true);
    }

    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  returnFilters() {
    const { page, limit, user } = this.state;
    const { serviceId, surveyId, filters } = this.props;

    let _filters = {
      page,
      limit,
      ["service._id"]: [serviceId],
      // id: surveyId,
      ...formateFilters(filters),
      // user: [user.id],
    };

    // if (surveyId) {
    //   delete _filters["service._id"];
    // }

    return _filters;
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const formateF = this.returnFilters();

      delete formateF.location;

      const { data, count } = await surveyQuery(defaultLanguage, formateF);

      const { tableHeaders, rowData } = dynamicTableData(this, data);

      tableHeaders.push({
        title: language[this.props.defaultLanguage].actions,
        key: "multiple-actions",
        actions: [
          {
            icon: icons.eye_on,
            title: language[this.props.defaultLanguage].edit,
            onPress: (item, dataIndex) =>
              this.handleShowModal("showInfoModal", item.questionText, {
                ...item,
                dataIndex,
              }),
          },
          {
            icon: icons.delete,
            className: "text-danger",
            showNotificationCount: true,
            user: this.state.user.id,
            onPress: (item, dataIndex) =>
              this.handleShowModal("showDeleteModal", item.questionText, {
                ...Object.values(item)[0],
                dataIndex,
              }),
          },
        ],
      });

      this.setState({
        data: rowData,
        tableHeaders,
        totalPageCount: count,
        isLoading: false,
        surveyAnswers: data.length > 0 ? data[0].questionnaireAnswer : [],
        serviceInfo: data.length > 0 ? data[0] : {},
      });

      copyData = rowData?.slice(0) || [];
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}, answerIndex) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
      answerIndex,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSelectCategory(item) {
    this.setState(
      {
        selectedCategory: item,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData) {
    this.setState({ [modal]: true, selectedData, modalTitle });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  handleTableLinkClick(selectedData, headerTitle) {
    this.handleShowModal("showMoreInfoModal", headerTitle, selectedData);
  }

  renderMoreAnswerInfo(data) {
    try {
      if (Array.isArray(data)) {
        return (
          <div className="card-body">
            <ul className="list-group">
              {data.map((el, i) => {
                if (el.label && el.value) {
                  return (
                    <li key={i} className="list-group-item">
                      <span>{el.label}</span>
                    </li>
                  );
                } else {
                  return Object.keys(el).map((elKey) => {
                    return (
                      <li key={elKey} className="list-group-item">
                        <span>{el[elKey]}</span>
                      </li>
                    );
                  });
                }
              })}
            </ul>
          </div>
        );
      } else if (typeof data === "object") {
        if (data.label === data.value)
          return (
            <li className="list-group-item">
              <div className="d-flex gap-2">
                <span className="text-bold">{data.value}</span>
              </div>
            </li>
          );
        return (
          <>
            {Object.keys(data).map((obElKey) => {
              if (typeof data[obElKey] === "object") return;

              return (
                <div className="d-flex gap-2 list-group-item">
                  <span style={{ flex: 1, textTransform: "capitalize" }}>
                    {obElKey}
                  </span>
                  <span className="text-bold">{data[obElKey]}</span>
                </div>
              );
            })}
          </>
        );
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      let { selectedData } = this.state;

      await this.handleDeleteSurveyData(selectedData._id, "DELETE");

      this.getData(true);

      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleDeleteSurveyData = async (id, method) => {
    try {
      const user = await getStorage();

      const options = {
        method,
        url: `/survey`,
        data: {
          id,
        },
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      await apiInstance(options);

      this.setState({
        isDeleting: false,
      });

      toastMessage(
        "success",
        language[this.props.defaultLanguage].delete_success
      );
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
      console.log(error);
      toastMessage(
        "error",
        language[this.props.defaultLanguage].delete_data_failed +
        ": " +
        JSON.stringify(error?.response?.data) || error
      );
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const { data } = await surveyQuery(defaultLanguage, this.returnFilters());

      const { tableHeaders } = dynamicTableData(this, data);

      let formateData = [];
      let headers = tableHeaders.map((el) => el.title);

      data.forEach((el) => {
        formateData.push(handleFormateDataCSV(el.questionnaireAnswer, headers));
      });

      this.setState(
        {
          isLoading: false,
          csvData: [headers, ...formateData],
        },
        () => this.refs.csvDownload?.link.click()
      );
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleImportData = async (e) => {
    try {
      this.setState({
        isImporting: true,
      });

      const file = e.target.files[0];
      const user = await getStorage();

      let headersList = {
        Accept: "/",
        Authorization: `Bearer ${user.token}`,
      };

      let bodyContent = new FormData();
      bodyContent.append("category", this.props.category);
      bodyContent.append("service", this.props.serviceId);
      bodyContent.append("user", user.id);
      bodyContent.append("description", this.props.serviceName);
      bodyContent.append("file", file);

      const options = {
        method: "POST",
        url: `/survey/import`,
        data: bodyContent,
        headers: headersList,
      };

      await apiInstance(options);

      toastMessage("success", "Data uploaded successfully");

      this.getData(true);

      this.setState({
        isImporting: false,
      });
    } catch (error) {
      this.setState({
        isImporting: false,
      });

      toastMessage("error", error);
    }
  };

  deleteSurveyAllData = async () => {
    try {
      const user = await getStorage();
      const options = {
        method: "DELETE",
        url: `/survey/deleteByService`,
        data: {
          service: this.props.serviceId,
        },
        headers: {
          authorization: "Bearer " + user.token,
        },
      };
      await apiInstance(options);
      this.setState({
        isDeleting: false,
      });
      toastMessage(
        "success",
        language[this.props.defaultLanguage].delete_success
      );
      this.getData(true);

      this.handleCloseModal("showDeleteAllModal");

    } catch (error) {
      this.setState({
        isDeleting: false,
      });
      console.log(error);
      toastMessage(
        "error",
        language[this.props.defaultLanguage].delete_data_failed +
        ": " +
        JSON.stringify(error?.response?.data) || error
      );
    }
  };




  render() {
    return (
      <>
        {this.state.isImporting && (
          <>
            <Loading />
            <span>Importing....</span>
          </>
        )}
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.state.tableHeaders}
          showAdd={this.props.showAdd}
          addButtonText={language[this.props.defaultLanguage].new_entry}
          handleAddPressed={() =>
            (window.location.href = `/user/services/${this.props.serviceId}/${this.props.serviceName}`)
          }
          rowPress={(item, dataIndex) =>
            this.handleShowModal("showInfoModal", item.questionText, {
              ...item,
              dataIndex,
            })
          }
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          filters={[
            {
              title: language[this.props.defaultLanguage].date_range,
              button_type: "dropdown",
              icon: icons.date,
              filterName: "date_range",
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "Surveys",
                operation: "read",
              }),
              type: "button",
              title: language[this.props.defaultLanguage].import_data,
              button_type: "button",
              icon: "upload_file",
              className: "btn-transparent btn-bordered",
              // onPress: this.handleImportData.bind(this),
              onPress: () => this.upload.click(),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "Surveys",
                operation: "read",
              }),
              type: "export",
              title: language[this.props.defaultLanguage].export_,
              button_type: "dropdown",
              icon: "download",
              options: [
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "Surveys",
                operation: "delete",
              }),
              type: "delete",
              title: language[this.props.defaultLanguage].delete,
              button_type: "button",
              icon: "delete",
              className: "btn-transparent btn-bordered",
              onPress: () =>
                this.handleShowModal("showDeleteAllModal",language[this.props.defaultLanguage].deleteAllSurveyData,""),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showInfoModal")}
          show={this.state.showInfoModal}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <div>
            <SurveyInfo
              selectedAnswer={this.state.selectedData}
              surveyAnswers={this.state.surveyAnswers}
              getData={this.getData.bind(this, true)}
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showInfoModal"
              )}
              surveyId={this.props.surveyId}
              answerIndex={this.state.answerIndex}
            />
          </div>
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showMoreInfoModal")}
          show={this.state.showMoreInfoModal}
          title={this.state.modalTitle}
        >
          <div>{this.renderMoreAnswerInfo(this.state.selectedData)}</div>
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData._id}
            getData={this.getData.bind(this)}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showDeleteAllModal"
          )}
          show={this.state.showDeleteAllModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteAllModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.deleteSurveyAllData.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.props.serviceId}
            getData={this.getData.bind(this)}
          />
        </Modal>

        <CSVLink
          ref="csvDownload"
          filename={this.props.serviceName + " - " + moment().format("lll")}
          data={this.state.csvData}
        ></CSVLink>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={this.handleImportData.bind(this)}
          ref={(ref) => (this.upload = ref)}
          style={{ display: "none" }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Surveys);
