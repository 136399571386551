import React from "react";
import { KPIs } from "../../components/KPI";
import { withRouter } from "../../utils/withRouter";
import { DashboardHeader } from "../../components/Header";
import capitalizeText from "../../utils/capitalizeText";
import DateFilter from "../../components/Filters/DateFilter";
import ServiceFilter from "../../components/Filters/ServiceFilter";
import LocationFilter from "../../components/Filters/LocationFilter";
import { Tabs, Tab } from "react-bootstrap";
import DistrictFilter from "../../components/Filters/DistrictFilter";
import ProvinceFilter from "../../components/Filters/ProvinceFilter";
import SectorFilter from "../../components/Filters/SectorFilter";
import AdvancedFilter from "../../components/Filters/AdvancedFilter";
import Impacts from "../../components/Impact/Impacts";

class ImpactsScreen extends React.Component {



    render() {
        return (
            <>
                <h1 className="mt-4">Impacts</h1>
                <div>
                    <Tabs>
                        <Tab eventKey="FSC" title="FSC">
                            <Impacts {...this.props} category="FSC" />
                        </Tab>
                        <Tab eventKey="young-farmers" title="Young Farmers">
                            <Impacts {...this.props} category="Young Farmers" />
                        </Tab>
                    </Tabs>

                </div>
            </>
        );
    }
}

export default withRouter(ImpactsScreen);
