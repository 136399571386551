import React from "react";
import { DashboardHeader } from "../../Header";
import language from "../../../language";
import { connect } from "react-redux";
import ServiceQuestionnaire from "./ServiceQuestionnaire";
import Surveys from "./SurveysRA";

class ContainerComponent extends React.Component {
  state = {
    refetch: false,
  };
  setFetching = (refetching) => {
    this.setState({ refetch: refetching });
  };
  render() {
    return (
      <div>
        <DashboardHeader title={this.props.title} />
        <div className="row" style={{ marginTop: "-3rem" }}>
          <div className="col-md-6">
            <ServiceQuestionnaire
              serviceId={this.props.serviceId}
              setRefetching={this.setFetching}
            />
          </div>
        </div>
        <DashboardHeader
          title={language[this.props.defaultLanguage].log_and_history}
        />
        <Surveys
          serviceId={this.props.serviceId}
          role={this.props.role}
          refetch={this.state.refetch}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ContainerComponent);
