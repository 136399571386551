import React from "react";
import { DashboardHeader } from "../../components/Header";
import { Services } from "../../components/Services";
import language from "../../language";
import { connect } from "react-redux";

class SurveysScreen extends React.Component {
  render() {
    return (
      <>
        <DashboardHeader
          title={language[this.props.defaultLanguage].surveys}
          description={
            language[this.props.defaultLanguage].choose_survey_description
          }
        />
        <Services role={this.props.role} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SurveysScreen);
