import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_API;
// "https://faaf-2c0f-eb68-640-ee00-74bd-e830-f80f-72ad.ngrok-free.app/api/access";

export const apiInstance = axios.create({
  baseURL,
  headers: {
    // "Content-Type": "application/json",
    // "Access-Control-Allow-Origin":
    //   "https://faaf-2c0f-eb68-640-ee00-74bd-e830-f80f-72ad.ngrok-free.app",
    // "ngrok-skip-browser-warning": "any",
  },
});
