// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import "./styles.css";
import { CardHeader } from "../Header";
import { Loading } from "../Loader";

// ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  legend: {
    position: "right",
    display: true,
    labels: {
      usePointStyle: true,
      boxWidth: 6,
    },
  },
  maintainAspectRatio: true,
  responsive: true,
};

const DoughnutChart = (props) => {
  return (
    <div className={`chart-container ${props.className}`}>
      {props.title && <CardHeader title={props.title} amount={props.amount} />}
      {props.isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 200,
          }}
        >
          <Loading />
        </div>
      ) : props?.data?.labels?.length > 0 ? (
        <div className="doughnut-chart">
          <div className="chart">
            <Doughnut data={props.data} options={options} />
          </div>
        </div>
      ) : (
        <div className="empty-chart">
          <span>No Data</span>
        </div>
      )}
    </div>
  );
};

export default DoughnutChart;

export const SimpleDoughnutChart = ({
  chartTitle = "Doughnut Chart",
  chartData,
  loading = false,
  showLegend = true, // Controls legend visibility
  legendPosition = "bottom",
  showTitle = true, // Controls title visibility
}) => {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: showLegend, // Dynamically control legend visibility
        position: legendPosition,
      },
      title: {
        display: showTitle, // Dynamically control title visibility
        text: chartTitle,
      },
    },
  };

  return (
    <>
      {!loading && !!chartData?.labels ? (
        <div>
          <Doughnut data={chartData} options={options} />
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export const DashboardPieChart = ({
  pieTitle = "Pie Title",
  chartData,
  loading = false,
  hideTitle = false,
  legendPosition = "bottom",
}) => {
  return (
    <>
      {!loading && !!chartData?.labels ? (
        <Pie
          redraw={true}
          options={{
            responsive: true,
            legend: {
              position: legendPosition,
              display: true,
              labels: {
                usePointStyle: true,
                boxWidth: 6,
              },
            },
            title: {
              display: hideTitle,
              text: pieTitle,
            },
            // plugins: {
            // },
          }}
          data={chartData}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export const DashboardDoughnutChart = ({
  chartTitle = "",
  chartData,
  loading = false,
  hideTitle = false,
  legendPosition = "bottom",
}) => {
  return (
    <>
      {!loading && !!chartData?.labels ? (
        <Doughnut
          options={{
            responsive: true,
            maintainAspectRatio: true,
            legend: {
              position: legendPosition,
              display: true,
              labels: {
                usePointStyle: true,
                boxWidth: 6,
              },
            },
            title: {
              display: hideTitle,
              text: chartTitle,
            },
            // plugins: {},
          }}
          data={chartData}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export const DashboardLineChart = ({
  chartTitle = "Line chart variations",
  chartData,
  loading = false,
  hideTitle = false,
  legendPosition = "bottom",
}) => {
  return (
    <>
      {!loading && !!chartData?.labels ? (
        <Line
          redraw={true}
          options={{
            responsive: true,
            // maintainAspectRatio: false,
            legend: {
              position: legendPosition,
              display: true,
              labels: {
                usePointStyle: true,
                boxWidth: 6,
              },
            },
            title: {
              display: hideTitle,
              text: chartTitle,
            },
            // plugins: {
            // },
            interaction: {
              mode: "index",
              intersect: false,
            },
          }}
          data={chartData}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export const DashboardBarChart = ({
  chartTitle = "",
  chartData,
  loading = false,
  isStackedChart = false,
  isHorizontalBar = false,
  hideTitle = false,
  legendPosition = "bottom",
}) => {
  return (
    <>
      {!!loading ? (
        <Loading />
      ) : !!chartData?.labels ? (
        <Bar
          redraw={true}
          options={{
            indexAxis: isHorizontalBar ? "y" : undefined,
            elements: {
              bar: {
                borderWidth: isHorizontalBar ? 2 : undefined,
              },
            },
            responsive: true,
            legend: {
              position: legendPosition,
              display: true,
              labels: {
                usePointStyle: true,
                boxWidth: 6,
              },
            },
            title: {
              display: hideTitle,
              text: chartTitle,
            },
            // plugins: {
            // },

            scales: {
              xAxes: {
                stacked: isStackedChart,
              },
              yAxes: {
                stacked: isStackedChart,
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    return value.toLocaleString();
                  },
                },
              },
            },

            interaction: {
              mode: "index",
              intersect: false,
            },
          }}
          data={chartData}
        />
      ) : (
        <div className="text-center my-3">No Data Found</div>
      )}
    </>
  );
};
