import React from "react";
import "./styles.css";
import Wrapper from "./Wrapper";
import TableContainer from "./TableContainer";

const Table = (props) => {
  const { data } = props;

  return (
    <>
      <Wrapper data={data} {...props}>
        <table
          className={`table table-hover table-condensed ${
            props.bordered && "table-bordered"
          }`}
          style={props.style}
        >
          <TableContainer {...props} />
        </table>
      </Wrapper>
    </>
  );
};

export default Table;
