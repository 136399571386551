import React from "react";
import { connect } from "react-redux";
import { GeographicalMapping } from "../../../components/Geographical";

class GeographicalNavigationScreen extends React.Component {
  render() {
    return (
      <>
        <GeographicalMapping />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(GeographicalNavigationScreen);
