import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { jwtDecode } from "jwt-decode";
import reducers from "../reducers";

const middleware = [];
const STORAGE_NAME = process.env.REACT_APP_NAME;

if (process.env.REACT_APP_NODE_ENV === "development") {
  middleware.push(logger);
}

const checkTokenExpiration = (store) => (next) => (action) => {
  const token =
    JSON.parse(sessionStorage.getItem(STORAGE_NAME)) &&
    JSON.parse(sessionStorage.getItem(STORAGE_NAME))["token"];

  if (token && jwtDecode(token).exp < Date.now() / 1000) {
    next(action);
    sessionStorage.clear();
    window.location.href = "/login";
  }
  next(action);
};

const store = createStore(
  reducers,
  applyMiddleware(...middleware, checkTokenExpiration)
);

export default store;
