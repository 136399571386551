import { combineReducers } from "redux";
import Language from "./Language";
import Notifications from "./Notifications";
import Filters from "./Filters";
import Sidebar from "./Sidebar";

const appReducer = combineReducers({
  Language,
  Notifications,
  Filters,
  Sidebar,
});

const rootReducer = (state, action) => {
  if (action.type === "ON_USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
