import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { Loading } from "../Loader";
import { Button } from "../Button";
import icons from "../../constants/icons";

class Input extends React.Component {
  state = {
    showPassword: false,
  };

  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  render() {
    const inputProps = {
      type:
        this.props.type === "password"
          ? !this.state.showPassword
            ? "password"
            : "text"
          : this.props.type,
      value: this.props.value,
      onChange: this.props.onChange,
      placeholder: this.props.placeholder,
      disabled: this.props.disabled,
      className: `form-control ${this.props.className}`,
      maxLength: this.props.maxLength,
      onInput: (e) => {
        e.target.value = e.target.value.slice(0, this.props.maxLength);

        if (!this.props.textarea) return;
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
      },
      multiline: this.props.multiline,
      onFocus: this.props.onFocus,
      min: this.props.min || 1,
      max: this.props.max,
      style: this.props.inputStyles,
    };
    return (
      <div
        className={`input-container ${this.props.className}
        ${this.props.disabled && "disabled-input"} ${
          this.props.error && "errored-input"
        } ${this.props.success && "success-input"}`}
        style={this.props.styles}
      >
        {this.props.label && (
          <label
            className="form-label"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ flex: 1 }}>
              {this.props.label}{" "}
              {this.props.required && <span className="error-text">*</span>}
            </div>
            {this.props.labelAction && (
              <div
                style={{ cursor: "pointer" }}
                onClick={this.props.labelActionClicked}
              >
                <span className="text-primary">{this.props.labelAction}</span>
              </div>
            )}
          </label>
        )}

        <div className={`input-content ${this.props.inputClassName}`}>
          {this.props.leftIcon && (
            <div className="left-container">
              <span className={process.env.REACT_APP_ICONS_TYPE}>
                {this.props.leftIcon}
              </span>
            </div>
          )}

          <div className="input-input">
            {this.props.textarea ? (
              <textarea {...inputProps} className="form-control" />
            ) : (
              <input {...inputProps} />
            )}
          </div>
          {this.props.rightText && this.props.rightText !== "" && (
            <div className="right-container">
              <Link className="text-bold" to="#">
                {this.props.rightText}
              </Link>
            </div>
          )}
          {this.props.rightIcon && (
            <div className="right-container">
              <span className={process.env.REACT_APP_ICONS_TYPE}>
                {this.props.rightIcon}
              </span>
            </div>
          )}

          {Object.keys(this?.props?.profileData || {})?.length > 0 && (
            <div className="right-container">
              <span className="material-symbols-outlined icon-primary-color">
                done
              </span>
            </div>
          )}
          {this.props.type === "password" && (
            <div
              className="right-container"
              onClick={this.handleShowPassword.bind(this)}
            >
              <span className={process.env.REACT_APP_ICONS_TYPE}>
                {this.state.showPassword ? icons.eye_on : icons.eye_off}
              </span>
            </div>
          )}
          {this.props.rightButtonIcon && (
            <Button
              className="btn-xsm"
              icon={this.props.rightButtonIcon}
              isSubmitting={this.props.rightButtonSubmitting}
              onPress={this.props.rightButtonPressed}
            />
          )}
          {this.props.rightButtonText && (
            <Button
              className={`btn-xsm ${this.props.rightButtonClassName}`}
              text={this.props.rightButtonText}
              isSubmitting={this.props.rightButtonSubmitting}
              onPress={this.props.rightButtonPressed}
            />
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {this.props.isLoading && <Loading className="primary" />}
          <div>
            {" "}
            {this.props.helperText && (
              <div className="helper-section">
                <p className="helper-text text-disabled">
                  {this.props.helperText}
                </p>
              </div>
            )}
            {this.props.error && (
              <div className="helper-section">
                <p className="error-text">{this.props.error}</p>
              </div>
            )}
            {this.props.success && (
              <div className="helper-section">
                <p className="success-text">{this.props.success}</p>
              </div>
            )}
            {Object.keys(this.props.profileData || {}).length > 0 && (
              <div className="input-profile-data">
                <div>
                  {this.props?.profileData?.firstName && (
                    <span>{this.props.profileData.firstName}</span>
                  )}
                </div>
                <div>
                  {this.props?.profileData?.lastName && (
                    <span>{this.props.profileData.lastName}</span>
                  )}
                </div>
                {this.props.profileData.institution && (
                  <>
                    <span>{this.props.profileData.institution}</span>
                    <br />
                  </>
                )}
                {this.props.profileData.department && (
                  <>
                    <span>{this.props.profileData.department}</span>
                    <br />
                  </>
                )}
                {this.props?.profileData?.position && (
                  <span>{this.props.profileData.position}</span>
                )}
              </div>
            )}
            {this.props.helperLink && (
              <div
                className="helper-link"
                onClick={this.props.onPressHelperLink}
              >
                <Link to="#">{this.props.helperLink}</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Input;
