import React from "react";
import { connect } from "react-redux";
import { Checkbox, Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import toastMessage from "../../../utils/toastMessage";
import serviceCategoryQuery from "../../../utils/queries/serviceCategoryQuery";
import { getStorage } from "../../../utils/storage";
import icons from "../../../constants/icons";
import apiError from "../../../utils/apiError";
import { apiInstance } from "../../../utils/axiosInstance";
import Separator from "../../Communications/Separator";
import { cardCounts } from "../../../constants/cardCounts";

class NewService extends React.Component {
  state = {
    error: {},
    languages: Object.keys(language),
    serviceLanguages: [this.props.defaultLanguage],
    categories: [],
    name: "",
    description: "",
    settings: {
      smsEnabled: false,
    },
  };

  componentDidMount = async () => {
    this.setState({ ...this.props });

    await this.getCategories(true);
  };

  getCategories = async (isFetchingServiceCategories) => {
    try {
      const { routeCategory } = this.props;

      this.setState({
        isFetchingServiceCategories,
      });

      const data = await serviceCategoryQuery(this.props.defaultLanguage, {
        type: "dropdown",
        categoryType: routeCategory,
      });

      this.setDefaultCategory(data);

      this.setState({
        categories: data,
        isFetchingServiceCategories: false,
      });
    } catch (error) {
      this.setState({ isFetchingServiceCategories: false });
    }
  };

  setDefaultCategory(categories) {
    const { category } = this.props;
    if (category) {
      const selectedCategory = categories.find((el) => {
        return el.value === category?._id;
      });

      if (selectedCategory?._id) {
        this.setState({
          category: {
            label: selectedCategory.name,
            value: selectedCategory._id,
            type: selectedCategory.type,
          },
        });
      }
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  validateForm() {
    let { category, error, name } = this.state;

    if (!category) {
      error.category = language[this.props.defaultLanguage].category_required;
    }

    if (name === "") {
      error.name = language[this.props.defaultLanguage].service_name_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      const { category, description, name, _id, settings } = this.state;
      const user = await getStorage();
      const { update_service_success } = language[this.props.defaultLanguage];

      let method = "POST";

      if (
        settings.listOnSidebar &&
        (!settings.menuName || settings.menuName === "")
      ) {
        settings.menuName = name;
      }

      let requiredBody = {
        category: category.value,
        name,
        type: category.type,
        description,
        settings,
      };

      if (_id) {
        method = "PUT";

        requiredBody.id = _id;
      }

      const options = {
        method,
        url: `/service`,
        data: requiredBody,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      apiInstance(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "update_service_success"
                : "add_service_success"
            ]
          );

          if (method === "POST")
            window.location.href = `/dashboard/services/single-view/${data?.data?._id}`;

          if (this.props.handleCloseModal) {
            this.props.handleCloseModal();
          }

          if (this.props.getData) {
            this.props.getData();
          }
        })
        .catch((error) => {
          const { add_service_error, service_exist } =
            language[this.props.defaultLanguage];

          this.setState({
            isSubmitting: false,
          });

          apiError(error, {
            requestMethod: method,
            addErrorMessage: add_service_error,
            updateErrorMessage: update_service_success,
            dataExistErrorMessage: service_exist,
          });
        });
    }
  };

  handleCheckboxChange(field) {
    let { settings } = this.state;

    settings[field] = !this.state.settings[field];
    this.setState({ settings });
  }

  onChangeInputSettings(field, e) {
    let { settings } = this.state;

    settings[field] = e.target ? e.target.value : e;
    this.setState({ settings });
  }

  render() {
    return (
      <div>
        <div className="card-body">
          <Select
            leftIcon={icons.category}
            options={this.state.categories}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].category}
            required
            value={this.state.category}
            onChange={(e) => this.onChangeText("category", e)}
            error={this.state.error.category}
            isLoading={this.state.isFetchingServiceCategories}
          />
          <Input
            leftIcon={icons.service}
            placeholder={
              language[this.props.defaultLanguage].service_name_placeholder
            }
            label={language[this.props.defaultLanguage].service_name}
            required
            value={this.state.name}
            onChange={(e) => this.onChangeText("name", e)}
            error={this.state.error.name}
          />
          <Input
            placeholder={
              language[this.props.defaultLanguage].description_placeholder
            }
            label={language[this.props.defaultLanguage].description}
            required
            value={this.state.description}
            onChange={(e) => this.onChangeText("description", e)}
            error={this.state.error.description}
            textarea
          />
          <Separator title={language[this.props.defaultLanguage].settings} />
          {/* <Checkbox
            label={language[this.props.defaultLanguage].sms_enabled}
            checked={this.state.settings.smsEnabled}
            onCheck={() => this.handleCheckboxChange("smsEnabled")}
          /> */}
          <Checkbox
            label={language[this.props.defaultLanguage].list_on_sidebar}
            checked={this.state.settings.listOnSidebar}
            onCheck={() => this.handleCheckboxChange("listOnSidebar")}
          />
          {this.state.settings.listOnSidebar && (
            <div>
              <Input
                placeholder={
                  language[this.props.defaultLanguage].menu_name_placeholder
                }
                label={language[this.props.defaultLanguage].menu_name}
                value={this.state.settings.menuName}
                onChange={(e) => this.onChangeInputSettings("menuName", e)}
                error={this.state.error.menuName}
              />
              <Input
                placeholder={
                  language[this.props.defaultLanguage].group_placeholder
                }
                label={language[this.props.defaultLanguage].group}
                value={this.state.settings.group}
                onChange={(e) => this.onChangeInputSettings("group", e)}
                error={this.state.error.group}
              />
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .group_position_placeholder
                }
                label={language[this.props.defaultLanguage].group_position}
                value={this.state.settings.groupPosition}
                onChange={(e) => this.onChangeInputSettings("groupPosition", e)}
                error={this.state.error.groupPosition}
              />
              <Input
                placeholder={
                  language[this.props.defaultLanguage].position_placeholder
                }
                label={language[this.props.defaultLanguage].menu_position}
                value={this.state.settings.position}
                onChange={(e) => this.onChangeInputSettings("position", e)}
                error={this.state.error.position}
                type="number"
              />
            </div>
          )}
          {/* <Checkbox
            label={language[this.props.defaultLanguage].charts}
            checked={this.state.settings.listOnSidebar}
            onCheck={() => this.handleCheckboxChange("chartEnabled")}
          /> */}
          <Select
            options={cardCounts}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].card_counts}
            required
            value={this.state.settings.cardCounts}
            onChange={(e) => this.onChangeInputSettings("cardCounts", e)}
            isMulti
          />
          {/* {this.state.settings.chartEnabled && (
            <Select
              options={[
                {
                  label: "Region per gender",
                  value: "regionPerGender",
                },
                {
                  label: "Farmers per gender",
                  value: "farmersPerGender",
                },
                {
                  label: "Youth and Non youth per region",
                  value: "youthNonYouthPerRegion",
                },
                {
                  label: "Total counts per region",
                  value: "totalCountsPerRegion",
                },
              ]}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].available_charts}
              required
              value={this.state.settings.charts}
              onChange={(e) => this.onChangeInputSettings("charts", e)}
              isMulti
            />
          )} */}
        </div>
        <div className="modal-footer gap-2">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewService);
