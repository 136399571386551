import { connect } from "react-redux";
import { ParagraphOne, ParagraphTwo, TitleLoader } from "../Loader";
import "./styles.css";
import language from "../../language";
import { Button } from "../Button";
import icons from "../../constants/icons";

const Header = (props) => {
  return (
    <div className="dashboard-header-container">
      {props.showBack && (
        <div className="icon-container" onClick={props.goBack}>
          <span className={process.env.REACT_APP_ICONS_TYPE}>
            {icons.arrowLeft}
          </span>
        </div>
      )}
      <div className="left-container">
        {props.isLoading ? (
          <TitleLoader />
        ) : (
          <>
            {props.size === "md" ? (
              <h3 className="text-capitalized text-bold">
                {props.name || props.title}
              </h3>
            ) : (
              <h2 className="text-capitalized text-bold">
                {props.name || props.title}
              </h2>
            )}
            {props.description && <p>{props.description} </p>}
          </>
        )}
        <div className="dashboard-header-info-container">
          {props.isLoading ? (
            <ParagraphOne />
          ) : (
            props.category && (
              <div className="d-flex">
                <div className="dashboard-header-info-item bg">
                  <span>{props?.category?.name || props.category}</span>
                </div>
              </div>
            )
          )}

          {!props.isLoading && props.isEdit && (
            <div className="dashboard-header-info-item">
              <Button
                icon="edit"
                text="Edit"
                className="btn-transparent primary btn-sm"
                onPress={props.onPressEdit}
              />
            </div>
          )}
        </div>
      </div>
      <div className="right-container">
        {props.status && (
          <div className={`badge badge-lg badge-${props.status?.toLowerCase()}`}>
            {props.status}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Header);
