import React from 'react';
import { Check, CheckCheck } from 'lucide-react';

const MessageStatus = ({ status }) => {
  if (status === 'read') {
    return <CheckCheck size={16} className="text-white-50" />;
  }
  if (status === 'delivered') {
    return <Check size={16} className="text-white-50" />;
  }
  return <Check size={16} className="text-white-50" />;
};

export default MessageStatus;
