import React from "react";
import Avatar from "./Avatar";
import MessageStatus from "./MessageStatus";
import { formatTimestamp } from "../../utils/chatHelpers";

const Message = ({ message }) => (
  <div
    className={`d-flex mb-3 ${
      message.isOwn ? "justify-content-end" : "justify-content-start"
    }`}
  >
    {!message.isOwn && (
      <Avatar
        // initials={`${message.sender.firstName[0]}${
        //   (message.sender.lastName && message.sender.lastName[0]) ?? "D"
        // }`}
        initials={`JD`}
        size={32}
        className="me-2"
      />
    )}
    <div
      className={`p-3 rounded-4 shadow-sm ${
        message.isOwn ? "bg-primary text-white" : "bg-light text-dark"
      }`}
      style={{
        maxWidth: "70%",
        fontSize: "0.9rem",
        lineHeight: "1.4",
        borderRadius: message.isOwn ? "20px 20px 0 20px" : "20px 20px 20px 0",
      }}
    >
      <p
        className={`mb-1 ${message.isOwn && "text-white"}`}
        style={{ fontWeight: 500 }}
      >
        {message.text}
      </p>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ fontSize: "0.75rem", opacity: 0.7 }}
      >
        <small>{formatTimestamp(message.timestamp)}</small>
        {message.isOwn && message.status && (
          <MessageStatus status={message.status} />
        )}
      </div>
    </div>
  </div>
);

export default Message;
