import language from "../language";

export default (lng = "english") => [
  {
    label: language[lng].employee_id,
    value: "ippisNumber",
    field: "ippisNumber",
    name: "ippis_number",
    type: "number",
  },
  // {
  //   label: language[lng].national_id,
  //   value: "nationalId",
  //   field: "nationalId",
  //   name: "national_id",
  //   type: "number",
  //   maxLength: process.env.REACT_APP_NATIONAL_ID_LENGTH,
  // },
  // UNCOMMENT IF PASSPORT GET INFO API IS AVAILABLE
  // {
  //   label: language[lng].passport_number,
  //   value: "passportNumber",
  //   name: "passport_number",
  //   type: "string",
  //   maxLength: process.env.REACT_APP_PASSPORT_NUMBER_LENGTH,
  // },
];
