import React from "react";
import { DropdownButton } from "../Button";

import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import language from "../../language";
import { Checkbox } from "../Input";
import { onAddFilter } from "../../actions/Filters";
import locations from "../../constants/locations";

let copyData = [];

class LocationFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      locations: locations(props.defaultLanguage),
    };
  }

  componentDidMount() {
    this.props.dispatch(
      onAddFilter({
        location: this.state.locations[0],
      })
    );
  }

  onCheck(el) {
    let { filters } = this.props;
    let { location = [] } = filters;

    location = {
      value: el.value,
      label: el.label,
    };

    this.props.dispatch(
      onAddFilter({
        location,
      })
    );
  }

  handleSearch(e) {
    const searchValue = e.target.value;
    this.setState({ searchValue });

    let results = [];
    copyData.forEach((el) => {
      if (
        JSON.stringify(el).toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1
      ) {
        results.push(el);
      }
    });

    this.setState({ data: results });
  }

  render() {
    return (
      <DropdownButton
        leftIcon={icons.location}
        text={language[this.props.defaultLanguage].location}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        selectedData={this.props?.filters?.location}
        clickOutside
      >
        <CardHeader
          title={language[this.props.defaultLanguage].locations}
          borderBottom
          // closeButton
          isSearch
        />
        <div className="dropdown-content">
          {this.state?.locations?.map((el, i) => {
            return (
              <Checkbox
                label={el.label}
                key={i}
                onCheck={() => this.onCheck(el)}
                checked={this.props?.filters?.location?.value === el.value}
              />
            );
          })}
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(LocationFilter);
