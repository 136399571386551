import React from "react";
import { DropdownButton } from "../Button";
import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import { Checkbox } from "../Input";
import { Loading } from "../Loader";

class CustomFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      isSubmitting: false,
      isResetting: false,
      selectedFilters: props.selectedData || [],
    };
  }

  handleTextChange(e) {
    this.setState({ value: e.target.value });
  }

  handleTextFilter(textFilter = "") {
    let results = this.props.data;
    if (!!textFilter) {
      results = results.filter(
        (item) =>
          item.toLowerCase().includes(textFilter.toLowerCase()) ||
          this.state.selectedFilters?.find(
            (sEl) =>
              (!!sEl?._id && sEl?._id === item?._id) ||
              (!sEl?._id && sEl === item)
          )
      );
    }
    return results;
  }

  onCheck(el) {
    let {
      selectedData,
      setSelectedData,
      isNotMultiple = false,
      resetValue = [],
    } = this.props;

    if (!!isNotMultiple) {
      setSelectedData(el);
      this.setState({ selectedFilters: el || resetValue });
      return;
    }
    let _selectedData = selectedData;

    const index = _selectedData.findIndex((sEl) => sEl === el);

    if (index < 0) {
      _selectedData.push(el);
    } else {
      _selectedData.splice(index, 1);
    }

    setSelectedData([..._selectedData]);
    this.setState({ selectedFilters: [..._selectedData] });
  }

  isChecked(el) {
    let { selectedData, isNotMultiple = false } = this.props;

    if (!!isNotMultiple) {
      return !!(
        (!!selectedData?.id && selectedData?.id === el?.id) ||
        selectedData === el
      );
    }

    return !!this.state.selectedFilters?.find(
      (sEl) => (!!sEl?._id && sEl?._id === el?.id) || sEl === el
    );
  }

  getSelectedValue() {
    let { isNotMultiple = false } = this.props;

    if (!!isNotMultiple) {
      return (
        this.state.selectedFilters?.name ||
        this.state.selectedFilters?.label ||
        this.state.selectedFilters ||
        ""
      );
    }
    return this.state.selectedFilters.map((item) => ({
      value: item?.name || item?.value || item?.label || item,
    }));
  }

  resetValue() {
    let { isNotMultiple = false, resetValue = [] } = this.props;

    if (!!isNotMultiple) {
      this.props.setSelectedData(resetValue);
      return;
    }
    this.props.setSelectedData(resetValue);
  }

  render() {
    return (
      <DropdownButton
        leftIcon={icons.filter}
        text={this.props.title || "Custom Filter"}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        selectedData={this.getSelectedValue()}
        clickOutside={!this.props.isNotMultiple}
      >
        <CardHeader
          title={this.props.title || "Custom Filter"}
          borderBottom
          closeButton
          // isSearch
          reset={
            this.state.selectedFilters && this.state.selectedFilters?.length > 0
          }
          handleResetFilter={this.resetValue}
          // handleSearch={this.handleTextChange}
        />
        <div className="dropdown-content">
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            this.handleTextFilter(this.state?.value)?.map((el, i) => {
              return (
                <Checkbox
                  label={el?.name || el}
                  key={i}
                  onCheck={() => this.onCheck(el)}
                  checked={this.isChecked(el)}
                  type={this.props.isNotMultiple ? "radio" : "checkbox"}
                />
              );
            })
          )}
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CustomFilter);
