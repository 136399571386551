import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import { apiInstance } from "../axiosInstance";

const fetchData = async (lng = "english", params = {}) => {
  try {
    let { page, limit, type, id, screen, serviceType, categories } = params;

    const user = await getStorage();

    const { accessRole } = user;

    const canRead = defineRole({
      roles: accessRole,
      menu: "Services",
      operation: "read",
    });

    if (!canRead && !user.role === "user") return;

    const categoryIds = categories?.map(
      (category) => category.value || category
    );

    let headers = {
      Authorization: `Bearer ${user.token}`,
    };

    if (screen === "default") {
      delete headers.Authorization;
    }

    const options = {
      method: "GET",
      url: `/service`,
      params: {
        page: page,
        limit: limit,
        id,
        type: serviceType,
        // institution: user.institution,
        category: categoryIds,
      },
      headers,
    };

    const queryRes = await apiInstance(options);

    let { data, count } = queryRes.data;

    let result = [];

    if (type === "dropdown") {
      result = formatSelectData(queryRes.data, "description", "_id");
    } else {
      result = !page && !limit ? queryRes.data : { data, count };
    }

    // if (accessRole?.name != "ALL ACCESS" && accessRole.name !="Farmer service center" (user.role === "account" || user.accountType === "account")) {
    //   const servicesData = handleServiceByAccessLevel(
    //     accessRole,
    //     result?.data || result
    //   );
    //   return id
    //     ? servicesData
    //     : {
    //       data: servicesData,
    //       count,
    //     };
    // }

    return result;
  } catch (error) {
    console.log(error);
    toastMessage("error", language[lng].unable_fetch_services);
  }
};

export default fetchData;

export const deleteService = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `/service`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await apiInstance(options);

    toastMessage("success", language[lng].success_delete_service);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_service);
  }
};

const handleServiceByAccessLevel = (accessRole, data) => {
  let result = [];
  data?.forEach((el) => {
    const canRead = defineRole({
      roles: accessRole,
      menu: el.type,
      operation: "read",
    });

    if (canRead) {
      result.push(el);
    }

    return result;
  });

  return result;
};
