import React from "react";

import { connect } from "react-redux";
import { Tabs } from "../Tabs";
import surveysQuery from "../../utils/queries/surveysQuery";
import FarmerSurveyAnswer from "./FarmerSurveyAnswer";
import capitalizeText from "../../utils/capitalizeText";
import { Button } from "../Button";
import { Select } from "../Input";
import { Modal } from "../Modal";
import language from "../../language";
import dynamicTableData from "../../utils/dynamicTableData";
import handleFormateDataCSV from "../../utils/handleFormateDataCSV";
import toastMessage from "../../utils/toastMessage";
import { CSVLink } from "react-csv";

class SingleFSC extends React.Component {
  state = {
    services: [],
    page: 1,
    limit: 10,
    farmerInfo: {},
    activeTab: "",
    csvData: [],
    servicesForDropdown: [],
  };

  componentDidMount = () => {
    this.getServices(true);
  };

  fetchData = (isLoading) => {
    this.getFarmers(isLoading);
    this.getCounts(isLoading, {
      fieldName: "totalYouthCounts",
      count: true,
    });

    this.getCounts(isLoading, {
      fieldName: "totalMaleYouthCounts",
      ["questionnaireAnswer.answer"]: ["Male", "M"],
      ["questionnaireAnswer.questionText"]: ["Age", "Gender"],
      ["startQuestionnaireAnswer.answer"]: "18",
      ["endQuestionnaireAnswer.answer"]: "35",
      count: true,
    });

    this.getCounts(isLoading, {
      fieldName: "totalFemaleYouthCounts",
      ["questionnaireAnswer.answer"]: ["Female", "F"],
      ["questionnaireAnswer.questionText"]: ["Age", "Gender"],
      ["startQuestionnaireAnswer.answer"]: "18",
      ["endQuestionnaireAnswer.answer"]: "35",
      count: true,
    });

    this.getCounts(isLoading, {
      fieldName: "totalNonYouthCounts",
      ["questionnaireAnswer.questionText"]: ["Age"],
      ["startQuestionnaireAnswer.answer"]: "36",
      count: true,
    });
  };

  returnFilters() {
    const { page, limit } = this.state;

    const { uniqueNumber } = this.props;

    let _filters = {
      page,
      limit:1000,
      ["questionnaireAnswer.answer"]: [
        uniqueNumber,
        uniqueNumber.toUpperCase(),
      ],
    };

    return _filters;
  }

  getServices = async (isLoadingFSC) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingFSC });

      const { data, count } = await surveysQuery(
        defaultLanguage,
        this.returnFilters()
      );

      console.log("count", count);
      console.log("data", data);

      let sortedOrder = [],
        serviceNames = [];

      data.forEach((el) => {

        if (el?.service?.type === "KPI") return;

        if (!serviceNames.includes(el?.service?.name)) {
          sortedOrder.push(el);
        }
        serviceNames.push(el?.service?.name);
      });

      const reversedData = sortedOrder.reverse();

      this.setState({
        services: reversedData,
        totalPageCount: count,
        isLoadingFSC: false,
        activeTab:
          reversedData.length > 0 && reversedData[0].service
            ? reversedData[0].service.name
            : "",
      });

      let servicesForDropdown = [];

      sortedOrder.forEach((el) => {
        servicesForDropdown.push({
          label: el.service?.name,
          value: el.service._id,
        });
      });

      this.setState({ servicesForDropdown, service: servicesForDropdown });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoadingFSC: false,
      });
    }
  };
  handleGetActiveTab(activeTab) {
    this.setState({ activeTab });
  }

  renderTabs() {
    return (
      <Tabs
        getActiveTab={this.handleGetActiveTab.bind(this)}
        options={this.state.services.map((item, key) => {
          return {
            title: capitalizeText(item.service?.name),
            data:
              item.service?.name?.toLowerCase() ===
                this.state?.activeTab?.toLowerCase() ? (
                <FarmerSurveyAnswer
                  key={key}
                  filters={{
                    ["questionnaireAnswer.answer"]: [this.props.uniqueNumber],
                    ["service._id"]: [item.service._id],
                  }}
                  activeTab={this.state.activeTab}
                  downloadExcel
                />
              ) : (
                <></>
              ),
          };
        })}
      />
    );
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleShowModal(modal, title) {
    this.setState({
      [modal]: true,
      modalTitle: title,
    });
  }

  onChangeText(field, e) {
    this.setState({ [field]: e });
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isExporting: true,
      });

      const { defaultLanguage } = this.props;
      const { service } = this.state;

      let serviceIds = service.map((el) => el.value);

      const { data } = await surveysQuery(defaultLanguage, {
        ["questionnaireAnswer.answer"]: [this.props.uniqueNumber],
        ["service._id"]: serviceIds,
      });

      let parentSurveyInfo = {};

      data.forEach((dataEl) => {
        if (service[0].value === dataEl.service._id) {
          parentSurveyInfo = dataEl;

          return;
        }
      });

      data.forEach((dataEl) => {
        if (parentSurveyInfo._id !== dataEl._id) {
          let { questionnaireAnswer } = parentSurveyInfo;

          parentSurveyInfo.questionnaireAnswer = questionnaireAnswer.concat(
            dataEl.questionnaireAnswer
          );
        }
      });

      if (!parentSurveyInfo._id)
        return toastMessage("error", "No survey information available");

      let responseData = [],
        formateData = {};

      parentSurveyInfo.questionnaireAnswer.map((el) => {
        formateData[el.questionText] = el.answer;
      });

      responseData.push(formateData);

      this.setState(
        {
          isExporting: false,
          csvData: responseData,
        },
        () => this.refs.csvDownload?.link.click()
      );
    } catch (error) {
      this.setState({
        isExporting: false,
      });
    }
  };

  render() {
    return (
      <>
        <Button
          text="Export All"
          onPress={() => this.handleShowModal("showExport", "Export Report")}
          isSubmitting={this.state.isExporting}
        />
        <br />
        {this.renderTabs()}

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showExport")}
          show={this.state.showExport}
          title={this.state.modalTitle}
        >
          <div className="card-body">
            <Select
              options={this.state.servicesForDropdown}
              label={language[this.props.defaultLanguage].service}
              value={this.state.service}
              onChange={(e) => this.onChangeText("service", e)}
              isMulti
              isLoading={this.state.isFetchingService}
            />
            <Button
              text={language[this.props.defaultLanguage].export_}
              isSubmitting={this.state.isExporting}
              onPress={this.downloadExcel.bind(this)}
            />
          </div>
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={`REPORT-${this.props.uniqueNumber}`}
          // headers={this.state.headers}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SingleFSC);
