import React from "react";

import { Login } from "../../../components/Login";
import { connect } from "react-redux";
class LoginScreen extends React.Component {
  render() {
    return (
      <>
        <Login />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateTopProps)(LoginScreen);
