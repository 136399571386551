import React from "react";
import "./styles.css";
import NotificationBadgeCount from "../Notifications/NotificationBadgeCount";

class DropdownButton extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };

    this.search_drop_ref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside.bind(this));

    const { isOpen } = this.props;
    if (isOpen) {
      this.setState({ isOpen });
    }
  }

  componentDidUpdate(nxtProps) {
    if (this.props.isOpen !== nxtProps.isOpen) {
      this.setState({ isOpen: nxtProps.isOpen });
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      "mousedown",
      this.handleClickOutside.bind(this)
    );
  }

  handleClickOutside(e) {
    if (
      this.search_drop_ref &&
      this.search_drop_ref.current &&
      !this.search_drop_ref.current.contains(e.target)
    ) {
      this.setState({
        isOpen: false,
      });
    }
  }

  handleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  onSelectDropdownItem(item) {
    if (this.props.isMulti) {
      let default_key = this.props.default_key
        ? this.props.default_key
        : "value";

      let new_item = this.props.selected_item;
      if (!this.props.selected_item.includes(item[default_key])) {
        new_item.push(item[default_key]);
      } else {
        new_item.splice(new_item.indexOf(item[default_key]), 1);
      }
      this.props.onSelectDropdownItem(new_item);

      return;
    }

    this.props.onSelectDropdownItem(item);

    this.setState({
      isOpen: false,
    });
  }

  onReset() {
    if (this.props.isMulti) {
      this.props.onSelectDropdownItem([]);
    } else {
      this.props.onSelectDropdownItem({});
    }

    this.props.handleReset && this.props.handleReset();

    this.setState({
      isOpen: false,
    });
  }

  returnLabels() {
    const { selectedData = [] } = this.props;

    let title = "";

    if (selectedData instanceof Array) {
      selectedData.forEach((el, i) => {
        if (el.label) {
          title += `${el.label} ${
            selectedData.length > 1 ? i !== selectedData.length - 1 && ", " : ""
          }`;
        }
      });
    } else {
      title = selectedData?.value || selectedData;
    }

    return title; //.substring(0, 20);
  }

  render() {
    let props = this.props;

    return (
      <>
        <div className="dropdown " style={{ position: "initial" }}>
          <button
            className={`btn btn-primary ${
              props.removeCaret ? "" : "dropdown-toggle"
            } dropdown-toggle-split ${props.className} ${
              props.isSubmitting && "btn-disabled"
            } ${this.props.hide && "hide"}`}
            id={props.id ? props.id : "dropdownMenuButton1"}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close={props.clickInside ? "inside" : "outside"}
            styles={props.styles}
          >
            <div
              onClick={this.handleOpen.bind(this)}
              style={{ display: "flex", alignItems: "center" }}
            >
              {(this.props.icon || this.props.leftIcon) && (
                <span
                  style={{ marginRight: ".3rem" }}
                  className={`${process.env.REACT_APP_ICONS_TYPE} icon`}
                >
                  {this.props.icon || this.props.leftIcon}
                </span>
              )}

              {this.props.leftImage && (
                <div className="left-img-container">
                  <img className="left-img" src={this.props.leftImage} />
                </div>
              )}

              <div
                style={{ paddingRight: ".5rem", display: "flex", gap: ".5rem" }}
              >
                <div>
                  <span className={` ${props.classNameButtonText}`}>
                    {props.text}
                    {props.selectedData ? ":" : ""}
                  </span>
                </div>
                {props.selectedData && (
                  <div className="button-selected_data-container">
                    <span className={` ${props.classNameButtonText} text-bold`}>
                      {props?.selectedData?.label || props.selectedData}
                    </span>
                  </div>
                )}
                {/* <span className="text-bold" style={{ marginRight: 5 }}>
                :
              </span> */}
                {this.props?.selectedData instanceof Array && (
                  <NotificationBadgeCount
                    count={this.props.selectedData.length}
                  />
                )}

                {/* {typeof this.props?.selectedData === "string" && (
                <span className="long-text">{this.props?.selectedData}</span>
              )} */}
              </div>
            </div>
          </button>
          <div
            className="dropdown-menu dropdown-menu-end"
            role="menu"
            aria-labelledby="dropdownMenuClickableInside"
          >
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

export default DropdownButton;
