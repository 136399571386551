import React from "react";
import { connect } from "react-redux";
import MultiAnswer from "./MultiAnswer";
import language from "../../language";
import RemoteDropdown from "./dynamicForm/RemoteDropdown";
import SingleInput from "./dynamicForm/SingleInput";
import { Button } from "../Button";
import icons from "../../constants/icons";
import toastMessage from "../../utils/toastMessage";
import { apiInstance } from "../../utils/axiosInstance";
import { getStorage } from "../../utils/storage";

class Questionnaire extends React.Component {
  state = {
    linkedElements: [],
    isRetrievingUserInfo: false,
    profileData: {},
    forms: [],
    isImporting: false,
  };

  componentDidMount() {
    console.log("Questionnaire props", this.props);
    const { form } = this.props;

    let forms = [form];

    this.setState({ forms });
  }

  handleDuplicateForm = async (e) => {
    e.preventDefault();
    let { forms } = this.state;
    const { form } = this.props;

    forms.push(form);

    await this.setState({
      forms,
    });

    this.props.updateNumberForms(forms.length);
  };

  handleDeleteForm(index) {
    let { forms } = this.state;

    forms.splice(index, 1);

    this.setState({
      forms,
    });
  }

  renderFormFooter(actions) {
    return (
      <div className={`d-flex gap-2`}>
        {actions.map((action, i) => {
          return (
            <Button
              key={i}
              className={`btn-default btn-${action.variant} btn-sm`}
              text={action.name}
              icon={action.icon}
              onPress={action.onPress}
            />
          );
        })}
      </div>
    );
  }

  handleToggleLinkedQuestionnaire = (element, linkedQuestionnaireId) => {
    const linkedQuestionnaire = this.props.form.find((q) => q._id === linkedQuestionnaireId);
    if (!linkedQuestionnaire) return;

    const newEntry = { element, linkedQuestionnaire };
    this.setState((prevState) => {
      const updatedElements = [...prevState.linkedElements, newEntry];

      // Ensure correct availability status for linked questionnaires
      updatedElements.forEach((entry) => {
        if (entry.element._id === element._id && entry.linkedQuestionnaire._id !== linkedQuestionnaire._id) {
          entry.linkedQuestionnaire.singleAnswer.available = false;
          linkedQuestionnaire.singleAnswer.available = true;
        }
      });

      return { linkedElements: updatedElements };
    });

    // Enable the linked questionnaire
    linkedQuestionnaire.singleAnswer.available = true;
  };

  handleImportData = async (e) => {
    try {
      this.setState({
        isImporting: true,
      });

      const file = e.target.files[0];
      const user = await getStorage();

      if (!file) {
        this.setState({
          isImporting: false,
        });
        return;
      }

      let headersList = {
        Accept: "/",
        Authorization: `Bearer ${user.token}`,
      };

      let bodyContent = new FormData();
      bodyContent.append("category", this.props.data.category._id);
      bodyContent.append("service", this.props.data._id);
      bodyContent.append("user", user.id);
      bodyContent.append("description", this.props.data.name);
      bodyContent.append("file", file);

      const options = {
        method: "POST",
        url: `/survey/import`,
        data: bodyContent,
        headers: headersList,
      };

      await apiInstance(options);

      toastMessage("success", "Data uploaded successfully");

      this.getData(true);

      this.setState({
        isImporting: false,
      });
    } catch (error) {
      this.setState({
        isImporting: false,
      });

      toastMessage("error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="card-header default">
          <div className="d-flex align-items-center gap-2 flex-wrap">
            <div className="flex-fill">
              <h3 className="text-primary">
                {
                  language[this.props.defaultLanguage]
                    .please_fill_the_form_below
                }
              </h3>
              <p>
                All field with <span className="text-danger">(*)</span> are
                mandatory
              </p>
            </div>
            <Button
              // selected={filter.selected}
              className={`btn-gray btn-sm import btn-transparent btn-bordered`}
              text={language[this.props.defaultLanguage].import_data}
              onPress={() => this.upload.click()}
              icon={"upload_file"}
              isSubmitting={this.state.isImporting}
            />
            <input
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              onChange={this.handleImportData.bind(this)}
              ref={(ref) => (this.upload = ref)}
              style={{ display: "none" }}
            />
          </div>
        </div>
        {this.state?.forms.map((parentForm, parentKey) => {
          return (
            <div key={parentKey}>
              <div className="row card-body">
                {this.props?.form?.map((el, inputIndex) => {
                  if (!el?.singleAnswer?.available) return;

                  if (
                    el.answerType === "multipleChoice" ||
                    el.answerType === "multipleAnswer"
                  ) {
                    return (
                      <>
                        <MultiAnswer
                          formKey={parentKey}
                          inputIndex={inputIndex}
                          {...this.props}
                          multiAnswer={el.multiAnswer}
                          questionText={el.questionText}
                          questionId={el._id}
                          answerType={el.answerType}
                          singleAnswer={el.singleAnswer}
                          onChange={(value) => {
                            this.props.onChange(value);
                            const selectedOption = el.singleAnswer?.moreOptions?.find(
                              (option) => option.questionGroupOption.name === value.value.name
                            );
                            if (selectedOption?.linkedQuestionnaire?.length) {
                              this.handleToggleLinkedQuestionnaire(el, selectedOption.linkedQuestionnaire[0].value);
                            }
                          }}
                          value={
                            this.props.questionnaireAnswer[parentKey] &&
                            this.props.questionnaireAnswer[parentKey][el._id]
                          }
                          error={this.props.error[el._id + "_" + parentKey]}
                          required={el?.singleAnswer?.required}
                        />
                      </>
                    );
                  }

                  if (el.answerType === "integration" && el.singleAnswer) {
                    return (
                      <div className="col-md-3">
                        <RemoteDropdown
                          formKey={parentKey}
                          questionText={el.questionText}
                          {...el.singleAnswer}
                          label={el.questionText}
                          responseKey={el.responseKey}
                          {...this.props}
                          questionId={el._id}
                          answerType={el.answerType}
                          onChange={this.props.onChange}
                          value={
                            this.props.questionnaireAnswer[parentKey] &&
                            this.props.questionnaireAnswer[parentKey][el._id]
                          }
                          error={this.props.error[el._id + "_" + parentKey]}
                          required={el?.singleAnswer?.required}
                        />
                      </div>
                    );
                  }
                  return (
                    <SingleInput
                      formKey={parentKey}
                      {...this.props}
                      questionText={el.questionText}
                      questionId={el._id}
                      answerType={el.answerType}
                      onChange={this.props.onChange}
                      value={
                        this.props.questionnaireAnswer[parentKey] &&
                        this.props.questionnaireAnswer[parentKey][el._id]
                      }
                      error={this.props.error[el._id + "_" + parentKey]}
                      required={el?.singleAnswer?.required}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Questionnaire);
