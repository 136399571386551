export default (filters) => {
  let results = {};

  if (filters.startDate) {
    results["startCreatedAt"] = filters.startDate;
  }

  if (filters.endDate) {
    results["endCreatedAt"] = filters.endDate;
  }

  if (filters.location) {
    results.location = filters.location.value;
  }

  if (filters.questionText) {
    results["questionnaireAnswer.questionText"] = filters.questionText;
  }

  if (filters.answer) {
    results["questionnaireAnswer.answer"] = filters.answer;
  }
  if (filters.startAnswer) {
    results["startQuestionnaireAnswer.answer"] = filters.startAnswer;
  }
  if (filters.endAnswer) {
    results["endQuestionnaireAnswer.answer"] = filters.endAnswer;
  }
  if (filters.service) {
    results["service._id"] = filters.service.value;
  }
  if (filters.advanced_search) {
    results.advanced_search = true;
  }

  return results;
};
