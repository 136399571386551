import { DragDropContext, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";
import "./styles.css";
import TableContainer from "./TableContainer";
import Wrapper from "./Wrapper";

export const useStrictDroppable = (loading) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    let animation;

    if (!loading) {
      animation = requestAnimationFrame(() => setEnabled(true));
    }

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, [loading]);

  return [enabled];
};

const DraggableTable = (props) => {
  const { data } = props;
  const currentData = data && data.data ? data.data : data;
  return (
    <Wrapper data={currentData} {...props}>
      <DragDropContext onDragEnd={props.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <>
              <table
                {...props}
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`table table-hover table-condensed ${
                  props.bordered && "table-bordered"
                } ${
                  snapshot.isDraggingOver
                    ? "drag-over-container"
                    : "drag-over-container"
                }`}
                draggable
                // style={`${props.style} ${{ width: "100%" }}`}
                style={{ width: "100%" }}
              >
                <TableContainer {...props} />
                {provided.placeholder}
              </table>
            </>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
};

export default DraggableTable;
