import language from "../language";

export default (lng, password = "") => {
  if (password.length < 8) {
    return language[lng].password_min_error;
  }
  if (!/[A-Z]/.test(password)) {
    return language[lng].password_contains_uppercase_letter;
  }

  if (!/[a-z]/.test(password)) {
    return language[lng].password_contains__lowercase_letter;
  }
  if (password.search(/[0-9]/) < 0) {
    return language[lng].password_contains_number;
  }
};
